import { useState } from "react"
import Swal from "sweetalert2"
import StringIntReq from "../../../../Requests/StringIntReq"
import CustomerApi from "../../../../API/CustomerApi"

export default function CustomFieldsList({ customFields,setCustomFields }) {
    

    // const deleteCustomField = (id) => {
    //     const req = new S
    // }

    const deleteCustomAttribute = async (id) => {
        
        const option = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "This action is irreversible",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        })

        if(!option.isConfirmed){
            return
        }

        const req = new StringIntReq()
        req.int = id;

        const res = await CustomerApi.DeleteCustomAttribute(req)
        if(res.status.success){
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Custom Field has been deleted"
            })
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message
            })
        }

    }

    return (
        <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data Type</th>
                            <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">For</th>
                            <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {customFields.map((field, index) => {
                            return <tr key={field.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{field.name}</td>
                                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{field.dataType}</td>
                                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{field.for}</td>
                                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
                                    <button
                                        className="text-red-600 hover:text-red-900 font-medium flex items-center"
                                        onClick={() => deleteCustomAttribute(field.id)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                        </svg>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
