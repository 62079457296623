import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';
import SearchFilters from './SearchFilters';
import CreateCandidateLead from './CreateCandidateLead';
import CandidateFilters from './CandidateSearchFilters';

const TabbedSearchFilters = ({ location, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCandidateTab, setShowCandidateTab] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };



    useEffect(() => {
      console.log("Recruit Mode:", props.recruitMode);
      setShowCandidateTab(props.recruitMode);
    }, [props.recruitMode]);


  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant={isMobile ? "fullWidth" : "standard"}
        centered={!isMobile}
        sx={{ mb: 2 }}
      >
        <Tab label="FiberHouse Filters" />
        {showCandidateTab  &&  <Tab label="Candidate Filters" />}
       
      </Tabs>

      <Box sx={{ p: 2 }}>
        {activeTab === 0 && (
          <SearchFilters
            {...props}
          />
        )}
        {activeTab === 1 && (
          <CandidateFilters 
            {...props}
          />
        )}
      </Box>
    </Box>
  );
};

export default TabbedSearchFilters;