import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import './index.scss';
import StringIntReq from "../../../Requests/StringIntReq";
import FiberCompanyApi from "../../../API/FiberCompanyApi";
import Swal from "sweetalert2";

function CalendlyUserSelection({ candidateId, onSchedule, firstInterviewCompleted = null, secondInterviewCompleted = null, type }) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [generatedLink, setGeneratedLink] = useState("");
    const [communicationTemplates, setCommunicationTemplates] = useState([])
    const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] = useState(null)

    const fetchCommunicationTemplates = async () => {
        try {
            let req = new StringIntReq()
            req.string = type
            req.category = "Schedule Interview"
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 1000;
            let response = await FiberCompanyApi.GetCommunicationTemplates(req);
            if (response.status.success) {
                const filteredTemplates = response.data.list;

                const formattedTemplates = filteredTemplates.map(template => ({
                    label: template.name + " | " + template.category,
                    value: template,
                }));
                setCommunicationTemplates(formattedTemplates);
                console.log(response.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCommunicationTemplates();
    }, []);

    // Hardcoded options for the two users
    const users = [
        {
            value: "https://calendly.com/jessica-ylwk/interview",
            label: "Jessica Man (jessica@lightningos.world)",
            type: "Recruit"
        },
        {
            value: "https://calendly.com/shasta-lightningos/interview",
            label: "Shasta Weishampel (shasta@lightningos.world)",
            type: "Company"
        }
    ];

    // Determine the interview round
    const determineInterviewRound = () => {
        if (!firstInterviewCompleted) {
            return "1";
        } else if (!secondInterviewCompleted) {
            return "2";
        }
    };

    // Generate Calendly link
    function handleGenerateLink() {
        if (!selectedUser) {
            alert("Please select a Calendly user.");
            return;
        }

        // Determine interview round
        const interviewRound = determineInterviewRound();

        if (interviewRound === "unknown") {
            alert("Both interviews are already completed.");
            return;
        }

        // Append candidateId and interview round to the URL
        let link = ``;
        link = `${selectedUser.value}?a2=${candidateId}&a3=${interviewRound}&a4=${type}`;
        if (type === "Company") {
            link = `${selectedUser.value}?a2=${candidateId}&a3=${type}`;
        }

        setGeneratedLink(link);
    }

    // Notify parent component with the generated link
    function handleSchedule() {
        if (generatedLink) {

            if(selectedCommunicationTemplate === null){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a template!',
                });
                return;
            }

            onSchedule(candidateId, generatedLink, selectedCommunicationTemplate);
        } else {
            alert("Please generate a link first.");
        }
    }

    return (
        <div className="calendly-user-selection">
            <div className="form-group">
                <label>Select a Calendly User</label>
                <Select
                    options={users.filter(user => user.type === type)}
                    value={selectedUser}
                    onChange={(selectedOption) => setSelectedUser(selectedOption)}
                    placeholder="Select a user"
                />
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateLink}
                fullWidth
                style={{ marginTop: "1rem" }}
            >
                Generate Link
            </Button>

            {generatedLink && (
                <div className="generated-link" style={{ marginTop: "1.5rem" }}>
                    <h3>Generated Link:</h3>
                    <a href={generatedLink} target="_blank" rel="noopener noreferrer">
                        {generatedLink}
                    </a>
                    <Select
                        options={communicationTemplates}
                        onChange={(selected) => {
                            setSelectedCommunicationTemplate(selected.value)
                            // setMessageText(selected.value.body)
                            // setSubject(selected.value.subject)
                        }}
                        // value={selectedCommunicationTemplate}
                        placeholder="Choose a template"
                        className="template-select"
                        menuPortalTarget={document.body} // renders the dropdown in the body element
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure a high z-index for the portal
                        }}
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSchedule}
                        fullWidth
                        style={{ marginTop: "1rem" }}
                    >
                        Send Schedule Interview
                    </Button>
                </div>
            )}
        </div>
    );
}

export default CalendlyUserSelection;
