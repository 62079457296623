class ReOrderTrainingModuleReq {
    constructor() {
        this.SubTrainingModuleId = null;
        this.SellingCardId = null;
        this.SellingCardNumber = null;
        this.SubTrainingModuleNumber = null;
        this.ParentSubTrainingModuleId = null;
        this.isSellingCard = null;
    }
}

export default ReOrderTrainingModuleReq;
