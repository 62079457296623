import { deleteObject, getStorage, ref } from 'firebase/storage';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import SellingCardApi from '../../../../API/SellingCardApi';
import RemoveFileReq from '../../../../Requests/CloudFile/RemoveFileReq';
import DeleteTrainingModule from '../../../../Requests/TrainingModule/DeleteTrainingModule';
import './trainingModule.scss';
import { MdDelete, MdDownload, MdEdit } from "react-icons/md";
import { UserContext } from '../../../../App';
import JSZip from 'jszip';
import GetTrainingModuleReq from '../../../../Requests/TrainingModule/GetTrainingModuleReq';
import { getIconForFirebaseFile, getIconForUploadedFile } from '../../../../Services/DisplayFile';
import { FaSave } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import EditTrainingModuleReq from '../../../../Requests/TrainingModule/EditTrainingModuleReq';
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const TrainingModule = ({ className, trainingModule, reload, setReload, sample }) => {
    const storage = getStorage();
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [edit, setEdit] = useState(false);
    const [image, setImage] = useState(null);
    const [name, setName] = useState(trainingModule.name);
    const [description, setDescription] = useState(trainingModule.description);

    function editClicked() {
        if (!sample)
            navigate("/manageTrainingModule/details", {
                state: {
                    id: trainingModule.id,
                    name: trainingModule.name,
                    description: trainingModule.description,
                    files: trainingModule.files
                }
            });
    }

    async function updateTrainingModule() {
        Swal.fire({
            title: 'Updating Training Module',
            text: 'Please wait a few seconds.',
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const req = new EditTrainingModuleReq();
        req.id = trainingModule.id;
        req.name = name;
        req.description = description;

        if (image) {
            if (trainingModule.files.length > 0) {
                const fileRef = ref(storage, trainingModule.files[0].path);
                await deleteObject(fileRef);
            }
            let downloadURLs = [];
            let filePaths = [];
            const uploadAndGetData = async (file, type) => {
                const uniqueId = uuidv4();
                let p = `/trainingModules/${user.firebaseId}/${uniqueId}`;

                const storageRef = ref(storage, p);
                const task = uploadBytesResumable(storageRef, file);

                task.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        Swal.update({
                            text: `Uploading File ${progress.toFixed(0)}%`,
                            customClass: { container: 'custom-swal' }
                        });
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                            default:
                                console.log('Unknown state: ' + snapshot.state);
                                break;
                        }
                    },
                    (error) => {
                        console.log(error);
                    },
                    async () => {
                        console.log('Upload is completed');
                        const downloadURL = await getDownloadURL(storageRef);
                        downloadURLs.push(downloadURL);
                        console.log(downloadURL);
                        filePaths.push(p);
                        const createFile = new CreateFile();
                        createFile.name = file.name;
                        createFile.extension = createFile.name.split('.').pop();
                        createFile.url = downloadURLs[0];
                        createFile.path = filePaths[0];
                        createFile.fileSize = file.size.toString();
                        createFile.userId = user.id;
                        createFile.trainingModuleId = trainingModule.id;
                        createFile.caption = "123";
                        createFile.fileType = file.type;
                        req.file = createFile;
                        const res = await SellingCardApi.EditTrainingModule(req);
                        if (res?.status?.success) {
                            Swal.fire({
                                title: 'Success',
                                text: 'Training Module Updated',
                                icon: 'success',
                                didOpen: () => {
                                    Swal.hideLoading();
                                }
                            });
                            setReload(!reload);
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: res.status.message,
                                icon: 'error',
                                didOpen: () => {
                                    Swal.hideLoading();
                                }
                            });
                        }
                    }
                );
            };
            uploadAndGetData(image, 'image');
        } else {
            const res = await SellingCardApi.EditTrainingModule(req);
            if (res?.status?.success) {
                Swal.fire({
                    title: 'Success',
                    text: 'Training Module Updated',
                    icon: 'success',
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                setReload(!reload);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res.status.message,
                    icon: 'error',
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        }
    }

    const onDownload = async () => {
        Swal.fire({
            title: 'Downloading',
            text: 'Please wait a few seconds.',
            icon: 'info',
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const jszip = new JSZip();

        const req = new GetTrainingModuleReq();
        req.int = trainingModule.id;
        const res = await SellingCardApi.GetTrainingModule(req);
        if (res?.status?.success) {
            if (res.data.files.length === 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'The Training Module does not contain any files.',
                    icon: 'error',
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }
            console.log('im data', res.data);
            console.log('im data length', res.data.files.length);
            for (let i = 0; i < res.data.files.length; i++) {
                const file = res.data.files[i];
                if (file) {
                    const url = file.url;
                    const filename = file.name;
                    const blob = await fetch(url).then(res => res.blob());

                    const folder = jszip.folder("Module" + res.data.name.toString());
                    folder.file(filename, blob);
                    folder.file("Description.txt", res.data.description);
                }

                console.log('im i', i);
                if (i === res.data.files.length - 1) {
                    console.log('im innn');
                    jszip.generateAsync({ type: "blob" }).then(content => {
                        if (content) {
                            const url = URL.createObjectURL(content);
                            const a = document.createElement("a");
                            a.href = url;
                            a.download = res.data.name + ".zip";
                            a.click();
                            URL.revokeObjectURL(url);
                            Swal.fire({
                                title: 'Downloaded',
                                text: 'The Training Module has been downloaded.',
                                icon: 'success',
                                didOpen: () => {
                                    Swal.hideLoading();
                                }
                            });
                        }
                    });
                }
            }
        }
    };

    function deleteTrainingModule(trainingModule) {
        if (!sample)
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Deleting",
                        text: "Please wait",
                        allowOutsideClick: false,
                        icon: "info",
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    if (trainingModule.subTrainingModules?.length > 0) {
                        trainingModule.subTrainingModules.forEach(async (subModule) => {
                            if (subModule.file) {
                                const fileRef = ref(storage, subModule.file.path);
                                try {
                                    await deleteObject(fileRef);
                                    const req = new RemoveFileReq();
                                    req.int = subModule.file.id;
                                    const res = await SellingCardApi.RemoveFile(req);
                                    if (res?.status?.success) {
                                    }
                                } catch (error) {
                                    console.error('An error occurred!', error);
                                }
                            }
                        });
                    }

                    if (trainingModule.files.length > 0) {
                        const deleteFiles = async () => {
                            for (const f of trainingModule.files) {
                                const fileRef = ref(storage, f.path);
                                try {
                                    await deleteObject(fileRef);
                                    const req = new RemoveFileReq();
                                    req.int = f.id;
                                    const res = await SellingCardApi.RemoveFile(req);
                                    if (res?.status?.success) {
                                    }
                                } catch (error) {
                                    console.error('An error occurred!', error);
                                }
                            }
                        };
                        deleteFiles().then(() => {
                            let req = new DeleteTrainingModule();
                            req.int = trainingModule.id;

                            SellingCardApi.DeleteTrainingModule(req).then(
                                (response) => {
                                    if (response.status.success) {
                                        Swal.fire({
                                            title: 'Deleted!',
                                            text: 'The Training Module has been deleted.',
                                            icon: 'success',
                                            didOpen: () => {
                                                Swal.hideLoading();
                                            }
                                        });
                                        setReload(!reload);
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error',
                                            text: response.status.message,
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            allowEnterKey: false,
                                            showConfirmButton: true,
                                            didOpen: () => {
                                                Swal.hideLoading();
                                            }
                                        });
                                    }
                                }
                            );
                        });
                    } else {
                        let req = new DeleteTrainingModule();
                        req.int = trainingModule?.id;
                        SellingCardApi.DeleteTrainingModule(req).then(
                            (response) => {
                                if (response.status.success) {
                                    Swal.fire({
                                        title: 'Deleted!',
                                        text: 'The Training Module has been deleted.',
                                        icon: 'success',
                                        didOpen: () => {
                                            Swal.hideLoading();
                                        }
                                    });
                                    setReload(!reload);
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: response.status.message,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                        showConfirmButton: true,
                                        didOpen: () => {
                                            Swal.hideLoading();
                                        }
                                    });
                                }
                            }
                        );
                    }
                }
            });
    }

    return (
        <>
            {edit && (
                <div className='edit_module'>
                    <h1>Edit Thumbnail</h1>
                    <label htmlFor="upload_file" className='edit_module_image'>
                        {image ? getIconForUploadedFile(image) : trainingModule?.files?.length > 0 ? getIconForFirebaseFile(trainingModule?.files[0]) : <h1>No Image</h1>}
                    </label>
                    <input type="file" id="upload_file" style={{ display: "none" }} onChange={(e) => { setImage(e.target.files[0]) }} />
                    <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                    <textarea value={description} rows={3} onChange={(e) => { setDescription(e.target.value) }} />
                    <div className='button_div'>
                        <button onClick={updateTrainingModule}><FaSave color='white' size={30} /></button>
                        <button onClick={() => { setEdit(false) }}><IoClose color='white' size={30} /></button>
                    </div>
                </div>
            )}
            <div className='trainingModule' onClick={() => { if (!sample) navigate(`/manage-training-modules/training-module-details/${trainingModule.id}`) }} style={{ boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.10)" }}>
                {trainingModule?.files?.map((file, index) => (
                    <div key={index} className='trainingModuleImage'>
                        {getIconForFirebaseFile(file)}
                    </div>
                ))}
                {trainingModule?.files?.length === 0 && (
                    <div style={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #d3d3d3",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                    }}>
                        No Image
                    </div>
                )}
                <div className='headingAndDownload'>
                    <h3>{trainingModule.name}</h3>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        {(user?.userType === "SuperAdmin" || user?.userType === "TeamAdmin") && <MdDownload onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDownload();
                        }} size={30} color='#2761D0' style={{ cursor: "pointer" }} />}
                        {user.userType === "SuperAdmin" && <MdEdit size={30} color='#2761D0' style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEdit(true) }} />}
                        {user.userType === "SuperAdmin" && <MdDelete size={30} color='#2761D0' style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteTrainingModule(trainingModule) }} />}
                    </div>
                </div>
                <p>{trainingModule.description}</p>
            </div>
        </>
    );
};

export default TrainingModule;