import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card, CardContent, Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box, Grid } from '@mui/material';
import Swal from 'sweetalert2';
import PublicLayout from '../../../../Layouts/PublicLayout';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import map from './../../Opportunity/images/opportunity_map.png';
import logo from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png";
import CreateJobApplicationReq from '../../../../Requests/Users/CreateJobApplicationReq';
import CreateFileReq from '../../../../Requests/CloudFile/CreateFileReq';
import UsersApi from '../../../../API/UsersApi';
import './index.scss';
import { useParams } from 'react-router-dom';
import StringIntReq from '../../../../Requests/StringIntReq';
import VersatileLoader from './../../../../components/VersatileLoader';

const RecruitInvitation = () => {
    const { id } = useParams();
    const [isAccepted, setIsAccepted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [unitNo, setUnitNo] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [outOfState, setOutOfState] = useState(null);
    const [preferToTravel, setPreferToTravel] = useState(null);
    const [haveDriverLicense, setHaveDriverLicense] = useState(null);
    const [workSchedule, setWorkSchedule] = useState(null);
    const [levelOfD2DSale, setLevelOfD2DSale] = useState(null);
    const [payReference, setPayReference] = useState(null);
    const [financialTarget, setFinancialTarget] = useState(null);
    const [message, setMessage] = useState('');
    const [resume, setResume] = useState(null);
    const [how, setHow] = useState('');
    const [other, setOther] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [preferredInterviewDate, setPreferredInterviewDate] = useState('');

    const [jobDescription, setJobDescription] = useState(null);
    const [requirements, setRequirements] = useState([]);
    const [position, setPosition] = useState(null);
    const [candidateName, setCandidateName] = useState(null);
    const [templateImage, setTemplateImage] = useState(null);



    //get candidate invitation by id
    const getCandidateInvitation = async () => {
        let req = new StringIntReq();
        req.string = id;
        const res = await UsersApi.GetCandidateInvitationById(req);

        if (res?.status?.success) {
            const { data } = res;
            setJobDescription(data.candidateRecruitTemplate.jobDescription ? data.candidateRecruitTemplate.jobDescription : "Join us as a Fiber Consultant, where you'll play a vital role in promoting Lightning OS fiber internet solutions through door-to-door visits, forging strong connections with potential customers. Collaborating closely with our sales team, you'll surpass targets and drive sustainable revenue growth. This role offers an exciting chance to make an impact and grow with us. Apply now to join our dynamic team!");
            setRequirements(data.candidateRecruitTemplate.jobRequirements.split('\n') ? data.candidateRecruitTemplate.jobRequirements.split('\n') : ["Must be 18 years or older", "Must have a valid driver's license", "Must have a reliable vehicle", "Must be able to work in the US", "Must be able to pass a background check", "Must be able to work in a fast-paced"]);
            setPosition(data.candidateRecruitTemplate.positionTitle ? data.candidateRecruitTemplate.positionTitle : "Fiber Consultant");
            setTemplateImage(data.candidateRecruitTemplate.templateImage.url);

            setCandidateName(data.candidate.name);
            setPhoneNumber(data.candidate.phone);
            setEmail(data.candidate.personalEmail);
            setIsAccepted(data.accepted);

            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCandidateInvitation();
    }, []);



    const storage = getStorage();

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'Name': setFirstName(value); break;
            case 'email': setEmail(value); break;
            case 'streetAddress': setStreetAddress(value); break;
            case 'unitNo': setUnitNo(value); break;
            case 'city': setCity(value); break;
            case 'state': setState(value); break;
            case 'zipCode': setZipCode(value); break;
            case 'outOfState': setOutOfState(value); break;
            case 'preferToTravel': setPreferToTravel(value); break;
            case 'haveDriverLicense': setHaveDriverLicense(value); break;
            case 'workSchedule': setWorkSchedule(value); break;
            case 'levelOfD2DSale': setLevelOfD2DSale(value); break;
            case 'payReference': setPayReference(value); break;
            case 'financialTarget': setFinancialTarget(value); break;
            case 'message': setMessage(value); break;
            case 'how': setHow(value); break;
            case 'other': setOther(value); break;
            case 'preferredInterviewDate': setPreferredInterviewDate(value); break;
            default: break;
        }
    };

    const searchLocationDetails = async (address) => {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0`;
        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();
            if (response.status === 200 && data.status === "OK") {
                const { lat, lng } = data.results[0].geometry.location;
                return { coordinates: [lng, lat], address: address };
            } else {
                console.log("Error", data.error_message);
                return { coordinates: null, address: address };
            }
        } catch (error) {
            console.error(error);
            return { coordinates: null, address: address };
        }
    };

    const submitApplication = async () => {
        Swal.fire({
            icon: "info",
            title: "Submitting Application",
            text: "Please wait...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!phoneNumber) {
            Swal.fire({
                icon: "error",
                title: "Phone Number is required",
                text: "Please enter your phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        


        if (phoneNumber.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Invalid Phone Number",
                text: "Please enter a valid phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!email) {
            Swal.fire({
                icon: "error",
                title: "Email is required",
                text: "Please enter your email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        let emailRegex = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w+)+$/;
        if (!emailRegex.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                text: "Please enter a valid email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!city) {
            Swal.fire({
                icon: "error",
                title: "City is required",
                text: "Please enter your city",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!state) {
            Swal.fire({
                icon: "error",
                title: "State is required",
                text: "Please enter your state",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!outOfState) {
            Swal.fire({
                icon: "error",
                title: "Please specify if you are open to travel out of state",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!preferToTravel) {
            Swal.fire({
                icon: "error",
                title: "Please specify if you prefer not to travel",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!haveDriverLicense) {
            Swal.fire({
                icon: "error",
                title: "Please specify if you have a valid driver's license",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!workSchedule) {
            Swal.fire({
                icon: "error",
                title: "Work Schedule is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!levelOfD2DSale) {
            Swal.fire({
                icon: "error",
                title: "Level of Door-to-Door Sales Experience is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!payReference) {
            Swal.fire({
                icon: "error",
                title: "Pay Reference is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!financialTarget) {
            Swal.fire({
                icon: "error",
                title: "Financial Target is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateJobApplicationReq();
        req.candidateName = candidateName;
        req.email = email;
        req.phone = phoneNumber;
        req.address = streetAddress;
        req.unitNumber = unitNo;
        req.city = city;
        req.state = state;
        req.travelOutOfState = outOfState === 'Yes' ? true : false;
        req.preferNotToTravel = preferToTravel === 'Yes' ? true : false;
        req.validDriverLicense = haveDriverLicense === 'Yes' ? true : false;
        req.workSchedule = workSchedule;
        req.experience = levelOfD2DSale;
        req.payReference = payReference;
        req.payExpectation = financialTarget;
        req.zipCode = zipCode;
        req.message = message;
        req.position = position;

        if (resume) {
            const file = new CreateFileReq();
            file.name = resume.name;
            const uniqueId = uuidv4();
            const storageRef = ref(storage, `/job-application/${uniqueId}/${file.name}`);
            await uploadBytes(storageRef, resume);
            const downloadURL = await getDownloadURL(storageRef);
            file.url = downloadURL;
            file.path = `/job-application/${uniqueId}/${file.name}`;
            file.extension = file.name.split('.').pop();
            file.fileSize = resume.size.toString();
            file.sellingCardId = 1;
            file.caption = "123";
            file.fileType = resume.type;
            req.file = file;
        }

        const location = `${streetAddress} ${city} ${state}`;
        const details = await searchLocationDetails(location);
        if (details.coordinates) {
            req.coordinate = {
                longitude: details.coordinates[0],
                latitude: details.coordinates[1]
            };
        }
        if (details.address) {
            req.mapString = details.address;
        }
        req.candidateInvitationGuid = id;
        console.log(req);
        const res = await UsersApi.CreateCandidateInviteApplication(req);
        if (res?.status?.success) {
            setIsAccepted(true);
            Swal.fire({
                icon: "success",
                title: "Application Submitted",
                text: "Thank you for applying",
                customClass: {
                    container: 'custom-swal'
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                }
            });
        }
    };


    if (isAccepted) {
        return (
            <PublicLayout>
                <Box className='thank_you_container'>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="h1" align="center" gutterBottom>
                                Thank you for your submission!
                            </Typography>
                            <Typography variant="body1" align="center">
                                Your job application has been successfully submitted. We appreciate your interest in joining our team.
                            </Typography>
                            <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
                                Our team will review your application and get back to you soon.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout>
            <Box className='opportunity_container'>
                {!isLoading ?

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card className='job_description'>
                            <CardContent>
                                <Typography variant="h2">Hey There, {candidateName} </Typography>
                                <Typography variant="h5" component="h3" gutterBottom>Job Description</Typography>
                                <Typography variant="body1" paragraph>{jobDescription}</Typography>
                                <Typography variant="h5" component="h3" gutterBottom>Requirements and Responsibilities</Typography>
                                <Box className='requirements'>
                                    {requirements.map((requirement, index) => (
                                        <Box key={index} className='requirement_item'>
                                            <Box className='icon_wrapper'>
                                                <FaCheck color='#2761D0' size={20} />
                                            </Box>
                                            <Typography variant="body1">{requirement}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box component="img" src={templateImage} alt="map" className='location_map' />
                                <Typography variant="body1" align="center" color="#2761D0">Stay Tuned For More Upcoming Positions</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card className='job_application_form'>
                            <CardContent className='form_content'>
                                <Box component="img" src={logo} alt='Lightning_Logo' className='company_logo' />
                                {/* <Typography variant="h4" component="h1" align="center" gutterBottom color={"black"}>Job Application</Typography> */}
                                <Box className='form_fields'>
                                    <Typography variant="h6" component="h3">Contact Information</Typography>
                                    <div className="input-group">
                                        <label htmlFor="firstName">Name</label>
                                        <input type="text" id="firstName" name="Name" value={candidateName} onChange={handleChange} required disabled/>
                                    </div>
                                    <PhoneInput
                                        containerClass='phone_input_container'
                                        inputClass='phone_input'
                                        country={'us'}
                                        value={phoneNumber}
                                        onChange={(phone) => setPhoneNumber(phone)}
                                    />
                                    <div className="input-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" value={email} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="streetAddress">Street Address</label>
                                        <input type="text" id="streetAddress" name="streetAddress" value={streetAddress} onChange={handleChange} />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="unitNo">Unit #</label>
                                        <input type="text" id="unitNo" name="unitNo" value={unitNo} onChange={handleChange} />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="city">City</label>
                                        <input type="text" id="city" name="city" value={city} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="state">State</label>
                                        <input type="text" id="state" name="state" value={state} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="zipCode">Zip Code</label>
                                        <input type="text" id="zipCode" name="zipCode" value={zipCode} onChange={handleChange} />
                                    </div>

                                    <Typography variant="h6" component="h3">Questions</Typography>
                                    {[
                                        { label: "Open to travel out of state? (our blitz model)", name: "outOfState", value: outOfState, setter: setOutOfState },
                                        { label: "Prefer not to travel", name: "preferToTravel", value: preferToTravel, setter: setPreferToTravel },
                                        { label: "Do you have a valid driver's license?", name: "haveDriverLicense", value: haveDriverLicense, setter: setHaveDriverLicense }
                                    ].map(({ label, name, value, setter }) => (
                                        <FormControl key={name} component="fieldset" fullWidth>
                                            <FormLabel component="legend">{label}</FormLabel>
                                            <RadioGroup row name={name} value={value} onChange={(e) => setter(e.target.value)}>
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    ))}

                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">What work schedule are you looking for?</FormLabel>
                                        <RadioGroup row name="workSchedule" value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)}>
                                            {['Full Time', 'Part Time', 'Blitz Model'].map((schedule) => (
                                                <FormControlLabel key={schedule} value={schedule} control={<Radio />} label={schedule} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">What level of door-to-door sales experience do you have?</FormLabel>
                                        <RadioGroup row name="levelOfD2DSale" value={levelOfD2DSale} onChange={(e) => setLevelOfD2DSale(e.target.value)}>
                                            {['Beginner', 'Intermediate', 'Expert', 'Pro'].map((level) => (
                                                <FormControlLabel key={level} value={level} control={<Radio />} label={level} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">What is your pay reference?</FormLabel>
                                        <RadioGroup row name="payReference" value={payReference} onChange={(e) => setPayReference(e.target.value)}>
                                            {['Commission', 'Salary'].map((pay) => (
                                                <FormControlLabel key={pay} value={pay} control={<Radio />} label={pay} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">What is your financial target?</FormLabel>
                                        <RadioGroup row name="financialTarget" value={financialTarget} onChange={(e) => setFinancialTarget(e.target.value)}>
                                            {['$3K - $5K', '$5K - $8K', '$8K - $12K', '$12K - $18K', '$18K - $30K', '$30K +'].map((target) => (
                                                <FormControlLabel key={target} value={target} control={<Radio />} label={target} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                    <div className="input-group">
                                        <label htmlFor="message">Message to Lightning Executive Team (Optional)</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={4}
                                            value={message}
                                            onChange={handleChange}
                                            className="full-width-textarea"
                                        />
                                    </div>

                                    <FormControl fullWidth>
                                        <FormLabel component="legend">Resume</FormLabel>
                                        <input
                                            type="file"
                                            onChange={(e) => setResume(e.target.files[0])}
                                        />
                                    </FormControl>

                                    {/* <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">How did you hear about us?</FormLabel>
                                        <RadioGroup row name="how" value={how} onChange={(e) => setHow(e.target.value)}>
                                            {['LinkedIn', 'Facebook', 'Instagram', 'Google', 'Referral', 'Other'].map((val) => (
                                                <FormControlLabel key={val} value={val} control={<Radio />} label={val} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                    {how === 'Other' && (
                                        <div className="input-group">
                                            <label htmlFor="other">Please specify</label>
                                            <input type="text" id="other" name="other" value={other} onChange={handleChange} />
                                        </div>
                                    )} */}
                                </Box>
                            </CardContent>
                            <Box className='form_footer'>
                                <div className="input-group datetime-picker">
                                    <label htmlFor="preferredInterviewDate">Preferred Interview Date/Time</label>
                                    <input
                                        type="datetime-local"
                                        id="preferredInterviewDate"
                                        name="preferredInterviewDate"
                                        value={preferredInterviewDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <Box className='submit_button_container'>
                                    <Button variant="contained" color="primary" onClick={submitApplication} fullWidth>Submit Application</Button>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
                :                     <VersatileLoader size='large' color='#fff' />
                
                }
            </Box>
        </PublicLayout>
    );
};

export default RecruitInvitation;