/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
// import Datatable from "./../../../components/Datatable"
import "./LeaderBoard.scss"
// import { FaFilter, FaSearch } from "react-icons/fa";
import SearchSalesOrgLeaderboardReq from '../../../Requests/Organizations/SearchSalesOrgLeaderboardReq';
import OrgApi from '../../../API/OrgApi';
// import OrganizationSelector from './../../../components/OrganizationSelector';
// import UserSelector from './../../../components/UserSelector';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Pagination } from '@mui/material';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import { PaginationItem } from '@mui/material';
// import QuickDate from './../../../components/QuickDate';
import anonUser from './../../../assets/images/userGrey.png';
import { MdExpandMore } from 'react-icons/md';
import { UserContext } from '../../../App';
import QuickDate from '../../../components/QuickDate';
import { useNavigate } from 'react-router-dom';
import FiberCompanySelector from "./../../../components/FiberCompanySelector";


const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const Index = () => {

    const user = useContext(UserContext)
    // receiving an error when using the getSalesRepLeaderboard API
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [list, setList] = useState([]);
    // const [selectedTab, setSelectedTab] = useState("SalesRep");
    const [loading, setLoading] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [installCompany, setInstallCompany] = React.useState('')


    const currentDay = new Date();
    const firstDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - (currentDay.getDay() + 6) % 7));
    const lastDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 7));
    let from = new Date(firstDayOfWeek.setHours(0, 0, 0, 0));
    let to = new Date(lastDayOfWeek.setHours(23, 59, 59, 999));

    const [fromDate, setFromDate] = useState(formatDateToISOString(from));
    const [toDate, setToDate] = useState(formatDateToISOString(to));
    const [quickDate, setQuickDate] = useState("This Week");
    // const [selectedSalesRep, setSelectedSalesRep] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // const [expanded, setExpanded] = useState(false);
    const [rankedBy, setRankedBy] = useState("Sales");
    const [reset, setReset] = useState(1);
    const navigate = useNavigate();

    const getleaderBoardData = async () => {
        setLoading(true);
        const req = new SearchSalesOrgLeaderboardReq();
        let res = null;
        if (user.userType === "SalesOrgAdmin") {
            if (selectedOrg)
                req.salesOrgIds = Array.isArray(selectedOrg) ? selectedOrg : [selectedOrg];
            else {
                req.salesOrgIds = [];
            }
        }
        else {
            if (installCompany?.length > 0) {
                req.fiberCompanyIds = installCompany;
            }
            req.salesOrgId = Array.isArray(selectedOrg) ? selectedOrg[0] : selectedOrg;
        }
        // let from = new Date(new Date(fromDate).setHours(0, 0, 0, 0));
        // let to = new Date(new Date(toDate).setHours(23, 59, 59, 999));
        req.from = fromDate !== "" ? fromDate : null;
        req.to = toDate !== "" ? toDate : null;
        req.rankedBy = rankedBy;
        // req.salesRepIds = selectedSalesRep;
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 1000;
        if (user.userType === "SalesOrgAdmin" || user.userType === "SuperAdmin") {
            console.log(req);
            res = await OrgApi.SearchSalesOrgLeaderboard(req);
        }
        else {
            req.salesRepId = user.id;
            req.salesOrgId = user.salesOrgId;
            res = await OrgApi.SearchSalesRepLeaderboard(req);
        }
        if (res?.status?.success) {
            console.log(res.data);
            setTotalPages(res.data.totalPages);

            setLeaderBoardData(res.data.entries);

            setLoading(false);
        }
    }

    // useEffect(
    //     () => {
    //         if (user.userType === "SalesOrgAdmin") {
    //             setSelectedOrg([]);
    //         }
    //         else {
    //             setSelectedOrg(null);
    //         }
    //         setCurrentPage(1);
    //         setTotalPages(1);
    //         setList([])
    //         getleaderBoardData();
    //     }, [selectedTab]
    // )

    useEffect(
        () => {
            setList([]);
            getleaderBoardData();
        }, [currentPage, rankedBy, fromDate, toDate, installCompany]
    )




    useEffect(
        () => {
            if (user.userType === "SalesOrgAdmin" || user.userType === "SuperAdmin") {
                const temp = [];
                leaderBoardData.map((data, index) => {
                    let rank = index + 1;
                    temp.push({
                        rank: <p style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold"
                        }}>{'#' + rank}</p>,
                        fiberCompany: <p style={{
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "underline",
                            // width: "100%",
                        }} onClick={() => {
                            setSelectedOrg(data.salesOrg.id);
                            // setSelectedTab("SalesRep");
                        }}  >{data?.salesOrg?.name}</p>,
                        noOfReps: data?.salesRepList?.length,
                        AvgSales: Math.ceil(data.salesCount / data.salesRepList?.length),
                        salesCount: data.salesCount,
                        ds: data.ds,
                        di: data.di,
                        dc: data.dc,
                        pi: data.pi,
                        cs: data.cs,
                        v: data.v,
                        nv: data.nv,
                        percentage: data.di === 0 ? 0 + "%" : (data?.di / (data.di + data.dc)) * 100 + "%",
                        ...data,
                        salesRepList: rankedBy === "Sales" ? data.salesRepList.sort((a, b) => b.salesCount - a.salesCount) : data.salesRepList.sort((a, b) => b._DI - a._DI)
                    })
                })

                if (rankedBy === "Sales") {
                    temp.sort((a, b) => b.salesCount - a.salesCount)
                }
                else {
                    temp.sort((a, b) => b.di - a.di)
                }

                setList(temp);
            }
            else {
                let temp = [];
                leaderBoardData.map((data, index) => {
                    let rank = index + 1;
                    temp.push({
                        rank: <p style={{
                            fontWeight: "bold"
                        }}>{'#' + rank}</p>,
                        salesRepName: data?.salesRep?.firstName + " " + data?.salesRep?.lastName,
                        location: data?.salesRep?.location,
                        salesCount: data?.salesCount,
                        profileImage: data?.salesRep?.profileImage ? data?.salesRep.profileImage.url : anonUser,
                        _DS: data?.ds,
                        _DI: data?.di,
                        _DC: data?.dc,
                        _PI: data?.pi,
                        _CS: data?.cs,
                        _V: data?.v,
                        _NV: data?.nv,
                        percentage: data.di === 0 ? 0 + "%" : ((data?.di / (data.di + data.dc)) * 100).toFixed(1) + "%"
                    })
                })

                if (rankedBy === "Sales") {
                    temp.sort((a, b) => b.salesCount - a.salesCount)
                }
                else {
                    temp.sort((a, b) => b._DI - a._DI)
                }
                setList(temp);
            }
        }, [leaderBoardData]
    )

    const rankStyle = (rank) => {
        // if (rank === 1) {
        //     return {
        //         color: "gold",
        //         fontWeight: "bold"
        //     }
        // }
        // else if (rank === 2) {
        //     return {
        //         color: "silver",
        //         fontWeight: "bold"
        //     }
        // }
        // else if (rank === 3) {
        //     return {
        //         color: "#cd7f32",
        //         fontWeight: "bold"
        //     }
        // }
        // else {
        return {
            color: "black",
            fontWeight: "bold"
        }
        // }
    }

    const salesOrgLeaderboard = () => {

        return <div className='sales_org_leaderboard'>
            <div style={{
                // background: `linear-gradient(45deg, #41b6e6 12%,#fff 12%)`,
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px 0px #41b6e6",
                padding: "1rem",
            }} className='sales_org_leaderboard_item'>
                <p style={{
                    color: "black",
                    fontWeight: "bold"
                }}>Rank</p>
                <p style={{
                    color: "black",
                    fontWeight: "bold"
                }}>Name</p>
                <p style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold"
                }}>No. of Reps</p>
                <p style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold"
                }}>AVG {rankedBy === "Sales" ? "Sale" : "Install"}/REPS</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>{rankedBy === "Sales" ? "Total Sales" : "Total Installs"}</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>Currently Sold</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>Verified</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>Not Verified</p>

                
                <div className='equation'>
                    <p>DS</p>
                    <p>PI</p>
                    <p>DI</p>
                    {/* <p style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem"
                    }}>Ratio</p> */}
                </div>
            </div>
            {
                // list>0 &&
                // selectedTab === "Organizations" &&
                list.filter(o => !o.salesOrg?.name?.includes("Test")).map(
                    (org, index) => {
                        console.log(org.salesRepList.length)
                        return <Accordion key={index} sx={{
                            borderRadius: "10px",
                        }}>
                            <AccordionSummary
                                expandIcon={null}
                                sx={{
                                    borderBottom: "1px solid #41b6e6",
                                    boxShadow: "0px 0px 5px 0px #41b6e6",
                                    borderRadius: "10px",
                                    // background: `linear-gradient(45deg, #cdebf8 12%,#fff 12%)`
                                }}
                            >
                                <div className='sales_org_leaderboard_item'>
                                    <p style={rankStyle(index + 1)} className='rank'>#<span style={{
                                        fontSize: "1.5rem"
                                    }}>{index + 1}</span></p>
                                    <p style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: "0.5rem", alignItems: "center" }}>{org?.salesOrg?.name} <MdExpandMore size={30} /></p>
                                    <p style={{ textAlign: "center" }}>{org?.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SuperAdmin").length}</p>
                                    <p style={{ textAlign: "center" }}>
                                        {
                                            rankedBy === "Sales" ?
                                                org.salesCount === 0 || org.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SuperAdmin").length === 0 ? 0 : Math.ceil(org.salesCount / org.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SuperAdmin").length)
                                                :
                                                org.di === 0 || org.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SuperAdmin").length === 0 ? 0 : Math.ceil(org.di / org.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SalesOrgAdmin").length)
                                        }
                                    </p>
                                    
                                    <p style={{ textAlign: "center" }}>{rankedBy === "Sales" ? org.salesCount : org.di}</p>
                                    <p style={{ textAlign: "center" }}>{org.cs}</p>
                                    <p style={{ textAlign: "center" }}>{org.v}</p>
                                    <p style={{ textAlign: "center" }}>{org.nv}</p>

                                    <div className='equation'>
                                        <p>{org.ds}</p>
                                        <p>{org.pi}</p>
                                        <p>{org.di}</p>
                                        {/* <p style={{
                                            fontWeight: "bold",
                                            fontSize: "1.2rem"
                                        }}>{org.di === 0 ? 0 + "%" : ((org.di / (org.di + org.dc)) * 100).toFixed(1) + "%"}</p> */}
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "2rem"
                            }}>
                                <div className='sales_rep_leaderboard_item'>
                                    <p style={{ fontWeight: "bold" }}>Rank</p>
                                    <p></p>
                                    <p style={{ fontWeight: "bold" }}>Name</p>
                                    <p style={{ textAlign: "center", fontWeight: "bold" }}>{rankedBy === "Sales" ? "Total Sales" : "Total Installs"}</p>
                                    <p style={{ fontWeight: "bold" }}>Verified</p>
                                    <p style={{ fontWeight: "bold" }}>Not Verified</p>

                                
                                    <div className='equation'>
                                        <p>DS</p>
                                        <p>PI</p>
                                        <p>DI</p>
                                        {/* <p style={{
                                            fontWeight: "bold",
                                            fontSize: "1.2rem"
                                        }}>Ratio</p> */}
                                    </div>
                                </div>
                                {
                                    org?.salesRepList?.filter(rep => rep.salesRep.userType === "SalesRep" || rep.salesRep.userType === "SalesOrgAdmin" || rep.salesRep.userType === "SuperAdmin").map(
                                        (rep, index) => {

                                            return <div key={index} className='sales_rep_leaderboard_item'>
                                                <p style={
                                                    rankStyle(index + 1)
                                                }>#{index + 1}</p>
                                                <div className='profile_image'>
                                                    <img src={rep.salesRep.profileImage ? rep.salesRep.profileImage.url : anonUser} alt="profile" />
                                                </div>
                                                <p>{rep.salesRep.firstName + " " + rep.salesRep.lastName}</p>
                                                <p style={{ textAlign: "center" }}>{rankedBy === "Sales" ? rep.salesCount : rep._DI}</p>
                                                <p style={{ textAlign: "center" }}>{rep._V}</p>
                                                <p style={{ textAlign: "center" }}>{rep._NV}</p>
                                                <div className="equation">
                                                    <p>{rep._DS}</p>
                                                    <p>{rep._PI}</p>
                                                    <p>{rep._DI}</p>
                                                    {/* <p style={{
                                                        fontWeight: "bold",
                                                        fontSize: "1.2rem"
                                                    }}>{rep._DI === 0 ? 0 + "%" : ((rep._DI / (rep._DI + rep._DC)) * 100).toFixed(1) + "%"}</p> */}
                                                </div>
                                            </div>
                                        }
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                )
            }
        </div>
    }


    const salesRepLeaderboard = () => {

        return <div className='sales_org_leaderboard rep'>

            <div style={{
                // background: `linear-gradient(45deg, #41b6e6 12%,#fff 12%)`,
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px 0px #41b6e6",
                padding: "1rem",
                // paddingRight: "3rem"
            }} className='sales_rep_leaderboard_item'>
                <p style={{
                    color: "black",
                    fontWeight: "bold"
                }}>Rank</p>
                <p></p>
                <p>Name</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>{rankedBy === "Sales" ? "Total Sales" : "Total Installs"}</p>

                <p style={{ textAlign: "center", fontWeight: "bold" }}>Verified</p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>Not Verified</p>
                <div className='equation'>
                    <p>DS</p>
                    <p>PI</p>
                    <p>DI</p>
                    {/* <p style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem"
                    }}>Ratio</p> */}
                </div>
            </div>
            {
                list.map(
                    (rep, index) => {
                        return <div key={index} style={{
                            // gridTemplateColumns:"repeat(7,100px)"
                        }} className='sales_rep_leaderboard_item'>
                            <p style={rankStyle(index + 1)} className='rank'>#<span style={{
                                fontSize: "1.5rem"
                            }}>{index + 1}</span></p>
                            <div className='profile_image'>
                                <img src={rep.profileImage} alt="profile" />
                            </div>
                            <p>{rep.salesRepName}</p>
                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{rankedBy === "Sales" ? rep.salesCount : rep._DI}</p>
                            <p style={{ textAlign: "center" }}>{rep._V}</p>
                            <p style={{ textAlign: "center" }}>{rep._NV}</p>
                            <div className='equation'>
                                <p>{rep._DS}</p>
                                <p>{rep._PI}</p>
                                <p>{rep._DI}</p>
                                {/* <p style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem"
                                }}>{rep.percentage}</p> */}
                            </div>
                        </div>
                    }
                )
            }
        </div>
    }


    return (
        <Layout>
            <div className='leaderboard_container'>
                <div className='filter_container'>
                    <div className='btn_con'>
                        <div style={{ gap: "1rem" }}>

                            <button style={{
                                color: "white",
                                fontSize: "0.8rem",
                                padding: "0.3rem",
                                fontWeight: "normal",
                            }} onClick={() => {
                                navigate('/sales-reporting-page')
                            }}>Go To Sales Report</button>
                        </div>
                        <button style={{
                            backgroundColor: rankedBy !== "Sales" || fromDate !== "" || toDate !== "" ? "red" : "#E6E6E6",
                            color: "white",
                            fontSize: "0.8rem",
                            padding: "0.3rem",
                            fontWeight: "normal",
                        }} onClick={() => {
                            setRankedBy("Sales");
                            setFromDate("");
                            setToDate("");
                            setReset(prev => prev + 1);
                        }} disabled={!(rankedBy !== "Sales" || fromDate !== "" || toDate !== "")} >Reset Filters</button>
                    </div>
                    <div className='selector_con'>
                        <label>Ranked By</label>
                        <select onChange={(e) => {
                            setRankedBy(e.target.value)
                        }} value={rankedBy} >
                            <option value="Sales">Total Sales</option>
                            <option value="Installs">Total Installs</option>
                        </select>
                    </div>
                    {user.userType === "SuperAdmin" &&
                        <div className='selector_con'>
                            <label>Fiber Company</label>
                            <FiberCompanySelector setUser={setInstallCompany} companiesWithOfferTemplateOnly={true} disabled={false} multiple={true} placeholder="Select a Company" className="fiber_company_selector" />
                        </div>
                    }
                    {/* <div className='filter_input'>
                        <label>Quick Date</label>
                        <QuickDate reset={reset} setFrom={setFromDate} setTo={setToDate} />
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        gridColumn: "span 2",
                        alignItems: "center",
                        border: "1px solid #E5E5E5",
                        borderRadius: "10px",
                        padding: "0rem 0.5rem",
                    }}>
                        <label>Date Range</label>
                        <div className='quick_date'>
                            <input style={{ color: "#41b6e6" }} type='date' value={fromDate} onChange={(e) => { setFromDate(e.target.value) }} />
                            <p>To</p>
                            <input style={{ color: "#41b6e6" }} type='date' value={toDate} onChange={(e) => { setToDate(e.target.value) }} />
                        </div>
                    </div> */}
                    <QuickDate selectQuickDate={"This Week"} quickDate={quickDate} setQuickDateValue={setQuickDate} reset={reset} setFrom={setFromDate} setTo={setToDate} />

                </div>

                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}>
                            <CircularProgress size={50} sx={{
                                color: 'var(--color-icon)',
                            }} />
                        </div>
                        :
                        (user.userType === "SalesOrgAdmin" || user.userType === "SuperAdmin") ?
                            salesOrgLeaderboard()
                            // <Datatable headers={SalesOrgHeaders} list={list} loading={loading} />
                            :
                            // <Datatable headers={SalesRepHeaders} list={list} loading={loading} />
                            salesRepLeaderboard()
                }
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }} className='pagination'>
                    <Pagination color='light' renderItem={(item) => (
                        <PaginationItem components={{

                            next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                            first: (props) => <button {...props}>First</button>,
                            previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                        }}  {...item} sx={{ color: "white" }} />
                    )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>

            </div>
        </Layout >
    )
}

export default Index