import { Modal } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { IoCloseCircle } from 'react-icons/io5'
import AreaLabelSelector from "./../../../../components/AreaLabelSelector"
import Swal from 'sweetalert2'
import CreateServicableAreaReq from '../../../../Requests/ServicableArea/CreateServicableAreaReq'
import { MapContext } from '..'
import ServicableAreaApi from '../../../../API/ServicableAreaApi'
import { Fill, Stroke, Style } from 'ol/style'
import CreateAreaLabel from './CreateAreaLabel'
const EditServicableArea = ({ open, setOpen, area }) => {


    const map = useContext(MapContext)

    const [areaName, setAreaName] = React.useState("")
    const [label, setLabel] = React.useState(null)
    const [labelId, setLabelId] = React.useState("")
    const [openLabelModal, setOpenLabelModal] = React.useState(false)



    function makeTransparent(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    useEffect(() => {
        if (area) {
            setAreaName(area.name)
            setLabelId(area.label_id)

            console.log(area)

            setLabel(
                area.label ?
                    {
                        id: area.labelId,
                        label: <div className='flex items-center justify-start gap-2'>
                            <div style={{ backgroundColor: area.label.color }} className={` rounded-full h-5 w-5`}></div>
                            <span className="ml-2">{area.label.label}</span>
                        </div>,
                        color: area.label.color,
                    }
                    : null
            )

        }
    }, [area])

    async function editServicableArea() {




        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are saving the servicable area",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        if(!label){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a label",
                showConfirmButton: true,
                showCancelButton: false
            })
            return
        }

        if(!areaName){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a name",
                showConfirmButton: true,
                showCancelButton: false
            })
            return
        }

        const req = new CreateServicableAreaReq()
        req.name = areaName
        req.color = label.color
        req.notes = ""
        req.id = area.id
        req.coordinates = null;
        req.areaLabelId = labelId
        const res = await ServicableAreaApi.EditServicableArea(req)
        if (res.status.success) {

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Servicable Area Saved Successfully",
                showConfirmButton: true,
                showCancelButton: false,
                willClose: () => {
                    // map.clearSearchArea()
                }
            })


            map.setServicableAreas((prev) => {
                if (prev.length > 0) {
                    return prev.map(area => {
                        if (area.id === req.id) {
                            return {
                                latlngs: res.data.coordinates.map(
                                    (point) => {
                                        return [point.latitude, point.longitude]
                                    }
                                ),
                                color: req.color,
                                id: req.id,
                                name: req.name,
                                fiberCompanyId: area.fiberCompanyId,
                                salesOrgId: area.salesOrgId,
                                type: area.type,
                                label: {
                                    color: req.color,
                                    label: label.label,
                                    id: req.areaLabelId
                                },
                                labelId: req.areaLabelId
                            }
                        }
                        return area
                    })
                }
            })



        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                showConfirmButton: true,
                showCancelButton: false
            })
        }
    }


    return (
        <>
        <CreateAreaLabel open={openLabelModal} setOpen={setOpenLabelModal} setPrevious={setOpen} />
        <Modal open={open} onClose={() => setOpen(false)}>
            <div style={{ width: "min(500px,80%)" }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  bg-white p-4 rounded-lg'>
                <div className='flex flex-col p-4 gap-4 justify-center items-center '>
                    <IoCloseCircle onClick={() => {
                        setOpen(false)
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    <img alt="Logo" src={'/images/nav/nav-logo.png'} className='object-fit' />
                    <h1 className='text-2xl font-bold'>Edit Area</h1>
                    <input
                        className='w-full p-2 border border-gray-300 rounded-lg outline-none'
                        type="text" placeholder="Enter Area Name" value={areaName} onChange={(e) => setAreaName(e.target.value)} />
                    <AreaLabelSelector value={label} setLabelId={setLabelId} placeholder='Select a Label' setLabelObject={setLabel} className='w-full' />
                    <p className='text-sm text-gray-500'>Don't see the label you want? <span className='text-blue-500 cursor-pointer' onClick={() => setOpenLabelModal(true)}>Create Label</span></p>
                    <button className='bg-blue-500 text-white p-2 rounded-lg w-full' onClick={editServicableArea}>
                        Edit
                    </button>
                </div>
            </div>
        </Modal>
        </>
    )
}

export default EditServicableArea