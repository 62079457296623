import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class ServicableAreaApi{
    baseUrl = EnvSettings.url;
    CreateServicableAreaUrl = "CreateServicableArea"
    SearchServicableAreaUrl = "SearchServicableAreas"
    EditServicableAreaUrl = "EditServicableArea"
    DeleteServicableAreaUrl = "DeleteServicableArea"
    GetUserServicableAreasUrl = "GetUserServicableAreas"
    AddUserToServicableAreaUrl = "AddUserToServicableArea"
    RemoveUserFromServicableAreaUrl = "RemoveUserFromServicableArea"
    GetServicableAreaUsersUrl = "GetServicableAreaUsers"
    AddFiberHousesToServicableAreaUrl = "AddFiberHousesToServicableArea"
    SearchAreaLabelsUrl = "SearchAreaLabels"
    CreateAreaLabelUrl = "CreateAreaLabel"

    async CreateAreaLabel(req)
    {
        const res = await fetch(this.baseUrl + this.CreateAreaLabelUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchAreaLabels(req)
    {
        const res = await fetch(this.baseUrl + this.SearchAreaLabelsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.CreateServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.SearchServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.EditServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserServicableAreas(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserServicableAreasUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddUserToServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.AddUserToServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveUserFromServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveUserFromServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async GetServicableAreaUser(req)
    {
        const res = await fetch(this.baseUrl + this.GetServicableAreaUsersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddFiberHousesToServicableArea(req)
    {
        const res = await fetch(this.baseUrl + this.AddFiberHousesToServicableAreaUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
}

export default new ServicableAreaApi();