import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class SellingCardApi {
    baseUrl = EnvSettings.url;
    CreateSellingCardUrl = "CreateSellingCard";
    SearchSellingCardUrl = "SearchSellingCards";
    DeleteSellingCardUrl = "DeleteSellingCard";
    EditSellingCardUrl = "EditSellingCard";
    RemoveFileUrl = "DeleteCloudFile";
    AddFileUrl = "AddFilesToSellingCard";
    CreateSellingCardBlockUrl = "CreateSellingCardBlock";
    GetSellingCardUrl = "GetSellingCard";
    DeleteSellingCardBlockUrl = "DeleteSellingCardBlock";
    EditSellingCardBlockUrl = "EditSellingCardBlock";
    ChangeSellingCardBlockNumbersUrl = "ChangeSellingCardBlockNumbers";
    AddSalesOrgsToSellingCardUrl = "AddSalesOrgsToSellingCard";
    RemoveSalesOrgFromSellingCardUrl = "RemoveSalesOrgFromSellingCard";
    AssignAllSalesOrgsToSellingCardUrl = "AssignAllSalesOrgsToSellingCard";
    DeleteAllSalesOrgsFromSellingCardUrl = "DeleteAllSalesOrgsFromSellingCard";
    saveEditorDataUrl = "SaveEditorData";
    createTrainingModuleUrl = "CreateTrainingModule";
    GetAllSellingCardUrl = "GetAllSellingCard";
    GetSubTrainingModuleTreeUrl = "GetSubTrainingModuleTree";
    GetAllTrainingModulesUrl = "GetAllTrainingModules";
    GetTrainingModuleTreeUrl = "GetTrainingModuleTree";
    DeleteTrainingModuleUrl = "DeleteTrainingModule";
    EditTrainingModuleUrl = "EditTrainingModule";
    CreateTrainingModuleUrl = "CreateTrainingModule";
    GetTrainingModuleUrl = "GetTrainingModule";
    SearchTrainingModuleUrl = "SearchTrainingModule";
    CreateSubTrainingModuleUrl = "CreateSubTrainingModule";
    DeleteSubTrainingModuleUrl = "DeleteSubTrainingModule";
    UpdateSubTrainingModuleUrl = "UpdateSubTrainingModule";
    GetAdjacentSellingCardUrl = "GetAdjacentSellingCard";
    ReOrderTrainingModuleUrl = "ReOrderTrainingModule";
    CreateQuizSubmissionUrl = "CreateQuizSubmission";
    GetQuizSubmissionsUrl = "GetQuizSubmissions";

    async UpdateSubTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.UpdateSubTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateSubTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.CreateSubTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async DeleteSubTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.DeleteSubTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async SearchTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.SearchTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async GetSubTrainingModuleTree(req) {
        const res = await fetch(this.baseUrl + this.GetSubTrainingModuleTreeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async GetAllSellingCard(req) {
        const res = await fetch(this.baseUrl + this.GetAllSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.createTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async saveEditorData(req) {
        const res = await fetch(this.baseUrl + this.saveEditorDataUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                "Content-type": "application/json",
            },
            body: JSON.stringify(req),
        });
        const data = await res.json();
        return data;
    }

    async RemoveSalesOrgFromSellingCard(req) {
        const res = await fetch(this.baseUrl + this.RemoveSalesOrgFromSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async AddSalesOrgsToSellingCard(req) {
        const res = await fetch(this.baseUrl + this.AddSalesOrgsToSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async ChangeSellingCardBlockNumbers(req) {
        const res = await fetch(this.baseUrl + this.ChangeSellingCardBlockNumbersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateSellingCard(req) {
        const res = await fetch(this.baseUrl + this.CreateSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async SearchSellingCard(req) {
        const res = await fetch(this.baseUrl + this.SearchSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async DeleteSellingCard(req) {
        const res = await fetch(this.baseUrl + this.DeleteSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async EditSellingCard(req) {
        const res = await fetch(this.baseUrl + this.EditSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async RemoveFile(req) {
        const res = await fetch(this.baseUrl + this.RemoveFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async AddFile(req) {
        const res = await fetch(this.baseUrl + this.AddFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateSellingCardBlock(req) {
        const res = await fetch(this.baseUrl + this.CreateSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async DeleteSellingCardBlock(req) {
        const res = await fetch(this.baseUrl + this.DeleteSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async EditSellingCardBlock(req) {
        const res = await fetch(this.baseUrl + this.EditSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async GetSellingCard(req) {
        const res = await fetch(this.baseUrl + this.GetSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async DeleteAllSalesOrgsFromSellingCard(req) {
        const res = await fetch(this.baseUrl + this.DeleteAllSalesOrgsFromSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async AssignAllSalesOrgsToSellingCard(req) {
        const res = await fetch(this.baseUrl + this.AssignAllSalesOrgsToSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }
    //new
    async GetTrainingModuleTree() {
        const res = await fetch(this.baseUrl + this.GetTrainingModuleTreeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
        });
        const data = await res.json();
        return data;
    }
    //new
    async GetAllTrainingModules(req) {
        const res = await fetch(this.baseUrl + this.GetAllTrainingModulesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }
    //new
    async EditTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.EditTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    
    //new
    async DeleteTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.DeleteTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }
    //new
    async GetTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.GetTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async GetAdjacentSellingCard(req) {
        const res = await fetch(this.baseUrl + this.GetAdjacentSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async ReOrderTrainingModule(req) {
        const res = await fetch(this.baseUrl + this.ReOrderTrainingModuleUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateQuizSubmission(req) {
        const res = await fetch(this.baseUrl + this.CreateQuizSubmissionUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                "Content-type": "application/json",
            },
            body: JSON.stringify(req),
        });
        const data = await res.json();
        return data;
    }

    async GetQuizSubmissions(req) {
        const res = await fetch(this.baseUrl + this.GetQuizSubmissionsUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                "Content-type": "application/json",
            },
            body: JSON.stringify(req),
        });
        const data = await res.json();
        return data;
    }
}

export default new SellingCardApi;