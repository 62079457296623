import React, { useState, useRef, useEffect } from 'react';
import { Edit, Delete, Add, CloudUpload, SentimentDissatisfied, Close, VisibilityOffOutlined, Visibility } from '@mui/icons-material';
import Layout from '../../../Layouts/Layout';
import VersatileLoader from '../../../components/VersatileLoader';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { uploadFileToFirebase } from '../../../Services/DisplayFile';
import StringIntReq from '../../../Requests/StringIntReq';


const Index = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [editedText, setEditedText] = useState(null);
    const [editedName, setEditedName] = useState(null);
    const [editedImage, setEditedImage] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    // New fields
    const [recruitOrCompany, setRecruitOrCompany] = useState('');
    const [subject, setSubject] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);
    const [category,setCategory] = useState("");

    useEffect(() => {
        fetchCommunicationTemplates();
    }, []);

    const fetchCommunicationTemplates = async () => {
        try {
            const req = new StringIntReq();
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 1000;
            const response = await FiberCompanyApi.GetCommunicationTemplates(req);
            if (response.status.success) {
                setTemplates(response.data.list);
                setIsLoaded(true);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.status.message
                });
                setIsLoaded(true);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
            setIsLoaded(true);
        }
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setIsEditing(false);
        setIsAdding(false);
        setEditedName(template.name);
        console.log(template.attachments);
        setEditedImage(template?.attachments?.length>0 ? template?.attachments[0]?.url : '/placeholder.svg?height=300&width=400');

        console.log(template.type);
        setRecruitOrCompany(template.type || '');
        setCategory(template.category || '');
        setSubject(template.subject || '');
        setCustomMessage(template.body || '');
    };

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleUpdate = async () => {
        try {
            Swal.fire({
                title: "Updating Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let cloudFile = null;

            if (selectedFile) {
                const uniqueId = uuidv4();
                cloudFile = await uploadFileToFirebase(
                    selectedFile,
                    `recruitTemplates/${uniqueId}`
                );
            }

            if (recruitOrCompany === 'Recruit' && ( !recruitOrCompany  ||  !editedName)) {
                Swal.fire({
                    title: "Error Updating Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true
                });
                return;
            }

            if (recruitOrCompany === 'Company' && (!editedText || !editedName)) {
                Swal.fire({
                    title: "Error Updating Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            const updatedTemplateReq = {
                id: selectedTemplate.id,
                name: editedName,
                text: editedText,
                type: recruitOrCompany,
                body: customMessage, 
                subject: subject,
                category: category
            };

            if(cloudFile){
                updatedTemplateReq.attachments = [{
                    Name: cloudFile.name,
                    Url: cloudFile.url,
                    Path: cloudFile.path,
                    Extension: cloudFile.extension,
                    FileSize: cloudFile.fileSize,
                    FileType: cloudFile.fileType,
                    Caption: "Template Image"
                }];
            }
                    

            console.log(updatedTemplateReq);
            const response = await FiberCompanyApi.UpdateCommunicationTemplate(updatedTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Updated Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchCommunicationTemplates();

                handleTemplateSelect({
                    id: response.data.id, // Ensure you use the updated ID
                    templateName: response.data.name, // Updated name
                    templateText: response.data.text, // Updated text
                    templateImage: response.data.templateImage, // Updated image
                    positionTitle: response.data.positionTitle, // Updated position title
                    candidateType: response.data.candidateType, // Updated type
                    jobDescription: response.data.jobDescription, // Updated description
                    jobRequirements: response.data.jobRequirements, // Updated requirements (split back into array)
                    isVisible: response.data.isVisible,
                    category: response.data.category,
                });
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Updating Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Updating Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const handleDelete = async () => {
        try {
            Swal.fire({
                title: "Deleting Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let req = new StringIntReq()
            req.int = selectedTemplate.id
            let response = await FiberCompanyApi.DeleteCommunicationTemplate(req);
            if (response.status.success) {
                Swal.fire({
                    title: "Template Deleted Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchCommunicationTemplates();
                setSelectedTemplate(null);
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Deleting Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error Deleting Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const handleVisibility = async () => {
        try {
            Swal.fire({
                title: "Updating Visibility",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const updatedTemplateReq = {
                id: selectedTemplate.id,
                isVisible: !selectedTemplate.isVisible,
            };

            const response = await FiberCompanyApi.UpdateRecruitTemplateVisibility(updatedTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Updated Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchCommunicationTemplates();

                handleTemplateSelect({
                    id: response.data.id, // Ensure you use the updated ID
                    templateName: response.data.name, // Updated name
                    templateText: response.data.text, // Updated text
                    templateImage: response.data.templateImage, // Updated image
                    positionTitle: response.data.positionTitle, // Updated position title
                    candidateType: response.data.candidateType, // Updated type
                    jobDescription: response.data.jobDescription, // Updated description
                    jobRequirements: response.data.jobRequirements, // Updated requirements (split back into array)
                    isVisible: response.data.isVisible
                });
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Updating Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Deleting Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
        ;
    }

    const handleAddTemplate = () => {
        setIsAdding(true);
        setIsEditing(true);
        setSelectedTemplate(null);
        setEditedText(null);
        setEditedImage('/placeholder.svg?height=300&width=400');
        setNewTemplateName(null);
        setSelectedFile(null);
        setRecruitOrCompany('');
    };

    const handleSaveNewTemplate = async () => {
        try {
            Swal.fire({
                title: "Adding New Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let cloudFile = null;

            if (selectedFile) {
                const uniqueId = uuidv4();
                cloudFile = await uploadFileToFirebase(
                    selectedFile,
                    `communication-templates/${uniqueId}`
                );
            }

            console.log(newTemplateName, editedText, recruitOrCompany, cloudFile);
            if (recruitOrCompany === "Recruit" && (!newTemplateName  || !recruitOrCompany )) {
                console.log(newTemplateName, editedText, recruitOrCompany, cloudFile);
                Swal.fire({
                    title: "Error Saving Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            if (recruitOrCompany === "Company" && (!newTemplateName )) {
                Swal.fire({
                    title: "Error Saving Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            const newTemplateReq = {
                name: newTemplateName,
                type: recruitOrCompany,
                attachments : [],
                body: customMessage,
                subject: subject,
                category: category
            };

            if(cloudFile){
                newTemplateReq.attachments.push({
                    Name: cloudFile.name,
                    Url: cloudFile.url,
                    Path: cloudFile.path,
                    Extension: cloudFile.extension,
                    FileSize: cloudFile.fileSize,
                    FileType: cloudFile.fileType,
                    Caption: "Template Image"
                });
            }

            console.log(newTemplateReq);
            const response = await FiberCompanyApi.CreateCommunicationTemplate(newTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Added Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchCommunicationTemplates();
                handleTemplateSelect({
                    id: response.data.id,
                    templateName: newTemplateName,
                    templateImage: cloudFile,
                    type: recruitOrCompany,
                    content: customMessage,
                    subject: subject,
                    category: category
                });

            } else {
                Swal.fire({
                    title: "Error Saving Templatee",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsAdding(false);
            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Saving Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const triggerImageUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setEditedImage(URL.createObjectURL(file));
        }
    };





    const handleCandidateTypeChange = (e) => {
        setEditedText(null);
        setRecruitOrCompany(e.target.value);
    };


    if (!isLoaded) {
        return (
            <Layout>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%"
                }}>
                    <VersatileLoader size='large' color='#2761CF' />
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="manage-recruit-templates">
                {/* <h1 className="page-header">Manage Communication Templates</h1> */}
                <div className="template-content">
                    <div className="template-list-container">
                        <div className="template-list">
                            {templates.length === 0 ? (
                                <div style={{ alignContent: "center", textAlign: "center" }}>
                                    <SentimentDissatisfied style={{ width: "2rem", height: "2rem" }} />
                                    <h5>No templates available</h5>
                                </div>
                            ) : (
                                templates.map(template => (
                                    <div
                                        key={template.id}
                                        className={`template-item ${selectedTemplate?.id === template.id ? 'active' : ''}`}
                                        onClick={() => handleTemplateSelect(template)}
                                    >
                                        {template.name}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="template-item add-template" onClick={handleAddTemplate}>
                            <Add /> Add Template
                        </div>
                    </div>
                    <div className="template-details">
                        {(selectedTemplate || isAdding) && (
                            <>
                                <div className="template-actions">
                                    {!isAdding && (
                                        <>
                                            <Edit onClick={handleEdit} />
                                            {selectedTemplate.isVisible ? (
                                                <VisibilityOffOutlined onClick={handleVisibility} />
                                            ) : (
                                                <Visibility onClick={handleVisibility} />
                                            )}
                                        </>
                                    )}
                                </div>
                                {isAdding ? (
                                    <input
                                        type="text"
                                        value={newTemplateName}
                                        onChange={(e) => setNewTemplateName(e.target.value)}
                                        placeholder="Enter template name"
                                        className="template-name-input"
                                    />
                                ) : (
                                    <>
                                        <label>Template Name</label>
                                        <input
                                            type="text"
                                            value={isEditing ? editedName : selectedTemplate?.name}
                                            onChange={(e) => setEditedName(e.target.value)}
                                            placeholder="Enter template name"
                                            disabled={!isEditing}
                                        />
                                    </>
                                )}


                                <label>Recruit or Company</label>
                                <select
                                    value={recruitOrCompany}
                                    onChange={(e) => handleCandidateTypeChange(e)}
                                    disabled={!isAdding}
                                    className="template-name-input"
                                >
                                    <option value="">Select type</option>
                                    <option value="Recruit">Recruit</option>
                                    <option value="Company">Company</option>
                                </select>

                                <label>Actions</label>
                                <select 
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    disabled={!isEditing && !isAdding}
                                    className="template-name-input"
                                >
                                    <option value="">Select Action</option>

                                    {
                                        recruitOrCompany === "Recruit" ? (
                                            <>
                                                <option value="Job AD">Job AD</option>
                                                <option value="Job Offer">Job Offer</option>
                                                <option value="Schedule Interview">Schedule Interview</option>
                                            </>
                                        ) : (
                                            <>
                                                <option value="Consultation">Consultation</option>
                                                {/* <option value="Invitation">Invitation</option>
                                                <option value="Recruit Accept Offer">Recruit Accept Offer</option> */}
                                            </>
                                        )
                                    }
                                </select>

                                <label>Attachment (For Email Only)</label>
                                <div className="image-upload">
                                    <img src={isEditing ? editedImage :selectedTemplate?.attachments?.length>0 ? selectedTemplate?.attachments[0]?.url:""} alt="Template Preview" />
                                    {(isEditing || isAdding) && (
                                        <button onClick={triggerImageUpload} className="upload-button">
                                            <CloudUpload />
                                            <span>{isAdding ? 'Upload Image' : 'Edit'}</span>
                                        </button>
                                    )}
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                {(
                                    <>
                                        <label>Subject</label>
                                        <input
                                            type="text"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            placeholder="Enter Mesage Subject"
                                            disabled={!isEditing && !isAdding}
                                        />
                                    </>
                                )}

                                {(
                                    <>
                                        <label>Body</label>
                                        <textarea
                                            value={customMessage}
                                            onChange={(e) => setCustomMessage(e.target.value)}
                                            placeholder="Enter Message Body"
                                            disabled={!isEditing && !isAdding}
                                            className="template-name-input"
                                            rows={8}
                                        />
                                    </>
                                )
                                }


                                {(isEditing || isAdding) && (
                                    <button onClick={isAdding ? handleSaveNewTemplate : handleUpdate} className="save-button">
                                        {isAdding ? 'Add Template' : 'Update'}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Index;