import React, { useContext, useEffect, useState } from 'react'
import {
  Modal,
  Typography,
  TextField,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material'
import Select from 'react-select'
import { Email, Sms, ContentCopy, Send } from '@mui/icons-material'
import './InvitationModal.scss'

// Import your images here
import salesRep1 from '../../../../../assets/images/Sales_Rep_2.png'
import salesRep2 from '../../../../../assets/images/Sales_Rep_3.png'
import salesRep3 from '../../../../../assets/images/Sales_Rep_4.png'
import { set } from 'ol/transform'
import Swal from 'sweetalert2'
import { UserContext } from '../../../../../App'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import FiberCompanyApi from '../../../../../API/FiberCompanyApi'
import SendCandidateInvitationReq from '../../../../../Requests/FiberHouse/SendCandidateInvitationReq'
import StringIntReq from '../../../../../Requests/StringIntReq'


const InvitationModal = ({ custom, open = false, onClose = () => { }, isStatEmail, mode, isStatSMS, candidateId, filteredCandidateIds, candidateType, communicationTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templates, setTemplates] = useState([])
  const [subject, setSubject] = useState("")
  const [messageText, setMessageText] = useState("")
  const [attachmentBase64, setAttachmentBase64] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [communicationTemplates, setCommunicationTemplates] = useState([])
  const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] = useState(communicationTemplate)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [category, setCategory] = useState(candidateType === "Recruit" ? "Job AD" : "Consultation")



  const user = useContext(UserContext);


  useEffect(
    () => {
      // if (communicationTemplate) {
      setMessageText(communicationTemplate?.body)
      setSubject(communicationTemplate?.subject)
      setSelectedCommunicationTemplate(communicationTemplate)
      // }
    }, [communicationTemplate]
  )

  const fetchRecruitTemplates = async () => {
    try {
      let response = await FiberCompanyApi.GetRecruitTemplates();
      if (response.status.success) {
        const filteredTemplates = response.data.filter(template =>
          template.isVisible === true && template.candidateType === candidateType
        );

        const formattedTemplates = filteredTemplates.map(template => ({
          label: template.templateName,
          value: template.id,
          text: template.templateText,
          imageUrl: template.templateImage?.url,
          positionTitle: template.positionTitle,
          candidateType: template.candidateType,
          jobDescription: template.jobDescription,
          jobRequirements: template.jobRequirements.split('\n').map(req => req),
          fullData: template,
          subject: template.subject,
          content: template.content
        }));
        setTemplates(formattedTemplates);
        console.log(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCommunicationTemplates = async () => {
    try {
      let req = new StringIntReq()
      req.string = candidateType
      req.category = category
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 1000;
      let response = await FiberCompanyApi.GetCommunicationTemplates(req);
      if (response.status.success) {
        const filteredTemplates = response.data.list;

        const formattedTemplates = filteredTemplates.map(template => ({
          label: template.name + " | " + template.category,
          value: template,
        }));
        setCommunicationTemplates(formattedTemplates);
        console.log(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(
    () => {
      if (selectedCommunicationTemplate) {
        if (selectedCommunicationTemplate.attachments.length > 0) {
          const response = fetch(selectedCommunicationTemplate.attachments[0].url);
          response.then(res => {
            res.blob().then(blob => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                setAttachmentBase64(reader.result.split(',')[1]);
                setFileName(selectedCommunicationTemplate.attachments[0].name);
              }
            })
          })
        }
        else {
          setAttachmentBase64(null);
          setFileName(null);
        }
      }
    }, [selectedCommunicationTemplate]
  )

  useEffect(() => {
    fetchRecruitTemplates()
  }, [candidateType])

  useEffect(() => {
    fetchCommunicationTemplates()
  }, [communicationTemplate, candidateType, category])


  const handleTemplateChange = (selected) => {
    setSelectedTemplate(selected)

  }


  const sendBoth = async () => {
    const option = await Swal.fire({
      title: 'Send Invitation',
      text: 'Are you sure you want to send this invitation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    })

    if (!option.isConfirmed) return

    Swal.fire({
      title: 'Sending Invitation',
      text: 'Please wait...',
      icon: 'info',
      didOpen: () => {
        Swal.showLoading();
      }
    })

    let req = new SendCandidateInvitationReq()
    req.candidateId = candidateId
    req.templateId = selectedTemplate?.value
    req.userId = user.id
    req.content = messageText
    req.subject = subject
    req.templateType = selectedCommunicationTemplate?.category || category

    let res = await FiberHouseApi.SendCandidateInvitationWithSMS(req)
    let base64 = null;
    let fileName = null;


    const response = await fetch(selectedCommunicationTemplate?.attachments[0]?.url);


    if (attachmentBase64) {
      req.attachment = {
        fileName: fileName,
        base64string: attachmentBase64
      }
    }

    res = await FiberHouseApi.SendCandidateInvitation(req)
    if (res.status.success) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Invitation sent successfully',
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }





  }


  const sendInvitationEmail = async () => {

    //show swal confirm message 
    Swal.fire({
      title: 'Send Invitation',
      text: 'Are you sure you want to send this invitation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    }).then(async (result) => {
      if (result.isConfirmed) {
        //swal loader
        Swal.fire({
          title: 'Sending Invitation',
          text: 'Please wait...',
          icon: 'info',
          didOpen: () => {
            Swal.showLoading()
          }
        })

        let base64 = null;


        let req = new SendCandidateInvitationReq()
        req.candidateId = candidateId
        req.templateId = selectedTemplate?.value
        req.userId = user.id
        req.content = messageText
        req.subject = subject
        req.templateType = selectedCommunicationTemplate?.category || category
        let res = null;






        if (attachmentBase64) {
          req.attachment = {
            fileName: fileName,
            base64string: attachmentBase64
          }
        }

        res = await FiberHouseApi.SendCandidateInvitation(req)
        if (res.status.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Invitation sent successfully',
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }

        console.log(req)
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Invitation not sent',
        })
      }

    })


  }

  const sendBulkInvitationEmails = () => {


    if (!selectedCommunicationTemplate) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a template',
      })
      return;
    }

    Swal.fire({
      title: 'Send Invitation',
      text: 'Are you sure you want to send these invitations?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    }).then(async (result) => {
      if (result.isConfirmed) {
        //swal loader
        Swal.fire({
          title: 'Sending Invitations',
          text: 'Please wait...',
          icon: 'info',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          }
        })



        let req = new SendCandidateInvitationReq()
        req.candidateIds = filteredCandidateIds
        req.templateId = selectedTemplate?.value
        req.userId = user.id
        req.content = messageText
        req.subject = subject
        req.templateType = selectedCommunicationTemplate?.category || category
        if (attachmentBase64) {
          req.attachment = {
            fileName: fileName,
            base64string: attachmentBase64
          }
        }
        console.log(req)

        let res = await FiberHouseApi.BulkSendCandidateInvitations(req)
        if (res.status.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }

      } else {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Invitation not sent',
        })
      }

    })
  }

  const sendInvitationSMS = async () => {
    // Implement SMS functionality here
    const option = await Swal.fire({
      title: 'Send SMS',
      text: 'Are you sure you want to send this invitation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    })

    if (!option.isConfirmed) return

    let req = new SendCandidateInvitationReq()
    req.candidateId = candidateId
    req.templateId = selectedTemplate?.value
    req.userId = user.id
    req.content = messageText
    req.subject = subject
    req.templateType = selectedCommunicationTemplate?.category || category

    let res = await FiberHouseApi.SendCandidateInvitationWithSMS(req)

    if (res.status.success) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }


  }

  const sendBulkInvitationSMS = async () => {


    if (!selectedCommunicationTemplate) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a template',
      })
      return;
    }

    const option = await Swal.fire({
      title: 'Send SMS',
      text: 'Are you sure you want to send these invitations?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    })

    if (!option.isConfirmed) return

    Swal.fire({
      title: 'Sending SMS',
      text: 'Please wait...',
      icon: 'info',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    let req = new SendCandidateInvitationReq()
    req.candidateIds = filteredCandidateIds
    req.templateId = selectedTemplate?.value
    req.userId = user.id
    req.content = messageText
    req.subject = subject


    req.templateType = selectedCommunicationTemplate?.category || category

    let res = await FiberHouseApi.BulkSendInvitationWithSMS(req)

    if (res.status.success) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }

  }

  const handleCopyLink = () => {
    // Implement copy link functionality here
    console.log("Copy link")
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="invitation-modal"
    >
      <div className={`invitation-modal ${isMobile ? 'mobile' : ''}`}>
        <h1 className='text-2xl py-4 font-medium text-center'>Send {communicationTemplate?.category}</h1>
        <div className="scrollable-content">
          <div className="content">
            {
              ((!communicationTemplate && open !== true) || custom) &&
              <div className='flex flex-col gap-2'>
                <label>Mesage Type</label>
                <select className='message-field' value={category} onChange={(e) => {
                  setCategory(e.target.value)
                  setSelectedCommunicationTemplate(null)
                }} >
                  {
                    candidateType === "Recruit" ?
                      <>
                        <option value="Job AD">Job AD</option>
                        <option value="Job Offer">Job Offer</option>
                      </>
                      :
                      <>
                        <option value="Consultation" >Consultation</option>
                      </>
                  }
                </select>
              </div>
            }

            {
              !communicationTemplate && open !== true &&
              <div className='flex flex-col gap-2'>
                <label>Select Communication Template</label>
                <Select
                  options={communicationTemplates}
                  onChange={(selected) => {
                    setSelectedCommunicationTemplate(selected.value)
                    setMessageText(selected.value.body)
                    setSubject(selected.value.subject)
                  }}
                  // value={selectedCommunicationTemplate}
                  placeholder="Choose a template"
                  className="template-select"
                  menuPortalTarget={document.body} // renders the dropdown in the body element
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure a high z-index for the portal
                  }}
                />
              </div>
            }

            {
              ((candidateType === "Recruit" && (selectedCommunicationTemplate?.category == "Job AD" || category == "Job AD")) ||
                (candidateType === "Company" && (selectedCommunicationTemplate?.category == "Consultation" || category == "Consultation")))
              &&
              <div className='flex flex-col gap-2'>
                {
                  selectedCommunicationTemplate?.type === "Recruit" ?
                    <label>Select InApp Job AD Templates</label> :
                    <label>Select InApp Consultation Templates</label>
                }
                <Select
                  options={templates}
                  onChange={handleTemplateChange}
                  value={selectedTemplate}
                  placeholder="Choose a template"
                  className="template-select"
                  menuPortalTarget={document.body} // renders the dropdown in the body element
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure a high z-index for the portal
                  }}
                />
                {/* {
                  selectedTemplate && (
                    <p className='text-[#022780] underline cursor-pointer text-center'>Preview the In App Job Ad</p>
                  )
                } */}
              </div>
            }
            {/* {selectedTemplate && (
              <div className="preview-image">
                <img src={selectedTemplate?.imageUrl} alt="Selected template preview" />
              </div>
            )} */}

            {/* {selectedTemplate?.candidateType === 'Recruit' && (
              <div className='flex flex-col gap-2'>
                <label >Position Title</label>
                <input
                  label="Position Title"
                  fullWidth
                  value={selectedTemplate?.positionTitle}
                  disabled
                  className="message-field"
                />
              </div>
            )} */}

            {/* {selectedTemplate?.candidateType === 'Recruit' && (
              <div className='flex flex-col gap-2'>
                <label >Job Description</label>
                <textarea
                  label="Job Description"
                  fullWidth
                  value={selectedTemplate?.jobDescription}
                  disabled
                  className="message-field"
                  rows="4"
                />
              </div>
            )} */}


            {/* {selectedTemplate?.candidateType === 'Recruit' && (
              <div className='message-field flex flex-col gap-2'>
                {selectedTemplate?.jobRequirements.map((req, index) => (
                  <div key={index} >
                    <label>Requirement {index + 1}</label>
                    <input
                      label={`Requirement ${index + 1}`}
                      fullWidth
                      value={req}
                      disabled
                      className="message-field"
                    />
                  </div>
                ))}
              </div>
            )} */}

            {/* {selectedTemplate?.candidateType === 'Company' && (
              <div className='flex flex-col gap-2'>
                <label >Message</label>
                <textarea
                  value={selectedTemplate?.text || ''}
                  disabled
                  className="message-field"
                  rows="4"
                />
              </div>
            )} */}


            {
              // selectedCommunicationTemplate &&
              (
                <div className='flex flex-col gap-2'>
                  <label>Subject</label>
                  <input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className='message-field'
                    disabled={!custom}
                  />
                </div>
              )
            }

            {
              // selectedCommunicationTemplate &&
              (
                <div className='flex flex-col gap-2'>
                  <label>Body</label>
                  <textarea
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    className='message-field'
                    rows="4"
                    disabled={!custom}

                  />
                </div>
              )
            }

            {
              custom && (mode === "email" || mode === "both") &&
              <div>
                <label>Attachment (For Email Only)</label>
                <input type="file" onChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = function () {
                    setAttachmentBase64(reader.result.split(',')[1]);
                    setFileName(file.name);
                  }
                }} />
              </div>
            }

            {
              open !== "SMS" && mode !== "sms" && selectedCommunicationTemplate?.attachments?.length > 0 &&
              <div className='flex flex-col gap-2'>
                <label>Attachment (For Email Only)</label>
                <img src={selectedCommunicationTemplate.attachments[0].url} alt="attachment" className='h-20 w-fit object-contain' />
              </div>
            }


          </div>
        </div>
        {
          mode === "email" && (
            <button className='w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4' onClick={sendInvitationEmail}>Send Email</button>
          )
        }
        {
          mode === "sms" && (
            <button className='w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4' onClick={sendInvitationSMS}>Send SMS</button>
          )
        }
        {
          mode === "both" && (
            <button className='w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4' onClick={() => {
              sendBoth()
            }}>Send Both</button>
          )
        }
        {
          !mode &&
          <div className="action-buttons py-4">
            {isStatEmail && open === "Email" && (
              <button className='w-full px-4 py-2 bg-[#022780] text-white rounded-lg' onClick={sendBulkInvitationEmails}>Send Emails</button>
            )}
            {
              isStatSMS && open === "SMS" &&
              <button className='w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4' onClick={sendBulkInvitationSMS}>Send SMS</button>
            }

          </div>
        }

      </div>
    </Modal>
  )
}

export default InvitationModal