class UpdateSubTrainingModuleReq {
  constructor() {
    this.name = null;
    this.parentSubTrainingModuleId = null;
    this.trainingModuleId = null;
    this.sellingCards = [];
    this.childSubTrainingModules = [];
  }
}

export default UpdateSubTrainingModuleReq;
