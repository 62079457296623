import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import UsersApi from '../../API/UsersApi';
import OrgApi from '../../API/OrgApi';
import { UserContext } from '../../App';
import SearchOrgReq from '../../Requests/Organizations/SearchOrgReq';
import SearchUserReq from '../../Requests/Users/SearchUserReq';
import SoftTypography from './../SoftTypography';
import greyUser from "./../../assets/images/userGrey.png";
import { components } from 'react-select';

const Index = ({fiberCompaniesIds, placeNone,excludeSalesOrgId,includeYourself, conversationType, setUser,setUserWithName,userWithName, disabled = false, onlySelectedUserType, placeholder = "Select a parent Organization", SalesOrgId = null, userType = null ,userTypes,salesOrgIds=null, selected,includeDeactivated=false}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    const LoggedInUser = useContext(UserContext)
    const [renderUI, setRenderUI] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
            setRenderUI(true); // Set renderUI to true after options are fetched
        };
        setOptions([]);
        getOptions();
    }, [conversationType,salesOrgIds, fiberCompaniesIds]);

    const processSalesOrgResponse = (res) => {
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                if (LoggedInUser.id !== item.id) {
                    optionsArr.push({ value: item, label: `${item.name}`, id: item.id });
                }
            });
            if (placeNone) {
                optionsArr.unshift({ value: null, label: "None", id: null });
            }
            return optionsArr;
        } else {
            return [];
        }
    };

    const processUserResponse = (res) => {
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((user) => {

                optionsArr.push({ value: user, label: `${user.firstName} ${user.lastName}`, id: user.id });

            });
            if (placeNone) {
                optionsArr.unshift({ value: null, label: "None", id: null });
            }
            return optionsArr;
        } else {
            GetOrg();
        }
    };

    const GetOrg = async () => {
        let searchReq = new SearchUserReq();
        searchReq.salesOrgId = SalesOrgId;
        searchReq.salesOrgIds = salesOrgIds;
        if (fiberCompaniesIds) searchReq.fiberCompaniesIds = fiberCompaniesIds;
        searchReq.excludeSalesOrgId = excludeSalesOrgId;
        searchReq.includeDeactivated = includeDeactivated
        if (conversationType === "Private") {
            searchReq.userTypes = ["SalesRep", "SalesOrgAdmin", "SuperAdmin"];
        } else if (conversationType === "CustomerService") {
            if (LoggedInUser.userType === "Customer")
                searchReq.userTypes = ["SalesRep"];
            else if (LoggedInUser.userType === "SalesRep")
                searchReq.userTypes = ["Customer"];
            else
                searchReq.userTypes = ["Customer"];
        } else if (conversationType === "Group") {
            searchReq.userTypes = ["SalesRep", "SalesOrgAdmin", "SuperAdmin"];
        }

        if (onlySelectedUserType) {
            searchReq.userTypes = [];
        }
        searchReq.userType = userType;
        if (userTypes) {
            searchReq.userTypes = userTypes;
        }
        searchReq.pagingParams.pageSize = 10;
        if (UsersSearch) {
            searchReq.nameString = UsersSearch;
        }

        if (conversationType === "SalesOrg" && LoggedInUser.userType === "SuperAdmin") {
            let searchReq = new SearchOrgReq();
            searchReq.pagingParams.pageSize = 10;
            if (UsersSearch) {
                searchReq.name = UsersSearch;
            }
            let res = await OrgApi.SearchOrg(searchReq);
            return processSalesOrgResponse(res);
        } else {
            let res = await UsersApi.SearchUser(searchReq);
            return processUserResponse(res);
        }
    };

    const CustomOption = ({ data, ...props }) => (
        <components.Option {...props}>
            {data.value !== null ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img className='w-12 h-12 rounded-full' src={data?.value?.profileImage ? data.value.profileImage.url : greyUser} alt={`${data?.value?.firstName} ${data?.value?.lastName}`} />
                    <p>{`${data?.value?.firstName} ${data?.value?.lastName}`}</p>
                </div>
            ) : (
                <p>{data.label}</p>
            )}
        </components.Option>
    );

    const handleUserSelectionChange = (selectedOptions) => {
        setUser(selectedOptions.map(selectedOptions => selectedOptions.id));
        if(setUserWithName){
            setUserWithName(selectedOptions)
        }
    };
    

    return (
        <>
            {renderUI && (
                <div className='selector'>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {conversationType === 'Private' ? (
                            <AsyncSelect
                                defaultOptions={options}
                                cacheOptions
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={(e) => { 
                                    setUser(e.id)
                                    if(setUserWithName){
                                        setUserWithName(e)
                                    }
                                }}
                                placeholder={placeholder}
                                defaultValue={userWithName}
                                isDisabled={disabled}
                                components={{ Option: CustomOption }}
                                classNamePrefix={'async_select'}
                            />
                        ) : null}

                        {(conversationType === 'SalesOrg' && LoggedInUser.userType === "SuperAdmin") ? (
                            <AsyncSelect
                                defaultOptions={options}
                                cacheOptions
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={(e) => { 
                                    setUser(e.id)
                                    if(setUserWithName){
                                        setUserWithName(e)
                                    }
                                }}
                                
                                defaultValue={userWithName}
                                placeholder={"Select a Sales Org"}
                                isDisabled={disabled}
                                classNamePrefix={'async_select'}
                            />
                        ) : null}

                        {(conversationType === 'Group') ? (
                            <AsyncSelect
                                defaultOptions={options}
                                cacheOptions
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={handleUserSelectionChange}
                                placeholder={placeholder}
                                isDisabled={disabled}
                                isMulti
                                defaultValue={userWithName}
                                components={{ Option: CustomOption }}
                                classNamePrefix={'async_select'}
                            />
                        ) : null}

                        {(conversationType !== 'Private' && conversationType !== 'SalesOrg' && conversationType !== 'Global' && conversationType !== 'Group') ? (
                            <AsyncSelect
                                defaultOptions={options}
                                defaultValue={userWithName}
                                cacheOptions
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={(e) => { setUser(e.id); }}
                                placeholder={placeholder}
                                isDisabled={disabled}
                                components={{ Option: CustomOption }}
                                classNamePrefix={'async_select'}
                            />
                        ) : null}
                    </SoftTypography>
                </div>
            )}
        </>
    );
};

export default Index;
