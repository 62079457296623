import { Mail, Phone, Star, WhatsApp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { BiMapPin, BiMessageSquare } from "react-icons/bi";
import StringIntReq from "../../../../Requests/StringIntReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import VersatileLoader from "../../../../components/VersatileLoader";
import ContactButtons from "../../../../Services/ContactButtons";
const MiniLeadCard = ({ isVisible, onClose, children, fiberHouse }) => {

    const [lead, setLead] = useState(null)
    const [loading, setLoading] = useState(true)

    const contactButtons = new ContactButtons();

    const getLeadData = async () => {
        setLoading(true)

        const req = new StringIntReq()
        req.int = fiberHouse.id;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
        if (res?.status?.success) {
            console.log("Lead", res.data)
            setLead(res.data);
            setLoading(false);
        }

    }

    useEffect(
        () => {
            getLeadData()
        }, [fiberHouse]
    )


    if (!isVisible) return null;
    const name = "JOHN WILSON"
    const initial = name.charAt(0)


    return (

        loading ?
            <div className="w-full max-w-2xl bg-white rounded-3xl shadow-lg p-6">
                <VersatileLoader color="#2761D0" size="medium" />
            </div>
            :
            <div className="w-full max-w-md bg-white rounded-3xl shadow-lg p-6">
                {/* Background Pattern */}

                <div className="relative">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-4">
                        <div className="flex items-center gap-3">
                            {/* Initial Avatar */}
                            <div className="w-10 h-10 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-bold">
                                {
                                    lead?.name?.charAt(0)
                                }
                            </div>
                            <div>
                                <h2 className="font-bold text-lg">{lead?.name}</h2>
                                <p className="text-sm text-blue-500">{lead?.fidiumCustomer ? "Customer" : lead?.isProspect ? "Prospect" : "Lead"}</p>
                            </div>
                        </div>
                        {
                            lead?.fidiumCustomer && lead?.fidiumCustomer?.fiberCompany &&
                            <img
                                src={"data:image/png;base64," + lead?.fidiumCustomer?.fiberCompany?.logo}
                                alt="Lightcurve"
                                className="h-7"
                            />
                        }
                    </div>

                    {/* Rating */}
                    {/* <div className="flex mb-4">
                    {[...Array(5)].map((_, i) => (
                        <Star key={i} className="w-5 h-5 fill-yellow-400 text-yellow-400" />
                    ))}
                </div> */}

                    {/* Main Content Grid */}
                    <div className={`grid ${lead?.fidiumCustomer ? "grid-cols-2" : "grid-cols-1"} gap-x-8 gap-y-4 mb-6`}>
                        {/* Left Column */}
                        <div className="space-y-2">
                            <div className="flex items-start text-xs text-gray-600">
                                <BiMapPin className="w-4 h-4 mr-2 flex-shrink-0 mt-1" />
                                <span>{lead?.fiberHouse?.mapString}</span>
                            </div>
                            {
                                lead?.phone &&
                                <div className="flex items-center text-xs text-gray-600">
                                    <Phone className="w-4 h-4 mr-2 flex-shrink-0 mt-1" />
                                    <span>{lead?.phone}</span>
                                </div>
                            }
                            {
                                lead?.email &&
                                <div className="flex items-center text-xs text-gray-600">
                                    <Mail className="w-4 h-4 mr-2 flex-shrink-0 mt-1" />
                                    <span>{lead?.email}</span>
                                </div>
                            }
                        </div>

                        {/* Right Column */}
                        {
                            lead?.fidiumCustomer &&
                            <div className="space-y-2">
                                {
                                    lead?.fidiumCustomer?.fiberPlan &&
                                    <div className="text-xs">
                                        <span className="text-navy-blue font-semibold">FIBER PLAN: </span>
                                        <span>{
                                            lead?.fidiumCustomer?.fiberPlan?.name
                                        }</span>
                                    </div>
                                }
                                <div className="text-xs">
                                    <span className="text-navy-blue font-semibold">ACCOUNT STATUS: </span>
                                    <span>{lead?.fidiumCustomer?.accountStatus}</span>
                                </div>
                                {
                                    lead?.fidiumCustomer?.installDateTime &&
                                    <div className="text-xs">
                                        <span className="text-navy-blue font-semibold">INSTALL DATE: </span>
                                        <span>{
                                            new Date(lead?.fidiumCustomer?.installDateTime).toLocaleDateString()
                                        }</span>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                        <button onClick={()=>{
                            contactButtons.emailLead(lead)
                        }} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                            <Mail className="w-5 h-5 text-gray-600" />
                        </button>
                        <button onClick={()=>{
                            contactButtons.whatsappLead(lead)
                        }} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                            <WhatsApp className="w-5 h-5 text-gray-600" />
                        </button>
                        <button onClick={()=>{
                            contactButtons.smsLead(lead)
                        }} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                            <BiMessageSquare className="w-5 h-5 text-gray-600" />
                        </button>
                        <button onClick={()=>{
                            contactButtons.callLead(lead)
                        }} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                            <Phone className="w-5 h-5 text-gray-600" />
                        </button>
                        <button onClick={()=>{
                            contactButtons.directionsLead(lead)
                        }} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                            <BiMapPin className="w-5 h-5 text-gray-600" />
                        </button>
                    </div>
                </div>
            </div>

    );
};

export default MiniLeadCard;