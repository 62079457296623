import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomerApi from '../../../API/CustomerApi'
import Sidebar from '../../../Layouts/Sidebar'
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import Card from "../../../components/Card"
import OrganizationSelector from '../../../components/OrganizationSelector'
import SoftButton from '../../../components/SoftButton'
import UserSelector from '../../../components/UserSelector'
import FiberCompanySelector from "../../../components/FiberCompanySelector"
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import Datatable from '../../../components/Datatable'
import Creatable from 'react-select/creatable';
import QuickDate from '../../../components/QuickDate'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import ContextMenu from '../../../components/ContextMenu'
import ExportData from '../../../Services/ExportData'
import DateReq from '../../../Requests/Customer/DateReq'
import SearchMapIconReq from '../../../Requests/MapIcon/SearchMapIconReq'
import MapIconApi from '../../../API/MapIconApi'
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq'
import FiberCompanyApi from '../../../API/FiberCompanyApi'
import { useState } from 'react'
import { Modal, Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import ReactDOM from 'react-dom';

const Index = () => {

    const navigate = useNavigate()

    const [search, setSearch] = React.useState('')
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const [customers, setCustomers] = React.useState([])
    const [orderNumber, setOrderNumber] = React.useState(null)
    const [lastName, setLastName] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [phone, setPhone] = React.useState(null)
    const [address, setAddress] = React.useState(null)
    const [fiberPlan, setFiberPlan] = React.useState(null)
    const [status, setStatus] = React.useState(null)
    const [notes, setNotes] = React.useState(null)
    const [salesOrgId, setSalesOrgId] = React.useState([])
    const [salesRepId, setSalesRepId] = React.useState([])
    const [hotLead, setHotLead] = React.useState(null);
    const [installDateFrom, setInstallDateFrom] = React.useState(null);
    const [installDateTo, setInstallDateTo] = React.useState(null);
    const [timeStampFrom, setTimeStampFrom] = React.useState(null);
    const [timeStampTo, setTimeStampTo] = React.useState(null);
    const [desiredInstallDateString, setDesiredInstallDateString] = React.useState(null);
    const [timeZone, setTimeZone] = React.useState(null);
    const [freeMonth, setFreeMonth] = React.useState(null);
    const [promoCode, setPromoCode] = React.useState(null);
    const [fourDigitPin, setFourDigitPin] = React.useState(null);
    const [secondaryPhone, setSecondaryPhone] = React.useState(null);
    const [desiredInstallDate, setDesiredInstallDate] = React.useState(null);
    const [desriedInstallTime, setDesiredInstallTime] = React.useState(null);
    const [installStatuses, setInstallStatuses] = React.useState([]);
    const [fiberCompanyIds, setFiberCompanyIds] = React.useState([])
    const [pageSize, setPageSize] = React.useState(20)
    const [selectedCustomers, setSelectedCustomers] = React.useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [accountStatuses, setAccountStatuses] = React.useState([])
    const exportData = new ExportData();
    const [selectedColumns, setSelectedColumns] = React.useState(['id'])
    const [signedUpBy, setSignedUpBy] = React.useState(null)
    const [mapIcons, setMapIcons] = React.useState([])
    const [fiberCompaniesForDisplay, setFiberCompaniesForDisplay] = React.useState([])
    const [totalCustomers, setTotalCustomers] = React.useState(0)

    const allFields = [
        { key: "id", label: "ID" },
        { key: "fiberCompany", label: "Fiber Company" },
        { key: "salesOrgName", label: "Sales Org Name" },
        { key: "salesRep", label: "Sales Representative" },
        { key: "leadType", label: "Lead Type" },
        { key: "mapIcon", label: "Map Icon" },
        { key: "lightningNotes", label: "Lightning Notes" },
        { key: "accountStatus", label: "Account Status" },
        { key: "soldTimestamp", label: "Sold Timestamp" },
        { key: "contactName", label: "Contact Name" },
        { key: "secondaryContactName", label: "Secondary Contact Name" },
        { key: "phone", label: "Phone" },
        { key: "email", label: "Email" },
        { key: "address", label: "Address" },
        { key: "originalInstallDate", label: "Original Install Date" },
        { key: "updatedInstallDate", label: "Updated Install Date" },
        { key: "internetType", label: "Internet Type" },
        { key: "fiberPlan", label: "Fiber Plan" },
        { key: "promos", label: "Promos" },
        { key: "noOfReviews", label: "Number of Reviews" },
        { key: "typeOfReview", label: "Type of Review" },
        { key: "locationOfReview", label: "Location of Review" },
        { key: "noOfIssues", label: "Number of Issues" },
        { key: "accountIssue", label: "Account Issue" },
        { key: "issueDescription", label: "Issue Description" },
        { key: "issueSubmittedTimestamp", label: "Issue Submitted Timestamp" },
        { key: "issueResolved", label: "Issue Resolved" },
        
    ];
    
    const getMapIcons = async () => {
        const req = new SearchMapIconReq()
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 1000

        const res = await MapIconApi.SearchMapIcon(req)

        if (res.status.success) {
            setMapIcons(res.data.list)
        }
        else {
            console.error(res.status.message)
        }
    }

    const getFiberCompanies = async () => {
        const req = new SearchFiberCompanyReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1000;

        const res = await FiberCompanyApi.SearchFiberCompany(req)

        if (res.status.success) {
            setFiberCompaniesForDisplay(res.data.list)
        }
        else {
            console.error(res.status.message)
        }

    }

    useEffect(
        () => {
            getFiberCompanies();
            getMapIcons();
        }, []
    )

    const ExportExcel = async () => {
        Swal.fire({
            icon: "info",
            title: "Generating Report",
            text: "Please Wait ...",
            toast: true,
            position: "top-right",
            showConfirmButton: false,

            didOpen: () => {
                Swal.showLoading()
            }
        })

        let req = new DateReq();
        req.dateFrom = timeStampFrom;
        req.dateTo = timeStampTo;

        let res = await CustomerApi.ExportReport(req);
        console.log(res);
        var csvURL = window.URL.createObjectURL(res);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Report.xlsx');
        tempLink.click();

        Swal.close();
    }

    const searchCustomers = () => {
        setLoading(true)
        const req = new SearchCustomerReq()
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = pageSize
        req.lastName = lastName
        req.email = email
        req.cellPhone = phone
        req.address = address
        req.fiberPlanString = fiberPlan
        req.accountStatus = status
        req.searchString = search
        req.userIds = salesRepId
        req.salesOrgIds = salesOrgId
        req.fiberCompanyIds = fiberCompanyIds
        req.desiredInstallDateString = desiredInstallDateString
        req.desiredInstallTime = desriedInstallTime

        req.installStatuses = installStatuses.map(
            (item, index) => {
                return item.value
            }
        )

        req.accountStatuses = accountStatuses.map(
            (item, index) => {
                return item.value
            }
        )

        if (desiredInstallDate) {
            req.desiredInstallDate = desiredInstallDate
        }
        if (secondaryPhone) {
            req.secondaryPhone = secondaryPhone
        }
        if (promoCode) {
            req.promoCode = promoCode
        }
        if (fourDigitPin) {
            req.fourDigitPin = fourDigitPin
        }
        if (hotLead) {
            req.isHotLead = hotLead
        }
        if (installDateFrom) {
            req.installDateTimeFrom = installDateFrom
        }
        if (installDateTo) {
            req.installDateTimeTo = installDateTo
        }
        if (timeStampFrom) {
            req.timeStampFrom = timeStampFrom
        }
        if (timeStampTo) {
            req.timeStampTo = timeStampTo
        }
        if (timeZone) {
            req.timeZone = timeZone
        }
        if (freeMonth) {
            req.freeMonth = freeMonth
        }
        if (signedUpBy) {
            req.signedUpBy = [signedUpBy]
        }
        console.log(req)
        CustomerApi.SearchFidiumCustomers(req).then(
            (res) => {
                console.log(res)
                res?.data?.list.map(
                    (item) => {

                        item.info = <div onClick={() => {
                            navigate(`/CustomerDetails/${item.id}`)
                        }} >
                            <img src={'/images/digital_tool_belt/customerInfo.svg'} alt="info" />
                        </div>
                        item.select = <input onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedCustomers((prev) => {
                                    return [...prev, item.leadId]
                                })
                            } else {
                                setSelectedCustomers((prev) => {
                                    return prev.filter((id) => {
                                        return id !== item.leadId
                                    })
                                })
                            }


                        }
                        }
                            type='checkbox' className='selects' />
                        item.timeStamp = item.timeStamp ? new Date(item.timeStamp).toLocaleString(
                            'en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                        ) : ""
                        item.fiberCompanyName = item.fiberCompany?.name
                        item.salesRepName = item.salesRep?.firstName + " " + item.salesRep?.lastName
                        item.address = item.address ? item.address : item?.lead?.fiberHouse?.address
                        item.customerName = item.firstName + " " + item.lastName
                        item.installDateTime = item.installDateTime ? new Date(item.installDateTime).toLocaleString(
                            'en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                        ) : ""
                        item.p = item.promo
                        item.fiberPlanName = item.fiberPlan?.name
                        item.promo = <div>
                            {
                                item?.promo?.split('\n').map(
                                    (p, index) => {
                                        return <p key={index} style={{
                                            borderBottom: "1px solid #ccc",
                                        }}>{p}</p>
                                    }
                                )
                            }
                        </div>
                    }
                )
                setCustomers(res?.data?.list)
                setTotalCustomers(res?.data?.totalItems)
                setTotalPages(res?.data?.totalPages)
                setLoading(false)
            }
        )

    }

    const handleSelectedColumn = (e, key) => {
        if (e.target.checked) {
            setSelectedColumns((prev) => [...prev, key])
        }
        else {
            setSelectedColumns((prev) => prev.filter((item) => item !== key))
        }
    }

    const headers = [
        {
            name: "",
            key: "select"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'timeStamp') }} type="checkbox" /> */}
                <p>Time Stamp</p>
            </div>,
            key: "timeStamp"
        },
        {
            name: "Customer ID",
            key: "id"
        },

        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'customerName') }} type="checkbox" /> */}
                <p>Name</p>
            </div>,
            key: "customerName"
        },

        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }} >
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'address') }} type="checkbox" /> */}
                <p>Address</p>
            </div>,
            key: "address"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'email') }} type="checkbox" /> */}
                <p>Email</p>
            </div>,
            key: "email"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'cellphone') }} type="checkbox" /> */}
                <p>Phone</p>
            </div>,
            key: "cellPhone"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'address') }} type="checkbox" /> */}
                <p>Address</p>
            </div>,
            key: "address"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'salesRep') }} type="checkbox" /> */}
                <p>Sales Rep</p>
            </div>,
            key: "salesRepName"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'accountStatus') }} type="checkbox" /> */}
                <p>Account Status</p>
            </div>,
            key: "accountStatus"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'installDateTime') }} type="checkbox" /> */}
                <p>Install Date</p>
            </div>,
            key: "installDateTime"
        },
        {
            name: <div className='' style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'fiberPlanName') }} type="checkbox" /> */}
                <p>Fiber Plan</p>
            </div>,
            key: "fiberPlanName"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'promo') }} type="checkbox" /> */}
                <p>Promo</p>
            </div>,
            key: "promo"
        },
        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'fiberCompanyName') }} type="checkbox" /> */}
                <p>Fiber Company</p>
            </div>,
            key: "fiberCompanyName"
        },

        {
            name: <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                {/* <input className='c_select' onChange={(e) => { handleSelectedColumn(e, 'accountNotes') }} type="checkbox" /> */}
                <p>Signed Up By</p>
            </div>,
            key: "signedUpBy"
        },
        {
            name: "Info",
            key: "info"
        }
    ]


    useEffect(
        () => {
            searchCustomers()
        }, [currentPage, pageSize]
    )

    useEffect(
        () => {
            console.log(selectedCustomers)
        }, [selectedCustomers]
    )


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setTimeout(() => {
                searchCustomers()
            }, 1000)
        }
    };

    // const getDataToExport = async (type) => {
    //     Swal.fire({
    //         icon: "info",
    //         title: "Generating Report",
    //         text: "Please Wait ...",
    //         // toast: true,
    //         // position: "top-right",
    //         showConfirmButton: false,

    //         didOpen: () => {
    //             Swal.showLoading()
    //         }
    //     })

    //     let data = customers.map(
    //         (c) => {

    //             return {
    //                 ...c.lead,
    //                 fidiumCustomer: {
    //                     ...c,
    //                     lead: null,
    //                     promo: c.p
    //                 }
    //             }
    //         }
    //     )

    //     let l = exportData.dataToExport(data, fiberCompaniesForDisplay, mapIcons)
    //     if (type === "csv") {
    //         exportData.ExportToCsv("Customers.csv", l, selectedCustomers)
    //     }
    //     else {
    //         exportData.ExportToXLSX("Customers.xlsx", l, selectedCustomers)
    //     }

    //     Swal.fire({
    //         icon: "success",
    //         title: "Data Exported",
    //         showCancelButton: true,
    //         didOpen: () => {
    //             Swal.hideLoading()
    //         }
    //     })
    // }


    

    const getDataToExport = async (type) => {
        const selectedFields = await exportData.promptFieldSelection(allFields);
    
        if (selectedFields.length === 0) {
            Swal.fire({
                icon: "warning",
                title: "No Fields Selected",
                text: "Please select at least one field to export.",
            });
            return;
        }
        
    
        Swal.fire({
            icon: "info",
            title: "Generating Report",
            text: "Please Wait ...",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let data = customers.map((c) => {
            return {
                ...c.lead,
                fidiumCustomer: {
                    ...c,
                    lead: null,
                    promo: c.p
                }
            };
        });
    
        let l = exportData.dataToExport(data, fiberCompaniesForDisplay, mapIcons, selectedFields);
        if (type === "csv") {
            exportData.ExportToCsv("Customers.csv", l, selectedCustomers);
        } else {
            exportData.ExportToXLSX("Customers.xlsx", l, selectedCustomers);
        }
    
        Swal.fire({
            icon: "success",
            title: "Data Exported",
            showCancelButton: true,
            didOpen: () => {
                Swal.hideLoading();
            }
        });
    };

    

    return (
        <Layout>
            <div className=' SearchTableLayout ManageCustomersDiv'>
                <div className='SearchCard'>
                    <div className='SearchDiv'>
                        <h3 className='SearchTitle' >Search Customers</h3>
                        <div className='SearchInputs'>
                            <input className='SearchInput search_input' onKeyDown={handleKeyPress} value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
                            {
                                expanded &&
                                <>
                                    <input className='SearchInput search_input' value={orderNumber} onKeyDown={handleKeyPress} onChange={(e) => { setOrderNumber(e.target.value) }} placeholder='Order Number' />
                                    {
                                        /* <div className='date_div'>
                                            <label>Time From</label>
                                            <input type='date' className='SearchInput' value={timeStampFrom} onKeyDown={handleKeyPress} onChange={(e) => setTimeStampFrom(e.target.value)} placeholder='Time Stamp From' />
                                        </div>
                                        <div className='date_div'>
                                            <label>Time To</label>
                                            <input type='date' className='SearchInput' value={timeStampTo} onKeyDown={handleKeyPress} onChange={(e) => setTimeStampTo(e.target.value)} placeholder='Time Stamp To' />
                                        </div> */
                                    }

                                    <QuickDate setFrom={setTimeStampFrom} setTo={setTimeStampTo} label='Quick Time Stamp' />
                                    {/* <div className='date_div'>
                                        <label htmlFor='install_date_from'>Install Date From</label>
                                        <input id='install_date_from' type='date' className='SearchInput' onKeyDown={handleKeyPress} value={installDateFrom} onChange={(e) => setInstallDateFrom(e.target.value)} placeholder='Install Date From' />
                                    </div>
                                    <div className='date_div'>
                                        <label htmlFor='install_date_to'>Install Date To</label>
                                        <input type='date' id='install_date_to' className='SearchInput' onKeyDown={handleKeyPress} value={installDateTo} onChange={(e) => setInstallDateTo(e.target.value)} placeholder='Install Date To' />
                                    </div> */}

                                    {/* <select style={{
                                        gridColumn: 'span 2'
                                    }}>
                                        <option value="">Quick Install Date</option>
                                        <option value="Today">Today</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                        <option value="This Year">This Year</option>
                                        <option value="All Time">All Time</option>
                                    </select> */}
                                    <QuickDate setFrom={setInstallDateFrom} setTo={setInstallDateTo} onKeyDown={handleKeyPress} label='Quick Install Date' />
                                    {/* <div className='date_div'>
                                        <label htmlFor='desired_install_date'>Desired Install Date</label>
                                        <input id='desired_install_date' type='date' className='SearchInput' value={desiredInstallDateString} onChange={(e) => setDesiredInstallDateString(e.target.value)} placeholder='Desired Install Date' />
                                    </div>
                                    <div className='date_div'>
                                        <label htmlFor='desired_install_time'>Desired Install Time</label>
                                        <input id='desired_install_time' type='time' className='SearchInput' value={desriedInstallTime} onChange={(e) => setDesiredInstallTime(e.target.value)} placeholder='Desired Install Time' />
                                    </div> */}
                                    <OrganizationSelector multiple={true} placeholder='Select Sales Org' onKeyDown={handleKeyPress} setUser={setSalesOrgId} />
                                    <UserSelector salesOrgIds={salesOrgId} conversationType={'Group'} setUser={setSalesRepId} onKeyDown={handleKeyPress} userTypes={["SalesRep", "SalesOrgAdmin"]} placeholder='Select Sales Rep' />
                                    <FiberCompanySelector multiple={true} setUser={setFiberCompanyIds} onKeyDown={handleKeyPress} placeholder='Select Fiber Companies' />

                                    <div >
                                        <Creatable isMulti={true} onChange={(e) => { console.log(e); setInstallStatuses(e) }} onKeyDown={handleKeyPress} placeholder='Install Statuses' />
                                    </div>
                                    <div >
                                        <Creatable isMulti={true} onChange={(e) => { console.log(e); setAccountStatuses(e) }} onKeyDown={handleKeyPress} placeholder='Account Statuses' />
                                    </div>
                                    <select onChange={(e) => {
                                        setSignedUpBy(e.target.value)
                                    }} value={signedUpBy} >
                                        <option value="">Select SignedUp By</option>
                                        <option value="Sales Rep">Sales Rep</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Self Signup">Self Signup</option>
                                        <option value="Offer">Offer</option>
                                    </select>
                                </>
                            }
                            <div className='expand_icon'>
                                {
                                    expanded ?
                                        <MdExpandLess size={30} className='ExpandIcon' onClick={() => setExpanded(false)} />
                                        :
                                        <MdExpandMore size={30} className='ExpandIcon' onClick={() => setExpanded(true)} />
                                }
                            </div>

                        </div>
                        <button className='SearchButton' onClick={searchCustomers} >Search</button>
                    </div>
                </div>

                {
                    loading ?
                        <>

                            <div className='spinner'>
                                <CircularProgress sx={{ color: "var(--color-icon)" }} />
                            </div>
                        </>
                        :
                        <>
                            <div className='button_div' >

                                <button onClick={() => { ExportExcel() }} style={{
                                    backgroundColor: "var(--color-primary)",
                                    color: "white",
                                    padding: "0.5rem 1rem",
                                    borderRadius: "10px",
                                    border: "1px solid white"
                                }}>Sales Report (Timestamp Only)</button>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    backgroundColor: "var(--color-primary)",
                                    color: "white",
                                    borderRadius: "10px",
                                    padding: "0.5rem 1rem"
                                }}>
                                    <p>Page Size</p>
                                    <select style={{
                                        backgroundColor: "var(--color-primary)",
                                        border: "1px solid white",
                                        borderRadius: "10px",
                                    }} value={pageSize} onChange={(e) => {
                                        setPageSize(e.target.value)
                                    }}>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                        <option value="2000">2000</option>
                                    </select>
                                </div>
                                <button onClick={() => {
                                    setSelectedCustomers(customers.map((item, index) => {
                                        return item.leadId
                                    }))
                                    document.querySelectorAll('.selects').forEach((item) => {
                                        item.checked = true
                                    })
                                }} >Select All</button>
                                <button onClick={() => {
                                    setSelectedCustomers([])
                                    document.querySelectorAll('.selects').forEach((item) => {
                                        item.checked = false
                                    })
                                }}>Deselect All</button>
                                <button style={{ display: "flex", alignItems: "center" }} onClick={(e) => { setAnchorEl(e.currentTarget) }}>Export | <MdExpandMore size={20} color='white' /></button>
                                <ContextMenu handleClose={() => {
                                    setAnchorEl(null)
                                }} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={[
                                    {
                                        title: "Export to XLSX",
                                        onClick: () => {
                                            getDataToExport("xlsx");
                                        }
                                    },
                                    {
                                        title: "Export to CSV",
                                        onClick: () => {
                                            getDataToExport("csv");
                                        }
                                    }
                                ]} />
                            </div>
                            <div style={{
                                width: "100%",
                                marginBottom: "0.5rem"
                            }}>
                                <h3 style={{
                                    color: "var(--color-primary)",
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    textAlign: "left"
                                }}>Total Customers: {totalCustomers}</h3>
                            </div>
                            <div className='TableDiv'>
                                <Datatable headers={headers} list={customers} currentPage={currentPage} totalPages={totalPages} />
                                <div className='pagination'>
                                    <Pagination color='light' renderItem={(item) => (
                                        <PaginationItem components={{

                                            next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                            first: (props) => <button {...props}>First</button>,
                                            previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                        }}  {...item} sx={{ color: "white" }} />
                                    )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />




                                </div>
                            </div>
                        </>
                }

            </div>
        </Layout>
    )
}

export default Index