import { useState, useCallback, useEffect } from 'react';

export function useColumnOrder(initialHeaders, initialData) {
  const [headers, setHeaders] = useState(initialHeaders);
  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState(null);
  const dates = ['timeStamp', 'originalInstallDate', 'updatedInstallDate', 'issueTimestamp'];

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const moveColumn = useCallback((dragIndex, hoverIndex) => {
    let newHeaders = [];
    if (dragIndex === hoverIndex) {
      newHeaders = headers;
    } else {
      newHeaders = [...headers];
      const [draggedHeader] = newHeaders.splice(dragIndex, 1); // Remove the dragged header
      newHeaders.splice(hoverIndex, 0, draggedHeader); // Insert it at the hover index
    }
    setHeaders(newHeaders);
  }, [headers]);

  const mergeSort = (array, key, direction) => {
    if (array.length <= 1) {
      return array;
    }

    const middle = Math.floor(array.length / 2);
    const left = array.slice(0, middle);
    const right = array.slice(middle);

    return merge(
      mergeSort(left, key, direction),
      mergeSort(right, key, direction),
      key,
      direction
    );
  };

  const merge = (left, right, key, direction) => {
    let result = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      let leftValue = left[leftIndex][key];
      let rightValue = right[rightIndex][key];

      if (dates.includes(key)) {
        leftValue = new Date(leftValue);
        rightValue = new Date(rightValue);
      } else {
        leftValue = Array.isArray(leftValue) ? leftValue.join(',') : leftValue;
        rightValue = Array.isArray(rightValue) ? rightValue.join(',') : rightValue;
      }

      if (
        (direction === 'asc' && leftValue < rightValue) ||
        (direction === 'desc' && leftValue > rightValue)
      ) {
        result.push(left[leftIndex]);
        leftIndex++;
      } else {
        result.push(right[rightIndex]);
        rightIndex++;
      }
    }

    return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  };

  const sortData = useCallback((key) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    setData((prevData) => mergeSort([...prevData], key, direction));
  }, [sortConfig]);

  const updateData = useCallback((rowIndex, columnKey, value) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], [columnKey]: value };
      return newData;
    });
  }, []);

  return { headers, setHeaders, data, moveColumn, sortData, sortConfig, updateData };
}