import React from 'react'
import Layout from '../../../Layouts/Layout'
import { TabProvider } from './TabContext'
import { Tabs } from './Tabs'
import { ContentArea } from './ContentArea'
const Index = () => {


    return (
        <Layout>
            <TabProvider>
                <div className="flex flex-col flex-1">
                    <Tabs />
                    <ContentArea   />
                </div>
            </TabProvider>
        </Layout>
    )
}

export default Index