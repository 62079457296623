import React, { useState } from 'react'
import Layout from '../../../Layouts/Layout'
import { ArrowLeft, Search } from '@mui/icons-material'
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import CustomerApi from '../../../API/CustomerApi'
import StringIntReq from '../../../Requests/StringIntReq'

const Index = () => {

    const [so, setSo] = useState('')
    const [agreement, setAgreement] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [address, setAddress] = useState('')
    const [searchResults, setSearchResults] = useState([])

  
    const handleClawBack = (customerId) => {
       
        const req = new StringIntReq()
        req.int = customerId
        CustomerApi.ClawBackCustomer(req).then(
            (res) => {
                console.log(res)
                if (res?.status.success) {
                    searchCustomers()
                }
            }
        )
        
    }


    const searchCustomers = () => {
        //  setLoading(true)
        const req = new SearchCustomerReq()
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 100

        if (fromDate) {
            req.installDateTimeFrom = fromDate
        }
        if (toDate) {
            req.installDateTimeTo = toDate
        }

        if(so){
            req.orderNumber = so
        }

        if(agreement){
            req.agreement = agreement
        }

        console.log(req)
        CustomerApi.SearchFidiumCustomers(req).then(
            (res) => {
                console.log(res)
                res?.data?.list.map(
                    (item) => {



                        item.timeStamp = item.timeStamp ? new Date(item.timeStamp).toLocaleString(
                            'en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                        ) : ""
                        item.fiberCompanyName = item.fiberCompany?.name
                        item.salesRepName = item.salesRep?.firstName + " " + item.salesRep?.lastName
                        item.address = item.address ? item.address : item?.lead?.fiberHouse?.address
                        item.customerName = item.firstName + " " + item.lastName
                        item.installDateTime = item.installDateTime ? new Date(item.installDateTime).toLocaleString(
                            'en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                        ) : ""
                        item.p = item.promo
                        item.fiberPlanName = item.fiberPlan?.name
                        item.promo = <div>
                            {
                                item?.promo?.split('\n').map(
                                    (p, index) => {
                                        return <p key={index} style={{
                                            borderBottom: "1px solid #ccc",
                                        }}>{p}</p>
                                    }
                                )
                            }
                        </div>
                        item.clawBack =  <button
                        onClick={() => handleClawBack(item.id)}
                        disabled={item.isClawedBack}
                        className={`flex items-center ${
                            item.isClawedBack
                            ? 'text-gray-400 cursor-not-allowed'
                            : 'text-red-600 hover:text-red-900'
                        }`}
                      >
                        <ArrowLeft className="w-4 h-4 mr-1" />
                        {item.isClawedBack ? 'Clawed Back' : 'Claw Back'}
                      </button>
                    }
                )
                setSearchResults(res?.data?.list)

            }
        )

    }

    const headers = [
        {
            name: "Claw Back",
            key: "clawBack"
        },
        {
            name: "Time Stamp",
            key: "timeStamp"
        },
        {
            name: "Customer Name",
            key: "customerName"
        },

        {
            name: "Address",
            key: "address"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Phone",
            key: "cellPhone"
        },
        {
            name: "Sales Rep",
            key: "salesRepName"
        },
        {
            name: "Account Status",
            key: "accountStatus"
        },
        {
            name: "Install Date",
            key: "installDateTime"
        },
        {
            name: "Fiber Plan",
            key: "fiberPlanName"
        },
        {
            name: "Promo",
            key: "promo"
        },
        {
            name: "Fiber Company",
            key: "fiberCompanyName"
        },

        {
            name: "Signed Up By",
            key: "signedUpBy"
        },
    ]

    return (
        <Layout>
            <div className=" mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Customer Search</h1>

                <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                        <div>
                            <label htmlFor="so" className="block text-sm font-medium text-gray-700 mb-1">SO</label>
                            <input
                                type="text"
                                id="so"
                                value={so}
                                onChange={(e) => setSo(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter SO"
                            />
                        </div>
                        <div>
                            <label htmlFor="agreement" className="block text-sm font-medium text-gray-700 mb-1">Agreement</label>
                            <input
                                type="text"
                                id="agreement"
                                value={agreement}
                                onChange={(e) => setAgreement(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter Agreement"
                            />
                        </div>
                        <div>
                            <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700 mb-1">Install Date From</label>
                            <input
                                type="date"
                                id="fromDate"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="toDate" className="block text-sm font-medium text-gray-700 mb-1">Install Date To</label>
                            <input
                                type="date"
                                id="toDate"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                            <input
                                type="text"
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter address"
                            />
                        </div>
                    </div>
                    <button
                        onClick={searchCustomers}
                        className="w-full md:w-auto px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center justify-center"
                    >
                        <Search className="w-5 h-5 mr-2" />
                        Search
                    </button>
                </div>

                {searchResults.length > 0 && (
                    <div style={{ width: "88vw" }} className="bg-white rounded-lg shadow-md ">
                        <div className="overflow-x-auto ">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {
                                            headers.map((header) => (
                                                <th key={header.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{header.name}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {searchResults.map((customer) => (
                                        <tr key={customer.id}>
                                            {headers.map((header) => (
                                                <td key={header.key} className="px-6 py-4 whitespace-nowrap">
                                                    {customer[header.key]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Index