import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CandidateStats from './CandidateStats';
import './TabbedStatsPage.scss';
import Stats from './Stats';
import { MapContext as MP } from '..';

const TabbedStatsPage = ({ fiberHouses, candidates, recruitMode }) => {
  const [value, setValue] = useState(0); // Set default tab to be 'Fiber Houses'
  const map = useContext(MP);

  useEffect(() => {
    if (map?.fiberHouseIds?.length > 0) {
      setValue(0); // Set to Fiber Houses tab
    } else if (map?.candidateIds?.length > 0) {
      setValue(1); // Set to Candidates tab
    } else if (
      map?.candidateIds?.length > 0 &&
      map?.fiberHouseIds?.length > 0
    ) {
      setValue(0); // Default to Fiber Houses tab if both exist
    }
    console.log("Fiber Houses Count:", map?.fiberHouseIds?.length);
    console.log("Candidate Count:", map?.candidateIds?.length);
    console.log("Current Tab Value:", value);
  }, [map?.fiberHouseIds, map?.candidateIds]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabbed-stats-page">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="stats tabs">
          {(map?.fiberHouseIds && map?.fiberHouseIds.length > 0) && (
            <Tab label="Lead Stats"
              disabled = {!(map?.candidateIds && map.candidateIds.length > 0)}
             />
          )}
          {( (map?.candidateIds && map.candidateIds.length > 0)) && (
            <Tab label="Candidate Stats"
              disabled = {!(map?.fiberHouseIds && map?.fiberHouseIds.length > 0)}
             />
          )}
        </Tabs>
      </Box>
      <Box className="tab-content">
        {value === 0 && (map?.fiberHouseIds && map?.fiberHouseIds.length > 0) && <Stats fiberHouses={fiberHouses} />}
        {value === 1 && (recruitMode || (map?.candidateIds && map.candidateIds.length > 0)) && <CandidateStats candidates={candidates} />}
      </Box>
    </div>
  );
};

export default TabbedStatsPage;
