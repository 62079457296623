import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card, CardContent, Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box, Grid } from '@mui/material';
import Swal from 'sweetalert2';
import PublicLayout from '../../../Layouts/PublicLayout';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import logo from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png";
import CreateJobApplicationReq from '../../../Requests/Users/CreateJobApplicationReq';
import CreateFileReq from '../../../Requests/CloudFile/CreateFileReq';
import UsersApi from '../../../API/UsersApi';
import { useParams } from 'react-router-dom';
import StringIntReq from '../../../Requests/StringIntReq';
import VersatileLoader from './../../../components/VersatileLoader';

const RecruitInvitation = () => {
    const { id } = useParams();
    const [isAccepted, setIsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [candidate, setCandidate] = useState(null);

    const [jobDescription, setJobDescription] = useState(null);
    const [requirements, setRequirements] = useState([]);
    const [position, setPosition] = useState(null);
    const [candidateName, setCandidateName] = useState(null);
    const [templateImage, setTemplateImage] = useState(null);
    const [jobAccepted, setJobAccepted] = useState(null);


    //get candidate invitation by id
    const getCandidateInvitation = async () => {
        let req = new StringIntReq();
        req.string = id;
        const res = await UsersApi.GetCandidateInvitationById(req);

        if (res?.status?.success) {
            const { data } = res;
            setJobDescription(data.candidateRecruitTemplate.jobDescription ? data.candidateRecruitTemplate.jobDescription : "Join us as a Fiber Consultant, where you'll play a vital role in promoting Lightning OS fiber internet solutions through door-to-door visits, forging strong connections with potential customers. Collaborating closely with our sales team, you'll surpass targets and drive sustainable revenue growth. This role offers an exciting chance to make an impact and grow with us. Apply now to join our dynamic team!");
            setRequirements(data.candidateRecruitTemplate.jobRequirements.split('\n') ? data.candidateRecruitTemplate.jobRequirements.split('\n') : ["Must be 18 years or older", "Must have a valid driver's license", "Must have a reliable vehicle", "Must be able to work in the US", "Must be able to pass a background check", "Must be able to work in a fast-paced"]);
            setPosition(data.candidateRecruitTemplate.positionTitle ? data.candidateRecruitTemplate.positionTitle : "Fiber Consultant");
            setTemplateImage(data.candidateRecruitTemplate.templateImage.url);

            setCandidateName(data.candidate.name);
            setCandidate(data.candidate);
            setIsAccepted(data.candidate.offerAccepted);
            if(data.candidate.offerAccepted){
                setJobAccepted(data.candidate.jobAccepted);
            }
            else if(data.candidate.offerAccepted === false){
                setJobAccepted(false);
            }

            setIsLoading(false);
        }
    };

    const declineOffer = async () => {
        const option = await Swal.fire({
            title: 'Are you sure you want to decline this offer?',
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
        });

        if (!option.isConfirmed) {
            setIsAccepted(true);
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Proccessing your request",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },

        })

        let req = new StringIntReq();
        req.int = candidate.id;
        req.bool = false;

        const res = await UsersApi.CandidateAcceptOffer(req);

        if (res?.status?.success) {

            Swal.fire({
                icon: "success",
                title: "Offer Declined",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading();
                },
            });

            setIsAccepted(true);
            setJobAccepted(false);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading();
                },
            });
        }
    }


    const acceptOffer = async () => {

        Swal.fire({
            icon: "info",
            title: "Proccessing your request",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },

        })

        let req = new StringIntReq();
        req.int = candidate.id;
        req.bool = true;

        const res = await UsersApi.CandidateAcceptOffer(req);

        if (res?.status?.success) {

            Swal.fire({
                icon: "success",
                title: "Offer Accepted",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading();
                },
            });

            setIsAccepted(true);
            setJobAccepted(true);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading();
                },
            });
        }
    }



    useEffect(() => {
        getCandidateInvitation();
    }, []);


    if (isAccepted) {
        return (
            <PublicLayout>
                <Box className='thank_you_container'>
                    <Card>
                        <CardContent>
                            {
                                jobAccepted ?
                                    <Typography variant="h2">Thank You {candidateName}, we will be in touch soon!</Typography>
                                    : <Typography variant="h2">Thank You {candidateName}, sorry we missed you this time!</Typography>

                            }
                        </CardContent>
                    </Card>
                </Box>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout>
            <Box className='opportunity_container'>
                {!isLoading ?

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card className='job_description'>
                                <CardContent>
                                    <Typography variant="h2">Hey There, {candidateName} </Typography>
                                    <Typography variant="h5" component="h3" gutterBottom>Job Description</Typography>
                                    <Typography variant="body1" paragraph>{jobDescription}</Typography>
                                    <Typography variant="h5" component="h3" gutterBottom>Requirements and Responsibilities</Typography>
                                    <Box className='requirements'>
                                        {requirements.map((requirement, index) => (
                                            <Box key={index} className='requirement_item'>
                                                <Box className='icon_wrapper'>
                                                    <FaCheck color='#2761D0' size={20} />
                                                </Box>
                                                <Typography variant="body1">{requirement}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box component="img" src={templateImage} alt="map" className='location_map' />
                                    <Typography variant="body1" align="center" color="#2761D0">Stay Tuned For More Upcoming Positions</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className='job_application_form'>
                                <CardContent className='form_content'>
                                    <Box component="img" src={logo} alt='Lightning_Logo' className='company_logo' />
                                    <Box className='form_fields'>
                                        <Typography sx={{ textAlign: "center" }} variant="h2" gutterBottom>Job Offer</Typography>
                                        <Typography sx={{
                                            textAlign: "center",
                                        }} variant="body1" paragraph>You have received a job offer. Would you like to accept this job offer?</Typography>

                                        <div className='flex gap-2'>
                                            <button onClick={()=>{
                                                acceptOffer();
                                            }}>Accept</button>
                                            <button style={{
                                                backgroundColor: '#e43e46',
                                                color: 'white'
                                            }} onClick={()=>{
                                                declineOffer();
                                            }}>Decline</button>
                                        </div>

                                    </Box>
                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                    : <VersatileLoader size='large' color='#fff' />

                }
            </Box>
        </PublicLayout>
    );
};

export default RecruitInvitation;