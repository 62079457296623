import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import MobileMenuMoreOptions from './MobileMenuMoreOptions';
import { MenuItemsContext } from '../../App';
import { FaEllipsisVertical } from "react-icons/fa6";

const MobileLayoutNavbar = () => {

    const menuItems = useContext(MenuItemsContext)
    const [open,setOpen] = useState(null) 
    const navigate = useNavigate()
    const route = useLocation().pathname.split("/").slice(1);
    const [style, setStyle] = useState({})
    const items = menuItems.map(item => {
        if (item.groupName){
            return  item.items
            
        }
        return item


        
    })
    .flat()
    console.log(items)

    // useEffect(() => {
    //     if (route[route.length - 1] === "map") {
    //         setStyle({
    //             position: 'fixed',
    //             bottom: '0',
    //             width: '99vw',
    //             left:'50%',
    //             transform:'translateX(-50%)',
    //         })
    //     }
        
    // }, [])

    return (
        route[route.length - 1] !== "" ?
        <div style={style} className='MobileLayoutNavbar'>
            <div className='menuItemsContainer'>
                {
                    items.filter(m => !m.disabled).length <= 7  ?
                    items.filter(m => !m.disabled).map((item, index) => {
                        if(!item.disabled)
                        return <div key={index} style={{cursor:"pointer"}} onClick={
                            () => {
                                navigate(item.route)
                            }
                        } >
                            {
                                route[route.length - 1] === item.route.split("/")[1] ? item.icon('#4DD4FF',30) : item.icon('var(--color-icon)',30)
                            }
                        </div>
                    })
                    :
                    items.filter(m => !m.disabled).map((item, index) => {
                            if(index < 6 && !item.disabled)
                            return <div  key={index} style={{cursor:"pointer"}} onClick={
                                () => {
                                    navigate(item.route)
                                }
                            } >
                                {
                                    route[route.length - 1] === item.route.split("/")[1] ? item.icon('#4DD4FF',30) : item.icon('var(--color-icon)',30)
                                }
                            </div>
                            else if(index === 6)
                            return <div style={{cursor:"pointer"}}  key={index} onClick={
                                (e) => {
                                    setOpen(e.currentTarget)
                                }
                            } >
                                {
                                  <FaEllipsisVertical fontSize='large' color="var(--color-icon)" size={30}  />
                                }
                            </div>
                    })
                }

            </div>
                <MobileMenuMoreOptions handleClose={()=>{setOpen(null)}} anchorEl={open} setAnchorEl={setOpen} menuItems={items.filter(m => !m.disabled).slice(6)}  />
        </div>:null
    )
}

export default MobileLayoutNavbar