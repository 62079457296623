import { getApps, initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import EnvSettings from "./EnvSettings";
import firebaseConfig from "../Services/FirebaseConfig";

const app = getApps().length > 0 ? getApps()[0] : initializeApp(firebaseConfig);
const auth = getAuth(app);
var accessToken = ""

onAuthStateChanged(auth, (user) => {

    if (user) {

        accessToken = user.accessToken;
    }
});

class UsersApi {

    GetToken() {
        auth.onIdTokenChanged((user) => {
            if (user) {
                user.getIdToken().then((idToken) => {
                
                    accessToken = idToken;
                });
            }
        });
        return accessToken;
    }

    baseUrl = EnvSettings.url

    CreateUserUrl = 'CreateUser'
    CreateUserUsingPhoneUrl = 'CreateUserUsingPhone'
    GetCurrentUserUrl = 'GetCurrentUser'
    GetUserByIdUrl = 'GetUserById'
    SearchUserUrl = 'SearchUsers'
    EditUserUrl = 'EditUserData'
    ChangeUserStatusUrl = 'ChangeUserStatus'
    UpdateUserImageAndVideoUrl = 'UpdateUserImageAndVideo'
    GenerateAffiliateCodeUrl = 'GenerateAffiliateCode'
    GetUserAffiliateStatusesUrl = 'GetUserAffiliateStatuses'
    UpdateReferralCodeUrl = 'UpdateReferralCode'
    ChangeReferralCodeStatusUrl = 'ChangeReferralCodeStatus'
    RequestRewardUrl = 'RequestReward'
    CreateJobApplicationUrl = 'CreateJobApplication'
    EditJobApplicationUrl = 'EditJobApplication'
    SearchJobApplicationUrl = 'SearchJobApplications'
    DeleteJobApplicationUrl = 'DeleteJobApplication'
    LinkUserPhoneNumberUrl = 'LinkUserPhoneNumber'
    LinkUserEmailAndPasswordUrl = 'LinkUserEmailAndPassword'
    IsPhoneNumberAlreadyRegisteredUrl = 'IsPhoneNumberAlreadyRegistered'
    CalculateUserAffiliateBalanceUrl = 'CalculateUserAffiliateBalance'
    AddUserVideoForBizCardUrl = 'AddUserVideoForBizCard'
    SearchUserVideosForBizCardUrl = 'SearchUserVideosForBizCard'
    SearchBugTicketsUrl = 'SearchBugTickets'
    ResloveBugTicketUrl = 'ResloveBugTicket'
    CreateBugTicketUrl = 'CreateBugTicket'
    SearchNotificationsUrl = 'SearchNotifications'
    MarkNotificationAsSeenUrl = 'MarkNotificationAsSeen'
    BulkMarkNotificationsAsSeenUrl = 'BulkMarkNotificationsAsSeen'
    DeleteNotificationsUrl = 'DeleteNotifications'
    SearchCalenderAppointmentsUrl = 'SearchCalenderAppointments'
    DeleteBusinessCardUserVideoUrl = 'DeleteBusinessCardUserVideo'
    GetAllAnnouncementsUrl = 'GetAllAnnouncements'
    EditAnnouncementUrl = 'EditAnnouncement'
    AddAnnouncementUrl = 'AddAnnouncement'
    GetAssignedFiberCompaniesForSalesRepUrl = 'GetAssignedFiberCompaniesForSalesRep'
    GetAssignedSalesRepsForFiberCompanyUrl = 'GetAssignedSalesRepsForFiberCompany'
    SetDefaultCompanyUrl = "SetDefaultCompany"
    MarkBugTicketAsSeenUrl = "MarkBugTicketAsSeen"
    SaveSalesRepLocationHistoryUrl = "SaveSalesRepLocationHistory"
    GetRepLocationPathUrl = "GetRepLocationPath"
    SaveRepPathColorUrl = "SetUserColor";
    GetLastKnownRepLocationUrl = "GetLastKnownRepLocation";
    GetCandidatesUrl = "GetCandidates";
    SetUserTimeZoneUrl = "SetUserTimeZone";
    CreateCandidateInviteApplicationUrl = "CreateCandidateInviteApplication";
    GetCandidateInvitationByGuidUrl = "GetCandidateInvitationByGuid";
    CreateCandidateInviteConsultationUrl = "CreateCandidateInviteConsultation";
    RejectJobApplicationUrl = "RejectRecruitCandidate";
    CalendlyUsersUrl = "api/calendly/users";
    ScheduleRecruitInterviewUrl = "ScheduleRecruitCandidateInterview";
    RejectCompanyConsultationUrl = "RejectCompanyConsultation";
    SetupConsultationMeetingUrl = "ScheduleCompanyConsultation";
    SearchUserBalancesUrl = "SearchUserBalances";
    CandidateAcceptOfferUrl = "CandidateAcceptOffer";

    async CandidateAcceptOffer(req) {
        const res = await fetch(this.baseUrl + this.CandidateAcceptOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async SearchUserBalances(req) {
        const res = await fetch(this.baseUrl + this.SearchUserBalancesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SetUserTimeZone(req) {
        const res = await fetch(this.baseUrl + this.SetUserTimeZoneUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async DeleteNotifications(req) {
        const res = await fetch(this.baseUrl + this.DeleteNotificationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async BulkMarkNotificationsAsSeen(req) {
        const res = await fetch(this.baseUrl + this.BulkMarkNotificationsAsSeenUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkNotificationAsSeen(req) {
        const res = await fetch(this.baseUrl + this.MarkNotificationAsSeenUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchNotifications(req) {
        const res = await fetch(this.baseUrl + this.SearchNotificationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateUser(req) {
        const res = await fetch(this.baseUrl + this.CreateUserUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditJobApplication(req) {
        const res = await fetch(this.baseUrl + this.EditJobApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async LinkUserEmailAndPassword(req) {
        const res = await fetch(this.baseUrl + this.LinkUserEmailAndPasswordUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async IsPhoneNumberAlreadyRegistered(req) {
        const res = await fetch(this.baseUrl + this.IsPhoneNumberAlreadyRegisteredUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async LinkUserPhoneNumber(req) {
        const res = await fetch(this.baseUrl + this.LinkUserPhoneNumberUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteJobApplication(req) {
        const res = await fetch(this.baseUrl + this.DeleteJobApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchJobApplication(req) {
        const res = await fetch(this.baseUrl + this.SearchJobApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GenerateAffiliateCode(req) {
        const res = await fetch(this.baseUrl + this.GenerateAffiliateCodeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserAffiliateStatuses(req) {
        const res = await fetch(this.baseUrl + this.GetUserAffiliateStatusesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UpdateReferralCode(req) {
        // console.log(JSON.stringify(req))
        const res = await fetch(this.baseUrl + this.UpdateReferralCodeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async ChangeReferralCodeStatus(req) {
        // console.log(JSON.stringify(req))
        const res = await fetch(this.baseUrl + this.ChangeReferralCodeStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RequestReward(req) {
        // console.log(JSON.stringify(req))
        const res = await fetch(this.baseUrl + this.RequestRewardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CalculateUserAffiliateBalance(req) {
        // console.log(JSON.stringify(req))
        const res = await fetch(this.baseUrl + this.CalculateUserAffiliateBalanceUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetCurrentUser() {
        const res = await fetch(this.baseUrl + this.GetCurrentUserUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            }
        })
        const data = await res.json();
        return data;
    }

    async GetUserById(req) {
        const res = await fetch(this.baseUrl + this.GetUserByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async SearchUser(req) {
        const res = await fetch(this.baseUrl + this.SearchUserUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditUser(req) {
        const res = await fetch(this.baseUrl + this.EditUserUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async ChangeUserStatus(req) {
        const res = await fetch(this.baseUrl + this.ChangeUserStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UpdateUserImageAndVideo(req) {
        const res = await fetch(this.baseUrl + this.UpdateUserImageAndVideoUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateUserUsingPhone(req) {
        const res = await fetch(this.baseUrl + this.CreateUserUsingPhoneUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateJobApplication(req) {
        const res = await fetch(this.baseUrl + this.CreateJobApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddUserVideoForBizCard(req) {
        const res = await fetch(this.baseUrl + this.AddUserVideoForBizCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchUserVideosForBizCard(req) {
        const res = await fetch(this.baseUrl + this.SearchUserVideosForBizCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    //SearchBugTickets
    async SearchBugTickets(req) {
        const res = await fetch(this.baseUrl + this.SearchBugTicketsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //ResloveBugTicket
    async ResolveBugTicket(req) {
        const res = await fetch(this.baseUrl + this.ResloveBugTicketUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkBugTicketAsSeen(req) {
        const res = await fetch(this.baseUrl + this.MarkBugTicketAsSeenUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //CreateBugTicket
    async CreateBugTicket(req) {
        const res = await fetch(this.baseUrl + this.CreateBugTicketUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    //SearchCalenderAppointments
    async SearchCalenderAppointments(req) {
        const res = await fetch(this.baseUrl + this.SearchCalenderAppointmentsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //DeleteUserVideo
    async DeleteUserBizCardVideo(req) {
        const res = await fetch(this.baseUrl + this.DeleteBusinessCardUserVideoUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAllAnnouncements(req)
    {
        const res = await fetch(this.baseUrl + this.GetAllAnnouncementsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async EditAnnouncement(req)
    {
        const res = await fetch(this.baseUrl + this.EditAnnouncementUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddAnnouncement(req)
    {
        const res = await fetch(this.baseUrl + this.AddAnnouncementUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAssignedFiberCompaniesForSalesRep(req)
    {
        const res = await fetch(this.baseUrl + this.GetAssignedFiberCompaniesForSalesRepUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAssignedSalesRepsForFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.GetAssignedSalesRepsForFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async setDefaultCompany(req)
    {
        const res = await fetch(this.baseUrl + this.SetDefaultCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async saveSalesRepLocationHistory(req)
    {
        const res = await fetch(this.baseUrl + this.SaveSalesRepLocationHistoryUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async getRepLocationPath(req)
    {
        const res = await fetch(this.baseUrl + this.GetRepLocationPathUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async saveRepPathColor(req)
    {
        const res = await fetch(this.baseUrl + this.SaveRepPathColorUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async getLastKnownRepLocation()
    {
        const res = await fetch(this.baseUrl + this.GetLastKnownRepLocationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            }
        })
        const data = await res.json();
        return data;
    }

    async getCandidates(req)
    {
        const res = await fetch(this.baseUrl + this.GetCandidatesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    //CreateCandidateInviteApplication
    async CreateCandidateInviteApplication(req) {
        const res = await fetch(this.baseUrl + this.CreateCandidateInviteApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //getCandidateInvitation
    async GetCandidateInvitationById(req) {
        const res = await fetch(this.baseUrl + this.GetCandidateInvitationByGuidUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //CreateCandidateInviteConsultation
    async CreateCandidateInviteConsultation(req) {
        const res = await fetch(this.baseUrl + this.CreateCandidateInviteConsultationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //Reject Job Application
    async RejectJobApplication(req) {
        const res = await fetch(this.baseUrl + this.RejectJobApplicationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //fetchCalendlyUsers
    async fetchCalendlyUsers() {
        const res = await fetch(this.baseUrl + this.CalendlyUsersUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            }
        })
        if (res.ok) {
            const data = await res.json();
            return data.collection;
        } else {
            console.error("Failed to fetch users:", await res.text());
        }
    }

    //scheduleRecruitInterview
    async scheduleRecruitInterview(req) {
        const res = await fetch(this.baseUrl + this.ScheduleRecruitInterviewUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //RejectConsultation
    async RejectConsultation(req) {
        const res = await fetch(this.baseUrl + this.RejectCompanyConsultationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    //SetupConsultationMeeting
    async SetupConsultationMeeting(req) {
        const res = await fetch(this.baseUrl + this.SetupConsultationMeetingUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.GetToken()}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    


    
}




export default new UsersApi;