/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContext as MP } from '..';
import Swal from 'sweetalert2';
import AssignLeadsToRepsReq from '../../../../Requests/FiberHouse/AssignLeadsToRepsReq';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import { CircularProgress, Menu, Pagination, Rating } from '@mui/material';
import { UserContext } from '../../../../App';
import MapIconSelector from "./../../../../components/MapIconSelector"
import MapIconApi from '../../../../API/MapIconApi';
import SearchFiberHousesReq from '../../../../Requests/FiberHouse/SearchFiberHousesReq';
import MapHelper from '../MapHelper';
import { RiTeamFill } from 'react-icons/ri';
import anonUser from '../../../../assets/images/userGrey.png';
import { TfiWrite } from "react-icons/tfi";
import { RiFileVideoLine } from "react-icons/ri";
import { SiPagespeedinsights } from "react-icons/si";
import Modal from '@mui/material/Modal';
import AssignLeadsToOrgreq from '../../../../Requests/FiberHouse/AssignLeadsToOrgreq';
import ReviewContainer from '../../../Reps/DigitalBusinessCenter/components/ReviewContainer';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaDoorClosed, FaQuestionCircle, FaUserMinus, FaUsers } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';
import EditFiberHouseReq from '../../../../Requests/FiberHouse/EditFiberHouseReq';
import FiberCompanySelector from './../../../../components/FiberCompanySelector';
import BulkChangeReq from '../../../../Requests/BulkChangeReq';
import ExportData from '../../../../Services/ExportData';
import StatsLeadCard from './StatsLeadCard';
import SaveServicableArea from './SaveServicableArea';
import { useSwipeable } from 'react-swipeable';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import ReactDOM from 'react-dom';
import n from '@editorjs/paragraph';

import ExportToWorksheetModal from './ExportToWorkSheetModal';

const Stats = ({ fiberHouses }) => {

    let map = React.useContext(MP);
    const user = useContext(UserContext)
    const [fiberHousesState, setFiberHousesState] = useState([])
    let mapObject = map.mapObject;
    const [filterMapIconIds, setFilterMapIconIds] = useState([]);
    const [selectedLeadIds, setSelectedLeadIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [initialSalesOrg, setInitialSalesOrg] = useState([])
    const [initialSalesRep, setInitialSalesRep] = useState([])
    const [addMode, setAddMode] = useState(false)
    const [selectedMapIcon, setSelectedMapIcon] = useState(null)
    const itemsPerPage = 10;
    const exportData = new ExportData();

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedLeadIds
        ?.slice(indexOfFirstItem, indexOfLastItem);
    const filteredSalesOrg = useRef([])
    const filteredSalesRep = useRef([])
    const [loading, setLoading] = useState(true)
    const [openReviewModel, setOpenReviewModel] = useState(false)
    const [reviewType, setReviewType] = useState(null)
    const [openSalesRepView, setOpenSalesRepView] = useState(null)
    const helper = useRef(new MapHelper(mapObject))
    const [fiberCompany, setFiberCompany] = useState(null)
    const [selectedLead, setSelectedLead] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openExportToWorksheet, setOpenExportToWorksheet] = useState(false)
    const stopRef = React.useRef(null);
    const refPassthrough = (el) => {
        handlers.ref(el);
        stopRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })

    const allFields = [
        { key: "id", label: "ID" },
        { key: "fiberCompany", label: "Fiber Company" },
        { key: "salesOrgName", label: "Sales Org Name" },
        { key: "salesRep", label: "Sales Representative" },
        { key: "leadType", label: "Lead Type" },
        { key: "mapIcon", label: "Map Icon" },
        { key: "lightningNotes", label: "Lightning Notes" },
        { key: "accountStatus", label: "Account Status" },
        { key: "soldTimestamp", label: "Sold Timestamp" },
        { key: "contactName", label: "Contact Name" },
        { key: "secondaryContactName", label: "Secondary Contact Name" },
        { key: "phone", label: "Phone" },
        { key: "email", label: "Email" },
        { key: "address", label: "Address" },
        { key: "originalInstallDate", label: "Original Install Date" },
        { key: "updatedInstallDate", label: "Updated Install Date" },
        { key: "internetType", label: "Internet Type" },
        { key: "fiberPlan", label: "Fiber Plan" },
        { key: "promos", label: "Promos" },
        { key: "noOfReviews", label: "Number of Reviews" },
        { key: "typeOfReview", label: "Type of Review" },
        { key: "locationOfReview", label: "Location of Review" },
        { key: "accountIssue", label: "Account Issue" },
        { key: "issueDescription", label: "Issue Description" },

    ];



    useEffect(
        () => {
            searchFiberHousesPrev();
            map.setUsingLight(false);
        }, [map.fiberHouseIds]
    )

    useEffect(
        () => {
            return () => {
                setFiberHousesState([])
                setInitialSalesOrg([])
                setInitialSalesRep([])
            }
        }, []
    )

    const searchFiberHousesPrev = async (pageSize = 500, pageNumber = 1) => {
        setLoading(true)
        map.setFiberHousesLoading(true)
        const req = new SearchFiberHousesReq()
        req.pagingParams.pageNumber = pageNumber
        req.pagingParams.pageSize = pageSize

        let orgId = null;
        if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
            orgId = null
        }
        else {
            orgId = user.salesOrgId
        }
        req.salesOrgId = orgId

        let repId = null;
        if (user?.userType === "SalesRep") {
            repId = user.id
        }

        req.salesRepId = repId

        req.ids = map.fiberHouseIds

        let res = await FiberHouseApi.SearchFiberHousesOptimized(req) // SEARCHING x4 TIMES???
        if (res?.status?.success) {
            console.log(res.data)
            setFiberHousesState(res.data)
            setLoading(false)
            map.setFiberHousesLoading(false)

            delete res.data
        }

    }


    const getMapIconStats = () => {
        let mapIconStats = [];
        fiberHousesState?.forEach((fiberHouse, index) => {
            let assignedMapIcons = map.mapIcons.find((icon) => icon.id === fiberHouse.mapIconId);
            const mapIconIndex = mapIconStats.findIndex((stat) => stat.assignedMapIcons.id === assignedMapIcons.id);
            if (mapIconIndex === -1) {
                mapIconStats.push({ assignedMapIcons: assignedMapIcons, count: 0 });
            }
        });

        selectedLeadIds?.forEach((fh) => {
            let assignedMapIcons = map.mapIcons.find((icon) => icon.id === fh.mapIconId);
            const mapIconIndex = mapIconStats.findIndex((stat) => stat.assignedMapIcons.id === assignedMapIcons.id);
            if (mapIconIndex !== -1) {
                mapIconStats[mapIconIndex].count++;
            }
        });

        return mapIconStats;
    };




    useEffect(
        () => {

            if (loading) {
                return
            }

            setSelectedLeadIds(fiberHousesState);
            setInitialSalesOrg(helper.current.getUniqueSalesOrgsFromFiberHouses(fiberHousesState));
            setInitialSalesRep(helper.current.getUniqueSalesRepsFromFiberHouses(fiberHousesState));

            if (filterMapIconIds.length > 0)
                setFilterMapIconIds([])
        }, [fiberHousesState]
    )

    useEffect(
        () => {
            if (selectedLeadIds)
                setTotalPages(Math.ceil(selectedLeadIds.length / itemsPerPage))
            if (map.searchAreaFeature.length > 0) {
                // Get the layer by its name
                const idsToRemove = fiberHousesState.filter((fiberHouse) => {
                    return !selectedLeadIds.some((fh) => fh.id === fiberHouse.id)
                }).map((fiberHouse) => fiberHouse.id)
                map.setFiberHouses(
                    fiberHouses.map(
                        (fiberHouse) => {
                            if (idsToRemove.includes(fiberHouse.id))
                                return {
                                    ...fiberHouse,
                                    remove: true,
                                }
                            else {
                                const fh = selectedLeadIds.filter((fh) => fh.id === fiberHouse.id)[0]
                                return {
                                    ...fiberHouse,
                                    remove: false,
                                    selected: fh ? true : false
                                }
                            }
                        }
                    )
                )
                console.log(initialSalesRep)
            }
        }, [selectedLeadIds]
    )


    // filtering map icons
    useEffect(
        () => {
            setSelectedLeadIds(
                fiberHousesState.filter((fiberHouse) => {
                    return !filterMapIconIds?.includes(fiberHouse.mapIconId) &&
                        !filteredSalesOrg?.current?.includes(fiberHouse.salesOrgId) &&
                        !fiberHouse?.owners?.some(owner => filteredSalesRep.current.includes(owner.userId));
                })
            )

        }, [filterMapIconIds]
    )




    const assignLeadsToReps = async (salesRep) => {
        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to ' + salesRep.firstName + ' ' + salesRep.lastName + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const filteredFiberHouses = selectedLeadIds.filter((fiberHouse) => {
            return fiberHouse.salesOrgId === salesRep.salesOrgId && !fiberHouse.owners.some(owner => owner.userId === salesRep.id)
        })

        const req = new AssignLeadsToRepsReq()
        req.assingedBy = user.id
        req.fiberHouseIds = filteredFiberHouses.map((fiberHouse) => fiberHouse.id)
        req.repId = salesRep.id
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        if (fh.salesOrgId === salesRep.salesOrgId && !fh.owners.some(owner => owner.userId === salesRep.id)) {
                            return {
                                ...fh,
                                owners: [
                                    ...fh.owners,
                                    {
                                        userId: salesRep.id,
                                        salesRepFiberHouseId: null,
                                        name: salesRep.name,
                                        profileImage: salesRep.profileImage?.url
                                    }
                                ]
                            }
                        }
                        return fh
                    }
                )
            }
        )
        setInitialSalesRep(
            (prev) => {
                return prev.map(
                    (owner) => {
                        if (owner.userId === salesRep.id) {
                            return {
                                ...owner,
                                count: owner.count + filteredFiberHouses.length
                            }
                        }
                        return owner
                    }
                )
            }
        )
        const res = await FiberHouseApi.AssignRepsToLeads(req)
        if (res?.status?.success) {
            console.log(res)
        }
        else {

        }
    }




    const removeRepFromLeads = async (salesrep, idsToRemove) => {
        console.log(salesrep)
        const option = await Swal.fire({
            title: 'Remove Sales Rep',
            text: 'Are you sure you want to remove leads from ' + salesrep.firstName + " " + salesrep.lastName + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            cancelButtonColor: 'green',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToRepsReq()
        let ids = []

        if (idsToRemove) {
            ids = idsToRemove
        }
        else {

            selectedLeadIds.filter(f => f.owners.length === 1).forEach((fiberHouse) => {
                fiberHouse.owners.forEach((owner) => {
                    if (owner.userId === salesrep.id) {
                        ids.push(owner.salesRepFiberHouseId)
                    }
                })
            })
        }
        req.salesRepFiberHouseIds = ids
        req.repId = salesrep.id
        if (idsToRemove) {
            setSelectedLeadIds(
                (prev) => {
                    return prev.map(
                        (fh) => {
                            return {
                                ...fh,
                                owners: fh.owners.filter((owner) => !idsToRemove.includes(owner.salesRepFiberHouseId))
                            }
                        }
                    )
                }
            )
        }
        else {

            setSelectedLeadIds(
                (prev) => {
                    return prev.map(
                        (fh) => {
                            return {
                                ...fh,
                                owners: fh.owners.length === 1 ? fh.owners.filter((owner) => owner.userId !== salesrep.id) : fh.owners
                            }
                        }
                    )
                }
            )

        }


        const res = await FiberHouseApi.RemoveRepsFromLeads(req)
        if (res?.status?.success) {

        }
        else {

        }

    }



    const getAddress = (fiberHouse) => {
        if (fiberHouse.mapstring) {
            return fiberHouse.mapstring
        }
        else {
            let address = "";
            if (fiberHouse.address) {
                address += fiberHouse.address + " "
            }
            if (fiberHouse.city) {
                address += fiberHouse.city + ", "
            }
            if (fiberHouse.state) {
                address += fiberHouse.state + " "
            }
            if (fiberHouse.zipCode) {
                address += fiberHouse.zipCode
            }
            return address
        }
    }

    const getCustomerName = (fiberHouse) => {
        console.log(fiberHouse)
        let customerName = "";
        if (fiberHouse?.customer?.firstName) {
            customerName += fiberHouse.customer.firstName + " ";
        }
        if (fiberHouse?.customer?.lastName) {
            customerName += fiberHouse.customer.lastName + " ";
        }
        if (customerName === "") {
            customerName = fiberHouse.lead.name
        }
        if (customerName === null) {
            return ""
        }
        return customerName;
    };

    const getCustomerPhoneNumber = (fiberHouse) => {
        let customerPhone = "";
        if (fiberHouse?.customer?.cellPhone) {
            customerPhone += fiberHouse.customer.cellPhone;
        }
        return customerPhone;
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                const address = getAddress(fiberHouse).toLowerCase();
                const customerName = getCustomerName(fiberHouse).toLowerCase();
                console.log(customerName)

                const customerPhone = getCustomerPhoneNumber(fiberHouse).toLowerCase();
                return customerName.includes(searchTerm) || address.includes(searchTerm) || customerPhone.includes(searchTerm);
            })
        );
    };


    function filterSalesOrg(salesOrgId) {
        if (filteredSalesOrg.current.length > 0 && filteredSalesOrg.current.includes(salesOrgId)) {
            filteredSalesOrg.current = filteredSalesOrg.current.filter((id) => id !== salesOrgId)
        }
        else {
            filteredSalesOrg.current.push(salesOrgId)
        }
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                    !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
            })
        )
    }

    const filterSalesRep = (salesRepId) => {
        if (filteredSalesRep.current.length > 0 && filteredSalesRep.current.includes(salesRepId)) {
            filteredSalesRep.current = filteredSalesRep.current.filter((id) => id !== salesRepId)
        }
        else {
            filteredSalesRep.current.push(salesRepId)
        }
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                if (fiberHouse.owners.length > 1) {
                    return true
                }
                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                    !(fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId)));
            })
        )
    }

    async function updateIcons() {
        if (selectedMapIcon) {

            const option = await Swal.fire({
                title: 'Update Map Icons',
                text: 'Are you sure you want to update the map icons of the selected fiber houses?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                confirmButtonColor: 'red',
                cancelButtonColor: 'green',
            });

            if (!option.isConfirmed) {
                return
            }


            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "We are updating the map icons",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            })
            const req = {
                ids: selectedLeadIds.map((fiberHouse) => fiberHouse.id),
                id: selectedMapIcon.id
            }
            MapIconApi.BulkChangeFiberHouseMapIcons(req).then((res) => {
                if (res?.status?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Map Icons Updated Successfully",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                    searchFiberHousesPrev()
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                }
            })
        }
    }

    const removeLeadsFromOrg = async (salesOrg) => {

        const option = await Swal.fire({
            title: 'Remove Leads',
            text: 'Are you sure you want to remove the selected leads from ' + salesOrg.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            cancelButtonColor: 'green',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToOrgreq()
        req.fiberHouseIds = selectedLeadIds.filter(fh => fh.salesOrgId === salesOrg.id).map((fiberHouse) => fiberHouse.id)
        req.salesOrgId = salesOrg.id

        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        if (fh.salesOrgId === salesOrg.id) {
                            return {
                                ...fh,
                                salesOrgId: null
                            }
                        }
                        return fh
                    }
                )
            }
        )

        const res = await FiberHouseApi.RemoveFiberHousesFromSalesOrg(req)
        if (res?.status?.success) {
            console.log(res)
        }
        else {
        }
    }


    const assignLeadsToOrg = async (salesOrg) => {

        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to ' + salesOrg.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToOrgreq()
        req.fiberHouseIds = selectedLeadIds.filter(fh => fh.salesOrgId !== salesOrg.id).map((fiberHouse) => fiberHouse.id)
        req.salesOrgId = salesOrg.id
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        return {
                            ...fh,
                            salesOrgName: salesOrg.name,
                            salesOrgId: salesOrg.id
                        }
                    }
                )
            }
        )
        setInitialSalesOrg(
            (prev) => {
                if (prev.some((s) => s.id === salesOrg.id)) {
                    return prev
                }
                return [...prev, salesOrg]
            }
        )
        const res = await FiberHouseApi.AddFiberHouseToSalesOrg(req)
        if (res?.status?.success) {

        }
        else {

        }
    }

    const assignLeadToFiberCompany = async () => {
        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to the selected fiberCompany ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }
        const req = new BulkChangeReq()

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are assigning the leads to the selected fiber company",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })



        req.ids = selectedLeadIds.map((fiberHouse) => fiberHouse.leadId)
        req.id = fiberCompany
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        return {
                            ...fh,
                            lead: {
                                ...fh.lead,
                                fiberCompanyId: fiberCompany
                            }
                        }
                    }
                )
            }
        )
        const res = await FiberHouseApi.AddLeadsToCompany(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Leads Assigned Successfully",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            console.log(res)
        }
    }

    function showSharedLeads() {
        let sharedLeads = {}

        selectedLeadIds.filter(fh => fh.owners.length > 1).forEach((fiberHouse) => {
            let ownerIds = fiberHouse.owners.map((owner) => owner.userId).sort()
            if (sharedLeads[ownerIds]) {
                sharedLeads[ownerIds].totalCount++
                fiberHouse.owners.forEach((owner) => {
                    if (sharedLeads[ownerIds][owner.userId]) {
                        sharedLeads[ownerIds][owner.userId].push(owner.salesRepFiberHouseId)
                    }
                    else {
                        sharedLeads[ownerIds][owner.userId] = [owner.salesRepFiberHouseId]
                    }
                })
            }
            else {
                sharedLeads[ownerIds] = {
                    totalCount: 1
                }
                fiberHouse.owners.forEach((o) => {
                    sharedLeads[ownerIds][o.userId] = [o.salesRepFiberHouseId]
                })
            }
        })

        return Object.keys(sharedLeads).map((owner) => {
            console.log(owner)
            return (
                <div className='sales_rep_wrapper'>
                    <div className={`lead_owner`}>
                        <div className='flex'>
                            {
                                owner.split(',').map((o) => {

                                    return initialSalesRep.find((s) => s.userId === parseInt(o))?.profileImage ?
                                        <img style={{ objectFit: "cover", marginLeft: "-10px" }} src={initialSalesRep.find((s) => s.userId === parseInt(o))?.profileImage} alt="Map Icon" />
                                        :
                                        <img src={anonUser} alt="Map Icon" />
                                })
                            }
                        </div>
                        {
                            owner.split(',').map((o) => {

                                return (
                                    <p>
                                        {
                                            initialSalesRep.find((s) => s.userId === parseInt(o))?.name
                                        }
                                    </p>
                                )
                            })
                        }
                        <p style={{
                            fontWeight: "bold"
                        }}>{
                                sharedLeads[owner].totalCount
                            }</p>
                    </div>
                    {
                        (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                        owner.split(',').map((o) => {

                            return (
                                <button onClick={() => {
                                    removeRepFromLeads({
                                        id: parseInt(o),
                                        firstName: initialSalesRep.find((s) => s.userId === parseInt(o))?.name?.split(' ')[0],
                                        lastName: initialSalesRep.find((s) => s.userId === parseInt(o))?.name?.split(' ')[1]
                                    }, sharedLeads[owner][o])
                                }} className='remove_rep'>Remove {initialSalesRep.find(s => s.userId === parseInt(o))?.name}</button>
                            )
                        })
                    }
                </div>
            )
        })

    }

    const editMapIcon = async (id) => {

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are updating the map icon",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            toast: true,
            position: 'bottom-end',
            willOpen: () => {
                Swal.showLoading();
            }
        })

        const req = new EditFiberHouseReq()
        req.id = selectedLead.id;
        req.mapIconId = id;
        req.coordinate = null;
        const res = await FiberHouseApi.EditFiberHouse(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Map Icon Updated',
                text: 'Map Icon Updated Successfully',
                showConfirmButton: false,
                toast: true,
                position: 'bottom-end',
                timer: 1500,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            searchFiberHousesPrev()
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                showConfirmButton: false,
                toast: true,
                position: 'bottom-end',
                timer: 1500,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    function showStats() {

        let noOfInstalledCustomers = selectedLeadIds.filter(l => l.customer?.accountStatus === "Completed").length
        let noOfCanceledCustomers = selectedLeadIds.filter(l => l.customer?.accountStatus === "Canceled").length
        let noOfOpenedDoors = selectedLeadIds.filter(l => l.opened).length
        let noOfKnockedDoors = selectedLeadIds.filter(l => l.knocked).length
        let noOfDoorsSold = selectedLeadIds.filter(l => l.customer).length
        let noOfConversations = selectedLeadIds.filter(l => l.conversation).length
        let arr = [
            {
                title: "Doors Knocked",
                value: noOfKnockedDoors,
            },
            {
                title: "Doors Opened",
                value: noOfOpenedDoors,
            },
            {
                title: "Doors Pitched",
                value: noOfConversations,
            },
            {
                title: "Doors Sold",
                value: noOfDoorsSold,
            },
            {
                title: "Doors Installed",
                value: noOfInstalledCustomers,
            },
            {
                title: "Doors Canceled",
                value: noOfCanceledCustomers,
            },
        ]

        return <div className='grid sm:grid-cols-3 grid-cols-2 gap-4 p-4'>
            {
                arr.map((stat, index) => {
                    return (
                        <div key={index} className='bg-white p-2 rounded-lg shadow-lg flex flex-col items-center justify-center gap-4'>
                            <p className='text-center'>{stat.title}</p>
                            <p className={`text-2xl font-bold`}>{stat.value}</p>
                        </div>
                    )
                })
            }
        </div>
    }


    function showPercentages() {

        let noOfCustomers = selectedLeadIds.filter(l => l.customer).length
        let noOfInstalledCustomers = selectedLeadIds.filter(l => l.customer?.accountStatus === "Completed").length
        let noOfCanceledCustomers = selectedLeadIds.filter(l => l.customer?.accountStatus === "Canceled").length
        let noOfScheduledCustomers = selectedLeadIds.filter(l => l.customer?.accountStatus === "Scheduled" || l.customer?.accountStatus === "Rescheduled" || l.customer?.accountStatus === "Sold").length

        let arr = [
            {
                title: "Completion",
                value: noOfInstalledCustomers === 0 ? "0%" : ((noOfInstalledCustomers / noOfCustomers) * 100).toFixed(2) + "%",
                color: "bg-blue-300"
            },
            {
                title: "Cancelation",
                value: noOfCanceledCustomers === 0 ? "0%" : ((noOfCanceledCustomers / noOfCustomers) * 100).toFixed(2) + "%",
                color: "bg-[#FF0000]"
            },
            {
                title: "Pending",
                value: noOfScheduledCustomers === 0 ? "0%" : ((noOfScheduledCustomers / noOfCustomers) * 100).toFixed(2) + "%",
                color: "bg-blue-700"
            },
        ]

        return <div className='grid sm:grid-cols-3 grid-cols-2 gap-4 p-4'>
            {
                arr.map((stat, index) => {
                    return (
                        <div key={index} className={` p-2 rounded-lg shadow-lg flex flex-col items-center justify-center gap-4 ${stat.color}`}>
                            <p className='text-center text-white uppercase'>{stat.title}</p>
                            <p className={`text-2xl font-bold text-white`}>{stat.value}</p>
                        </div>
                    )
                })
            }
        </div>
    }




    // const promptFieldSelection = () => {
    //     return new Promise((resolve) => {
    //         const ModalComponent = () => {
    //             const [open, setOpen] = useState(true);
    //             const [selectedFields, setSelectedFields] = useState(allFields.map(field => field.key));

    //             const handleClose = () => {
    //                 setOpen(false);
    //                 // resolve([]);
    //             };

    //             const handleExport = () => {
    //                 resolve(selectedFields);
    //                 setOpen(false);
    //             };

    //             const handleCheckboxChange = (event) => {
    //                 const { value, checked } = event.target;
    //                 setSelectedFields((prev) =>
    //                     checked ? [...prev, value] : prev.filter((field) => field !== value)
    //                 );
    //             };

    //             return (
    //                 <Modal open={open} onClose={handleClose}>
    //                     <Box
    //                         sx={{
    //                             position: 'absolute',
    //                             top: '50%',
    //                             left: '50%',
    //                             transform: 'translate(-50%, -50%)',
    //                             width: '100%',
    //                             maxWidth: 600,
    //                             bgcolor: 'background.paper',
    //                             border: '2px solid #000',
    //                             boxShadow: 24,
    //                             p: 4,
    //                             scrollbarWidth: 'none',
    //                             display: 'flex',
    //                             flexDirection: 'column',
    //                             justifyContent: 'center',
    //                             alignItems: 'center',
    //                             gap: 2,
    //                         }}
    //                     >
    //                         <Typography variant="h6" component="h2" gutterBottom>
    //                             Select Fields to Export
    //                         </Typography>
    //                         <Box
    //                             sx={{
    //                                 height: 450,
    //                                 overflowY: 'auto',
    //                                 display: 'flex',
    //                                 flexWrap: 'wrap',
    //                                 gap: 2,
    //                                 border: '1px solid grey',
    //                                 width: '100%',
    //                             }}
    //                         >
    //                             <FormGroup
    //                                 sx={{
    //                                     display: 'grid',
    //                                     gridTemplateColumns: 'repeat(1, 1fr)',
    //                                     gap: 2,
    //                                     width: '100%',
    //                                 }}
    //                             >
    //                                 {allFields.map((field) => (
    //                                     <FormControlLabel
    //                                         key={field.key}
    //                                         control={
    //                                             <Checkbox
    //                                                 value={field.key}
    //                                                 checked={selectedFields.includes(field.key)}
    //                                                 onChange={handleCheckboxChange}
    //                                             />
    //                                         }
    //                                         label={field.label}
    //                                         sx={{
    //                                             display: 'inline-block'
    //                                         }}
    //                                     />
    //                                 ))}
    //                             </FormGroup>
    //                         </Box>
    //                         <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, width: '100%', padding: "0px !important" }}>
    //                             <Button variant="contained" color="primary" onClick={handleExport}
    //                                 sx={{
    //                                     width: '50%',
    //                                 }}>
    //                                 Export
    //                             </Button>
    //                             <Button variant="contained" color="error" onClick={handleClose}
    //                                 sx={{
    //                                     width: '50%',
    //                                 }}>
    //                                 Cancel
    //                             </Button>
    //                         </Box>
    //                     </Box>
    //                 </Modal>
    //             );
    //         };

    //         // Render the modal component
    //         const modalContainer = document.createElement('div');
    //         document.body.appendChild(modalContainer);
    //         ReactDOM.render(<ModalComponent />, modalContainer);
    //     });
    // };



    const handleExportClick = async () => {

        const selectedFields = await exportData.promptFieldSelection(allFields)

        if (selectedFields.length === 0) {
            Swal.fire({
                icon: "warning",
                title: "No Fields Selected",
                text: "Please select at least one field to export.",
            });
            return;
        }

        let ed = new ExportData();

        let l = fiberHousesState.map((fh) => {
            let allData = {
                fiberCompany: fh?.lead?.fiberCompany?.name,
                salesOrgName: fh?.salesOrgName,
                salesRep: fh?.owners?.length > 0 ? fh?.owners[fh?.owners?.length - 1].name : "",
                leadType: fh.customer ? "Customer" : "Lead",
                mapIcon: map.mapIcons.find((icon) => icon.id === fh.mapIconId)?.name,
                lightningNotes: fh?.lead?.notes,
                accountStatus: fh.customer?.accountStatus,
                soldTimestamp: fh.customer?.timeStamp ? new Date(fh.customer?.timeStamp).toLocaleDateString() + " " + new Date(fh.customer?.timeStamp).toLocaleTimeString() : null,
                contactName: fh.customer != null ? fh.customer.firstName + " " + fh.customer.lastName : fh.name ? fh.name : "No Name",
                secondaryContactName: fh.customer?.secondaryAccountHolderFullName,
                phone: fh?.phone ? fh.phone : "",
                email: fh.customer?.email ? fh.customer.email : fh?.lead?.email ? fh.lead.email : "",
                address: fh.address ? fh.address : fh.mapstring,
                originalInstallDate: fh.customer?.originallInstallDateTimeFrom ? new Date(fh.customer?.originallInstallDateTimeFrom).toLocaleDateString() + " " + new Date(fh.customer?.originallInstallDateTimeFrom).toLocaleTimeString() : null,
                updatedInstallDate: fh.customer?.updatedInstallDateTimeFrom ? new Date(fh.customer?.updatedInstallDateTimeFrom).toLocaleDateString() + " " + new Date(fh.customer?.updatedInstallDateTimeFrom).toLocaleTimeString() : null,
                internetType: fh.customer?.fiberPlan?.name ? fh.customer.fiberPlan.name.includes("HFC") ? "HFC" : fh.customer.fiberPlan.name.includes("FTTH") ? "FTTH" : "" : "",
                fiberPlan: fh.customer?.fiberPlan?.name,
                promos: fh.customer?.promo,
                noOfReviews: fh?.lead?.customerFeedbacks?.length,
                typeOfReview: fh?.lead?.customerFeedbacks?.length > 0 ? "Written" : "",
                locationOfReview: fh?.lead?.customerFeedbacks?.length > 0 ? fh?.lead?.customerFeedbacks[0]?.content ? "Lightning" : "Google" : "",
                accountIssue: fh.customer?.issueType,
                issueDescription: fh.customer?.issueDescription,
                id: fh.id
            };

            // Filter the data to include only the selected fields
            let filteredData = {};
            selectedFields.forEach(field => {
                if (allData.hasOwnProperty(field)) {
                    filteredData[field] = allData[field];
                }
            });

            return filteredData;
        });

        let ids = selectedLeadIds.map((fh) => fh.id);
        ed.ExportToCsv("data.csv", l, ids);
    };

    return (
        loading ?
            <div className='w-full flex justify-center items-center h-full'>
                <CircularProgress sx={{
                    color: "blue",
                }} />
            </div>
            :
            <>
                <Modal open={addMode} onClose={() => {
                    // setReload(!reload)
                    setAddMode(false)
                }}>
                    <div className='assign_leads_container'>
                        <IoCloseCircle onClick={() => {
                            setAddMode(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <h1>Change Ownership</h1>
                        <h2 style={{
                            color: "black",
                            fontSize: "1.2rem",
                            textAlign: "center"
                        }}>Leads Selected: {selectedLeadIds.length}</h2>
                        <div className='assign_leads_content'>
                            <h3 style={{
                                color: "black",
                                fontSize: "1.2rem",
                                textAlign: "center"

                            }}>{user.userType === "SuperAdmin" ? "Sales Orgs" : "Sales Reps"}</h3>
                            <div className='sales_orgs'>
                                {
                                    user.userType === "SuperAdmin" ?
                                        map.salesOrg.map((salesOrg, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div >
                                                        <div className={`sales_org ${initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? 'active' : ''}`}>
                                                            <div className='image'>
                                                                {
                                                                    salesOrg.icon ?
                                                                        <img src={"data:image/png;base64," + salesOrg.icon} alt="Map Icon" />
                                                                        :
                                                                        <RiTeamFill size={30} color='black' />
                                                                }
                                                            </div>
                                                            <p>{salesOrg.name}

                                                                {
                                                                    openSalesRepView === salesOrg.id ?
                                                                        <MdExpandLess onClick={() => {
                                                                            setOpenSalesRepView(null)
                                                                        }} size={30} style={{
                                                                            cursor: "pointer"
                                                                        }} />
                                                                        :
                                                                        <MdExpandMore onClick={() => {
                                                                            setOpenSalesRepView(salesOrg.id)
                                                                        }} size={30} style={{
                                                                            cursor: "pointer"
                                                                        }} />
                                                                }
                                                            </p>
                                                            {/* <div className='input'>
                                                            <div onClick={() => {
                                                                handleSalesOrgClicked(salesOrg, initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? "remove" : "assign")
                                                            }} type="checkbox" className={`${initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? 'active' : ''}`} />
                                                        </div> */}
                                                            <div className='input'>
                                                                <button onClick={() => { assignLeadsToOrg(salesOrg) }}
                                                                    disabled={selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length === 0}
                                                                    style={{ color: selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length === 0 ? "#ccc" : '' }} className='assign'>Assign {selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length} Leads</button>
                                                                <button onClick={() => { removeLeadsFromOrg(salesOrg) }}
                                                                    style={{ color: selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length === 0 ? "#ccc" : '' }}
                                                                    disabled={selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length === 0}
                                                                    className='remove'>Remove {selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length} Leads</button>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            paddingLeft: "2rem",
                                                            paddingTop: "1rem",
                                                            display: openSalesRepView === salesOrg.id ? 'flex' : 'none',
                                                            flexDirection: "column",
                                                            gap: "0.5rem"
                                                        }}>
                                                            {
                                                                // eslint-disable-next-line array-callback-return
                                                                map.salesReps?.map((salesRep, index) => {
                                                                    if (salesRep.salesOrgId === salesOrg.id && salesRep.status === "Active") {
                                                                        const noOfLeadsToAssign = selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id && fiberHouse.owners.filter(owner => owner.userId === salesRep.id).length === 0).length
                                                                        const noOfLeadsToRemove = selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id && fiberHouse.owners.some(owner => owner.userId === salesRep.id)).length

                                                                        return (
                                                                            <div key={index} className={`sales_org ${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`}>
                                                                                <div className='image'>
                                                                                    {
                                                                                        salesRep.profileImage ?
                                                                                            <img src={salesRep.profileImage.url} alt="Map Icon" />
                                                                                            :
                                                                                            <img src={anonUser} alt="Map Icon" />
                                                                                    }
                                                                                </div>
                                                                                <p>{salesRep.firstName + " " + salesRep.lastName}</p>
                                                                                {/* <div className='input'>
                                                                                <div onClick={() => {
                                                                                    handleSalesOrgClicked(salesRep, initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? "remove" : "assign")
                                                                                }} type="checkbox" className={`${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`} />
                                                                            </div> */}
                                                                                <div className='input'>
                                                                                    <button onClick={() => { assignLeadsToReps(salesRep) }}
                                                                                        disabled={noOfLeadsToAssign === 0}
                                                                                        style={{ color: noOfLeadsToAssign === 0 ? "#ccc" : '' }} className='assign'>Assign {noOfLeadsToAssign} Leads</button>
                                                                                    <button onClick={() => { removeRepFromLeads(salesRep) }}
                                                                                        style={{ color: noOfLeadsToRemove === 0 ? "#ccc" : '' }}
                                                                                        disabled={noOfLeadsToRemove === 0}
                                                                                        className='remove'>Remove {noOfLeadsToRemove} Leads</button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            <p className='note'>{selectedLeadIds.filter(l => l.salesOrgId === salesOrg.id && l.owners.length === 0).length} Leads not assigned To any rep</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }) :
                                        user.userType === "SalesOrgAdmin" ?
                                            map.salesReps.map((salesRep, index) => {
                                                const noOfLeadsToAssign = selectedLeadIds.filter((fiberHouse) => fiberHouse.owners.filter(owner => owner.userId === salesRep.id).length === 0).length
                                                const noOfLeadsToRemove = selectedLeadIds.filter((fiberHouse) => fiberHouse.owners.some(owner => owner.userId === salesRep.id)).length
                                                return (
                                                    <div key={index} className={`sales_org ${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`}>
                                                        <div className='image'>
                                                            {
                                                                salesRep.profileImage ?
                                                                    <img src={salesRep.profileImage.url} alt="Map Icon" />
                                                                    :
                                                                    <img src={anonUser} alt="Map Icon" />
                                                            }
                                                        </div>
                                                        <p>{salesRep.firstName + " " + salesRep.lastName}</p>
                                                        {/* <div className='input'>
                                                            <div onClick={() => {
                                                                handleSalesRepClicked(salesRep, initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? "remove" : "assign")
                                                            }} type="checkbox" className={`${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`} />
                                                        </div> */}
                                                        <div className='input'>
                                                            <button onClick={() => { assignLeadsToReps(salesRep) }}
                                                                disabled={noOfLeadsToAssign === 0}
                                                                style={{ color: noOfLeadsToAssign === 0 ? "#ccc" : '' }} className='assign'>Assign {noOfLeadsToAssign} Leads</button>
                                                            <button onClick={() => { removeRepFromLeads(salesRep) }}
                                                                style={{ color: noOfLeadsToRemove === 0 ? "#ccc" : '' }}
                                                                disabled={noOfLeadsToRemove === 0}
                                                                className='remove'>Remove {noOfLeadsToRemove} Leads</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                }
                                {
                                    user.userType === "SuperAdmin" ?
                                        <p className='note'>{selectedLeadIds.filter(l => l.salesOrgId === null).length} Leads not assigned to any org</p>
                                        :
                                        <p className='note'>{selectedLeadIds.filter(s => s.owners.length === 0).length} Leads not assigned to any rep</p>
                                }
                            </div>

                        </div>
                    </div>
                </Modal>
                <ExportToWorksheetModal leadIds={selectedLeadIds.map(l => l.id)} open={openExportToWorksheet} setOpen={setOpenExportToWorksheet} />
                <Modal open={openReviewModel} onClose={() => {
                    setOpenReviewModel(false)
                }}>
                    <div style={{
                        padding: "1rem",
                        maxWidth: "90%",
                        minWidth: "50%"
                    }} className='assign_leads_container'>
                        <IoCloseCircle onClick={() => {
                            setOpenReviewModel(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <ReviewContainer type={reviewType} searchArea={map.searchArea} />
                    </div>
                </Modal>
                <div className='StatsDiv'>
                    <div
                        style={{ height: window.innerHeight - 260 + 'px', overflowY: 'auto' }}
                        className='scrollable-content'
                        ref={refPassthrough}
                    >


                        <div className='TitleDiv justify-center'>
                            <h1 className='text-4xl font-bold'>Leads Management & Stats</h1>
                        </div>

                        <div className="Stats grid grid-cols-1 gap-1">
                            <div className='grid grid-cols-3 gap-2'>
                                <SaveServicableArea />
                                {
                                    (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                    <button onClick={() => {
                                        setAddMode(true)
                                    }} className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'>Manage Area</button>
                                }
                                <button onClick={() => {
                                    setOpenExportToWorksheet(true)
                                }} className='px-4 py-2 text-xs rounded-lg bg-blue-500 text-white w-full ml-auto'>Export to Worksheet</button>
                            </div>
                            <div className='Stat'>
                                <p>Total Leads: {selectedLeadIds?.length}</p>
                            </div>
                            <hr style={{ margin: "0.5rem 0rem" }} />
                            <div className='ratio_container'>
                                <p className='ratio'>{((fiberHousesState.filter(fh => fh.convertedToCustomer).length / fiberHousesState.length) * 100).toFixed(1)}%</p>
                                <p className='label'> of Total Leads within Area Sold</p>
                            </div>
                            <div className='ratio_container'>
                                <p className='ratio'>{((fiberHousesState.filter(l =>l.conversation && l.convertedToCustomer).length / fiberHousesState.filter(l => l.conversation).length) * 100).toFixed(1)}%</p>
                                <p className='label'> of Total Leads Pitched within Area Sold</p>
                            </div>
                            <div className='ratio_container'>
                                <p className='ratio'>{((fiberHousesState.filter(l => l.conversation && !l.convertedToCustomer).length / fiberHousesState.filter(l => l.conversation).length) * 100).toFixed(1)}%</p>
                                <p className='label'> of Total Leads Pitched within Area Rejected</p>
                            </div>

                            <hr style={{ margin: "0.5rem 0rem" }} />

                            {
                                showStats()
                            }
                            <hr className='my-2 mx-0' />
                            {
                                showPercentages()
                            }
                            <hr className='my-2 mx-0' />
                            <h1 className='lead_owners_title'>Leads Status</h1>
                            <div className='select_deselect'>
                                <button className='select' onClick={() => {
                                    setFilterMapIconIds([])
                                }}>Select All</button>
                                <button className='deselect' onClick={() => {
                                    setFilterMapIconIds(
                                        map.mapIcons.map((icon) => icon.id)
                                    )
                                }}>Deselect All</button>
                            </div>
                            <div className='MapIconStats'>
                                {
                                    getMapIconStats().map((stat, index) => {
                                        return (
                                            <div className='flex flex-col items-center content-center gap-1' key={index}>
                                                <div key={index} onClick={() => {
                                                    setFilterMapIconIds(
                                                        (prev) => {
                                                            if (prev.includes(stat.assignedMapIcons.id)) {
                                                                return prev.filter((id) => id !== stat.assignedMapIcons.id)
                                                            }
                                                            else {
                                                                return [...prev, stat.assignedMapIcons.id]
                                                            }
                                                        }
                                                    )
                                                }} style={{ borderRadius: "10px" }} className={`MapIconStat  ${!filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : null} border-2`}>
                                                    <h3>
                                                        <img src={"data:image/png;base64," + stat.assignedMapIcons.image} alt="Map Icon" />
                                                    </h3>
                                                    <p>{stat.assignedMapIcons.abbreviation}</p>
                                                    <p style={{
                                                        fontWeight: !filterMapIconIds.includes(stat.assignedMapIcons.id) ? "bold" : null
                                                    }}>{!filterMapIconIds.includes(stat.assignedMapIcons.id) ? stat.count : 0}</p>
                                                </div>
                                                <p>{!filterMapIconIds.includes(stat.assignedMapIcons.id) ? ((stat.count / selectedLeadIds.length) * 100).toFixed(1) + "%" : 0}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                user.userType === "SuperAdmin" &&
                                <>
                                    <hr className='my-2 mx-0' />
                                    <h1 className='lead_owners_title'>Sales Org</h1>
                                    <div className='select_deselect'>
                                        <button className='select' onClick={
                                            () => {
                                                filteredSalesOrg.current = []
                                                setSelectedLeadIds(
                                                    fiberHousesState.filter((fiberHouse) => {
                                                        return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                            !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                            !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                    })
                                                )
                                            }
                                        }>Select All</button>
                                        <button className='deselect' onClick={() => {
                                            filteredSalesOrg.current = initialSalesOrg.map((owner) => owner.id)
                                            setSelectedLeadIds(
                                                fiberHousesState.filter((fiberHouse) => {
                                                    return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                        !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                        !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                })
                                            )
                                        }}>Deselect All</button>
                                    </div>
                                    <div className='lead_owners'>
                                        {
                                            initialSalesOrg.map((salesOrg, index) => {

                                                return (
                                                    <div key={index} onClick={() => {
                                                        filterSalesOrg(salesOrg.id)
                                                    }} className={`lead_owner ${filteredSalesOrg.current.find((s) => salesOrg.id === s) ? null : 'active'}`}>
                                                        {
                                                            salesOrg.icon ?
                                                                <img src={"data:image/png;base64," + salesOrg.icon} alt="Map Icon" />
                                                                :
                                                                <RiTeamFill size={30} color='black' />
                                                        }
                                                        <p>{salesOrg.name}</p>
                                                        <p style={{
                                                            fontWeight: "bold"
                                                        }}> {selectedLeadIds.filter(fh => fh.salesOrgName === salesOrg.name).length}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            <div onClick={() => {
                                                filterSalesOrg(null)
                                            }} className={`lead_owner ${selectedLeadIds.filter(fh => fh.salesOrgId === null).length === 0 ? null : 'active'}`}>
                                                <FaQuestionCircle size={30} color='black' />
                                                <p>Unassigned</p>
                                                <p style={{
                                                    fontWeight: "bold"
                                                }}> {selectedLeadIds.filter(fh => fh.salesOrgId === null).length}</p>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            <hr className='my-2 mx-0' />
                            {
                                (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                <>
                                    <h1 className='lead_owners_title'>Sales Rep</h1>
                                    <div className='select_deselect'>
                                        <button className='select' onClick={
                                            () => {
                                                filteredSalesRep.current = []
                                                setSelectedLeadIds(
                                                    fiberHousesState.filter((fiberHouse) => {
                                                        return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                            !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                            !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                    })
                                                )
                                            }
                                        }>Select All</button>
                                        <button className='deselect' onClick={() => {
                                            filteredSalesRep.current = initialSalesRep.map((owner) => owner.userId)
                                            setSelectedLeadIds(
                                                fiberHousesState.filter((fiberHouse) => {
                                                    return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                        !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                        !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                })
                                            )
                                        }}>Deselect All</button>
                                    </div>
                                    <div className='lead_owners'>
                                        {
                                            initialSalesRep?.filter((owner, index, self) =>
                                                index === self.findIndex((o) => o.userId === owner.userId)
                                            ).map((owner) => {

                                                return (
                                                    <div className='sales_rep_wrapper'>
                                                        <div onClick={() => {
                                                            filterSalesRep(owner.userId)
                                                        }} className={`lead_owner ${filteredSalesRep.current.find((user) => user === owner.userId) ? null : 'active'}`}>
                                                            {
                                                                owner.profileImage ?
                                                                    <img style={{ objectFit: "cover" }} src={owner.profileImage} alt="Map Icon" />
                                                                    :
                                                                    <img src={anonUser} alt="Map Icon" />
                                                            }
                                                            <p >{owner.userId === user.id ? "You" : owner.name}</p>
                                                            <p style={{
                                                                fontWeight: "bold"
                                                            }}>{
                                                                    selectedLeadIds.filter(fh => fh.owners.length === 1).filter((fh) => {
                                                                        return fh.owners.some((o) => o.userId === owner.userId)
                                                                    }).length
                                                                }</p>
                                                        </div>
                                                        {
                                                            (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                                            <button onClick={() => {
                                                                removeRepFromLeads({
                                                                    id: owner.userId,
                                                                    firstName: owner.name.split(" ")[0],
                                                                    lastName: owner.name.split(" ")[1]
                                                                })
                                                            }} className='remove_rep'>Remove</button>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            <div onClick={() => {
                                                let unassignedLeads = selectedLeadIds.filter(fh => fh.owners.length === 0)
                                                if (unassignedLeads.length > 0) {
                                                    setSelectedLeadIds(
                                                        selectedLeadIds.filter(fh => fh.owners.length > 0)
                                                    )
                                                }
                                                else {
                                                    setSelectedLeadIds(
                                                        fiberHousesState.filter((fiberHouse) => {
                                                            return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                                !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                                !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                        })
                                                    )
                                                }
                                            }} className={`lead_owner ${selectedLeadIds.filter(fh => fh.owners.length === 0).length === 0 ? null : 'active'}`}>
                                                <FaQuestionCircle size={30} color='black' />
                                                <p>Unassigned</p>
                                                <p style={{
                                                    fontWeight: "bold"
                                                }}> {selectedLeadIds.filter(fh => fh.owners.length === 0).length}</p>
                                            </div>
                                        }

                                    </div>
                                    {
                                        selectedLeadIds.filter(fh => fh.owners.length > 1).length > 0
                                        &&
                                        <>
                                            <h1 className='lead_owners_title'>Shared Leads</h1>
                                            <div className='select_deselect'>
                                                <button className='select'>Select All</button>
                                                <button className='deselect'>Deselect All</button>
                                            </div>
                                            <div className='lead_owners'>
                                                {
                                                    showSharedLeads()
                                                }
                                            </div>
                                        </>
                                    }
                                    <hr style={{ margin: "0.5rem 0rem" }} />
                                </>}

                            <div className='stats_review_container'>
                                <h1 className='lead_owners_title'>Customer Reviews ({fiberHousesState.filter((fh) => fh.hasReviews).length})</h1>
                                <Rating value={5} precision={0.5} readOnly />
                            </div>
                            <div className='stats_icon_reviews'>
                                <div onClick={() => {
                                    setReviewType("Written")
                                    setOpenReviewModel(true)
                                }} className='icon'>
                                    <TfiWrite size={40} color='black' />
                                </div>
                                <div onClick={() => {
                                    setReviewType("Video")
                                    setOpenReviewModel(true)
                                }} className='icon'>
                                    <RiFileVideoLine size={40} color='black' />
                                </div>
                                <div onClick={() => {
                                    setReviewType("SpeedTest")
                                    setOpenReviewModel(true)
                                }} className='icon'>
                                    <SiPagespeedinsights size={40} color='black' />
                                </div>
                            </div>
                        </div>

                        {
                            (user.userType === "SuperAdmin" || user.userType === "Admin") &&
                            <>
                                <hr style={{ margin: "0.5rem 0rem" }} />
                                <h1 style={{
                                    marginTop: "1rem"
                                }} className='lead_owners_title'>Update Lead Status</h1>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    gap: "0.5rem",

                                }}>
                                    <MapIconSelector setAssignedMapIcons={setSelectedMapIcon} placeholder='Select Map Icon' />
                                    <button style={{
                                        backgroundColor: "#181E4B ",
                                        color: "white",
                                        padding: "0.5rem",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                    }} onClick={() => { updateIcons() }} >Update</button>
                                </div>
                            </>
                        }
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        {
                            user.userType === "SuperAdmin" &&
                            <>
                                <h1 style={{
                                    marginTop: "1rem"
                                }} className='lead_owners_title'>Assign Fiber Company</h1>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    gap: "0.5rem",
                                }}>
                                    <FiberCompanySelector setUser={setFiberCompany} placeholder='Select Company' />
                                    <button style={{
                                        backgroundColor: "#181E4B ",
                                        color: "white",
                                        padding: "0.5rem",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                    }} onClick={() => { assignLeadToFiberCompany() }} >Update</button>
                                </div>
                            </>
                        }
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        <button
                            onClick={() => {


                                handleExportClick()
                            }} className='p-2 bg-[#2B92D5] text-white rounded-lg text-sm'>Export {selectedLeadIds.length} leads</button>
                        <div className='stats_input_container'>
                            <input className='stats_input' type='text' placeholder='Search FiberHouses' onChange={handleSearchChange} />
                        </div>


                        <div className='fiberHouse_list'>
                            <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={() => {
                                setAnchorEl(null)
                            }}>
                                <div style={{ display: "flex", alignItems: "center", maxWidth: "500px", gap: "1rem", overflow: "auto" }}>
                                    {
                                        map.mapIcons.map((icon, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    editMapIcon(icon.id)
                                                    setAnchorEl(null)
                                                }} className='MapIconStat'>
                                                    <div className='w-8 h-8 '>
                                                        <img src={"data:image/png;base64," + icon.image} alt="Map Icon" />
                                                    </div>
                                                    <p style={{ textAlign: "center" }}>{icon.abbreviation}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Menu>
                            {
                                currentItems != null &&
                                currentItems?.map(
                                    (fh, index) => {
                                        return (
                                            <StatsLeadCard fh={fh} key={index} setAnchorEl={setAnchorEl} setSelectedLead={setSelectedLead} setSelectedLeadIds={setSelectedLeadIds} />
                                        )
                                    }
                                )
                            }
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "1rem"
                            }}>
                                <Pagination count={totalPages} page={currentPage} onChange={(e, page) => setCurrentPage(page)} />
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
}

export default Stats