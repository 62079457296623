import React, { useState } from 'react'
import Swal from 'sweetalert2'
import MapIconApi from '../../../../API/MapIconApi'
import Input from '../../../../components/Input'
import CreateMapIconReq from '../../../../Requests/MapIcon/CreateMapIconReq'
import { Checkbox, FormControlLabel, FormGroup, Radio } from '@mui/material'

const CreateMapIcon = () => {
    const [mapIconName, setMapIconName] = useState("");
    const [mapIconImage, setMapIconImage] = useState(null);
    const [abbreviation, setAbbreviation] = useState("");
    const [isRecruit, setIsRecruit] = useState(false);
    const [isCompany, setIsCompany] = useState(false);
    const [isFiberHouse, setIsFiberHouse] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const createMapIcon = () => {

        if (!selectedOption) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Select Only One of the options",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Creating Map Icon",
            text: "Please Wait ...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!mapIconImage) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Select an Image",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (!mapIconName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Enter a Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        let req = new CreateMapIconReq();
        req.Name = mapIconName;
        req.Image = mapIconImage;
        req.Abbreviation = abbreviation;
        req.SalesOrgId = "";

        if (selectedOption === 'Recruit') {
            req.iconType = "Recruit";
        }
        if (selectedOption === 'Company') {
            req.iconType = "Company";
        }

        MapIconApi.CreateMapIcon(req).then(
            (res) => {
                if (res?.status?.success) {
                    Swal.fire(
                        {
                            title: "Map Icon Created",
                            text: "Map Icon has been created",
                            icon: "success",
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        }
                    )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        )
    }

    return (
        <div className='create-map-icon-container'>
            <div className='title-div'>
                <h1>Create Map Icon</h1>
            </div>
            <div className='create-map-icon-form'>
                <div className='form-row'>
                    <Input label="Name" value={mapIconName} onChange={(e) => setMapIconName(e.target.value.slice(0, 120))} placeholder="NAME" />
                    <Input label="Abbreviation" value={abbreviation} onChange={(e) => setAbbreviation(e.target.value.slice(0, 32))} placeholder="Abbreviation" />
                </div>
                <div className='form-row'>
                    <input className="hidden" type="file" id="fileUpload" onChange={(e) => setMapIconImage(e.target.files?.[0] || null)} />
                    <label htmlFor="fileUpload" className="file-upload-label">
                        <div className='file-upload-content'>
                            <p>{mapIconImage ? mapIconImage.name : "Select Image"}</p>
                            {mapIconImage && (
                                <div className='image-preview'>
                                    <img src={URL.createObjectURL(mapIconImage)} alt="Uploaded" />
                                </div>
                            )}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                            <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                        </svg>
                    </label>
                </div>
                <div className='form-row checkbox-row'>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Radio
                                checked={selectedOption === 'Recruit'}
                                onChange={() => setSelectedOption('Recruit')}
                            />}
                            label="Recruit"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={selectedOption === 'Company'}
                                onChange={() => setSelectedOption('Company')}
                            />}
                            label="Company"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={selectedOption === 'FiberHouse'}
                                onChange={() => setSelectedOption('FiberHouse')}
                            />}
                            label="Lead"
                        />
                    </FormGroup>
                </div>
            </div>
            <button className='create-button' onClick={createMapIcon}>Create</button>
        </div>
    )
}

export default CreateMapIcon