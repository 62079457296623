import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Avatar,
    IconButton,
    Divider,
    Pagination,
    CircularProgress,
    Box,
    Grid
} from '@mui/material';
import {
    Email,
    Message,
    Phone,
    LocationOn,
    WhatsApp,
    ContentCopy,
    Map,
    Business,
    Person,
    Send,
    MailOutline,
    GetApp,
    DoorFront
} from '@mui/icons-material';
import './CandidateStats.scss';
import { MapContext as MP } from '..';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import GetCandidatesByIdsReq from '../../../../Requests/FiberHouse/GetCandidatesByIdsReq';
import { filter } from 'jszip';
import { BiBriefcase } from 'react-icons/bi';
import { FcOrganization } from 'react-icons/fc';
import InvitationModal from './CandidateMainLeadCard/InviteForm';
import Swal from 'sweetalert2';
import { useSwipeable } from 'react-swipeable';
import ContactButtons from '../../../../Services/ContactButtons';
import { UserContext } from '../../../../App';
import { MdOutlineDirections } from 'react-icons/md';
import { IoMapOutline } from 'react-icons/io5';
import { fromLonLat } from 'ol/proj';
import ExportData from '../../../../Services/ExportData';
import MapIconSelector from '../../../../components/MapIconSelector';
import FiberCompanySelector from '../../../../components/FiberCompanySelector';

const CandidateStats = ({ candidates }) => {
    const map = useContext(MP);
    const [candidatesState, setCandidatesState] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterMapIconIds, setFilterMapIconIds] = useState([]);
    const [filterLeadTypes, setFilterLeadTypes] = useState(['Recruit', 'Company']);
    const [filterInviteStatus, setFilterInviteStatus] = useState(['sent', 'unsent']);
    const [filterContactInfoStatus, setFilterContactInfoStatus] = useState(['emailOnly', 'phoneOnly', 'both', 'none']);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [candidateType, setCandidateType] = useState('');
    const [fiberCompany, setFiberCompany] = useState(null);
    const itemsPerPage = 10;
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const user = useContext(UserContext);
    const contactButtons = new ContactButtons(user);
    const stopRef = React.useRef(null);
    const [selectedMapIcon, setSelectedMapIcon] = useState(null)
    const refPassthrough = (el) => {
        handlers.ref(el);
        stopRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })

    useEffect(() => {
        fetchData();
    }, [map.candidateIds]);

    // Fetch the candidate data when component mounts
    const fetchData = async () => {
        setLoading(true);
        try {
            let req = new GetCandidatesByIdsReq();
            req.candidateIds = map.candidateIds;
            console.log(req);
            const candidateResponse = await FiberHouseApi.GetCandidatesByIds(req);

            if (candidateResponse.status.success) {
                const candidates = candidateResponse.data;
                setCandidatesState(candidates);
                setFilteredCandidates(candidates);

                setFilterMapIconIds(map.mapIcons.map((icon) => icon.id));
                setLoading(false);
            } else {
                console.error("Error fetching candidate data:", candidateResponse.status.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        filterCandidates();
    }, [filterMapIconIds, filterLeadTypes, filterInviteStatus, searchTerm, candidatesState, filterContactInfoStatus]);

    const filterCandidates = () => {
        let filtered = candidatesState.filter(candidate => {
            // Map Icon Filtering
            console.log(candidate);
            const mapIconMatch = filterMapIconIds.length === 0 || filterMapIconIds.includes(candidate.mapIconId);

            // Lead Type Filtering
            const leadTypeMatch = filterLeadTypes.length === 0 || filterLeadTypes.includes(candidate.leadType);

            let inviteStatusMatch = true; // Default to true if no filter is applied
            if (filterInviteStatus.includes('sent') && filterInviteStatus.includes('unsent')) {
                inviteStatusMatch = true;
            } else if (filterInviteStatus.includes('sent')) {
                inviteStatusMatch = candidate.candidateInvitation !== null;
            } else if (filterInviteStatus.includes('unsent')) {
                inviteStatusMatch = candidate.candidateInvitation === null;
            }

            let contactInfoMatch = true;
            if (filterContactInfoStatus.length > 0) {
                const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
                const hasPhone = !!candidate.phone;

                if (filterContactInfoStatus.includes('none')) {
                    contactInfoMatch = !hasEmail && !hasPhone;
                }
                if (filterContactInfoStatus.includes('emailOnly')) {
                    contactInfoMatch = hasEmail && !hasPhone;
                }  
                if (filterContactInfoStatus.includes('phoneOnly')) {
                    contactInfoMatch = hasPhone && !hasEmail;
                }
                if (filterContactInfoStatus.includes('both')) {
                    contactInfoMatch = hasEmail && hasPhone;
                }
                
            }

            const searchTermMatch = !searchTerm || (candidate?.name && candidate.name.toLowerCase().includes(searchTerm.toLowerCase()));

            return mapIconMatch && leadTypeMatch && inviteStatusMatch && searchTermMatch && contactInfoMatch;
        });
        console.log(filtered);
        setFilteredCandidates(filtered);
        // if (filtered.length > 0) {
        //     map?.setCandidateIds(filtered.map(candidate => candidate.id));
        // } else {
        //     //map?.setCandidateIds([]); // Clear candidate IDs if no candidates match the filters
        // }
    };

    const toggleMapIcon = (iconId) => {
        setFilterMapIconIds(prev =>
            prev.includes(iconId)
                ? prev.filter(id => id !== iconId)
                : [...prev, iconId]
        );
    };



    const handleSelectAllMapIcons = () => {
        const allMapIcons = [...new Set(candidatesState.map(candidate => candidate.mapIconId).filter(id => id !== null))];
        setFilterMapIconIds(allMapIcons);
    };

    const handleDeselectAllMapIcons = () => {
        setFilterMapIconIds([]);
    };

    const toggleLeadType = (type) => {
        setFilterLeadTypes(prev =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    const toggleContactInfoStatus = (status) => {
        setFilterContactInfoStatus(prev =>
            prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
        );
    };

    const toggleInviteStatus = (status) => {
        setFilterInviteStatus(prev =>
            prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
        );
    };

    const exportDataInstance = new ExportData();

    const handleExportLeads = () => {
        exportDataInstance.exportCandidateStatsLeads(filteredCandidates);
    };
    const hasValidEmailCandidates = filteredCandidates.some(candidate => candidate.personalEmail || candidate.companyEmail);
    const hasValidPhoneCandidates = filteredCandidates.some(candidate => candidate.phone);

    const getMapIconStats = () => {
        const mapIconStats = [];
        const mapIconIdsFromCandidates = [...new Set(candidatesState.map(candidate => candidate.mapIconId).filter(id => id !== null))];

        mapIconIdsFromCandidates.forEach(iconId => {
            const assignedMapIcon = map.mapIcons.find((icon) => icon.id === iconId);
            if (assignedMapIcon) {
                const count = filteredCandidates.filter(candidate => candidate.mapIconId === iconId).length;
                mapIconStats.push({ assignedMapIcons: assignedMapIcon, count });
            }
        });

        return mapIconStats;
    };

    const getRecruitCompanyStats = () => {
        const recruitCompanyStats = { recruit: 0, company: 0 };
        filteredCandidates.forEach((candidate) => {
            console.log(candidate);
            if (candidate.leadType === 'Recruit') {
                recruitCompanyStats.recruit += 1;
            } else if (candidate.leadType === 'Company') {
                recruitCompanyStats.company += 1;
            }
        });

        return recruitCompanyStats;
    };

    const getInviteStatusStats = () => {
        const inviteStats = { sent: 0, unsent: 0 };
        filteredCandidates.forEach((candidate) => {
            console.log(candidate);
            if (candidate.candidateInvitation !== null) {
                inviteStats.sent += 1;
            } else {
                inviteStats.unsent += 1;
            }
        });

        return inviteStats;
    };

    const getContactInfoStats = () => {
        const stats = {
            emailOnly: 0,
            phoneOnly: 0,
            both: 0,
            none: 0
        };

        filteredCandidates.forEach(candidate => {
            const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
            const hasPhone = !!candidate.phone;

            if (hasEmail && hasPhone) {
                stats.both += 1;
            } else if (hasEmail) {
                stats.emailOnly += 1;
            } else if (hasPhone) {
                stats.phoneOnly += 1;
            } else {
                stats.none += 1;
            }
        });

        return stats;
    };

    const snapToLocation = (candidate) => {
        map.candidateClicked(candidate);
        let lonlat = [candidate.coordinates.longitude, candidate.coordinates.latitude]
        map.mapObject.getView().setCenter(fromLonLat(lonlat));
        map.mapObject.getView().setZoom(21);
    }

    useEffect(() => {
        if (map.searchAreaFeature.length > 0) {
            try {
                // Identify candidate IDs that should be removed
                console.log("Filtered Candidates:", filteredCandidates);
                console.log("Candidates on map:", candidatesState);
                const idsToRemove = candidatesState
                    .filter((candidate) => !filteredCandidates.some((fh) => fh.id === candidate.id))
                    .map((candidate) => candidate.id);

                console.log(idsToRemove);
                // Update the map with the candidates
                map.setCandidates(
                    candidates.map((candidate) => {
                        if (idsToRemove.includes(candidate.id)) {
                          
                            return {
                                ...candidate,
                                remove: true,
                            };
                        } else {
                       
                            const fh = filteredCandidates.filter((fh) => fh.id === candidate.id)[0];
                            return {
                                ...candidate,
                                remove: false,
                                selected: fh ? true : false,
                            };
                        }
                    })
                );
            } catch (error) {
                console.error("Error updating candidates on the map:", error);
            }
        }
    }, [filteredCandidates]);

    const contactInfoStats = getContactInfoStats();


    const recruitCompanyStats = getRecruitCompanyStats();
    const inviteStatusStats = getInviteStatusStats();

    const paginatedCandidates = filteredCandidates.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </div>
        );
    }

    const InfoRow = ({ label, value }) => (
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 0.5 }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold', mr: 0.5, minWidth: '80px' }}>
                {label}:
            </Typography>
            <Typography variant="caption">{value}</Typography>
        </Box>
    );

    //open invitation model
    const openInvitationModelForEmail = () => {
        //if recruits and companies aer both selected return swal error
        if (filterLeadTypes.includes('Recruit') && filterLeadTypes.includes('Company')) {
            //show swal

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select only one either Recruits or Companies to send invites',
            });
            return;

        } else {
            if (filterLeadTypes.includes('Recruit')) {
                setCandidateType('Recruit');
            } else {
                setCandidateType('Company');
            }
            setIsInviteOpen("Email");
        }


    }


    const openInvitationModelForSMS = () => {
        //if recruits and companies aer both selected return swal error
        if (filterLeadTypes.includes('Recruit') && filterLeadTypes.includes('Company')) {
            //show swal

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select only one either Recruits or Companies to send invites',
            });
            return;

        } else {
            if (filterLeadTypes.includes('Recruit')) {
                setCandidateType('Recruit');
            } else {
                setCandidateType('Company');
            }
            setIsInviteOpen("SMS");
        }
    }



    return (
        <div className="candidate-stats">
            <div
                style={{ height: window.innerHeight - 260 + 'px', overflowY: 'auto' }}
                ref={refPassthrough}
            >
                <div className='TitleDiv justify-center'>
                    <h1 className='text-4xl font-bold'>Candidate Stats</h1>
                </div>

                <div className="Stats grid grid-cols-1 gap-1">
                    <div className='grid grid-cols-3 gap-2'>

                        <button

                            onClick={() => openInvitationModelForEmail()}
                            startIcon={<Email />}
                            // className="px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto"
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'
                            disabled={!hasValidEmailCandidates}
                        >
                            Send Email Invites
                        </button>
                        <button

                            startIcon={<Message />}
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'
                            onClick={() => openInvitationModelForSMS()}
                            disabled={!hasValidPhoneCandidates}
                        >
                            Send SMS Invites
                        </button>
                        <button

                            startIcon={<GetApp />}
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'

                            onClick={handleExportLeads}
                        >
                            Export {filteredCandidates.length} Leads
                        </button>




                    </div>

                    <div className='Stat flex justify-center items-center gap-2 mt-2'>
                        <p>TOTAL LEADS: {filteredCandidates.length}</p>
                    </div>

                    <hr className='my-2 mx-0' />
                    <h1 className='lead_owners_title'>Leads Status</h1>
                    <div className='select_deselect'>
                        <button className='select' onClick={() => {
                            setFilterMapIconIds([])
                        }}>Select All</button>
                        <button className='deselect' onClick={() => {
                            setFilterMapIconIds(
                                map.mapIcons.map((icon) => icon.id)
                            )
                        }}>Deselect All</button>
                    </div>



                    <div className="MapIconStats">
                        {
                            getMapIconStats().map((stat, index) => (
                                <div className='flex flex-col items-center content-center gap-1' key={index}>
                                    <div
                                        key={index}
                                        // className={`map-icon-stat ${filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : ''}`}
                                        onClick={() => toggleMapIcon(stat.assignedMapIcons.id)}
                                        style={{ borderRadius: "10px" }} className={`MapIconStat  ${!filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : null} border-2`}
                                    >
                                        <h3>
                                            <img src={"data:image/png;base64," + stat.assignedMapIcons.image} alt="Map Icon" />

                                        </h3>
                                        <p >{stat.assignedMapIcons.abbreviation}</p>
                                        <p style={{
                                            fontWeight: !filterMapIconIds.includes(stat.assignedMapIcons.id) ? "bold" : null
                                        }}>
                                            {stat.count}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>


                    <hr className='my-2 mx-0' />

                    <>
                        <h1 className='lead_owners_title'>Recruit & Company Stats</h1>

                        <div className="stats-section">
                            {/* <Typography variant="h6">Recruit & Company Stats</Typography> */}
                            <div className="type-stats">
                                <div
                                    className={`stat-item ${filterLeadTypes.includes('Recruit') ? 'active' : ''}`}
                                    onClick={() => toggleLeadType('Recruit')}
                                >
                                    <Person fontSize="large" />
                                    <Typography variant="body1">Recruits</Typography>
                                    <Typography variant="h6">{recruitCompanyStats.recruit}</Typography>
                                </div>
                                <div
                                    className={`stat-item ${filterLeadTypes.includes('Company') ? 'active' : ''}`}
                                    onClick={() => toggleLeadType('Company')}
                                >
                                    <Business fontSize="large" />
                                    <Typography variant="body1">Companies</Typography>
                                    <Typography variant="h6">{recruitCompanyStats.company}</Typography>
                                </div>
                            </div>
                        </div>
                    </>

                    <hr className='my-2 mx-0' />

                    <div className="stats-section">
                        <Typography variant="h6">Info </Typography>
                        <div className="invite-stats">
                            <div
                                className={`stat-item ${filterInviteStatus.includes('sent') ? 'active' : ''}`}
                                onClick={() => toggleInviteStatus('sent')}
                            >
                                <Send fontSize="medium" />
                                <Typography variant="body1">Sent</Typography>
                                <Typography variant="h6">{inviteStatusStats.sent}</Typography>
                            </div>
                            <div
                                className={`stat-item ${filterInviteStatus.includes('unsent') ? 'active' : ''}`}
                                onClick={() => toggleInviteStatus('unsent')}
                            >
                                <MailOutline fontSize="medium" />
                                <p>Unsent</p>
                                <Typography variant="h6">{inviteStatusStats.unsent}</Typography>
                            </div>
                        </div>
                    </div>

                    <hr className='my-2 mx-0' />

                    <div className="stats-section">
                        <Typography variant="h6">Invite Status</Typography>
                        <div className="invite-stats">
                            <div
                                className={`stat-item ${filterContactInfoStatus.includes('emailOnly') ? 'active' : ''}`}
                                onClick={() => toggleContactInfoStatus('emailOnly')}
                            >
                                <Send fontSize="medium" />
                                <Typography variant="body1">Has Email Only</Typography>
                                <Typography variant="h6">{contactInfoStats.emailOnly}</Typography>
                            </div>
                            <div
                                className={`stat-item ${filterContactInfoStatus.includes('phoneOnly') ? 'active' : ''}`}
                                onClick={() => toggleContactInfoStatus('phoneOnly')}
                            >
                                <MailOutline fontSize="medium" />
                                <Typography variant="body1">Has Phone Only</Typography>
                                <Typography variant="h6">{contactInfoStats.phoneOnly}</Typography>
                            </div>
                            <div
                                className={`stat-item ${filterContactInfoStatus.includes('both') ? 'active' : ''}`}
                                onClick={() => toggleContactInfoStatus('both')}
                            >
                                <MailOutline fontSize="medium" />
                                <Typography variant="body1">Has Both</Typography>
                                <Typography variant="h6">{contactInfoStats.both}</Typography>
                            </div>
                            <div
                                className={`stat-item ${filterContactInfoStatus.includes('none') ? 'active' : ''}`}
                                onClick={() => toggleContactInfoStatus('none')}
                            >
                                <MailOutline fontSize="medium" />
                                <Typography variant="body1">Has None</Typography>
                                <Typography variant="h6">{contactInfoStats.none}</Typography>
                            </div>
                        </div>
                    </div>



                </div>

                <InvitationModal open={isInviteOpen} onClose={() => setIsInviteOpen(false)} isStatSMS={contactInfoStats.phoneOnly || contactInfoStats.both} isStatEmail={contactInfoStats.emailOnly || contactInfoStats.both} candidateType={candidateType} filteredCandidateIds={filteredCandidates.map((c) => c.id)} />

                {/* {
                    (user.userType === "SuperAdmin" || user.userType === "Admin") &&
                    <>
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        <h1 style={{
                            marginTop: "1rem"
                        }} className='lead_owners_title'>Update Lead Status</h1>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            gap: "0.5rem",

                        }}>
                            <MapIconSelector setAssignedMapIcons={setSelectedMapIcon} placeholder='Select Map Icon' />
                            <button style={{
                                backgroundColor: "#181E4B ",
                                color: "white",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}  >Update</button>
                        </div>
                    </>
                }
                <hr style={{ margin: "0.5rem 0rem" }} />
                {
                    user.userType === "SuperAdmin" &&
                    <>
                        <h1 style={{
                            marginTop: "1rem"
                        }} className='lead_owners_title'>Assign Fiber Company</h1>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            gap: "0.5rem",
                        }}>
                            <FiberCompanySelector setUser={setFiberCompany} placeholder='Select Company' />
                            <button style={{
                                backgroundColor: "#181E4B ",
                                color: "white",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}  >Update</button>
                        </div>
                    </>
                } */}

                <div className="search-section">
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search candidates..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>

                {/* <div className="candidates-list">
                    {paginatedCandidates.map(candidate => (
                        <Card key={candidate.id} className="candidate-card" sx={{ mb: 2 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={8}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <img
                                                src={`data:image/png;base64,${candidate.mapIcon.image}`}
                                                alt="Map Icon"
                                                style={{ width: 24, height: 24, marginRight: 8 }}
                                            />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1 }}>
                                                {candidate.name ? candidate.name : 'No Name'}
                                            </Typography>
                                            {candidate.leadType === "Recruit" ? <BiBriefcase /> : <FcOrganization />}
                                            {candidate.doorToDoor && <DoorFront sx={{ ml: 1, fontSize: 18 }} />}
                                        </Box>
                                        <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                            {candidate.mapString}
                                        </Typography>
                                        {candidate.personalEmail && (
                                            <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                                {candidate.personalEmail}
                                            </Typography>
                                        )}

                                        <Box sx={{ mt: 1 }}>
                                            <InfoRow label="Company" value={candidate.companyName} />
                                            {candidate.industry && <InfoRow label="Industry" value={candidate.industry} />}
                                            {candidate.positionTitle && <InfoRow label="Title" value={candidate.positionTitle} />}
                                            {candidate.companyEmail && <InfoRow label="Company Email" value={candidate.companyEmail} />}
                                            {candidate.linkedIn && <InfoRow label="LinkedIn" value={candidate.linkedIn} />}
                                        </Box>

                                    </Grid>
                                    <div className="candidate-actions">
                                        <IconButton onClick={() => contactButtons.email(candidate.personalEmail ? candidate.personalEmail : candidate.companyEmail)}>
                                            <Email />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.whatsapp(candidate.phone)}>
                                            <WhatsApp />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.sms(candidate.phone)}>
                                            <Message />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.call(candidate.phone)}>
                                            <Phone />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.directions(candidate.coordinates)}>
                                            <MdOutlineDirections color='#81848A' size={30} />
                                        </IconButton>
                                        <IconButton onClick={() => snapToLocation(candidate)}>
                                            <IoMapOutline color='#81848A' size={30} />
                                        </IconButton>
                                        
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </div> */}

                {/* <div className="candidates-list"> */}
                {paginatedCandidates.map(candidate => (
                    <div className='NewCandidateCard' style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid var(--primary-color)",
                        marginTop: "2rem",
                        padding: "1rem 0",
                    }} 
                  // flex direction column on 700px
                  
                    >


                        <div className='flex gap-2 items-center' >


                            <div className='ImagesDiv'>
                                <div className='Image'>
                                    <img
                                        src={`data:image/png;base64,${candidate.mapIcon.image}`} alt="Map Icon" />
                                </div>

                            </div>

                            <div className='DataDiv'>
                                <div className='Name'>
                                    <h1 style={{ fontSize: "1.5rem", fontWeight: 'bold' }}> {candidate.name ? candidate.name : 'No Name'}
                                        {/* <span style={{
                                        fontSize: "0.8rem",
                                        color: "#2761D0",

                                    }}>{getSalesRepName(fh)}</span> */}
                                    </h1>
                                </div>

                                <div className='Address'>
                                    <p style={{
                                        fontSize: "1.2rem",
                                    }}
                                    >
                                        {candidate.mapString}
                                    </p>
                                </div>
                                <div className='additional_info'>
                                    {

                                        <p style={{ fontSize: ".8rem" }}>Company | {candidate.companyName}</p>
                                    }

                                    {
                                        candidate.industry && <p style={{ fontSize: ".8rem" }}>Industry | {candidate.industry} </p>
                                    }

                                    {
                                        candidate.positionTitle && <p style={{ fontSize: ".8rem" }}>Position | {candidate.positionTitle}</p>
                                    }
                                    {
                                        candidate.companyEmail && <p style={{ fontSize: ".8rem" }}>Company Email | {candidate.companyEmail}</p>
                                    }
                                    {
                                        candidate.linkedIn && <p style={{ fontSize: ".8rem" }}>LinkedIn | {candidate.linkedIn}</p>
                                    }

                                </div>


                            </div>
                        </div>

                        <div className= 'NewCandidateButtons'
                       

                          >

                            <Email fontSize='large' sx={{cursor:'pointer', color:'#81848A'}} onClick={() => contactButtons.email(candidate.personalEmail ? candidate.personalEmail : candidate.companyEmail)} />
                            <WhatsApp fontSize='large' sx={{cursor:'pointer',color:'#81848A' }} onClick={() => contactButtons.whatsapp(candidate.phone)} />
                            <Message fontSize='large' sx={{cursor:'pointer',color:'#81848A'}} onClick={() => contactButtons.sms(candidate.phone)} />
                            <Phone fontSize='large' sx={{cursor:'pointer',color:'#81848A'}}  onClick={() => contactButtons.call(candidate.phone)} />
                            <MdOutlineDirections style={{fontSize:"2rem", cursor:"pointer",color:'#81848A'}} onClick={() => contactButtons.directions(candidate.coordinates)} />

                        </div>









                    </ div>



                ))}
                {/* </div> */}

                <Pagination
                    className="pagination mb-4"
                    count={Math.ceil(filteredCandidates.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                    
                />
            </div>
        </div>
    );
};

export default CandidateStats;
