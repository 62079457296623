import React, { useEffect, useState, useContext, useRef } from "react";
import {
  CircularProgress,
  Modal,
  Box,
  IconButton,
  // stepIconClasses,
  // CssBaseline,
} from "@mui/material";
import Swal from "sweetalert2";
import "./SellingCardDetails.scss";
import { UserContext } from "../../../../App";
import Layout from "../../../../Layouts/Layout";
import SellingCardApi from "../../../../API/SellingCardApi";
import StringIntReq from "../../../../Requests/StringIntReq";
import { useNavigate, useParams } from "react-router-dom";
// import { getIconForFirebaseFile } from "../../../../Services/DisplayFile";
import {
  // IoIosAddCircle,
  // IoMdArrowDropdownCircle,
  // IoMdArrowDropupCircle,
  // IoMdArrowRoundBack,
  IoMdCloseCircle,
} from "react-icons/io";
import { MdClose, MdDelete} from "react-icons/md";
import StepCard from "../../SellingCardTutorial/component/StepCard";
import OrganizationSelector from "../../../../components/OrganizationSelector";
import { getStorage, ref, deleteObject } from "firebase/storage";
import ShopItemsApi from "../../../../API/ShopItemsApi";
import DeleteSellingCardBlockReq from "../../../../Requests/SellingCard/DeleteSellingCardBlockReq";
import RemoveFileReq from "../../../../Requests/CloudFile/RemoveFileReq";

// import SellingCardTreeSideBar from "../components/SellingCardTreeSideBar";
import SellingCardEditor from "../components/SellingCardEditor";
import SideBarTree from "../components/SideBarTree";
import VersatileLoader from "../../../../components/VersatileLoader";
import { min } from "moment";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import ModuleQuiz from "../components/ModuleQuiz";

export function usePrompt(message, when = true) {
  const navigate = useNavigate();
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = (...args) => {
      if (window.confirm(message)) {
        push.apply(navigator, args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [message, navigator, when]);
}

const SellingCardDetailsNew = () => {
  const { trainingModuleId } = useParams();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [trainingModule, setTrainingModule] = useState(null);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [organizationIds, setOrganizationIds] = useState([]);
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const[selectedSellingCardId, setSelectedSellingCardId] = useState(null);
  const [selectedSubTrainingModuleId, setSelectedSubTrainingModuleId] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [sellingCardIds, setSellingCardIds] = useState([]);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  // const sortable = useRef(null);
  const storage = getStorage();
  const initialRender = useRef(true);

  

  useEffect(() => {
    fetchSellingCardDetails();
  }, []);

  const fetchSellingCardDetails = async () => {
    setLoading(true);
    const req = new StringIntReq();
    req.int = trainingModuleId;
    const res = await SellingCardApi.GetTrainingModule(req);
    if (res.status.success) {
      setTrainingModule(res.data);
      // setSteps(res.data.blocks.sort((a, b) => a.blockNumber - b.blockNumber));
      setLoading(false);
    } else {
      Swal.fire("Error", res.status.message, "error");
    }
  };

  const handleCardClick = (step, index) => {
    setCurrentStep(step);
    navigate(
      `/manage-training-modules/training-module-tutorial/${trainingModuleId}/${trainingModule?.name}/${index}`
    );
  };

  const handleAddNewStep = () => {
    setCurrentStep(null);
    setModalOpen(true);
  };

  const handleDeleteSellingCard = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this selling card!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const req = new StringIntReq();
        req.int = trainingModuleId;
        const res = await SellingCardApi.DeleteSellingCard(req);
        if (res.status.success) {
          Swal.fire(
            "Deleted!",
            "Your selling card has been deleted.",
            "success"
          );
          navigate("/manage-training-modules");
        } else {
          Swal.fire("Error", res.status.message, "error");
        }
      }
    });
  };

  const swapSteps = async (direction, index) => {
    const newSteps = [...steps];
    if (direction === "up" && index > 0) {
      [newSteps[index], newSteps[index - 1]] = [
        newSteps[index - 1],
        newSteps[index],
      ];
    } else if (direction === "down" && index < newSteps.length - 1) {
      [newSteps[index], newSteps[index + 1]] = [
        newSteps[index + 1],
        newSteps[index],
      ];
    } else {
      return;
    }

    setSteps(newSteps);

    const blockNumbers = newSteps.reduce((acc, step, i) => {
      acc[step.id] = i + 1;
      return acc;
    }, {});
    try {
      await SellingCardApi.ChangeSellingCardBlockNumbers({
        sellingCardId: trainingModuleId,
        blockNumbers: blockNumbers,
      });
    } catch (error) {
      Swal.fire("Error", "Failed to update step order", "error");
    }
  };

  const removeStep = (id) => {
    console.log(id);
    Swal.fire({
      icon: "info",
      title: "Deleting Step",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const req = new DeleteSellingCardBlockReq();
    req.int = id;
    const deleteFiles = async (f) => {
      const fileRef = ref(storage, f.path);
      try {
        await deleteObject(fileRef);
        const req = new RemoveFileReq();
        req.int = f.id;
        const res = await ShopItemsApi.RemoveFile(req);
        if (res?.status?.success) {
        }
      } catch (error) {
        console.error("An error occurred!", error);
      }
    };

    const item = steps.filter((i) => i.id === id)[0];
    if (item.file)
      deleteFiles(item.file).then(() => {
        SellingCardApi.DeleteSellingCardBlock(req).then((response) => {
          if (response.status.success) {
            Swal.fire({
              title: "Step Deleted",
              text: "Step Deleted Successfully",
              icon: "success",
              didOpen: () => {
                Swal.hideLoading();
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.status.message,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: true,
              didOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        });
      });
    else {
      SellingCardApi.DeleteSellingCardBlock(req).then((response) => {
        if (response.status.success) {
          Swal.fire({
            title: "Step Deleted",
            text: "Step Deleted Successfully",
            icon: "success",
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.status.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      });
    }
    const newList = steps.filter((item) => item.id !== id);
    setSteps(newList);
  };

  const updateVisibility = async () => {
    Swal.fire({
      icon: "info",
      title: "Updating Visibility",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = {
      sellingCardId: trainingModuleId,
      salesOrgIds: [...organizationIds],
    };

    const res = await SellingCardApi.AddSalesOrgsToSellingCard(req);
    if (res?.status?.success) {
      fetchSellingCardDetails(false);
      setVisibilityModalOpen(false);
      Swal.fire({
        title: "Visibility Updated",
        text: "Visibility Updated Successfully",
        icon: "success",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const assignAllSalesOrgs = async () => {
    Swal.fire({
      icon: "info",
      title: "Assigning Sales Orgs",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = {
      sellingCardId: trainingModuleId,
      salesOrgIds: [...organizationIds],
    };

    const res = await SellingCardApi.AssignAllSalesOrgsToSellingCard(req);
    if (res?.status?.success) {
      fetchSellingCardDetails(false);
      setVisibilityModalOpen(false);
      Swal.fire({
        title: "Sales Orgs Assigned",
        text: "All Sales Orgs Assigned Successfully",
        icon: "success",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const deleteAllSalesOrgs = async () => {
    Swal.fire({
      icon: "info",
      title: "Deleting Sales Orgs",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = {
      sellingCardId: trainingModuleId,
      salesOrgIds: [...organizationIds],
    };

    const res = await SellingCardApi.DeleteAllSalesOrgsFromSellingCard(req);
    if (res?.status?.success) {
      fetchSellingCardDetails(false);
      setVisibilityModalOpen(false);
      Swal.fire({
        title: "Sales Orgs Deleted",
        text: "All Sales Orgs Deleted Successfully",
        icon: "success",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const removeSalesOrg = async (id) => {
    Swal.fire({
      icon: "info",
      title: "Deleting Sales Org",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = id;

    const res = await SellingCardApi.RemoveSalesOrgFromSellingCard(req);
    if (res?.status?.success) {
      fetchSellingCardDetails(false);
      setVisibilityModalOpen(false);
      Swal.fire({
        title: "Sales Org Removed",
        text: "Sales Org Removed Successfully",
        icon: "success",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const updateBlockNumber = async (newList) => {
    const req = {
      sellingCardId: parseInt(trainingModuleId),
      blockNumbers: {},
    };
    newList.forEach((step, index) => {
      req.blockNumbers[step.id] = index;
    });
    const res = await SellingCardApi.ChangeSellingCardBlockNumbers(req);

    if (res?.status?.success) {
      console.log("Block numbers updated");
      //searchTutorial()
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else {
      const timerId = setTimeout(() => {
        updateBlockNumber(steps);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [steps]);
  usePrompt("You have unsaved changes. Are you sure you want to leave?", hasUnsavedChanges);
  return (
    <Layout>
      {loading ? (
        <div className="spinner">
          <VersatileLoader color = "#2761D0" size ='large' />
        </div>
      ) : (
        <div className="flex min-h-full max-h-full border-2 sm:mt-2 mt-0 rounded-lg" style={{minHeight: '80vh'}}>
          <div
            className={`h-full transition-all duration-100 border-r-2 ${
              isSidebarOpen ? "w-auto" : "w-0"
            }`} 
          >
            <SideBarTree onSidebarToggle={setIsSidebarOpen} trainingModule = {trainingModule} setSelectedSellingCardId = {setSelectedSellingCardId} setSelectedSubTrainingModuleId={setSelectedSubTrainingModuleId} hasUnsavedChanges={hasUnsavedChanges} selectedSellingCardId={selectedSellingCardId} setHasUnsavedChanges={setHasUnsavedChanges} setSellingCardIds={setSellingCardIds} setQuizModalOpen={setQuizModalOpen}/>
          </div>
          <div className="w-full min-h-full">
            {quizModalOpen ? (
              <ModuleQuiz setQuizModalOpen={setQuizModalOpen}/> ): (
                <SellingCardEditor editorId={trainingModuleId} selectedSellingCardId = {selectedSellingCardId} setSelectedSellingCardId={setSelectedSellingCardId} selectedSubTrainingModuleId={selectedSubTrainingModuleId} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} sellingCardIds={sellingCardIds}/>
              )}
            
            </div>
        </div>
      )}
      {/* Modal for Adding/Editing Step */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="step-modal-title"
        aria-describedby="step-modal-description"
      >
        <Box
          sx={{
            maxWidth: "90vw",
            maxHeight: "90vh",
            margin: "auto",
            mt: 4,
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            position: "relative",
            padding: 2,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => setModalOpen(false)}
          >
            <MdClose />
          </IconButton>
          <div className="sellingCardTutorialContainer">
            <div className="innerContainer">
              <StepCard
                index={currentStep ? currentStep.blockNumber - 1 : steps.length}
                card={trainingModule}
                blockNumber={
                  currentStep ? currentStep.blockNumber : steps.length + 1
                }
                update={!!currentStep}
                setUpdate={() => {}}
                blockId={currentStep ? currentStep.id : null}
                searchTutorial={fetchSellingCardDetails}
                title={currentStep ? currentStep.title : ""}
                description={currentStep ? currentStep.content : ""}
                removeStep={removeStep}
                files={currentStep ? currentStep.file : null}
                create={!currentStep}
                totalSteps={steps.length}
                cardId={trainingModuleId}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for Managing Visibility */}
      <Modal
        open={visibilityModalOpen}
        onClose={() => setVisibilityModalOpen(false)}
        aria-labelledby="visibility-modal-title"
        aria-describedby="visibility-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: 600,
            margin: "auto",
            mt: 4,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => setVisibilityModalOpen(false)}
          >
            <MdClose />
          </IconButton>
          <div className="visibility_container">
            <OrganizationSelector
              placeholder="Select Sales Orgs"
              setUser={setOrganizationIds}
              multiple={true}
            />
            <button
              style={{
                backgroundColor: "#2761D0",
                color: "white",
                padding: "0.5rem 1rem",
                borderRadius: "10px",
                border: "none",
                width: "100%",
                marginTop: "1rem",
              }}
              onClick={updateVisibility}
            >
              Update
            </button>
            <button
              style={{
                backgroundColor: "#008000",
                color: "white",
                padding: "0.5rem 1rem",
                borderRadius: "10px",
                border: "none",
                width: "100%",
                marginTop: "1rem",
              }}
              onClick={assignAllSalesOrgs}
            >
              Assign All
            </button>
            <button
              style={{
                backgroundColor: "#FF0000",
                color: "white",
                padding: "0.5rem 1rem",
                borderRadius: "10px",
                border: "none",
                width: "100%",
                marginTop: "1rem",
              }}
              onClick={deleteAllSalesOrgs}
            >
              Remove All
            </button>
            <h1
              style={{
                marginTop: "1rem",
                fontSize: "1.2rem",
              }}
            >
              Currently Visible to:
            </h1>
            <div className="sales_org_list">
              {trainingModule?.sellingCardSalesOrgs?.length > 0 ? (
                trainingModule?.sellingCardSalesOrgs?.map((i, index) => {
                  return (
                    <div key={index} className="sales_org">
                      <p>{i.salesOrg.name}</p>
                      <IoMdCloseCircle
                        color="red"
                        style={{ cursor: "pointer" }}
                        size={20}
                        onClick={() => {
                          removeSalesOrg(i.id);
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <p>No Sales Orgs</p>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default SellingCardDetailsNew;
