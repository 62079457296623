import * as XLSX from 'xlsx';

const fieldMappings = {
  Recruit: {
    fullName: {
      single: ['Full Name', 'Name', 'Recruit Name', 'First Name', 'Last Name', 'Contact Name', 'Representative'],
      composite: {
        first: ['DM First Name', 'First Name', 'Given Name'],
        last: ['DM Last Name', 'Last Name', 'Family Name']
      }
    },
    personalEmail: ['Email 2', 'Personal Email', 'Secondary Email', 'Personal Email ', 'Alternate Email', 'Personal Email Address'],
    companyEmail: ['Email', 'Work Email', 'Primary Email', 'Business Email', 'Company Email', 'Business Email Address'],
    linkedIn: ['Linkedin Links', 'LinkedIn', 'LinkedIn URL', 'LinkedIn Link', 'Company LinkedIn', 'LinkedIn Profile', 'DM Linkedin Link'],
    doorToDoor: ['Door to Door sell? Yes/No', 'Door to Door', 'D2D', 'Accepts Door to Door', 'D2D Allowed', 'Door Sales'],
    address: ['Address', 'Street Address', 'Location', 'Company Address'],
    city: ['City', 'Town', 'Company City'],
    state: ['State', 'Province', 'Company State'],
    zip: ['Zip Code', 'Postal Code', 'ZIP', 'Company Zip Code', 'Code'],
    title: ['Title', 'Job Title', 'Position', 'Contact Title', 'DM Title'],
    industry: ['Industry', 'Sector', 'Business Type'],
    company: ['Company Name', 'Organization', 'Employer', 'Business Name'],
    country: ['Country', 'Nation'],
    phone: ['Phone', 'Phone Number', 'Phone Numbers', 'Contact Number', 'Number']
  },
  Company: {
    fullName: {
      single: ['Full Name', 'Name', 'Recruit Name', 'First Name', 'Last Name', 'Contact Name', 'Representative'],
      composite: {
        first: ['DM First Name', 'First Name', 'Given Name'],
        last: ['DM Last Name', 'Last Name', 'Family Name']
      }
    },
    personalEmail: ['Email 2', 'Personal Email', 'Secondary Email', 'Personal Email ', 'Alternate Email', 'Personal Email Address'],
    companyEmail: ['Email', 'Work Email', 'Primary Email', 'Business Email', 'Company Email', 'Business Email Address'],
    linkedIn: ['Linkedin Links', 'LinkedIn', 'LinkedIn URL', 'LinkedIn Link', 'Company LinkedIn', 'LinkedIn Profile', 'DM Linkedin Link'],
    doorToDoor: ['Door to Door sell? Yes/No', 'Door to Door', 'D2D', 'Accepts Door to Door', 'D2D Allowed', 'Door Sales'],
    address: ['Address', 'Street Address', 'Location', 'Company Address'],
    city: ['City', 'Town', 'Company City'],
    state: ['State', 'Province', 'Company State'],
    zip: ['Zip Code', 'Postal Code', 'ZIP', 'Company Zip Code', 'Code'],
    title: ['Title', 'Job Title', 'Position', 'Contact Title', 'DM Title'],
    industry: ['Industry', 'Sector', 'Business Type'],
    company: ['Company Name', 'Organization', 'Employer', 'Business Name'],
    country: ['Country', 'Nation'],
    phone: ['Phone', 'Phone Number', 'Phone Numbers', 'Contact Number', 'Number']
  }
};

const findMatchingHeader = (headers, fieldOptions) => {
  console.log('Available headers:', headers);
  if (Array.isArray(fieldOptions)) {
    return fieldOptions.find(option => 
      headers.some(header => {
        if (header && typeof header === 'string') {
          const normalizedHeader = header.trim().toLowerCase();
          const normalizedOption = option.trim().toLowerCase();
          console.log('Comparing:', normalizedHeader, 'with', normalizedOption);
          return normalizedHeader === normalizedOption;
        }
        return false;
      })
    ) || null;
  }
  return null;
};

const findMatchingHeaders = (headers, compositeOptions) => {
  const firstNameHeader = findMatchingHeader(headers, compositeOptions.first);
  const lastNameHeader = findMatchingHeader(headers, compositeOptions.last);
  return { firstNameHeader, lastNameHeader };
};

const parseWorksheet = (worksheet, headers, mapping) => {
  const data = XLSX.utils.sheet_to_json(worksheet, { header: headers, raw: false, defval: null });
  data.shift(); // Remove the header row

  const headerMap = {};
  for (const [field, options] of Object.entries(mapping)) {
    if (field === 'fullName') {
      // Handle composite name fields
      const singleNameHeader = findMatchingHeader(headers, options.single);
      const { firstNameHeader, lastNameHeader } = findMatchingHeaders(headers, options.composite);
      
      headerMap[field] = {
        single: singleNameHeader,
        first: firstNameHeader,
        last: lastNameHeader
      };
    } else {
      headerMap[field] = findMatchingHeader(headers, options);
    }
    console.log(`Mapping for ${field}:`, headerMap[field]);
  }

  return data.map((row, index) => {
    // Handle full name composition
    let fullName = null;
    if (headerMap.fullName.single) {
      fullName = row[headerMap.fullName.single] || null;
    } else if (headerMap.fullName.first || headerMap.fullName.last) {
      const firstName = row[headerMap.fullName.first] || '';
      const lastName = row[headerMap.fullName.last] || '';
      fullName = `${firstName} ${lastName}`.trim() || null;
    }

    return {
      fullName,
      personalEmail: row[headerMap.personalEmail] || null,
      companyEmail: row[headerMap.companyEmail] || null,
      linkedIn: row[headerMap.linkedIn] || null,
      doorToDoor: row[headerMap.doorToDoor] === "Yes" ? true : (row[headerMap.doorToDoor] ? false : null),
      address: row[headerMap.address] || null,
      city: row[headerMap.city] || null,
      state: row[headerMap.state] || null,
      zip: row[headerMap.zip] || null,
      title: row[headerMap.title] || null,
      industry: row[headerMap.industry] || null,
      company: row[headerMap.company] || null,
      country: row[headerMap.country] || null,
      phone: row[headerMap.phone] || null,
      mapString: `${row[headerMap.address] || ''} ${row[headerMap.city] || ''} ${row[headerMap.state] || ''} ${row[headerMap.zip] || ''}`.trim(),
      index,
      sheet: worksheet.name
    };
  });
};

// Keep parseFile and processParsedData functions unchanged
const parseFile = (file, leadType) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        console.log('File loaded, starting to parse...');
        const workbook = XLSX.read(e.target.result, { type: 'array' });
        console.log('Workbook read successfully');
        
        const sheetNames = workbook.SheetNames;
        console.log('Sheet names:', sheetNames);

        const mapping = fieldMappings[leadType];
        let allParsedData = [];

        sheetNames.forEach(sheetName => {
          console.log(`Processing sheet: ${sheetName}`);
          const worksheet = workbook.Sheets[sheetName];
          
          // Get the range of cells
          const range = XLSX.utils.decode_range(worksheet['!ref']);
          
          // Extract headers from the first row
          const headers = [];
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = worksheet[XLSX.utils.encode_cell({r: 0, c: C})];
            headers.push(cell ? cell.v : '');
          }
          console.log(`Extracted headers for ${sheetName}:`, headers);

          const sheetData = parseWorksheet(worksheet, headers, mapping);
          allParsedData = allParsedData.concat(sheetData);
        });

        console.log('Parsed data from all sheets:', allParsedData.slice(0, 2));
        resolve(allParsedData);
      } catch (error) {
        console.error('Parsing error:', error);
        reject(error);
      }
    };

    reader.onerror = (error) => {
      console.error('File reading error:', error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

const processParsedData = (data, helper) => {
  return data.map(async (row, index) => {
    const mapString = `${row.address || ''} ${row.city || ''} ${row.state || ''} ${row.zip || ''}`.trim();
    let coordinates = null;
    try {
      const details = await helper.searchLocationDetails(mapString);
      coordinates = details.coordinates;
    } catch (e) {
      console.error('Error fetching coordinates:', e);
    }
    return {
      ...row,
      coordinates,
      mapString
    };
  });
};

export { parseFile, processParsedData };

