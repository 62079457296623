import { useTabs } from './TabContext'
import { FileList } from './FileList'
import FileContent from './FileContent'
import { useContext, useEffect } from 'react'
import { ConnectionContext } from '../../../App'

export function ContentArea() {
  const { activeTabId, getListId, setFileData } = useTabs()
  const listId = getListId(activeTabId)


  return (
    <div className="flex-grow flex bg-white text-[#181E4B]">
      {listId ? (
            <FileContent listId={listId} />
      ) : (
        <FileList   />
      )}
    </div>
  )
}

