import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DashboardCard = ({ item, user }) => {
  const navigate = useNavigate();
  // if user.userType is not in item.userTypes array, return null
  // if (!item?.userTypes?.includes(user.userType)) {
  //   return null;
  // }
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    }
  }, []);
  // console.log('item', item)

  return (
    <div onClick={()=>{if(!item.disabled)navigate(item.route)}}
    style={{
      backgroundColor: item.disabled ? '#c6c6c6' : '',
    }}
    className='dashboardCard'>
        <div className='dashboardCardIcon'>
            {item.icon("var(--color-icon)",screenWidth>1000?60:30)}
        </div>
        <div className='dashboardCardTitle'>
            <p>{item.name}</p>
            {
              item.disabled && <p style={{color: 'black',fontSize:"0.6rem"}}>(Coming Soon)</p>
            }
        </div>
    </div>
  )
}

export default DashboardCard