export const DeleteIcon = `
        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.741 7.96179C15.741 14.6443 16.7029 17.665 10.233 17.665C3.76238 17.665 4.74413 14.6443 4.74413 7.96179" stroke="#D21D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.971 5.39978H3.51221" stroke="#D21D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.0957 5.39982C13.0957 5.39982 13.5362 2.26172 10.241 2.26172C6.94656 2.26172 7.38704 5.39982 7.38704 5.39982" stroke="#D21D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `;

export const QuizIcon = `
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.7961 7.14092L16.1275 3.47235C15.8205 3.16532 15.419 3 14.99 3H5.60598C4.72033 3 4 3.72033 4 4.60598V21.394C4 22.2797 4.72033 23 5.60598 23H18.6625C19.5481 23 20.2685 22.2797 20.2685 21.394V8.27455C20.2645 7.8455 20.0992 7.44401 19.7961 7.14092ZM15.978 4.89726L18.3673 7.28262H15.978V4.89726ZM19.1545 21.394C19.1545 21.6656 18.9341 21.886 18.6585 21.886H5.60598C5.33438 21.886 5.11395 21.6656 5.11395 21.394V4.60598C5.11395 4.33438 5.33438 4.11395 5.60598 4.11395H14.8719V7.84157C14.8719 8.14859 15.1199 8.39658 15.4269 8.39658H19.1545V21.394Z" fill="black"/>
        <path d="M10.7034 10.7977H9.56979L7.29858 16.0526H8.54637L9.03446 14.9189H11.2388L11.7268 16.0526H12.9746L10.7034 10.7977ZM9.47926 13.8876L10.1405 12.3604L10.794 13.8876H9.47926Z" fill="black"/>
        <path d="M15.6512 12.9547V11.6007H14.6121V12.9547H13.2974V13.8798H14.6121V15.2338H15.6512V13.8798H16.9659V12.9547H15.6512Z" fill="black"/>
    </svg>
`;

export const RadioInputIcon = `
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 5.49939C8.40993 5.49939 5.49951 8.40981 5.49951 12C5.49951 15.5902 8.40993 18.5006 12.0001 18.5006C15.5903 18.5006 18.5007 15.5902 18.5007 12C18.5007 8.40981 15.5903 5.49939 12.0001 5.49939ZM3.99951 12C3.99951 7.58139 7.58151 3.99939 12.0001 3.99939C16.4187 3.99939 20.0007 7.58139 20.0007 12C20.0007 16.4186 16.4187 20.0006 12.0001 20.0006C7.58151 20.0006 3.99951 16.4186 3.99951 12Z" fill="#1F2328"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 9.5C10.6194 9.5 9.50016 10.6193 9.50016 12C9.50016 13.3807 10.6194 14.5 12.0002 14.5C13.3809 14.5 14.5002 13.3807 14.5002 12C14.5002 10.6193 13.3809 9.5 12.0002 9.5ZM8.00016 12C8.00016 9.79086 9.79102 8 12.0002 8C14.2093 8 16.0002 9.79086 16.0002 12C16.0002 14.2091 14.2093 16 12.0002 16C9.79102 16 8.00016 14.2091 8.00016 12Z" fill="#1F2328"/>
    </svg>
`;

export const CheckboxIcon = `
    <svg fill="#000000" width="15px" height="15px" viewBox="0 0 424.113 424.113" xml:space="preserve">
        <g>
            <g>
                <path d="M376.955,120.307c-6.514,0-11.807,5.286-11.807,11.807v215.593c0,22.785-18.539,41.322-41.322,41.322H64.936
                    c-22.781,0-41.322-18.537-41.322-41.322V88.816c0-22.786,18.541-41.323,41.322-41.323h258.89c6.525,0,11.809-5.287,11.809-11.807
                    c0-6.521-5.281-11.807-11.809-11.807H64.936C29.137,23.88,0,53.01,0,88.815v258.891c0,35.806,29.137,64.936,64.936,64.936h258.89
                    c35.812,0,64.938-29.13,64.938-64.936V132.113C388.762,125.594,383.482,120.307,376.955,120.307z"/>
                <path d="M420.654,14.931c-4.611-4.612-12.096-4.612-16.693,0l-237.24,237.228l-59.297-59.291c-4.611-4.611-12.084-4.611-16.695,0
                    c-4.611,4.612-4.611,12.086,0,16.695l67.656,67.639c2.307,2.308,5.328,3.459,8.348,3.459c3.021,0,6.043-1.151,8.348-3.459
                    c0-0.006,0-0.012,0.012-0.018L420.654,31.625C425.266,27.017,425.266,19.539,420.654,14.931z"/>
            </g>
        </g>
    </svg>
`;