/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import { UserContext } from '../../../../../App'
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import MapIconDisplay from './../MapIconDisplay'
import './MainLeadCard.scss'
import LeadEdit from './LeadEdit'
import LeadData from './LeadData'
import LightningMessage from './Images/LightningMessage.png'
import Call from './Images/Call.png'
import Email from './Images/Email.png'
import Message from './Images/Message.png'
import wa from './Images/whatsapp.svg'
import Directions from './Images/Directions.png'
// import { MdEmail, MdLocationOn } from 'react-icons/md'
// import CustomerApi from '../../../../../API/CustomerApi'
import StringIntReq from '../../../../../Requests/StringIntReq'
import ContactButtons from '../../../../../Services/ContactButtons'
import { useNavigate } from 'react-router-dom'

import { ImSpinner8 } from "react-icons/im";
// import SearchLeadsForContactCenterReq from '../../../../../Requests/Customer/SearchLeadsForContactCenterReq'
// import MapIconApi from '../../../../../API/MapIconApi'
import { MapContext } from '../..'
import { CircularProgress } from '@mui/material'
import { FaRegCopy } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io'
import CustomerApi from '../../../../../API/CustomerApi'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { Icon } from 'ol/style'
import { useSwipeable } from 'react-swipeable'
import ChangeAccountStatusModal from './ChangeAccountStatusModal'
// import { TbFilePhone } from 'react-icons/tb'
// import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'
const MainLeadCard = ({ searchFiberHouses, selectedFiberHouse, user, setIndex }) => {
    let User = useContext(UserContext)
    const { selectedLead, setSelectedLead, setSelectedFiberHouse, selectedFiberHouseFeature, mapIcons, mapObject } = useContext(MapContext);
    const [loading, setLoading] = useState(true);
    const [leadNotes, setLeadNotes] = useState(selectedLead?.notes);
    const [edit, setEdit] = useState(false);
    const [fidiumCustomer, setFidiumCustomer] = useState(null);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [mapIcon, setMapIcon] = useState(selectedFiberHouse?.mapIconId);
    const [markedInvalid, setMarkedInvalid] = useState(selectedLead?.fidiumCustomer?.isInvalid);
    const [accountStatusModal, setAccountStatusModal] = useState(false);
    // const [appointmentDate, setAppointmentDate] = useState(selectedLead?.appointmentDateTime)
    const [reload, setReload] = useState(false)
    const intialRender = useRef(true)
    const shouldSaveText = useRef(false)
    const stopRef = useRef(null)
    const navigate = useNavigate();
    const getLead = async () => {
        setFidiumCustomer(null);
        const req = new StringIntReq()
        req.int = selectedFiberHouse.id;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
        if (res?.status?.success) {
            console.log("Lead", res.data)
            setSelectedLead(res.data);
            setMapIcon(res.data.fiberHouse.mapIconId);
            setLoading(false);
        }
    }

    const refPassthrough = (el) => {
        handlers.ref(el);
        stopRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })

    useEffect(() => {
        if (selectedLead?.notes) {
            setLeadNotes(selectedLead?.notes);
        }
        else {
            setLeadNotes('');
        }
    }, [selectedLead])

    useEffect(
        () => {
            if (selectedFiberHouse != null) {
                setLoading(true);
                setMapIcon(selectedFiberHouse.mapIconId);
                getLead();
            }
        }, [selectedFiberHouse, reload]
    )

    // useEffect(
    //     () => {
    //         return () => {
    //             searchFiberHouses();
    //         }
    //     }, []
    // )

    // useEffect(() => {
    //     const getFidiumCustomer = async () => {
    //         const req = new StringIntReq();
    //         req.int = selectedLead.fidiumCustomerId;
    //         const res = await CustomerApi.GetFidiumCustomerById(req);
    //         if (res?.status?.success) {
    //             setFidiumCustomer(res.data);
    //             console.log("Fidium Customer", res.data);
    //         }
    //     }
    //     if (selectedLead?.fidiumCustomerId != null) {
    //         getFidiumCustomer();
    //     }
    // }, [selectedLead, loading])




    let contactButtons = new ContactButtons(User);

    const handleLightningMessage = async (lead) => {
        console.log("Lead", lead)
        const res = await contactButtons.lightningMessageLead({ ...lead, fidiumCustomer: fidiumCustomer });
        if (res != null) {
            console.log("Conversation", res)
            navigate('/chat', {
                state: { conversation: res }
            })
        }
    }


    const editNotes = useCallback(
        async () => {
            let req = new EditLeadReq();
            req.id = selectedLead.id;
            req.salesOrgId = User.salesOrgId;
            req.salesRepId = User.id;
            req.notes = leadNotes;
            await FiberHouseApi.EditLead(req);
            setRequestInProgress(false);
        }, [leadNotes]
    )

    useEffect(() => {

        if (intialRender.current) {
            intialRender.current = false;
            return;
        }
        else if (shouldSaveText.current) {
            const timer = setTimeout(
                async () => {
                    await editNotes();
                }, 1000);

            return () => clearTimeout(timer);
        }

    }, [leadNotes, editNotes]);

    if (loading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"
            }}>
                <CircularProgress size={40} sx={{
                    color: "var(--primary-color)"
                }} />
            </div>
        )
    }

    const copyNotes = () => {
        navigator.clipboard.writeText(leadNotes);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Notes Copied to Clipboard',
            showConfirmButton: false,
            toast: true,
            position: 'bottom-end',
            timer: 1500
        })
    }

    const markCustomerAsInvalid = async (lead) => {

        const opt = await Swal.fire({
            icon: 'warning',
            title: 'Mark Customer As Invalid',
            text: 'Are you sure you want to mark this customer as invalid?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,

        })

        if (!opt.isConfirmed) {
            return;
        }
        Swal.fire({
            title: 'Please Wait',
            text: 'Marking Customer As Invalid',
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq();
        req.int = lead.fidiumCustomer.id;
        const res = await CustomerApi.MarkCustomerAsInvalid(req);
        if (res?.status?.success) {
            let assignedMapIcons = mapIcons.find(x => x.abbreviation === 'IC')
            if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === selectedFiberHouse.id) {
                console.log("Fiber House Feature Found")
                const newStyle = new Style({
                    image: new Icon({
                        scale: 1.5, // Adjust this value to change the scale of the icon
                        src: "data:image/png;;base64," + assignedMapIcons?.image,
                    }),
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 15, // This is now in pixels
                        stroke: new Stroke({
                            color: "black", // Border color
                            width: 4, // Border width
                        }),
                        fill: new Fill({
                            color: "rgba(0, 0, 0, 0)", // Transparent fill
                        }),
                    }),
                });
                selectedFiberHouseFeature.setStyle([
                    newStyle, // This is the icon style
                    circleStyle, // This is the circle style
                ]);
                selectedFiberHouseFeature.values_.mapIconId = assignedMapIcons.id
                console.log(assignedMapIcons.id)
                console.log(selectedFiberHouseFeature)
                mapObject.getView().setZoom(
                    mapObject.getView().getZoom() + 0.000001
                )
            }
            Swal.fire({
                icon: 'success',
                title: 'Customer Marked As Invalid',
                text: 'The customer has been marked as invalid',
                onOpen: () => {
                    Swal.hideLoading()
                }
            })

            setMarkedInvalid(true)
            setMapIcon(assignedMapIcons.id)
            setReload(!reload)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res.status.message
            })
        }
    }

    const markCustomerAsValid = async (lead) => {

        const opt = await Swal.fire({
            icon: 'warning',
            title: 'Mark Customer As Valid',
            text: 'Are you sure you want to mark this customer as valid?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,

        })

        if (!opt.isConfirmed) {
            return;
        }
        Swal.fire({
            title: 'Please Wait',
            icon: "info",
            text: 'Marking Customer As Valid',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq();
        req.int = lead.fidiumCustomer.id;
        const res = await CustomerApi.MarkCustomerAsValid(req);
        if (res?.status?.success) {

            Swal.fire({
                icon: 'success',
                title: 'Customer Marked As Valid',
                text: 'The customer has been marked as valid',
                onOpen: () => {
                    Swal.hideLoading()
                }
            })
            setMarkedInvalid(false)
            setReload(!reload)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res.status.message
            })
        }
    }


    console.log(selectedFiberHouse)
    return (
        <div className="MainLeadCard">
            <div className="MainLeadCard_LeadData">
                <ChangeAccountStatusModal setReload={setReload} open={accountStatusModal} setOpen={setAccountStatusModal} customer={selectedLead?.fidiumCustomer} />
                {
                    edit ?
                        <LeadEdit lead={selectedLead} setEdit={setEdit} fiberHouse={selectedFiberHouse} searchFiberHouses={getLead} /> :
                        <LeadData mapIcon={mapIcon} setReload={setReload} fidiumCustomer={selectedLead?.fidiumCustomer} lead={selectedLead} setEdit={setEdit} fiberHouse={selectedFiberHouse} setFiberouse={setSelectedFiberHouse} searchFiberHouses={getLead} setIndex={setIndex} />
                }
            </div>

            <div style={{
                overflowY: "auto",
                // padding:"2rem",
                // make the height dynamic
                height: "100%",
                padding: "1rem",
                boxShadow: "0px 0px 5px rgba(0,0,0,0.1) inset",
                
                borderRadius: "10px",
            }} className='scrollable-content'>
                {
                    user.userType === "SuperAdmin" && selectedLead.fidiumCustomer != null &&
                    <div className='flex p-2 justify-center items-center'>
                        <p onClick={() => {
                            setAccountStatusModal(true)
                        }} className='font-bold uppercase text-[#2761D0] underline cursor-pointer' >Change Account Status</p>
                    </div>
                }
                {

                    selectedLead.fidiumCustomer != null &&
                    <div className="isCustomerMarked">
                        {
                            selectedLead?.fidiumCustomer?.orderNumber != null ? (
                                <span
                                    style={{
                                        color: selectedLead?.fidiumCustomer?.isInvalid
                                            ? 'black' // Invalid Customer
                                            : 'green' // Verified Customer
                                    }}
                                >
                                    <span className="check-icon">✔</span>
                                    {selectedLead?.fidiumCustomer?.isInvalid ? ' Invalid Customer' : ' Verified Customer'}
                                </span>
                            ) : (
                                <span style={{ color: 'gray' }}>
                                    <span className="check-icon">✔</span>
                                    Customer
                                </span>
                            )
                        }
                        {
                            selectedLead?.fidiumCustomer?.isInvalid === true || markedInvalid ?
                                <IoIosAddCircle onClick={() => {
                                    markCustomerAsValid(selectedLead)
                                }} color='#81848A' size={30} />
                                :
                                <IoIosRemoveCircle onClick={() => {
                                    markCustomerAsInvalid(selectedLead)
                                }} color='#81848A' size={30} />
                        }
                    </div>
                }
                {
                    // selectedLead.isProspect === true &&
                    // <div className="isCustomerMarked">
                    //     <span>
                    //         <span className="check-icon">✔</span>
                    //         Offer Sent
                    //         {/* put a check icon */}
                    //     </span>
                    // </div>
                }
                {
                    selectedLead.fidiumCustomer == null &&
                    <div className="MainLeadCard_MapIcons">
                        <MapIconDisplay isCustomer={selectedLead.fidiumCustomer != null} removeLightningBolts={true} setMapIcon={setMapIcon} selectedFiberHouse={selectedFiberHouse} />
                    </div>
                }

                <div className="MainLeadCard_Notes">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1em"
                    }}>
                        <h2 style={{
                            marginPTop: "0em"
                        }}>Notes</h2>
                        <FaRegCopy size={20} color='#84A1C8' onClick={() => {
                            copyNotes()
                        }} />
                    </div>
                    <div className='textArea'>
                        <textarea ref={refPassthrough} rows={4} value={leadNotes} onChange={(e) => { setLeadNotes(e.target.value); shouldSaveText.current = true }} />
                        {requestInProgress && <ImSpinner8 className='animate-spin' />}
                    </div>
                </div>

                <div className="MainLeadCard_Buttons">
                    <div className="MainLeadCard_Button" onClick={() => {
                        handleLightningMessage(selectedLead)
                    }}>
                        <img src={LightningMessage} alt="Lightning Message" />
                        <span>Lightning Message</span>
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.emailLead(selectedLead)}>
                        <img src={Email} alt="Email" />
                        <span>Email</span>
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.smsLead(selectedLead)}>
                        <img src={Message} alt="Message" />
                        <span>SMS</span>
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsappLead(selectedLead)}>
                        <img src={wa} alt="Message" />
                        <span>WA</span>
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.callLead(selectedLead)}>
                        <img src={Call} alt="Call" />
                        <span>Call</span>
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => {
                        contactButtons.directions(selectedLead.fiberHouse.coordinates)
                    }}>
                        <img src={Directions} alt="Directions" />
                        <span>Directions</span>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MainLeadCard