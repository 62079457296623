/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import AsyncSelect from 'react-select/async';
import OrgApi from './../../API/OrgApi';
import SoftTypography from './../SoftTypography';
import SearchOrgReq from '../../Requests/Organizations/SearchOrgReq';

const Index = ({ setUser, allOption, disabled = false, placeholder = "Select a parent Organization", className, multiple, setOrgWithName, orgWithName, fiberCompaniesIds }) => {
    const [UsersSearch, setUserSearch] = useState();
    const [options, setOptions] = useState([]);
    console.log(orgWithName)
    const render = useRef(false);
    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
            render.current = true;
        };
        getOptions();
    }, [fiberCompaniesIds]);

    const GetOrg = async () => {
        let searchReq = new SearchOrgReq();
        if (UsersSearch) searchReq.name = UsersSearch;
        if (fiberCompaniesIds) searchReq.fiberCompaniesIds = fiberCompaniesIds;

        let res = await OrgApi.SearchOrg(searchReq);
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name, id: item.id });
            });
            if (!multiple)
                optionsArr.unshift({ value: -1, label: "None", id: -1 }); // Assign -1 to "None" option
            if (allOption)
                optionsArr.unshift({ value: 0, label: "All", id: 0 }); // Assign -2 to "All" option
            return optionsArr;
        } else {
            return [];
        }
    };




    return (
        render.current ?
            <div className={'selector'}>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                    {
                        orgWithName ?
                            <AsyncSelect
                                defaultOptions={options}
                                cacheOptions
                                value={orgWithName}
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={(e) => {
                                    if (multiple) {
                                        setUser(e.map((item) => item.id));
                                        if (setOrgWithName) {
                                            setOrgWithName(e);
                                        }
                                    } else {
                                        setUser(e.id);
                                        if (setOrgWithName) {
                                            setOrgWithName(e);
                                        }
                                    }
                                }}
                                placeholder={placeholder}
                                isDisabled={disabled}
                                defaultValue={orgWithName}
                                isMulti={multiple}
                                classNamePrefix={'async_select'}
                            />
                            :
                            <AsyncSelect
                                defaultOptions={options}
                                cacheOptions
                                loadOptions={GetOrg}
                                onInputChange={setUserSearch}
                                onChange={(e) => {
                                    if (multiple) {
                                        setUser(e.map((item) => item.id));
                                        if (setOrgWithName) {
                                            setOrgWithName(e);
                                        }
                                    } else {
                                        setUser(e.id);
                                        if (setOrgWithName) {
                                            setOrgWithName(e);
                                        }
                                    }
                                }}
                                placeholder={placeholder}
                                isDisabled={disabled}
                                defaultValue={orgWithName}
                                isMulti={multiple}
                                classNamePrefix={'async_select'}
                            />
                    }

                </SoftTypography>
            </div>
            : null
    );
};

export default Index;
