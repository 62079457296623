
import { HiOutlineCreditCard } from "react-icons/hi";
import { LuFolderCog } from "react-icons/lu";
import { MdDashboard, MdLeaderboard, MdOutlineAnalytics, MdOutlineBugReport, MdOutlineLeaderboard, MdOutlineManageAccounts, MdOutlinePayments } from "react-icons/md";
import { PiMapTrifoldLight } from "react-icons/pi";
import { RiContactsBook2Line, RiContactsBookLine } from "react-icons/ri";
import { TbMessageBolt, TbReport, TbReportAnalytics } from "react-icons/tb";
import { TiUserAddOutline } from "react-icons/ti";
import { BiSolidOffer } from "react-icons/bi";
import { VscFeedback, VscSettings } from "react-icons/vsc";
import { GoOrganization } from "react-icons/go";
import { FaCalendarDays } from "react-icons/fa6";
import { TfiAnnouncement } from "react-icons/tfi";
import { RxDashboard } from "react-icons/rx";
import { BsChatSquareQuote } from "react-icons/bs";
const TeamAdminMenuItems = [
    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color, size = 40) => <RxDashboard size={size} color={color} />
    },
    {
        groupName: "Repoting & Analytics",
        icon: (color, size = 40) => <TbReport size={size} color={color} />,

        items: [
            {
                name: "General Sales Report",
                key: "GeneralSalesReport",
                route: "/general-sales-reporting-page",
                icon: (color, size = 40) => <TbReport size={size} color={color} />
            },
        ]

    },
    {
        name: "Leader Board",
        key: "LeaderBoard",
        route: "/leader-board",
        icon: (color, size = 40) => <MdOutlineLeaderboard size={size} color={color} />,
        // disabled:true
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center",
        icon: (color, size = 40) => <HiOutlineCreditCard size={size} color={color} />,
    },
    {
        name: "Map",
        key: "free-install-map",
        route: "/map",
        icon: (color, size = 40) => <PiMapTrifoldLight size={size} color={color} />,
    },
    {
        type: "collapse",
        name: "Contact center",
        key: 'ContactCenter',
        route: '/contact-center',
        icon: (color, size = 40) => <RiContactsBook2Line size={size} color={color} />,
        noCollapse: true,
        // disabled:true
    },
    {
        groupName: "Team Management",
        icon: (color, size = 40) => <TiUserAddOutline size={size} color={color} />,
        items: [
            {
                name: "Create Team Member",
                key: "create-team-member",
                route: "/team-member",
                icon: (color, size = 40) => <TiUserAddOutline size={size} color={color} />,
            },
            {
                name: "Manage Team Members",
                key: "manage-team-members",
                route: "/manage-team-members",
                icon: (color, size = 40) => <MdOutlineManageAccounts size={size} color={color} />,
                disabled: true
            },
        ]
    },





    // {
    //     name: "Comission Plan Maker",
    //     key: "ComissionPlanMaker",
    //     route: "/comission-plan-maker",
    //     icon: (color,size=40) => <GrPlan size={size} color={color} />,
    // },
    // {
    //     name: "Fiber Feedback",
    //     key: "fiber-feedback",
    //     route: "/fiber-feedback",
    //     icon: (color,size=40) => <VscFeedback size={size} color={color} />,
    // },
    // {
    //     name: "Order History",
    //     key: "order-history",
    //     route: "/order-history",
    //     icon: (color,size=40) => <GrDocumentTime size={size} color={color} />,
    // },
    // {
    //     name: "Chat",
    //     key: 'privatechat',
    //     route: '/chats',
    //     icon: (color, size = 40) => <TbMessageBolt size={size} color={color} />,
    //     disabled: true
    // },
    // {
    //     name: "Training Modules",
    //     key: 'manageSellingCard',
    //     route: '/manage-training-modules',
    //     icon: (color, size = 40) => <LuFolderCog size={size} color={color} />,
    //     noCollapse: true,
    //     disabled: true
    // },
    {
        name: "Customer Feedback",
        key: "CustomerFeedbackAnalysis",
        route: "/customer-feedback-analysis",
        icon: (color, size = 40) => <BsChatSquareQuote size={size} color={color} />,
    },
    {
        name: "Manage Announcements",
        key: "manageAnnouncements",
        route: "/manage-announcements-board",
        icon: (color, size = 40) => <TfiAnnouncement size={size} color={color} />,
    },
    {
        name: "My Calendar",
        key: "MyCalendar",
        route: "/my-calendar",
        icon: (color, size = 40) => <FaCalendarDays size={size} color={color} />,
    },
    {
        name: "Report A Bug",
        key: "CreateBugTicket",
        route: "/create-bug-ticket",
        icon: (color, size = 40) => <MdOutlineBugReport size={size} color={color} />,
    },

    {
        name: "My Commissions",
        key: "MyCommissions",
        route: "/my-commissions",
        icon: (color, size = 40) => <MdOutlinePayments size={size} color={color} />,
        disabled: true
    },



    {
        name: "My Calender",
        key: "MyCalender",
        route: "/my-calender",
        icon: (color, size = 40) => <TbReportAnalytics size={size} color={color} />,
        disabled: true
    },
    // {
    //     name:"Manage Business Card",
    //     key:"manageBusinessCard",
    //     route:"/manage-business-card",
    //     icon: (color,size=40) => <VscSettings size={size} color={color} />,
    // },

];

export default TeamAdminMenuItems;