import { Modal } from '@mui/material'
import React from 'react'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import ServicableAreaApi from '../../../../API/ServicableAreaApi'

const CreateAreaLabel = ({ open, setOpen, setPrevious }) => {

    const [tag, setTag] = React.useState("")
    const [color, setColor] = React.useState("#000000")
    const createAreaLabel = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are creating the label",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        if (tag === null || tag === "" || color === null || color === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please fill all the fields",
                showConfirmButton: true,
                showCancelButton: false
            })
            return
        }

        const req = {
            label: tag,
            color: color
        }

        const res = await ServicableAreaApi.CreateAreaLabel(req)

        if (res.status.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Label Created Successfully",
                showConfirmButton: true,
                showCancelButton: false,
                willClose: () => {
                    setOpen(false)
                    if (setPrevious) {
                        setPrevious(true)
                    }
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                showConfirmButton: true,
                showCancelButton: false
            })
        }



    }


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div style={{ width: "min(500px,80%)" }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  bg-white p-4 rounded-lg'>
                <div className='flex flex-col p-4 pt-0 pb-0 gap-4 justify-center items-center '>
                    <IoCloseCircle onClick={() => {
                        setOpen(false)
                        if (setPrevious) {
                            setPrevious(true)
                        }
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    <img alt="Logo" src={'/images/nav/nav-logo.png'} className='object-fit' />
                    <h1 className='text-2xl font-bold'>Create Label</h1>
                    <div className='w-full flex flex-col'>
                        <label className='w-full rounded-lg outline-none'>Label</label>
                        <input type="text" className='w-full p-2 border border-gray-300 rounded-lg outline-none' value={tag} onChange={(e) => setTag(e.target.value)} />
                    </div>
                    <div className='w-full flex flex-col '>
                        <label className='w-full rounded-lg outline-none'>Color</label>
                        <div className='grid grid-cols-2 gap-4 w-full p-2 border border-gray-300 rounded-lg'>
                            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                            <input type="text" className=' outline-none' value={color} onChange={(e) => setColor(e.target.value)} />
                        </div>
                    </div>

                    <button className='bg-blue-500 text-white p-2 rounded-lg w-full' onClick={createAreaLabel} >
                        Create Label
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateAreaLabel