import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import Select from 'react-select';
import { MapContext } from '..';
import MapHelper from '../MapHelper';
import Swal from 'sweetalert2';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';
import { Collection, Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import Modify from 'ol/interaction/Modify';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import CreateCandidateLeadReq from '../../../../Requests/FiberHouse/CreateCandidateLeadReq';
import BatchCreateCandidateLeadReq from '../../../../Requests/FiberHouse/BatchCreateCandidateLeadReq';
import { parseFile, processParsedData } from '../excelParser';

const CreateCandidateLead = () => {
    const [file, setFile] = useState(null);
    const [leadType, setLeadType] = useState({ value: 'Company', label: 'Company' });
    const [selectedMapIcon, setSelectedMapIcon] = useState(null);
    const [mapStringObjects, setMapStringObjects] = useState([]);
    const [mapStringObjectsGood, setMapStringObjectsGood] = useState([]);
    const [mapStringObjectsBad, setMapStringObjectsBad] = useState([]);
    const [mapStringObjectsWithServicableArea, setMapStringObjectsWithServicableArea] = useState([]);
    const [housesWithSameCoordinates, setHousesWithSameCoordinates] = useState([]);
    const [newLeadsLayer, setNewLeadsLayer] = useState(null);
    const { mapIcons, mapObject, setIndex } = useContext(MapContext);
    const helper = new MapHelper(mapObject);

    const leadTypeOptions = [
        { value: 'Recruit', label: 'Recruit' },
        { value: 'Company', label: 'Company' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#C4C4C4',
            borderRadius: '0.5rem',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#2761D0',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#2761D0' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#E6F0FF',
                color: '#2761D0',
            },
        }),
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setMapStringObjects([]);
        setMapStringObjectsGood([]);
        setMapStringObjectsBad([]);
        setHousesWithSameCoordinates([]);

        if (selectedFile) {
            try {
                //Show Swal Loader
                Swal.fire({
                    icon: 'info',
                    title: 'Parsing File',
                    text: 'Please wait...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                const parsedData = await parseFile(selectedFile, leadType.value);
                const processedData = await Promise.all(processParsedData(parsedData, helper));
                setMapStringObjects(processedData);

                //Hide Swal Loader
                Swal.close();
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'File Parsing Error',
                    text: error.message,
                    showConfirmButton: true,
                });
            }
        }
    };

    const handleLeadTypeChange = (selectedOption) => {
        setLeadType(selectedOption);
        setSelectedMapIcon(null);
    };

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            getHousesWithSameCoordinates();
        }
    }, [mapStringObjectsBad]);

    const getHousesWithSameCoordinates = () => {
        const validCoordinates = mapStringObjectsGood
            .filter((ms) => Array.isArray(ms.coordinates) && ms.coordinates.length === 2 && ms.coordinates[0] !== null && ms.coordinates[1] !== null)
            .map((ms) => ms.coordinates);

        console.log('Valid Coordinates:', validCoordinates);

        let duplicates = [];
        let uniqueCoordinates = new Set();

        validCoordinates.forEach((coord, index) => {
            const coordKey = `${coord[0]},${coord[1]}`;

            if (uniqueCoordinates.has(coordKey)) {
                setMapStringObjectsGood((prevList) =>
                    prevList.map((ms) => {
                        if (ms.coordinates && ms.coordinates[0] === coord[0] && ms.coordinates[1] === coord[1]) {
                            return { ...ms, duplicate: true };
                        }
                        return ms;
                    })
                );
                duplicates.push(coord);
            } else {
                uniqueCoordinates.add(coordKey);
            }
        });

        setHousesWithSameCoordinates(duplicates);
        console.log('Duplicates:', duplicates);
    };

    const filterMapStringObjects = () => {
        const good = [];
        const bad = [];

        mapStringObjects.forEach((entry) => {
            const hasValidCoordinates = Array.isArray(entry.coordinates) && 
                                        entry.coordinates.length === 2 && 
                                        entry.coordinates[0] !== null && 
                                        entry.coordinates[1] !== null;

            if (entry.address && entry.city && entry.state && hasValidCoordinates) {
                good.push(entry);
            } else {
                bad.push(entry);
            }
        });

        setMapStringObjectsGood(good);
        setMapStringObjectsBad(bad);
    };

    useEffect(() => {
        if (mapStringObjects.length > 0) {
            console.log(mapStringObjects);
            filterMapStringObjects();
        }
    }, [mapStringObjects]);

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            console.log("MAP STRING OBJECTS GOOD", mapStringObjectsGood);
            showFiberHousesOnMap();
            UpdateMapStringObjectsWithServicableArea();
        }
    }, [mapStringObjectsGood]);

    const UpdateMapStringObjectsWithServicableArea = () => {
        let updatedList = [];
        mapStringObjectsGood.forEach(fa => {
            let newListObject = fa;
            updatedList.push(newListObject);
        });

        console.log("UPDATED LIST", updatedList);
        setMapStringObjectsWithServicableArea(updatedList);
    };

    const CreateLead = async () => {
        if (!selectedMapIcon) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a map icon.',
                showConfirmButton: true,
            });
            return;
        }

        if (!leadType) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a lead type.',
                showConfirmButton: true,
            });
            return;
        }

        Swal.fire({
            icon: 'info',
            title: 'Creating Leads',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const list = mapStringObjectsWithServicableArea.map((mapStringObject) => {
            const req = new CreateCandidateLeadReq();
            req.name = mapStringObject.fullName;
            req.phone = mapStringObject.phone;
            req.companyEmail = mapStringObject.companyEmail;
            req.personalEmail = mapStringObject.personalEmail;
            req.state = mapStringObject.state;
            req.city = mapStringObject.city;
            req.zipCode = String(mapStringObject.zip);
            req.address = mapStringObject.address;
            req.country = mapStringObject.country;
            req.mapString = mapStringObject.mapString;
            req.mapIconId = selectedMapIcon.id;
            req.industry = mapStringObject.industry;
            req.title = mapStringObject.title;
            req.company = mapStringObject.company;
            req.doorToDoor = mapStringObject.doorToDoor;
            req.linkedIn = mapStringObject.linkedIn;
            req.status = "New";
            req.coordinate = {
                latitude: mapStringObject.coordinates[1],
                longitude: mapStringObject.coordinates[0]
            };
            return req;
        });

        let req = new BatchCreateCandidateLeadReq();
        req.candidateType = leadType.value;
        req.candidates = list;
        console.log(req);
         let res = await FiberHouseApi.BatchCreateCandidateLeads(req);
        if(res?.status?.success){
            Swal.fire({
                title: "Leads Created",
                text: res?.status?.message,
                icon: "success",
                allowEnterKey: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
    };

    const showFiberHousesOnMap = () => {
        const oldZoom = mapObject.getView().getZoom();
        if (newLeadsLayer) {
            mapObject.removeLayer(newLeadsLayer);
        }
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const markerStyle = new Style({
            image: new Icon({
                src: 'https://openlayers.org/en/latest/examples/data/icon.png',
                scale: 0.9,
                zIndex: 10000000,
            }),
        });

        mapObject.addLayer(vectorLayer);
        mapObject.getView().setZoom(oldZoom);

        const features = new Collection();
        const validEntries = mapStringObjectsGood.filter(
            (ms) => Array.isArray(ms.coordinates) && ms.coordinates.length === 2 && ms.coordinates[0] !== null && ms.coordinates[1] !== null
        );
        validEntries.map(ms => {
            const point = new Point(fromLonLat(ms.coordinates));
            const feature = new Feature(point);
            feature.set('mapString', ms.mapString);
            feature.set('index', ms.index);
            feature.set('coordinates', ms.coordinates);
            feature.set('bulk_mark', 1);
            feature.setStyle(markerStyle);
            vectorSource.addFeature(feature);
            features.push(feature);
        });

        const modify = new Modify({ features: features });
        mapObject.addInteraction(modify);
        modify.on('modifyend', function (e) {
            const features = e.features.getArray();
            console.log("MODIFY END", features);
            features.forEach((feature) => {
                const coordinates = feature.getGeometry().getCoordinates();
                const index = feature.get('index');
                setMapStringObjectsGood((prevList) => {
                    return prevList.map((ms) => {
                        if (ms.index === index) {
                            return { ...ms, coordinates: toLonLat(coordinates) };
                        }
                        return ms;
                    });
                });
            });
        });
        vectorLayer.setZIndex(1000);

        if (!newLeadsLayer) {
            mapObject.getView().fit(vectorSource.getExtent());
        }
        setNewLeadsLayer(vectorLayer);

        mapObject.on('click', function (evt) {
            const feature = mapObject.forEachFeatureAtPixel(evt.pixel,
                function (feature) {
                    return feature;
                });
            if (feature?.get('bulk_mark')) {
                Swal.fire({
                    title: 'Popup Title',
                    html: `<div><p>${feature.get('mapString')}</p></div>`,
                    footer: '<button id="customButton">Remove House</button>',
                    confirmButtonText: 'OK',
                });

                document.getElementById('customButton').addEventListener('click', () => {
                    removeEntryFromMapStringObjects(feature.get('index'));
                });
            }
        });
    };

    const removeEntryFromMapStringObjects = (index) => {
        console.log(index, "INDEX");
        console.log(mapStringObjects);
        setMapStringObjectsGood((prevList) => {
            return prevList.filter((ms) => ms.index !== index);
        });
        setMapStringObjectsWithServicableArea((prevList) => {
            return prevList.filter((ms) => ms.index !== index);
        });
    };

    const downloadBadAddresses = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        mapStringObjectsBad.forEach((ms) => {
            csvContent += ms.mapString + "\n";
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "badAddresses.csv");
        document.body.appendChild(link);
        link.click();
    };

    const snapToLocation = (fiberHouse) => {
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude];
        mapObject.getView().setCenter(fromLonLat(lonlat));
        mapObject.getView().setZoom(21);
    };

    return (
        <div className='FiberHousefileDiv'>
            <div className='TitleDiv'>
                <Typography variant="h5">Bulk Create Candidate Leads</Typography>
                <button onClick={() => setIndex(21)} className='uppercase text-[#2761D0]'>
                    Create Manually
                </button>
            </div>
            <div className='FormDiv'>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className='hidden'
                    id="fileUpload"
                />
                <label
                    htmlFor="fileUpload"
                    className="cursor-pointer overflow-hidden rounded-lg border-2 border-[#C4C4C4] bg-white text-center text-black text-base focus:outline-[#181E4B] flex justify-between items-center px-8 py-2"
                >
                    <div className='flex items-center'>
                        <Typography className="mr-2">{file ? file.name : 'Upload a File'}</Typography>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                        <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                    </svg>
                </label>

                <Box sx={{ mt: 2 }}>
                    <Select
                        options={leadTypeOptions}
                        value={leadType}
                        onChange={handleLeadTypeChange}
                        placeholder="Select Lead Type"
                        styles={customStyles}
                    />
                </Box>

                <div className='MapIconsDiv'>
                    {mapIcons
                        .filter((icon) =>
                            leadType?.value === 'Recruit'
                                ? icon.iconType === 'Recruit'
                                : icon.iconType === 'Company'
                        )
                        .map((assignedMapIcons, index) => (
                            <div
                                className='MapIconDiv'
                                key={index}
                                style={{
                                    border: selectedMapIcon?.id === assignedMapIcons.id ? '2px solid #2761D0' : 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setSelectedMapIcon(assignedMapIcons)}
                            >
                                <div className='MapIconImageDiv'>
                                    <img src={`data:image/png;base64,${assignedMapIcons.image}`} alt="Map Icon" />
                                </div>
                                <p className='MapIconName'>{assignedMapIcons.abbreviation}</p>
                            </div>
                        ))}
                </div>

                <button
                    onClick={CreateLead}
                    className='bg-[#2761D0] text-white px-4 py-2 rounded hover:bg-[#1E4C9A] transition-colors'
                >
                    Process File
                </button>

                {mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 &&
                    <div className='MapStringObjectDiv'>
                        <div className='MapStringObjectMapStringDiv'>
                            <p className='MapStringObjectMapString'>
                                {mapStringObjectsWithServicableArea.length} leads Detected from {mapStringObjects.length} Addresses
                                and {mapStringObjects.length} Lines of Data
                            </p>
                            <p className='MapStringObjectMapString'>
                                {mapStringObjectsGood.length} Good Addresses
                            </p>
                            <p className='MapStringObjectMapString'>
                                {housesWithSameCoordinates.length} Duplicate Coordinates
                            </p>
                            <p className='MapStringObjectMapString' style={{ color: mapStringObjectsBad.length > 0 ? "red" : "green" }}>
                                {mapStringObjectsBad.length} Bad Addresses
                                <button onClick={downloadBadAddresses} className='DownloadButton'>Download</button>
                            </p>
                        </div>
                        <div className='MapStringObjectServicableAreaDiv'>
                            <p className='MapStringObjectServicableArea'>
                                {mapStringObjectsWithServicableArea.filter(fa => fa.servicableArea !== null).length} Inside Serviceable Areas
                            </p>
                        </div>
                    </div>
                }
                {mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 ?
                    mapStringObjectsWithServicableArea.map((mapStringObject, index) => (
                        <div key={index} className='MapStringObjectDiv'>
                            <div className='MapStringObjectMapStringDiv'>
                                <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                                <p className='MapStringObjectServicableArea' style={{ color: mapStringObject.servicableArea ? mapStringObject.servicableArea.color : "black" }} >
                                    {mapStringObject.servicableArea && mapStringObject.servicableArea.name}
                                </p>
                            </div>
                            <div className='MapStringObjectServicableAreaDiv'>
                                <button onClick={() => {
                                    snapToLocation({
                                        coordinates: {
                                            latitude: mapStringObject.coordinates[1],
                                            longitude: mapStringObject.coordinates[0]
                                        }
                                    })
                                }} style={{ width: "100%" }} >Snap To Location</button>
                                <button style={{ width: "100%" }} onClick={() => removeEntryFromMapStringObjects(index)} className='remove'>Remove</button>
                            </div>
                        </div>
                    ))
                    :
                    <div className='MapStringObjectDiv'>
                        <div className='MapStringObjectMapStringDiv'>
                            <p className='MapStringObjectMapString'>No Map Strings</p>
                        </div>
                        <div className='MapStringObjectServicableAreaDiv'>
                            <p className='MapStringObjectServicableArea'>No Servicable Area</p>
                        </div>
                    </div>
                }

                {mapStringObjectsBad != null && mapStringObjectsBad.length > 0 &&
                    mapStringObjectsBad.map((mapStringObject, index) => (
                        <div key={index} className='MapStringObjectDiv'>
                            <div className='MapStringObjectMapStringDiv'>
                                <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                            </div>
                            <div className='MapStringObjectServicableAreaDiv'>
                                <p className='MapStringObjectServicableArea' style={{ color: "red" }} >
                                    Bad Address
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default CreateCandidateLead;

