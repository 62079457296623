import React, { useState, useRef, useEffect } from 'react';

export const MultiSelector = ({ options, value, onChange, multiSelect, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const selectedValues = Array.isArray(value) ? value : [value];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const toggleOption = (option) => {
    if (multiSelect) {
      const newValue = selectedValues.includes(option)
        ? selectedValues.filter(v => v !== option)
        : [...selectedValues, option];
      onChange(newValue);
    } else {
      onChange(option);
      setIsOpen(false);
      onClose();
    }
  };

  useEffect(
    () => {
      setIsOpen(false)
    },[value])

  return (
    <div className="relative w-full" ref={ref}>
      <div
        className="bg-white border border-gray-300 rounded-md p-2 cursor-pointer  flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate flex flex-wrap gap-2">
          {selectedValues.length > 0 ? 
          selectedValues.map((option) => (
            <span key={option} className="bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs mr-1">
              {option}
            </span>
          ))
          : 'Select options'}
        </span>
        <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      {isOpen && (
        <div style={{
          zIndex: 100
        }} className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              key={option}
              className={`p-2 hover:bg-gray-100 cursor-pointer ${
                selectedValues.includes(option) ? 'bg-blue-100' : ''
              }`}
              onClick={() => toggleOption(option)}
            >
              <label className="flex items-center space-x-2">
                <input
                  type={multiSelect ? 'checkbox' : 'radio'}
                  checked={selectedValues.includes(option)}
                  onChange={() => {}}
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span>{option}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

