import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTabs } from './TabContext'
import VersatileLoader from '../../../components/VersatileLoader';
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import MasterSheetApi from '../../../API/MasterSheetApi';
import AccountStatusChangeForm from './AccountStatusChangeForm';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { MultiColorSelector } from '../../../components/MultiSelector';
import { DataTable } from './DataTable/DataTable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Pagination, PaginationItem } from '@mui/material';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { ConnectionContext, UserContext } from '../../../App';
const FileContent = () => {
    const { getFileData, getListId, activeTabId, setFileData,files } = useTabs();
    const user = useContext(UserContext)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const intervalId = useRef(null)
    const [changeAccountStatusModal, setChangeAccountStatusModal] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const connectionService = useContext(ConnectionContext)
    const fetchData = async (first, page) => {
        setLoading(true)
        const id = await getListId(activeTabId)
        const data = await getFileData(id, page, first)
        setData(data.list);
        setTotalCustomers(data.totalItems)
        setTotalPages(data.totalPages)
        setCurrentPage(data.pageNumber)
        setLoading(false)
    }

    useEffect(
        () => {
            fetchData(true)
        }, [activeTabId]
    )

    useEffect(() => {
        if (data) {
            fetchData(false, currentPage)
        }
    }, [currentPage])

    // const sortedData = React.useMemo(() => {
    //     if (!sortColumn) return data;

    //     return [...data].sort((a, b) => {
    //         const aValue = a[sortColumn];
    //         const bValue = b[sortColumn];

    //         if (aValue === null && bValue === null) return 0;
    //         if (aValue === null) return sortDirection === 'asc' ? -1 : 1;
    //         if (bValue === null) return sortDirection === 'asc' ? 1 : -1;

    //         if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    //         if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    //         return 0;
    //     });
    // }, [data, sortColumn, sortDirection]);

    const actionChangeHandler = async (value, id, type) => {
        const listId = await getListId(activeTabId)
        const req = {
            leadId: id,
            dynamicListId: listId,
            userId : user?.id
        }

        if (type === 'action') {
            req.action = value.join('\n')
        }

        if (type === 'action2') {
            req.action2 = value.join('\n')
        }

        if (type === 'results') {
            req.results = value.join('\n')
        }

        Swal.fire({
            title: "Saving",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showCancelButton: false,
            showDenyButton: false,
            toast: true,
            position: 'bottom-right',
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const res = await MasterSheetApi.EditLeadActions(req)

        if (res.status.success) {
            Swal.fire({
                title: "Success",
                icon: "success",
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                timer: 2000,
                position: 'bottom-right'
            })

            setData(prev => {
                const newData = prev.map(item => {
                    if (item.leadId === id) {
                        return { 
                            ...item, 
                            action: res.data.action? res.data.action?.split('\n') : [],
                            action2: res.data.action2? res.data.action2?.split('\n') : [],
                            results: res.data.results? res.data.results?.split('\n') : [] 
                        }
                    }
                    return item
                })

                return newData
            })

            setFileData(prev => {
                const newData = prev[listId].list.map(item => {
                    if (item.leadId === id) {
                        return { 
                            ...item, 
                            action: res.data.action? res.data.action?.split('\n') : [], 
                            action2: res.data.action2? res.data.action2?.split('\n') : [],
                            results: res.data.results? res.data.results?.split('\n') : []
                        }
                    }
                    return item
                })
                console.log(newData)
                return { ...prev, [listId]: {
                    ...prev[listId],
                    list: newData
                } }
            })
        }
        else {
            Swal.fire({
                title: "Failed",
                text: res.status.message,
                icon: "error",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                timer: 2000
            })
        }

    }

    const onChange = async (value, id) => {


        const listId = await getListId(activeTabId)


        Swal.fire({
            title: "Saving",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showCancelButton: false,
            showDenyButton: false,
            toast: true,
            position: 'bottom-right',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const req = {
            customerId: id,
            accountNotes: value
        }
        console.log(req)
        CustomerApi.EditCustomerInfo(req).then(res => {


            Swal.fire({
                title: res.status.success ? "Success" : "Failed",
                text: res.status.message,
                icon: res.status.success ? "success" : "error",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                timer: 2000
            })
            if (res.status.success) {

                setData(prev => {
                    const newData = prev.map(item => {
                        if (item.customerId === id) {
                            return { ...item, accountNotes: res.data.accountNotes }
                        }
                        return item
                    })

                    return newData
                })

                setFileData(prev => {
                    const newData = prev[listId].list.map(item => {
                        if (item.customerId === id) {
                            return { ...item, accountNotes: res.data.accountNotes }
                        }
                        return item
                    })
                    console.log(newData)
                    return { ...prev, [listId]: {
                        ...prev[listId],
                        list: newData
                    } }
                })
            }
        })
            .catch(err => {
                Swal.fire({
                    title: "Failed",
                    text: err,
                    icon: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                    showCloseButton: true,
                    showCancelButton: false,
                    showDenyButton: false,
                    toast: true,
                    position: 'bottom-right',
                    timer: 2000
                })
            })




    }

    const saveDeal = async (id) => {
        const option = await Swal.fire({
            title: "Save Deal",
            text: "Are you sure you want to save this deal?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showDenyButton: false,
        })

        if(!option.isConfirmed){
            return
        }

        const req = {
            salesRepId : user.id,
            customerId : id
        }
        const listId = await getListId(activeTabId)
        const res = await CustomerApi.SaveDeal(req)

        if(res.status.success){
            Swal.fire({
                title: "Success",
                text: res.status.message,
                icon: "success",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                timer: 2000
            })

            setData(prev => {
                const newData = prev.map(item => {
                    if (item.customerId === id) {
                        return { ...item, dealSaved: true }
                    }
                    return item
                })

                return newData
            })

            setFileData(prev => {
                const newData = prev[listId].list.map(item => {
                    if (item.customerId === id) {
                        return { ...item, dealSaved: true }
                    }
                    return item
                })
                console.log(newData)
                return { ...prev, [listId]: {
                    ...prev[listId],
                    list: newData
                } }
            })
        }
        else{

            Swal.fire({
                title: "Failed",
                text: res.status.message,
                icon: "error",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                timer: 2000
            })
        }

    }


    const headers = [
        {
            label: "#",
            key: "index",
            type: "number"
        },

        {
            label: "Fiber Company",
            key: "fiberCompanyName",
            type: "text",
        },
        {
            label: "Fiber Plan",
            key: "fiberPlan",
            type: "text",
        },
        {
            label: "Sold Date",
            key: "timeStamp",
            type: "text",
        },
        {
            label: "Original Install Date",
            key: "originalInstallDate",
            type: "text",
        },
        {
            label: "Updated Intall Date",
            key: "updatedInstallDate",
            type: "text",
        },
        {
            label: "Sales Org Name",
            key: "salesOrgName",
            type: "text",
        },
        {
            label: "Sales Rep",
            key: "salesRepName",
            type: "text",
        },
        {
            label: "Lead Type",
            key: "leadType",
            type: "text",
        },
        {
            label: "Map Icon",
            key: "mapIconName",
            type: "text",
        },
        {
            label: "Rep Notes",
            key: "notes",
            type: "text",
        },
        {
            label: "Account Status",
            key: "accountStatus",
            type: "text"
        },

        {
            label: "Contact Name",
            key: "name",
            type: "text",
        },
        {
            label: "Save Deal",
            key: "dealSaved",
            type: "button",
            onClick: saveDeal,
            editable: true
        },
        // {
        //     label: "Issue Type",
        //     key: "issueType",
        //     type: "text",
        // },
        {
            label: "Address",
            key: "address",
            type: "text",
        },

        {
            label: "Recorded Action 1",
            key: "action",
            type: "selector",
            options: ["Called", "Send Text", "2nd Call", "2nd Text", "3rd Call", "3rd Text", "Email Sent", "4th Call", "4th Text"],
            multiSelect: true,
            editable: true,
            onChange: actionChangeHandler,
            showHistory: true
        },
        {
            label: "Recorded Action 2",
            key: "action2",
            type: "selector",
            options: ["Answered", "Left Voicemail", "Voicemail Not Set Up", "Voicemail Full", "No Answer", "Hung Up", "Call Not Going Through"],
            multiSelect: true,
            editable: true,
            onChange: actionChangeHandler,
            showHistory: true
        },
        {
            label: "Recorded Results",
            key: "results",
            type: "selector",
            options: ["Canceled", "Pending Reshcedule", "Rescheduled", "Scheduled", "No Response", "Not Interested", "Invalid", "Not Serviceable", "Install Complete", "Replied Stop"],
            multiSelect: true,
            editable: true,
            onChange: actionChangeHandler,
            showHistory: true
        },
        {
            label: "Notes",
            key: "accountNotes",
            type: "text",
            editable: true,
            onChange: onChange,
        },
        {
            label: "Account Issue",
            key: "issueType",
            type: "text",
        },
        {
            label: "Issue Description",
            key: "issueDescription",
            type: "text",
        },
        {
            label: "Issue Submission Date",
            key: "issueTimestamp",
            type: "text",
        },
        {
            label: "Issue Resolved",
            key: "issueResolved",
            type: "text",
        },
        {
            label: "Deal Saved Date",
            key: "dealSavedDate",
            type: "text",
        },
        {
            label: "Deal Saved By",
            key: "dealSavedBy",
            type: "text",
        }
    ]





    useEffect(() => {
        console.log(data)
    }, [data])



    


  useEffect(() =>{
    if(connectionService){
        connectionService.SheetUpdated = (data) => {
            setFileData(
              (prev) => {

                if(!prev[data.dynamicListId]){
                  return prev
                }
                return {
                  ...prev,
                  [data.dynamicListId] : {
                    ...prev[data.dynamicListId],
                    list : prev[data.dynamicListId].list.map(x => {
                      if(x.leadId == data.leadId){
                        return {
                          ...x,
                          action : data.action?.split('\n'),
                          action2 : data.action2?.split('\n'),
                          results : data.results?.split('\n')
                        }
                      }
                      return x
                    })
                  }
                }
              }
            )

            setData(
                (prev) => {
                    return prev.map(x => {
                    if(x.leadId == data.leadId){
                        return {
                        ...x,
                        action : data.action?.split('\n'),
                        action2 : data.action2?.split('\n'),
                        results : data.results?.split('\n')
                        }
                    }
                    return x
                    })
                }
            )
        }


        return () => {
            connectionService.SheetUpdated = null
        }
    }
  },[])


    const getAccountStatusColor = (status) => {
        switch (status) {
            case "Sold":
                return "text-green-600"
            case "Canceled":
                return "text-red-600"
            case "Scheduled":
                return "text-blue-600"
            case "Rescheduled":
                return "text-yellow-600"
            default:
                return "text-gray-600"
        }
    }

    const handleDataChange = (data) => {
        console.log(data)
    }

    return (
        <div style={{
            // fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
        }} className="flex-grow  overflow-y-auto ">
            <AccountStatusChangeForm customer={selectedCustomer} open={changeAccountStatusModal} setOpen={setChangeAccountStatusModal} />
            {
                !loading ?
                    <>
                        {/* show the description of the file */}
                        
                        <DndProvider backend={HTML5Backend}>
                            <DataTable totalPages={totalPages} setCurrentPage={setCurrentPage} headers={headers} data={data} onDataChange={handleDataChange} />
                        </DndProvider>
                        <div className='w-full mt-2 flex justify-center align-center'>
                            <Pagination color='light' renderItem={(item) => (
                                <PaginationItem components={{

                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                    first: (props) => <button {...props}>First</button>,
                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                }}  {...item} sx={{ color: "white" }} />
                            )} count={totalPages} page={currentPage} onChange={(e, pageNo) => {
                                setCurrentPage(pageNo)
                            }} />
                        </div>
                    </>
                    :
                    <VersatileLoader size='large' color='#2761CF' />
            }
        </div>
    )
}

export default FileContent