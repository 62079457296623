/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import { MapContext } from '..'
import FiberHouseApi from '../../../../API/FiberHouseApi'
// import MapIconApi from '../../../../API/MapIconApi'
import EditFiberHouseReq from '../../../../Requests/FiberHouse/EditFiberHouseReq'
import { useSwipeable } from 'react-swipeable'
import { UserContext } from '../../../../App'
import { Fill, Icon, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import EditCandidateReq from '../../../../Requests/FiberHouse/EditCandidateReq'
const MapIconDisplay = ({ selectedFiberHouse, setMapIcon, removeLightningBolts, isCustomer, isCandidate = false, candidateType = null }) => {

    // const [mapIcons,setMapIcons] = React.useState([])
    const user = React.useContext(UserContext)
    const { fiberHouses, shouldReRenderMap, setFiberHouses, mapIcons, selectedFiberHouseFeature, mapObject, currentLocationCoordinates, selectedCandidateFeature, candidates, selectedCandidateLead, setCandidates } = React.useContext(MapContext)
    const [selectedMapIcon, setSelectedMapIcon] = React.useState(null)
    const mapIconsRef = React.useRef(null)

    const lightningBolts = [
        "Buy1",
        "Inst1",
        "Inst2",
        "Inst3",
        "CNL",
        "PC",
        "Inst4",
        "IC",
        "OSL",
        "MA"
    ]

    const refPassthrough = (el) => {
        handlers.ref(el);
        mapIconsRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })


    const onClick = (assignedMapIcons) => {
        console.log(isCandidate)
        setSelectedMapIcon(assignedMapIcons);
        if (!isCandidate) {
            if (isCustomer) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "You cannot change the map icon of a customer",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    toast: true,
                    timer: 1500,
                    position: "bottom-end",
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
                return
            }

            setSelectedMapIcon(assignedMapIcons);
            console.log(selectedFiberHouseFeature)
            if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === selectedFiberHouse.id) {
                console.log("Fiber House Feature Found")
                const newStyle = new Style({
                    image: new Icon({
                        scale: 1.5, // Adjust this value to change the scale of the icon
                        src: "data:image/png;;base64," + assignedMapIcons?.image,
                    }),
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 15, // This is now in pixels
                        stroke: new Stroke({
                            color: "black", // Border color
                            width: 4, // Border width
                        }),
                        fill: new Fill({
                            color: "rgba(0, 0, 0, 0)", // Transparent fill
                        }),
                    }),
                });
                selectedFiberHouseFeature.setStyle([
                    newStyle, // This is the icon style
                    circleStyle, // This is the circle style
                ]);
                selectedFiberHouseFeature.values_.mapIconId = assignedMapIcons.id
                console.log(assignedMapIcons.id)
                console.log(selectedFiberHouseFeature)
                setMapIcon(assignedMapIcons.id)
                mapObject.getView().setZoom(
                    mapObject.getView().getZoom() + 0.000001
                )






            }
            shouldReRenderMap.current = false;
            const index = binarySearchFiberHouses(fiberHouses, selectedFiberHouse.id);
            console.log(index)
            const fh = fiberHouses
            if (index !== -1) {
                fh[index].mapIconId = assignedMapIcons.id;
                setFiberHouses([...fh]);
            }
            else {
                const fh = fiberHouses.map((fiberHouse) => {
                    if (fiberHouse.id === selectedFiberHouse.id) {
                        return {
                            ...fiberHouse,
                            mapIconId: assignedMapIcons.id
                        }
                    }
                    return fiberHouse;
                });
                setFiberHouses([...fh]);
            }
        } else {
            setSelectedMapIcon(assignedMapIcons);
            let featureData = selectedCandidateFeature.get('data')
            console.log(selectedCandidateLead)
            if (selectedCandidateFeature && featureData.id === selectedCandidateLead.id) {
                console.log("Candidate Feature Found")
                const newStyle = new Style({
                    image: new Icon({
                        scale: 1.5, // Adjust this value to change the scale of the icon
                        src: "data:image/png;;base64," + assignedMapIcons?.image,
                    }),
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 15, // This is now in pixels
                        stroke: new Stroke({
                            color: "black", // Border color
                            width: 4, // Border width
                        }),
                        fill: new Fill({
                            color: "rgba(0, 0, 0, 0)", // Transparent fill
                        }),
                    }),
                });
                selectedCandidateFeature.setStyle([
                    newStyle, // This is the icon style
                    circleStyle, // This is the circle style
                ]);
                selectedCandidateFeature.values_.mapIconId = assignedMapIcons.id
                console.log(assignedMapIcons.id)
                console.log(selectedCandidateFeature)
                setMapIcon(assignedMapIcons.id)
                mapObject.getView().setZoom(
                    mapObject.getView().getZoom() + 0.000001
                )
            }

            shouldReRenderMap.current = false;
            const index = binarySearchCandidates(candidates, selectedCandidateLead.id);
            console.log(index)
            const fh = candidates
            if (index !== -1) {
                fh[index].mapIconId = assignedMapIcons.id;
                setCandidates([...fh]);
            }
            else {
                const fh = candidates.map((candidate) => {
                    if (candidate.id === selectedCandidateLead.id) {
                        return {
                            ...candidate,
                            mapIconId: assignedMapIcons.id
                        }
                    }
                    return candidate;
                });
                setCandidates([...fh]);
            }
        }



    };

    function binarySearchFiberHouses(fiberHouses, targetId) {
        let left = 0;
        let right = fiberHouses.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midId = fiberHouses[mid].id;

            if (midId < targetId) {
                left = mid + 1;
            } else if (midId > targetId) {
                right = mid - 1;
            } else {
                return mid; // Found the target, return its index
            }
        }

        return -1; // Target not found
    }

    function binarySearchCandidates(candidates, targetId) {
        let left = 0;
        let right = candidates.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midId = candidates[mid].id;

            if (midId < targetId) {
                left = mid + 1;
            } else if (midId > targetId) {
                right = mid - 1;
            } else {
                return mid; // Found the target, return its index
            }
        }

        return -1; // Target not found
    }


    useEffect(() => {
        if (selectedMapIcon) {
            editMapIcon()
        }
    }, [selectedMapIcon])




    const editMapIcon = async () => {

        if (!isCandidate) {
            console.log("Selected Fiber House", selectedFiberHouse)
            const req = new EditFiberHouseReq()




            req.id = selectedFiberHouse.id

            req.coordinate = null
            req.mapIconId = selectedMapIcon.id
            req.salesRepId = user?.id
            req.repCoordinates = currentLocationCoordinates;
            FiberHouseApi.EditFiberHouse(req).then(
                (res) => {
                    if (res?.status?.success) {

                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res?.status?.message,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: true,
                            toast: true,
                            timer: 1500,
                            position: "bottom-end",
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        });
                    }
                }
            )
        } else {
            const req = new EditCandidateReq()




            req.id = selectedCandidateLead.id

            req.coordinate = null
            req.mapIconId = selectedMapIcon.id
            req.salesRepId = user?.id
            //req.repCoordinates = currentLocationCoordinates;
            FiberHouseApi.EditCandidateLead(req).then(
                (res) => {
                    if (res?.status?.success) {

                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res?.status?.message,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: true,
                            toast: true,
                            timer: 1500,
                            position: "bottom-end",
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        });
                    }
                }
            )
        }
    }


    return (
        <>
            {/* <h3 className="text-2xl text-center mt-4 mb-0">Select a Map Icon</h3> */}
            {/* <div className='flex gap-4 flex-wrap justify-center items-center' > */}
            <div ref={refPassthrough}
                {...handlers} className='MapIconDisplay'>
                {
                    mapIcons.filter(m => {
                
                        if(lightningBolts.includes(m.abbreviation)) {
                            
                            return false; // Exclude all lightning bolt icons
                        }

                        if (isCandidate) {
                            if (candidateType === "Recruit") {
                                return m.iconType === "Recruit"; // Show only Recruit icons
                            }
                            if (candidateType === "Company") {
                                return m.iconType === "Company"; // Show only Company icons
                            }
                            return false; // Exclude all other icons if isCandidate is true
                        } else if (candidateType === null) {
                            // Show all icons excluding Recruit and Company
                            return m.iconType !== "Recruit" && m.iconType !== "Company";
                        }

                        return true; // Default case, include all icons
                    }).map(assignedMapIcons => {
                        return (
                            <div
                                onClick={() => { onClick(assignedMapIcons) }}
                                className={`MapIconDiv`}
                                style={{
                                    border: (() => {
                                        // Check if there is a selectedMapIcon and it matches the current assignedMapIcon
                                        if (selectedMapIcon != null && selectedMapIcon?.id === assignedMapIcons.id) {
                                            return "2px solid #2563EB"; // Selected state for general map icons (including candidates)
                                        }
                            
                                        // Check if it's a candidate and the assignedMapIcons matches the selected candidate map icon ID
                                        if (isCandidate && !selectedMapIcon && selectedCandidateLead?.mapIconId === assignedMapIcons.id) {
                                            return "2px solid #2563EB"; // Selected state for candidate map icon
                                        }
                            
                                        // Check if it's not a candidate and the assignedMapIcons matches the selected fiber house map icon ID
                                        if (!isCandidate && !selectedMapIcon && selectedFiberHouse?.mapIconId === assignedMapIcons.id) {
                                            return "2px solid #2563EB"; // Selected state for fiber house map icon
                                        }
                            
                                        // Default case for unselected map icon
                                        return "2px solid #E5E7EB"; 
                                    })()
                                }}>
                                <img alt='Map Icon' className="w-16" src={"data:image/png;base64," + assignedMapIcons.image} />
                                <div>{assignedMapIcons.abbreviation}</div>
                            </div>
                        );
                    })
                }
            </div>
            {/* <div className='MapIconSaveButtonDiv' >
                {selectedMapIcon && <button onClick={() => { editMapIcon() }} className='bg-blue-500 text-white rounded-lg p-2 w-fit mx-auto mt-3 mb-3'>Save Map Icon</button>}
            </div> */}
        </>
    )
}

export default MapIconDisplay