import React, { useContext } from 'react'
// import SearchMapIconReq from '../../../../Requests/MapIcon/SearchMapIconReq'
// import MapIconApi from '../../../../API/MapIconApi'
import { CircularProgress } from '@mui/material'
import { MapContext } from '..'
import { Box, Tab, Tabs } from '@mui/material'
import n from '@editorjs/paragraph'

const Legends = () => {
    // const [loading, setLoading] = React.useState(false)4
    const [activeTab, setActiveTab] = React.useState(0)
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const map = useContext(MapContext)
    return (
        <div className='legend_container'>
            <h1>Lead Types</h1>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="create lead tabs">
                    <Tab label="Lead MapIcons" />
                    <Tab label="Company MapIcons" />
                    <Tab label="Recruit MapIcons " />

                </Tabs>
            </Box>

            <Box role="tabpanel" hidden={activeTab !== 0}>
                {
                    activeTab === 0 &&
                        map.mapIcons.length > 0 ?
                        <div className='map_icons'>
                            {map.mapIcons.map((icon, index) => {
                                if (icon.iconType == null) {
                                    return (
                                        <div className='icon' key={index}>
                                            <img alt='map_icon' style={{
                                                width: "2rem",
                                                height: "2rem"
                                            }} src={"data:image/png;base64," + icon.image} />
                                            <p>{icon.name}</p>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                        :
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"

                        }}>
                            <CircularProgress size={50} sx={{
                                color: "var(--primary-color)"
                            }} />
                        </div>

                }

            </Box>
            <Box role="tabpanel" hidden={activeTab !== 1}>
                {
                    activeTab === 1 &&
                        map.mapIcons.length > 0 ?
                        <div className='map_icons'>
                            {map.mapIcons.map((icon, index) => {
                                if (icon.iconType === "Company") {
                                    return (
                                        <div className='icon' key={index}>
                                            <img alt='map_icon' style={{
                                                width: "2rem",
                                                height: "2rem"
                                            }} src={"data:image/png;base64," + icon.image} />
                                            <p>{icon.name}</p>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                        :
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"

                        }}>
                            <CircularProgress size={50} sx={{
                                color: "var(--primary-color)"
                            }} />
                        </div>
                }
            </Box>

            <Box role="tabpanel" hidden={activeTab !== 2}>
                {
                    activeTab === 2 &&
                        map.mapIcons.length > 0 ?
                        <div className='map_icons'>
                            {map.mapIcons.map((icon, index) => {
                                if (icon.iconType === "Recruit") {
                                    return (
                                        <div className='icon' key={index}>
                                            <img alt='map_icon' style={{
                                                width: "2rem",
                                                height: "2rem"
                                            }} src={"data:image/png;base64," + icon.image} />
                                            <p>{icon.name}</p>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                        :
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"

                        }}>
                            <CircularProgress size={50} sx={{
                                color: "var(--primary-color)"
                            }} />
                        </div>
                }
            </Box>
        </div >
    )
}

export default Legends