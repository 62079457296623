import React, { useEffect, useState } from "react";
import SellingCardApi from "../../../../API/SellingCardApi";
import Swal from "sweetalert2";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import StringIntReq from "../../../../Requests/StringIntReq";

const columns = [
  {
    width: 100,
    label: "User ID",
    dataKey: "userId",
    align: "center",
  },
  {
    width: 200,
    label: "Timestamp",
    dataKey: "timestamp",
    align: "center",
  },
  {
    width: 150,
    label: "Actions",
    dataKey: "actions",
    align: "center",
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead sx={{ width: "100%",display: "table-header-group" }} {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow style={{ width: "100%" }}>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.align}
          style={{ width: column.width }}
          sx={{ backgroundColor: "background.paper" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.align}
        >
          {column.dataKey === "actions" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => Swal.fire("Answers", JSON.stringify(row.quizAnswers), "info")}
            >
              View Answers
            </Button>
          ) : column.dataKey === "timestamp" ? (
            new Date(row[column.dataKey]).toLocaleString()
          ) : (
            row[column.dataKey]
          )}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

const QuizSubmissionView = ({ quizId }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSubmissions = async () => {
    setLoading(true);
    try {
      const req = new StringIntReq();
      req.int = quizId;
      const response = await SellingCardApi.GetQuizSubmissions(req);
      if (response.status.success) {
        setSubmissions(response.data);
      } else {
        Swal.fire("Error", response.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubmissions();
  }, [quizId]);

  return (
    <Paper style={{ height: 400, width: "100%" }}>
      <TableVirtuoso
        data={submissions}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
  );
};

export default QuizSubmissionView;
