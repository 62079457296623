/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import FiberCompanyApi from '../../API/FiberCompanyApi';
import SearchFiberCompanyReq from '../../Requests/FiberCompany/SearchFiberCompanyReq';
import StringIntReq from '../../Requests/StringIntReq';
import SoftTypography from './../SoftTypography';
import UsersApi from '../../API/UsersApi';
import ManyToManyReq from '../../Requests/ManyToManyReq';
import { UserContext } from '../../App';
const Index = ({ setUser, setCompanyObject, companyObject, SalesOrgFiberCompanies = false, salesOrg, SalesRepFiberCompanies = false, salesRep, companiesWithOfferTemplateOnly, disabled = false, placeholder = "Select a parent Organization", className, multiple, value, isBusinessCard }) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    const user = useContext(UserContext);

    // useEffect(() => {
    //     console.log('setUser', salesRep)
    //     if (setUser) {
    //         setUser(null);
    //     }
    // }, []);
    const GetOrg = async () => {

        let res = null;
        let searchReq = new SearchFiberCompanyReq();
        // searchReq.userType = "company"
        if (UsersSearch)
            searchReq.name = UsersSearch;

        if (companiesWithOfferTemplateOnly) {
            searchReq.hasOfferTemplate = true;
        }
        if (salesOrg != null) {
            searchReq.salesOrgId = salesOrg
        }
        if (salesRep?.id != null) {
            searchReq.salesRepId = salesRep.id;
        }
        res = await FiberCompanyApi.SearchFiberCompany(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];

            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name, id: item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }



    }

    const setDefaultCompany = async (id) => {
        let req = new ManyToManyReq()
        req.id1 = user.id
        req.id2 = id
        let res = await UsersApi.setDefaultCompany(req)
        if (res?.status?.success) {
            console.log("Default Company Set")
            user.fiberCompanyId = id
        }
    }


    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, []);

    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">


                {
                    companyObject ?
                        <AsyncSelect
                            loadOptions={GetOrg}
                            onInputChange={setUserSearch}
                            onChange={(e) => {
                                if (multiple) {
                                    setUser(e.map((item) => item.id));
                                    if (setCompanyObject) {
                                        setCompanyObject(e);
                                    }
                                } else {
                                    if (isBusinessCard) {
                                        setDefaultCompany(e.id)
                                    }
                                    setUser(e.id);
                                    if (setCompanyObject) {
                                        setCompanyObject(e.value);
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            isDisabled={disabled}
                            isMulti={multiple}
                            value={companyObject}
                            defaultOptions={options}
                            classNamePrefix={'async_select'}
                        />
                        :

                        <AsyncSelect
                            loadOptions={GetOrg}
                            onInputChange={setUserSearch}
                            onChange={(e) => {
                                if (multiple) {
                                    setUser(e.map((item) => item.id));
                                    if (setCompanyObject) {
                                        setCompanyObject(e);
                                    }
                                } else {
                                    if (isBusinessCard) {
                                        setDefaultCompany(e.id)
                                    }
                                    setUser(e.id);
                                    if (setCompanyObject) {
                                        setCompanyObject(e.value);
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            isDisabled={disabled}
                            isMulti={multiple}
                            defaultValue={value}
                            defaultOptions={options}
                            classNamePrefix={'async_select'}
                        />
                }
            </SoftTypography>

        </div>
    )

}

export default Index