/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import StringIntReq from '../../../Requests/StringIntReq';
import ComissionApi from '../../../API/ComissionApi';
import './UserCommission.scss'
import { Accordion, AccordionDetails, CircularProgress } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { IoMdArrowDropup } from 'react-icons/io';
import Swal from 'sweetalert2';
import BulkPayCommissionTrackersReq from '../../../Requests/ComissionPlan/BulkPayCommissionTrackersReq';
import { SearchFiltersContext, UserContext } from '../../../App';
import { IoArrowBack } from 'react-icons/io5';


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<IoMdArrowDropup color='black' size={20} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, 0)',
    border: "none",
    borderBottom: '1px solid black',
    boxShadow: 'none',
    flexDirection: 'row-reverse',
    padding: "0px",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Index = () => {

    const { state } = useLocation();
    const [commissionTrackers, setCommissionTrackers] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [fiberCompanies, setFiberCompanies] = useState([]);
    const user = useContext(UserContext)
    const searchFilter = useContext(SearchFiltersContext)
    const fetchData = async () => {
        setLoading(true)
        console.log(state)
        const req = new StringIntReq()
        if (state) {
            req.int = state.userId
        }
        else {
            req.int = user.id
        }
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 20
        const res = await ComissionApi.GetUserCommissionTrackers(req);
        if (res?.status?.success) {
            console.log(res.data)
            console.log(res.data)
            let fiberCompany = [];
            const groupedData = res.data.reduce((acc, item) => {
                const commissionKey = item.commissionPlan.name;
                const saleKey = item.saleId;
                const weekRangeKey = item.weekRange;

                if (!acc[commissionKey]) {
                    acc[commissionKey] = {};
                    fiberCompany.push(item.sale.fiberPlanString?.split(' ')[0])
                }


                if (!acc[commissionKey][weekRangeKey]) {
                    acc[commissionKey][weekRangeKey] = {};
                }


                if (!acc[commissionKey][weekRangeKey][saleKey]) {
                    acc[commissionKey][weekRangeKey][saleKey] = [];
                }
                if (item.type !== "Deduction") {
                    acc[commissionKey][weekRangeKey][saleKey].push(item);
                }

                return acc;
            }, {});
            console.log(groupedData);
            setFiberCompanies(fiberCompany)
            const reductions = res?.data?.filter(item => item.type === "Deductions")
            setDeductions(reductions);

            setCommissionTrackers(groupedData)
            let totalSales = Object.keys(groupedData).reduce((acc, commissionPlanId) => {
                return acc + Object.keys(groupedData[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(groupedData[commissionPlanId][weekRangeKey]).length
                }, 0)
            }, 0)
            setTotalCustomers(totalSales)
            setLoading(false)
        }
    }



    useEffect(
        () => {
            fetchData()
        }, []
    )

    const payBulkCommissionTracker = async (ids, dict) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to pay all commission trackers",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = new BulkPayCommissionTrackersReq();
            req.commissionTrackerIds = ids;
            req.amounts = dict;
            const res = await ComissionApi.BulkPayCommissionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Commission trackers paid successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }
    }

    const deleteDeductionTrackers = async (ids) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to delete all deduction trackers",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = {
                commissionTrackerIds: ids
            }

            const res = await ComissionApi.DeleteDeductionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Deduction trackers deleted successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }
    }

    const payAndDeleteDeductionTrackers = (ids, object, payAmount, deductionAmount, isClawedBack) => {
        return isClawedBack ? <p className='text-red-500 font-bold'>Clawed Back</p> :
            user.userType === "SuperAdmin" &&
            <div className='flex gap-4 items-center justify-center'>
                {
                    payAmount > 0 ?
                        <button onClick={() => {
                            payBulkCommissionTracker(ids, object)
                        }}>Pay ${payAmount}</button>
                        : <p className='paid'>Paid</p>
                }
                {/* {
                    deductionAmount > 0 &&
                    <button onClick={() => {
                        deleteDeductionTrackers(ids)
                    }}>Delete Deductions</button>
                } */}
            </div>

    }

    function commissionStatus() {
        let totalCommission = 0;
        let totalPaid = 0;
        let totalPending = 0;
        let totalDeducted = 0;

        if (commissionTrackers === null) {
            return {
                totalCommission,
                totalPaid,
                totalPending,
                totalDeducted
            }
        }

        Object.keys(commissionTrackers).forEach(commissionPlanId => {
            Object.keys(commissionTrackers[commissionPlanId]).forEach(weekRangeKey => {
                Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).forEach(saleId => {
                    commissionTrackers[commissionPlanId][weekRangeKey][saleId].forEach(item => {
                        totalCommission += item.amount
                        totalPaid += item.accountingEntries.reduce((acc, item) => {
                            return acc + item.amount
                        }, 0)
                        totalPending += (item.amount - item.deductionTrackers.reduce((acc, item) => {
                            return acc + item.amount
                        }, 0)
                            - item.accountingEntries.reduce((acc, item) => {
                                return acc + item.amount
                            }, 0)
                        )
                        totalDeducted += item.deductionTrackers.reduce((acc, item) => {
                            return acc + (item.permanent ? 0 : item.amount)
                        }, 0)
                    })
                })
            })
        })

        return {
            totalCommission,
            totalPaid,
            totalPending,
            totalDeducted
        }
    }

    function payableAmount(commissionPlanId, weekRangeKey, saleId) {

        if (commissionTrackers === null) {
            return 0;
        }

        if (commissionPlanId && weekRangeKey && saleId) {

            return commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc, item) => {
                return acc + (item.amount - item.deductionTrackers.reduce((acc2, item) => {
                    return acc2 + item.amount
                }, 0)
                    - item.accountingEntries.reduce((acc3, item) => {
                        return acc3 + item.amount
                    }, 0)
                )
            }, 0)

        }
        else if (commissionPlanId && weekRangeKey) {

            return Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc, saleId) => {
                return acc + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc2, item) => {
                    return acc2 + (item.amount - item.deductionTrackers.reduce((acc3, item) => {
                        return acc3 + item.amount
                    }, 0)
                        - item.accountingEntries.reduce((acc4, item) => {
                            return acc4 + item.amount
                        }, 0)
                    )
                }, 0)
            }, 0)
        }
        else if (commissionPlanId) {

            return Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, weekRangeKey) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc2, saleId) => {
                    return acc2 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc3, item) => {
                        return acc3 + (item.amount - item.deductionTrackers.reduce((acc4, item) => {
                            return acc4 + item.amount
                        }, 0)
                            - item.accountingEntries.reduce((acc5, item) => {
                                return acc5 + item.amount
                            }, 0)
                        )
                    }, 0)
                }, 0)
            }, 0)
        }
        else {

            return Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + (item.amount - item.deductionTrackers.reduce((acc5, item) => {
                                return acc5 + item.amount
                            }, 0)
                                - item.accountingEntries.reduce((acc6, item) => {
                                    return acc6 + item.amount
                                }, 0)
                            )
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }

    }

    function deductionAmount(commissionPlanId, weekRangeKey, saleId) {

        if (commissionTrackers === null) {
            return 0;
        }

        if (commissionPlanId && weekRangeKey && saleId) {

            return commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc, item) => {
                return acc + item.deductionTrackers.reduce((acc2, item) => {
                    return acc2 + item.amount
                }, 0)
            }, 0)
        }
        else if (commissionPlanId && weekRangeKey) {

            return Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc, saleId) => {
                return acc + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc2, item) => {
                    return acc2 + item.deductionTrackers.reduce((acc3, item) => {
                        return acc3 + item.amount
                    }, 0)
                }, 0)
            }, 0)
        }
        else if (commissionPlanId) {

            return Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, weekRangeKey) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc2, saleId) => {
                    return acc2 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc3, item) => {
                        return acc3 + item.deductionTrackers.reduce((acc4, item) => {
                            return acc4 + item.amount
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else {

            return Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.deductionTrackers.reduce((acc5, item) => {
                                return acc5 + item.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
    }

    function goToContactCenterForSingleCustomer(customer) {
        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                customerName: customer.firstName + " " + customer.lastName
            }
        }

        navigate('/contact-center')   
    }

    function goToContactCenterWithWeekKey(weekRange){
        let weekRangeSplit = weekRange.split('-')
        let fromDate = new Date(weekRangeSplit[0].trim())
        // -1 day from the from date
        fromDate = new Date(fromDate.setDate(fromDate.getDate() - 1))

        let toDate = new Date(weekRangeSplit[1].trim())

        fromDate = new Date(fromDate.setHours(0,0,0,0))
        toDate = new Date(toDate.setHours(23,59,59,999))

        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                fromDate : formatDateToISOString(fromDate),
                toDate : formatDateToISOString(toDate),
                type: "Completed",
                quickDateValue: "Custom",
                dateType: "InstallDate",
                salesReps : [{
                    id: state ? state.userId : user.id,
                    label: state ? state.name : (user.firstName + " " + user.lastName)
                }],
            }
        }
        navigate("/contact-center")
    }
    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    function goToContactCenterForClawBacks(){
        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                type : "ClawBack",
                salesReps : [{
                    id: state ? state.userId : user.id,
                    label: state ? state.name : (user.firstName + " " + user.lastName)
                }]
            }
        }
        navigate("/contact-center")
    }

    return (
        <Layout>
            <IoArrowBack size={30} color='black' style={{ cursor: 'pointer', marginLeft: "1rem" }} onClick={() => { navigate(-1) }} />
            <div className='user_copmmission'>
                {
                    state ?
                        <h1>{state.name} Commissions</h1>
                        :
                        <h1>Your Commissions</h1>
                }

                <div className='commission_stats' >
                    <div className='total'>
                        <h3>Total Commission</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalCommission
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Paid</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalPaid
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Pending</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalPending
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Remaining Insurance</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalDeducted
                            }
                        </p>
                    </div>

                </div>
                {
                    loading ?
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            marginTop: '4rem'
                        }}>
                            <CircularProgress
                                sx={{
                                    color: "var(--color-primary)",
                                    margin: "auto"
                                }}
                            />
                        </div>
                        :
                        Object.keys(commissionTrackers).length === 0 ?
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                                marginTop: '4rem'
                            }}>
                                <h1>No Commissions To Show</h1>
                            </div>
                            :
                            <div className='commission_plan'>
                                <h1>Total Customers: {totalCustomers}</h1>
                                {
                                    Object.keys(commissionTrackers).map((commissionPlanId, index) => {
                                        const commissionPayAmount = payableAmount(commissionPlanId)
                                        const commissionDeduction = deductionAmount(commissionPlanId)
                                        let idsInCommission = [];
                                        let commissionObject = {};
                                        let totalClawBacks = Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, weekRangeKey) => {
                                            return acc + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc2, saleId) => {
                                                return acc2 + (commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.isClawedBack ? 1 : 0)
                                            }, 0)
                                        }, 0)

                                        return (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<IoMdArrowDropup color='black' size={30} />} >
                                                    <div>
                                                        <h3>Commission Plan: {commissionPlanId}</h3>
                                                        <p>Fiber Company: {fiberCompanies[index]}</p>
                                                        <p>Total ClawBacks: <span onClick={()=>{
                                                            goToContactCenterForClawBacks()
                                                        }} className='text-red-500 underline'>{totalClawBacks}</span></p>
                                                    </div>
                                                    {
                                                        payAndDeleteDeductionTrackers(idsInCommission, commissionObject, commissionPayAmount, commissionDeduction)
                                                    }
                                                </AccordionSummary>
                                                <AccordionDetails sx={{
                                                    marginLeft: "20px"
                                                }}>
                                                    <div>
                                                        {
                                                            Object.keys(commissionTrackers[commissionPlanId]).map((weekRangeKey, index) => {


                                                                const salesPayAmount = payableAmount(commissionPlanId, weekRangeKey)
                                                                const salesDeduction = deductionAmount(commissionPlanId, weekRangeKey)
                                                                let idsInWeekRange = [];
                                                                let weekRangeObject = {};
                                                                const noOfSales = Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).length
                                                                return (
                                                                    <Accordion key={index}>
                                                                        <AccordionSummary>
                                                                            <div>
                                                                                <h3>Week Range: {weekRangeKey}</h3>
                                                                                <p>Customers : <span onClick={()=>{
                                                                                    goToContactCenterWithWeekKey(weekRangeKey)
                                                                                }} className='underline font-bold text-[#2761D0]'>{noOfSales}</span></p>
                                                                            </div>
                                                                            {
                                                                                payAndDeleteDeductionTrackers(idsInWeekRange, weekRangeObject, salesPayAmount, salesDeduction)
                                                                            }

                                                                        </AccordionSummary>

                                                                        <AccordionDetails sx={{
                                                                            marginLeft: "20px"
                                                                        }}>
                                                                            <div>
                                                                                {
                                                                                    Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).map((saleId, index) => {
                                                                                        const sale = commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale
                                                                                        const customerName = commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.firstName + " " + commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.lastName
                                                                                        const fiberPlan = commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.fiberPlanString

                                                                                        let idsInSale = [];
                                                                                        let saleObject = {};

                                                                                        const amountToBePaid = payableAmount(commissionPlanId, weekRangeKey, saleId)
                                                                                        const customerDeductionAmount = deductionAmount(commissionPlanId, weekRangeKey, saleId)
                                                                                        return (
                                                                                            <Accordion key={index}>
                                                                                                <AccordionSummary>
                                                                                                    <div>
                                                                                                        <h3 onClick={()=>{
                                                                                                            goToContactCenterForSingleCustomer(sale)
                                                                                                        }} className='underline cursor-pointer text-[#2761D0]'>{customerName}</h3>
                                                                                                        <p>Fiber Plan: {fiberPlan}</p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        payAndDeleteDeductionTrackers(idsInSale, saleObject, amountToBePaid, customerDeductionAmount, sale.isClawedBack)
                                                                                                    }
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails sx={{
                                                                                                    marginLeft: "20px"
                                                                                                }} >
                                                                                                    <div>
                                                                                                        {
                                                                                                            commissionTrackers[commissionPlanId][weekRangeKey][saleId].map((item, index) => {

                                                                                                                const payableAmount = item.amount - item.deductionTrackers.reduce((acc, item) => {
                                                                                                                    return acc + item.amount
                                                                                                                }, 0) - item.accountingEntries.reduce((acc, item) => {
                                                                                                                    return acc + item.amount
                                                                                                                }, 0)

                                                                                                                const deductionAmount = item.deductionTrackers.reduce((acc, item) => {
                                                                                                                    return acc + item.amount
                                                                                                                }, 0)



                                                                                                                // store ids for bulk commission payment
                                                                                                                idsInCommission.push(item.id)
                                                                                                                commissionObject[item.id] = payableAmount;
                                                                                                                // store ids for bulk week range payment
                                                                                                                idsInWeekRange.push(item.id)
                                                                                                                weekRangeObject[item.id] = payableAmount;
                                                                                                                // store ids for bulk sale payment
                                                                                                                idsInSale.push(item.id)
                                                                                                                saleObject[item.id] = payableAmount;



                                                                                                                return (
                                                                                                                    <div style={{
                                                                                                                        padding: "0rem",
                                                                                                                        border: "none"
                                                                                                                    }} className='commission_tracker' key={index}>
                                                                                                                        <div>
                                                                                                                            <h3>{item.type} {item.commissionScale}</h3>
                                                                                                                            <p>Amount To be Paid: ${payableAmount}</p>
                                                                                                                            <p>Amount Paid : ${item.accountingEntries.reduce(
                                                                                                                                (acc, item) => {
                                                                                                                                    return acc + item.amount
                                                                                                                                }, 0
                                                                                                                            )}</p>
                                                                                                                            <p>Status: {payableAmount <= 0 ? 'Paid' : 'Pending'}</p>
                                                                                                                            {
                                                                                                                                item.deductionTrackers.map((deduction, index) => {

                                                                                                                                    return (
                                                                                                                                        <div key={index} >
                                                                                                                                            <h3 className={`deduction_reason ${deduction.permanent ? "text-red-500" : "text-orange-500"}`}>{deduction.deductionReason} {deduction.permanent ? "(Permanent)" : ""}</h3>
                                                                                                                                            <p className={`deduction_amount ${deduction.permanent ? "text-red-500" : "text-orange-500"}`}>Amount: - ${deduction.amount}</p>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            payAndDeleteDeductionTrackers([item.id], { [item.id]: payableAmount }, payableAmount, deductionAmount)
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </AccordionDetails>


                                                                    </Accordion>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }

                            </div>
                }
            </div>
        </Layout>
    )
}

export default Index