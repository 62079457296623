import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { BiReset } from 'react-icons/bi';
import Draggable from 'react-draggable';
import { Button, Switch } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import './Player.scss';
import { MdOutlinePanoramaHorizontalSelect } from 'react-icons/md';

const Player = () => {
  const speedText = ["x0.5", "x1", "x2", "x4", "x6", "x8"];
  const speedValue = [0.5, 1, 2, 4, 6, 10];
  const [speed, setSpeed] = useState(speedValue[1]);
  const [speedIndex, setSpeedIndex] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [zoom, setZoom] = useState(20);
  const [isPanning, setIsPanning] = useState(true);
      const [panValue, setPanValue] = useState(1);

  const handleSpeedChange = () => {
    const nextIndex = (speedIndex + 1) % speedText.length;
    setSpeedIndex(nextIndex);
    setSpeed(speedValue[nextIndex]);
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setProgress(0);
    setIsPlaying(false);
  };

  const handleProgressChange = (e) => {
    setProgress(parseFloat(e.target.value));
  };

  const handleZoomChange = (symbol) => {
    if (symbol === "-" && !(zoom <= 5)) {
        setZoom((prev) => prev -= 1)
        console.log(zoom)
    }
    if (symbol === "+" && !(zoom >= 25)) {
        setZoom((prev) => prev += 1)
        console.log(zoom)
    }
};

const handlePanSwitch = () => {
    setIsPanning((prev) => !prev)
    if (panValue === 1) {
        setPanValue(0)
    } else {
        setPanValue(1)
    }

    console.log(isPanning);
};

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 1) {
            clearInterval(interval);
            setIsPlaying(false);
            return 1;
          }
          return prevProgress + 0.01 * speed;
        });
      }, 100);
    }
    return () => clearInterval(interval);
  }, [isPlaying, speed]);

  return (
    <Draggable bounds="parent" handle=".player-handle">
      <div className="player">
        <div className="player-handle">
          <div className="handle-dots"></div>
        </div>
        <div className="player-content">
          <div className="controls-row">
            <Button
              className="control-btn"
              variant="outlined"
              value={zoom}
            id="zoomOutBtn"
              onClick={() => handleZoomChange("-")}
              aria-label="Zoom out"
            >
              <ZoomOut  style={{ width: '25px', height: '25px' }} color='black' />
            </Button>
            <Button
              className="control-btn"
              id="PlayPauseBtn"
              variant="contained"
              color="primary"
            >
            </Button>
            <Button
              id='speedBtn'
              variant="outlined"
              onClick={handleSpeedChange}
              value={speed}
            >
              {speedText[speedIndex]}
            </Button>
            <Button
              className="control-btn"
              variant="outlined"
              id="zoomInBtn"
              value={zoom}
              onClick={() => handleZoomChange("+")}
              aria-label="Zoom in"
            >
            <ZoomIn style={{ width: '25px', height: '25px' }} color='black' />
            </Button>
          </div>
          <div className="controls-row">
            <div className="slider-container">
              <input
                id="seeker"
                type="range"
                min="0"
                max="1"
                step="0.000000000000000001"
                aria-label="Progress"
              />
            </div>


            <div className="pan-control">
              <label htmlFor="panSwitch"><MdOutlinePanoramaHorizontalSelect color='black' size={20}/></label>
              <Switch
                id='panSwitch'
                checked={isPanning}
                value={panValue}
                onChange={handlePanSwitch}
              />
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Player;

