import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import { getAuth } from "firebase/auth";
import { createContext, useEffect, useRef, useState, useContext } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, Routes } from "react-router-dom";
import UsersApi from "./API/UsersApi";
import './App.css';
import AdminMenuItems from './MenuItems/AdminMenuItems';
import CustomerMenuItems from './MenuItems/CustomerMenuItems';
import RepMenuItems from './MenuItems/RepMenuItems';
import SuperAdminMenuItems from './MenuItems/SuperAdminMenuItems';
import TeamAdminMenuItems from './MenuItems/TeamAdminMenuItems';
import AdminRoutes from './Routes/AdminRoutes';
import CustomerRoutes from './Routes/CustomerRoutes';
import ErrorRoutes from './Routes/ErrorRoutes';
import PublicRoutes from './Routes/PublicRoutes';
import RepRoutes from './Routes/RepRoutes';
import SuperAdminRoutes from './Routes/SuperAdminRoutes';
import TeamAdminRoutes from './Routes/TeamAdminRoutes';
import ConnectionService from './Services/ConnectionService';
import "./assets/global/CustomGeneral.scss";
import "./assets/global/Tables.scss";
import theme from "./assets/theme";
import { SoftUIControllerProvider } from './context';
import NoFidiumCustomerRoutes from './Routes/NoFidiumCustomerRoutes';
import NoFidiumCustomerMenuItems from './MenuItems/NoFidiumCustomerMenuItems';
import CompanyUserRoutes from './Routes/CompanyUserRoutes';
import CompanyUserMenuItems from './MenuItems/CompanyUserMenuItems';
import DisabledRoutes from './Routes/DisabledRoutes';
import StringIntReq from './Requests/StringIntReq';
import Swal from 'sweetalert2';
import { Howl } from 'howler';
import EncryptionHelper from './Services/EncryptionHelper';
import FiberHouseApi from './API/FiberHouseApi';
import RequestedCustomerReminder from './RequestedCustomerReminder';
import LightningLoader from './components/Loader';
import { fromLonLat, toLonLat } from 'ol/proj';
import { getDistance } from 'ol/sphere';
import { MapContext } from './Views/TeamAdmin/MapPage';
import { MdOutlineReportProblem } from 'react-icons/md';
import IssuesPage from './Views/SuperAdmin/ManageIssueTypes';
export const ConnectionContext = createContext();
export const UserContext = createContext();
export const MenuItemsContext = createContext();
export const NotificationsContext = createContext();
export const SearchFiltersContext = createContext();
export const CandidateFiltersContext = createContext();

function App() {
  const auth = getAuth();
  //const {mapObject} = useContext(MapContext);
  const [user, loading, error] = useAuthState(auth);
  const [UserData, setUserData] = useState(null);
  const [RoutesState, setRoutesState] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [IsLoaded, setIsLoaded] = useState(false);
  const [ConnectionServiceObj, SetConnectionServiceObj] = useState();
  const [notifications, setNotifications] = useState([]);
  const searchFilters = useRef(null);
  const candidateFilters = useRef(null);


  useEffect(() => {
    // Only proceed if we have a valid user
    if (!UserData || !UserData.id) return;

    const watchId = navigator.geolocation.watchPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        // Convert to your map projection if needed
        const newCoordinates = fromLonLat([longitude, latitude]);

        // Get the last stored location (if any)
        const rawStored = localStorage.getItem("currentLocation");
        const storedCoords = rawStored ? JSON.parse(rawStored) : null;

        // If we have NO stored location yet, just store it in localStorage
        // and do NOT save to DB:
        if (!storedCoords) {
          localStorage.setItem("currentLocation", JSON.stringify(newCoordinates));
          console.log("Set initial location in localStorage (not saving to DB).");
          return;
        }

        // Otherwise, calculate how far the user moved from the last known location
        const distance = getDistance(
          toLonLat(storedCoords),
          toLonLat(newCoordinates)
        );
        console.log("Distance from last location:", distance, "meters");

        // If user moved >= 15 meters, update localStorage & save to DB
        if (distance >= 15) {
          localStorage.setItem("currentLocation", JSON.stringify(newCoordinates));
          console.log("Moved >= 15m, saving to DB...");

          try {
            const req = {
              repId: UserData.id,
              coordinate: {
                // Convert back to [longitude, latitude] for the DB if needed
                longitude: toLonLat(newCoordinates)[0],
                latitude: toLonLat(newCoordinates)[1],
              },
            };
            const res = await UsersApi.saveSalesRepLocationHistory(req);
            if (res?.status?.success) {
              console.log("Saved Sales Rep Location to DB");
            } else {
              console.warn("Failed to save location");
            }
          } catch (err) {
            console.error("Error saving location:", err);
          }
        }
      },
      (error) => {
        console.error("Error watching position:", error);
      },
      {
        enableHighAccuracy: true,
      }
    );

    // Cleanup: stop watching on unmount or when user changes
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [UserData]);


  useEffect(() => {
    if (UserData) {

      if (UserData.status === "Inactive") {
        setRoutesState(DisabledRoutes)
      }
      else if (UserData.userType === "SuperAdmin") {
        setRoutesState(SuperAdminRoutes);
        setMenuItems(SuperAdminMenuItems)
      }
      else if (UserData.userType === "Admin") {
        setRoutesState(AdminRoutes);
        setMenuItems(AdminMenuItems)
      }
      else if (UserData.userType === "SalesRep") {
        if (UserData.salesOrgId !== null) {
          setRoutesState(RepRoutes);
          setMenuItems(RepMenuItems);
        }
        else
          setRoutesState(ErrorRoutes)
      }
      else if (UserData.userType === "SalesOrgAdmin") {


        if (UserData.salesOrgId !== null) {

          let teamAdminRoutes = TeamAdminRoutes;
          let teamAdminMenuItems = TeamAdminMenuItems;

          if (UserData.id === 1844) {
            teamAdminMenuItems.push({
              name: "Manage Issue Types",
              key: "manageIssueTypes",
              route: "/manage-issue-types",
              icon: (color, size = 40) => <MdOutlineReportProblem size={size} color={color} />
            })

            teamAdminRoutes.push({
              name: "Manage Issues",
              key: "ManageIssueTypes",
              route: "/manage-issue-types",
              component: <IssuesPage />
            })

          }

          setRoutesState(TeamAdminRoutes);
          setMenuItems(TeamAdminMenuItems);
        }
        else {
          setRoutesState(ErrorRoutes);
        }
      }
      else if (UserData.userType === "Customer") {
        if (!UserData.fidiumCustomer) {



          setRoutesState(NoFidiumCustomerRoutes);
          setMenuItems(NoFidiumCustomerMenuItems)
        }
        else {
          setRoutesState(CustomerRoutes);
          setMenuItems(CustomerMenuItems);
        }

      }
      else if (UserData.userType === "CompanyUser") {
        setRoutesState(CompanyUserRoutes)
        setMenuItems(CompanyUserMenuItems)
      }
      else {
        setRoutesState(PublicRoutes);
      }
    }
    else {
      // getAuth().signOut()
      setRoutesState(PublicRoutes);
    }
  }, [UserData]);



  useEffect(() => {

    async function fetchData() {
      try {
        let res = await UsersApi.GetCurrentUser();
        console.log("APP USER DATA:", res);

        if (res?.status?.success === false) {
          Swal.fire({
            title: 'Error!',
            text: res.status.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
          return;
        }

        if (res?.data == null) {
          auth.signOut();
          return;
        }

        const encryptionHelper = new EncryptionHelper();
        //await encryptionHelper.loadPublicKey(); // Load the public key

        if (res.data.userType) {
          const decryptedUserType = encryptionHelper.decrypt(res.data.userType);
          console.log(decryptedUserType);
          setUserData({
            ...res.data,
            userType: decryptedUserType,
          });
        } else {
          console.log(res.data);
          setUserData(res.data);
        }
      } catch (e) {
        let option = await Swal.fire(
          {
            icon: "error",
            title: "Failed To Login",
            text: "Check Your Internet Connection, Error : " + e.message + " Error Name : " + e.name,
            showConfirmButton: true,
            confirmButtonText: "Retry!",
            showCancelButton: true,
            cancelButtonText: "Logout"
          }
        )

        if (option.isConfirmed) {
          fetchData()
          return
        }
        else {
          auth.signOut();
          return
        }

      }
    }
    if (!UserData && user && !loading) {

      fetchData();
    }
  }, [user, loading, UserData]);

  useEffect(() => {
    if (!user && !loading) {
      setUserData({});
    }
  }, [user, loading]);

  useEffect(() => {
    if (RoutesState && UserData) {
      setIsLoaded(true);
    }
    else {
      setIsLoaded(false);
    }
  }, [RoutesState, UserData]);

  const searchNotifications = async () => {
    const req = new StringIntReq();
    req.int = UserData.id;
    const res = await UsersApi.SearchNotifications(req);
    if (res?.status?.success) {
      setNotifications(res.data);
    }
  }



  // const markNotificationAsSeen = async (notificationId) => {
  //   const req = new StringIntReq();
  //   setNotifications(
  //     (prev) => prev.filter((notification) => notification.id !== notificationId)
  //   )
  //   req.int = notificationId;
  //   const res = await UsersApi.MarkNotificationAsSeen(req);
  //   if (res?.status?.success) {
  //     // searchNotifications();
  //     console.log("Notification marked as seen");
  //   }

  // }

  const getTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // e.g., "Asia/Karachi"
    return timeZone;
  }

  const setTimeZone = async () => {
    let timeZone = getTimeZone();
    if (UserData?.timeZone !== timeZone) {
      const req = new StringIntReq();
      req.int = UserData.id;
      req.str = timeZone;
      const res = await UsersApi.SetUserTimeZone(req);
      if (res?.status?.success) {
        console.log("Time Zone Updated");
      }
      else {
        console.log("Failed to update time zone");
      }
    }
  }

  useEffect(() => {

    if (UserData != null && typeof UserData == "object" && Object.keys(UserData).length > 0) {


      let connectionService = new ConnectionService(UserData);
      connectionService.StartConnection();
      setTimeZone();
      searchNotifications();
      SetConnectionServiceObj(connectionService);

    }
  }, [UserData])

  useEffect(() => {
    if (ConnectionServiceObj) {
      ConnectionServiceObj.newNotification = (notify) => {
        // searchNotifications();
        setNotifications((prev) => [notify, ...prev]);
        const sound = new Howl({
          src: ['/pop.wav'],
          volume: 1
        });
        if (localStorage.getItem('volumeEnabled') === 'false') {

        }
        else {
          sound.play();
        }

        Swal.fire({
          text: notify.message,
          icon: 'info',
          confirmButtonText: 'Ok',
          toast: true,
          timer: 4000,
          position: 'bottom-right',
        })
      }
    }
  }, [notifications])

  useEffect(() => {
    return () => {
      if (ConnectionServiceObj) {
        ConnectionServiceObj.StopConnection();
      }
    }
  }, [])


  useEffect(() => {

    if (localStorage.getItem('theme' + UserData?.id) === 'dark') {
      document.body.classList.add('dark');
    }
    else if (localStorage.getItem('theme' + UserData?.id) === 'light') {
      document.body.classList.add('default');
    }
    else {
      document.body.classList.add('default');
      localStorage.setItem('theme' + UserData?.id, 'light');
    }
  }, [UserData])

  const getRoutes = (allRoutes) => {
    allRoutes.map((route) => {
      return <Route path={route.route} element={route.component} key={route.key} />;
    });
  }

  if (!IsLoaded) {
    return <LightningLoader />;
  }

  return (
    <SoftUIControllerProvider>

      <ThemeProvider lightTheme={true} theme={theme}>
        <ConnectionContext.Provider value={ConnectionServiceObj}>
          <NotificationsContext.Provider value={{ notifications, setNotifications }}>
            <SearchFiltersContext.Provider value={searchFilters}>
              <CandidateFiltersContext.Provider value={candidateFilters}>
                <UserContext.Provider value={UserData}>
                  <MenuItemsContext.Provider value={menuItems}>
                    <RequestedCustomerReminder />
                    <Routes>
                      {
                        RoutesState && RoutesState.map((route) => {
                          return <Route path={route.route} element={route.component} key={route.key} />;
                        })
                      }
                    </Routes>
                  </MenuItemsContext.Provider>
                </UserContext.Provider>
              </CandidateFiltersContext.Provider>
            </SearchFiltersContext.Provider>
          </NotificationsContext.Provider>
        </ConnectionContext.Provider>
      </ThemeProvider>
    </SoftUIControllerProvider>

  );
}

export default App;
