import { CardMembership, Chat, Dashboard, Map } from "@mui/icons-material";
import { FcCustomerSupport } from "react-icons/fc";
import { AiOutlineGlobal, AiOutlineStock } from "react-icons/ai";
import { BiSolidNetworkChart, BiSolidOffer } from "react-icons/bi";
import { HiOutlineCreditCard } from "react-icons/hi";
import { LuFolderCog } from "react-icons/lu";
import { MdDashboard, MdLeaderboard, MdOutlineBugReport, MdOutlineLeaderboard, MdOutlinePayments } from "react-icons/md";
import { PiMapTrifoldLight } from "react-icons/pi";
import { RiContactsBook2Line, RiContactsBookLine } from "react-icons/ri";
import { TbMessageBolt, TbReport, TbReportAnalytics } from "react-icons/tb";
import { VscFeedback, VscSettings } from "react-icons/vsc";
import { GoOrganization } from "react-icons/go";
import { FaCalendarDays } from "react-icons/fa6";
import { TfiAnnouncement } from "react-icons/tfi";
import { RxDashboard } from "react-icons/rx";
import { BsChatSquareQuote } from "react-icons/bs";

const RepMenuItems = [

    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color, size = 40) => <RxDashboard size={size} color={color} />
    },
    {
        groupName: "Repoting & Analytics",
        icon: (color, size = 40) => <TbReport size={size} color={color} />,

        items: [
            {
                name: "General Sales Report",
                key: "GeneralSalesReport",
                route: "/general-sales-reporting-page",
                icon: (color, size = 40) => <TbReport size={size} color={color} />
            },
            {
                name: "Offers Sales Report",
                key: "offersSalesReport",
                route: "/offers-reporting-page",
                icon: (color, size = 40) => <BiSolidOffer size={size} color={color} />,
                disabled: true
            },
        ]

    },
    {
        name: "Leader Board",
        key: "LeaderBoard",
        route: "/leader-board",
        icon: (color, size = 40) => <MdOutlineLeaderboard size={size} color={color} />,
        // disabled:true
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center",
        icon: (color, size = 40) => <HiOutlineCreditCard size={size} color={color} />,
    },

    {
        name: "Map",
        key: "map",
        route: "/map",
        icon: (color, size = 40) => <PiMapTrifoldLight size={size} color={color} />,
    },
    {
        type: "collapse",
        name: "Contact Center",
        key: 'ContactCenter',
        route: '/contact-center',
        icon: (color, size = 40) => <RiContactsBook2Line size={size} color={color} />,
        noCollapse: true,
        // disabled: true
    },
    {
        name: "My Commissions",
        key: "MyCommissions",
        route: "/my-commissions",
        icon: (color, size = 40) => <MdOutlinePayments size={size} color={color} />,
        disabled: true
    },
    // {
    //     name: "Sales Portal",
    //     key: "SalesPortal",
    //     route: "/sales-portal",
    //     icon: (color,size=40) => <AiOutlineStock size={size} color={color} />,
    // },
    // {
    //     name: "Performance Analytics",
    //     key: "Analytics",
    //     route: "/performance-analytics",
    //     icon: (color,size=40) => <BiSolidNetworkChart size={size} color={color} />,
    // },
    // {
    //     name: "Chat",
    //     key: 'privatechat',
    //     route: '/chats',
    //     icon: (color, size = 40) => <TbMessageBolt size={size} color={color} />,
    //     disabled: true
    // },


    // {
    //     name: "Training Modules",
    //     key: 'manageSellingCard',
    //     route: '/manage-training-modules',
    //     icon: (color, size = 40) => <LuFolderCog size={size} color={color} />,
    //     noCollapse: true,
    //     disabled: true
    // },
    {
        name: "Announcements",
        key: "Announcements",
        route: "/announcements-board",
        icon: (color, size = 40) => <TfiAnnouncement size={size} color={color} />,
    },

    {
        name: "Customer Feedback",
        key: 'CustomerFeedback',
        route: '/customer-feedback',
        icon: (color, size = 40) => <BsChatSquareQuote size={size} color={color} />,
        noCollapse: true
    },
    {
        name: "My Calendar",
        key: "MyCalendar",
        route: "/my-calendar",
        icon: (color, size = 40) => <FaCalendarDays size={size} color={color} />,
    },
    {
        name: "Report A Bug",
        key: "CreateBugTicket",
        route: "/create-bug-ticket",
        icon: (color, size = 40) => <MdOutlineBugReport size={size} color={color} />,
    },



]

export default RepMenuItems