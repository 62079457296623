import CreateSellingCardReq from '../SellingCard/CreateSellingCardReq';

class CreateSubTrainingModuleReq {
  constructor() {
    this.name = null;
    this.parentSubTrainingModuleId = null;
    this.trainingModuleId = null;
    this.sellingCards = [];
    this.childSubTrainingModules = [];
  }
}

export default CreateSubTrainingModuleReq;
