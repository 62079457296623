/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar as ProSidebar, SubMenu, menuClasses } from "react-pro-sidebar";

import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Select, MenuItem as MuiMenu, FormControl, InputLabel } from '@mui/material';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { IoIosLogOut, IoIosNotifications, IoIosWarning, IoMdArrowDropdown } from "react-icons/io";
import SettingsIcon from '@mui/icons-material/Settings';
import { getAuth } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UsersApi from "../API/UsersApi";
import { ConnectionContext, MenuItemsContext, NotificationsContext, UserContext } from "../App";
import Breadcrumbs from "./examples/Breadcrumbs";

import anonUser from "../assets/images/userGrey.png";
import StringIntReq from "../Requests/StringIntReq";
import { MdCancel, MdCelebration, MdSchedule } from "react-icons/md";
import { BiHide } from "react-icons/bi";
import { ClickAwayListener } from "@mui/material";
import BulkMarkNotificationsAsSeenReq from "../Requests/Notifications/BulkMarkNotificationsAsSeenReq";
import { GoDotFill } from "react-icons/go";
import { FcInfo } from "react-icons/fc";
import ManyToManyReq from "../Requests/ManyToManyReq";
import CustomerApi from "../API/CustomerApi";
import Swal from "sweetalert2";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Avatar, Button, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Notification from './icons/notification.png'
import Search from './icons/search.png'
// import Settings from './icons/settings.png'
import Logo from './icons/logo.png'
import SMlogo from './icons/smlogo.png'
import Map from './icons/map.png'
import "./sidebar.scss";
import h from "@editorjs/simple-image";
import { LogOutIcon, Settings } from "lucide-react";
import ContextMenu from "../components/ContextMenu";





const Item = ({ title, to, icon, selected, setSelected, isLast, active }) => {
  return (
    <MenuItem
      rootStyles={{
        ['.' + menuClasses.button]: {
          color: 'var(--text-color)',
          fontSize: '0.9rem',
          textTransform: "uppercase",
          // fontWeight: "600",
          '&:hover': {
            // backgroundColor: 'var(--color-icon-hover) !important',
            color: 'var(--primary-color)',
          },
          '&:active': {
            // backgroundColor: 'var(--color-icon-hover) !important',
            color: 'var(--primary-color)',
          },
        },
      }}
      active={active}
      component={<Link to={to} />}
      icon={icon(active ? 'var(--primary-color)' : 'var(--text-color)')}
      onClick={() => setSelected(title)}
    >
      <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
        {title}
      </div>

    </MenuItem>
  );
};

const Sidebar = ({ children }) => {
  const menuItems = useContext(MenuItemsContext);
  const connectionService = useContext(ConnectionContext);
  const { notifications, setNotifications } = useContext(NotificationsContext)
  const user = useContext(UserContext);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebar-collapsed');
    return savedState === null ? true : JSON.parse(savedState);
  });
  const [selected, setSelected] = useState("Dashboard");
  const [toggled, setToggled] = useState(false);
  const [breakPoint, setBreakPoint] = useState(false);
  const navigate = useNavigate();
  const route = useLocation().pathname.split("/").slice(1);
  // const [user, setUser] = useState(null);
  const activeMenuItem = menuItems.find((item, index) => item.route === `/${route[0]}`);
  const [notificationPanel, setNotificationPanel] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);




  const handleArrowClick = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    localStorage.setItem('sidebar-collapsed', JSON.stringify(newCollapsedState));
    if (breakPoint) {
      setToggled(!toggled);
      setIsCollapsed(false);
    }
  };

  const [openGroups, setOpenGroups] = useState({});

  const toggleGroup = (key) => {
    setOpenGroups((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  // const searchNotifications = async () => {
  //   const req = new StringIntReq();
  //   req.int = user.id;
  //   const res = await UsersApi.SearchNotifications(req);
  //   if (res?.status?.success) {
  //     setNotifications(res.data.filter(notification => !notification.seen));
  //   }
  // }


  //   useEffect(() => {
  //     if (user && connectionService) {
  //         connectionService.newNotification = (notify) => {
  //             // searchNotifications();
  //             connectionService.newNotification = (notify) => {
  //               // searchNotifications();
  //               setNotifications((prev) => [notify,...prev]);
  //           }
  //         }
  //     }
  // }, [user,connectionService]);

  const markNotificationAsSeen = async (notificationId) => {
    const req = new StringIntReq();
    setNotifications(
      (prev) => prev.filter((notification) => notification.id !== notificationId)
    )
    req.int = notificationId;
    const res = await UsersApi.MarkNotificationAsSeen(req);
    if (res?.status?.success) {
      // searchNotifications();
      console.log("Notification marked as seen");
    }
  }

  const bulkMarkNotificationsAsSeen = async () => {
    const req = new BulkMarkNotificationsAsSeenReq();
    req.notificationIds = notifications.filter(notification => !notification.seen).map(notification => notification.id);
    setNotifications(
      (prev) => prev.map((notification) => {
        if (req.notificationIds.includes(notification.id)) {
          return { ...notification, seen: true }
        }
        return notification;
      })
    )
    const res = await UsersApi.BulkMarkNotificationsAsSeen(req);
    if (res?.status?.success) {
      // searchNotifications();
      console.log("All notifications marked as seen");

    }
  }




  const deleteNotifications = async () => {
    const req = new BulkMarkNotificationsAsSeenReq();
    req.notificationIds = notifications.map(notification => notification.id);
    setNotifications([]);
    const res = await UsersApi.DeleteNotifications(req);
    if (res?.status?.success) {
      // searchNotifications();
      console.log("All notifications deleted");
    }
  }



  const openMap = async (notification) => {
    if (notification.fiberHouse) {
      navigate('/map', {
        state: { fiberHouse: notification.fiberHouse }
      })
    }

  }

  const generateICalEvent = (appointmentDateTime) => {
    const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
    const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
    const icsContent = `
    BEGIN:VCALENDAR
    VERSION:2.0
    BEGIN:VEVENT
    SUMMARY:Consultation
    DTSTART:${start}
    DTEND:${end}
    DESCRIPTION:Appointment Details
    LOCATION:Appointment Location
    END:VEVENT
    END:VCALENDAR
`;

    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'appointment.ics';
    link.click();

    Swal.fire('iCalendar Event Generated!');
  };

  const addGoogleCalendarEvent = (appointmentDateTime) => {
    const base = 'https://calendar.google.com/calendar/r/eventedit?';

    const startTime = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');

    // Creating URL parameters
    const params = new URLSearchParams({
      text: `Consultation`,
      dates: `${startTime}/${endTime}`,
    });


    return `${base}${params.toString()}`;
  };


  const handleAddToCalendar = (appointmentDateTime) => {
    const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
    window.open(googleCalendarUrl, '_blank', 'noopener,noreferrer');
  };


  const promptAddToCalendar = async (appointmentDateTime) => {
    const { value } = await Swal.fire({
      title: 'Add Appointment to Calendar',
      text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Google Calendar',
      denyButtonText: 'iCalendar',
      cancelButtonText: 'No, thanks',
    });

    if (value === true) {
      // User chose Google Calendar
      handleAddToCalendar(appointmentDateTime);
    } else if (value === false) {
      // User chose iCalendar
      generateICalEvent(appointmentDateTime);
    }
  };

  const changeOwnerShipOfTheConsultation = async (notification) => {

    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Processing your request",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    })
    const req = new ManyToManyReq();
    req.id1 = notification.consultationId;
    req.id2 = user.id;
    const res = await CustomerApi.ChangeOwnershipOfConsultation(req);
    if (res?.status?.success) {
      console.log("Ownership Changed Successfully")
      setNotifications(
        (prev) => prev.map((not) => {
          if (not.id === notification.id) {
            return { ...not, consultation: { ...res.data } }
          }
          return notification;
        })
      )

      promptAddToCalendar(res.data.consultationTime);
    }
  }

  const profileMenu = [
    {
      title: "Settings",
      icon: (color) => <Settings fontSize='medium' sx={{ color: color + " !important" }} />,
      onClick: () => {
        navigate("/edit-account-details")
      }
    },
    {
      title: "Logout",
      icon: (color) => <LogOutIcon fontSize='medium' sx={{ color: color + " !important" }} />,
      onClick: () => {
        if (connectionService && typeof connectionService.StopConnection === "function") {
          console.log("Stopped Notificaiton Connection Service")
          connectionService.StopConnection();
        }
        localStorage.removeItem("searchFilters");
        localStorage.removeItem("contactCenterLeadsFilter")
        getAuth().signOut()
        navigate("/")
        window.location.reload();
      }
    },

  ]

  return (
    <>
      <div style={{ boxShadow: "none", borderBottom: "1px solid #ddd", backgroundColor: "var(--bg-grey)" }} >
        <ContextMenu menuItems={profileMenu} anchorEl={anchorEl} handleClose={() => {
          setAnchorEl(null)
        }} setAnchorEl={setAnchorEl} />
        <div style={{
          display: "flex", justifyContent: "space-between", width: "100%", minHeight: "65px",
          alignItems: "center", padding: "0 20px", backgroundColor: "var(--bg-grey)"
        }} >


          {/* Left Side */}

          <div className=" flex items-center sm:gap-4 gap-0">


            <IconButton edge="start" color="inherit" aria-label="menu" className="sm:mr-2 mr-0"
              onClick={() => setShowSideBar(!showSideBar)}
              sx={{
                outline: "none !important",
                textDecoration: "none",
                border: "none",
                color: showSideBar ? "var(--primary-color)" : "var(--text-color)",
                // zIndex: "100",




              }}
            >
              <MenuIcon />

            </IconButton>
            <img onClick={() => { navigate('/') }} src={Logo} alt="Logo" className="FullLogo cursor-pointer" />
            <img onClick={() => { navigate('/') }} src={SMlogo} alt="SMlogo" className="smlogo cursor-pointer" />
          </div>

          <Box className="searchBox"
            component="form"

          >
            <input
              type="text"
              placeholder="Search Leads/Customers/Features..."
              style={{
                border: "none",
                outline: "none",
                background: "none",
                width: "100%",
                marginLeft: "8px",
              }}
            />
            <img src={Search} alt="Search" style={{ width: "18px", height: "18px", cursor: "pointer" }} />


          </Box>

          {/* <div
            className="absolute left-0 top-full w-full bg-white px-4 py-3 lg:static lg:block lg:w-auto lg:bg-transparent lg:p-0"
            style={{ display: isSearchOpen ? "block" : "none" }}
            
            

          >
            <div className="relative lg:w-[400px]">
              <img
                src={Search}
                alt="Search"
                className="absolute left-3 top-1/2 transform -translate-y-1/2"
              />
              <input
                placeholder="Search Leads/Customers/Features..."
                className="pl-8"
              />
            </div>
          </div> */}


          <div

            className="SmSearch"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >

            <img src={Search} alt="Search" style={{ width: "20px", height: "20px" }} />

          </div>


          {/* Middle */}
          <FormControl className="showAllModes">
            {/* <InputLabel id="mode-select-label">Show All Modes</InputLabel> */}
            <Select

            >
              <MuiMenu selected value="mode1">Show all modes</MuiMenu>
              <MuiMenu value="mode2">Mode 2</MuiMenu>
              <MuiMenu value="mode3">Mode 3</MuiMenu>
            </Select>
          </FormControl>

          {/* Right Side */}

          <div className=" flex items-center gap-0 sm:gap-4">
            <img style={{
              visibility: "hidden"
            }} className="NewNavMap"
              src={Map} alt="Map" />

            <IconButton
              id="not-btn"
              sx={{
                position: "static",
                padding: "0",
                outline: "none !important"
              }}
            >
              <Badge onClick={(e) => {
                // e.stopPropagation();
                // e.preventDefault();
                setNotificationPanel(!notificationPanel);
                // if (notificationPanel) {
                bulkMarkNotificationsAsSeen();
              }}
             
                badgeContent={notifications.filter(n => !n.seen).length} 
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: 'var(--primary-color)',
                    color: 'white !important', 
                  },
                }}
             
                >


                <img className="NewNotify" src={Notification} alt="Notification" style={{ width: "30px", height: "30px", outline: "none !important" }} />
              </Badge>
              {
                notificationPanel &&
                <ClickAwayListener onClickAway={(e) => {
                  let isChild = document.getElementById('not-btn').contains(e.target);
                  if (!isChild) {
                    console.log(e.target)
                    setNotificationPanel(false)
                  }
                }}>
                  <div className="notification_panel">
                    <div style={{ padding: "5px 0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <h1 style={{
                        color: "var(--border-color)",
                        fontSize: "1rem",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        marginLeft:'15px'

                      }}>Notifications</h1>

                      <p style={{ color: "var(--border-color)", }}
                        onClick={() => setNotificationPanel(false)}
                      >
                        x
                      </p>


                      {/* <button style={{
                        backgroundColor: notifications?.length > 0 ? "#ff2d2d" : "#ccc",
                        color: "white",
                        fontSize: "0.8rem",
                        padding: "0.5rem 1rem",
                        borderRadius: "10px",
                      }}
                        disabled={notifications?.length === 0}
                        onClick={() => {
                          deleteNotifications();
                        }}>Delete All</button> */}

                    </div>
                    <div className="notification_panel_inner">
                      {
                        notifications?.length > 0 ?
                          notifications.map((notification, index) => {
                            return (
                              <div key={index} className="notification"
                              style={{
                                border: !notification.seen? "1px solid var(--light-border)" : "none",
                                // border: "1px solid var(--light-border)",
                              }}
                              >

                                <p style={{
                                  color: "var(--black-color)",
                                  fontSize: "10px",
                                  fontWeight: '600'

                                }}>{notification.message}
                                  {/* {

                                    notification.type !== "Consultation" && notification.fiberHouse && route[0] !== "map" && <span onClick={() => { openMap(notification) }} style={{
                                      color: "#2761D0",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      fontWeight: "bold"
                                    }}>Open on Map</span>}
                                  {
                                    notification.type === "Consultation" && notification?.consultation?.salesRepId == null &&
                                    <span onClick={() => {
                                      changeOwnerShipOfTheConsultation(notification)
                                    }} className="text-[#2761D0] cursor-pointer underline font-bold">Accept</span>
                                  } */}
                                </p>

                                {/* {
                                  !notification.seen &&
                                  <GoDotFill size={30} color="red" />
                                } */}
                                <p className="date" 
                                  style={{
                                    color: !notification.seen? " var(--primary-color)" : "var(--border-color)",
                                    // color: "var(--primary-color)",

                                  }}
                                >
                                  {
                                    // if timestamp is today, show time only else show date
                                    new Date(notification.timeStamp).toDateString() === new Date().toDateString() ?
                                      new Date(notification.timeStamp).toLocaleTimeString() :
                                      new Date(notification.timeStamp).toDateString()
                                  }
                                </p>
                              </div>
                            )
                          })
                          :
                          <div className="notification">
                            <p>No Notifications</p>
                          </div>
                      }
                    </div>
                  </div>
                </ClickAwayListener>
              }
            </IconButton>


            <div onClick={
              (e) => {
                setAnchorEl(e.currentTarget);
              }} className="NameAndRole"
              style={{
                display: "flex",
                alignItems: "center",
                // marginRight: "2rem",
                backgroundColor: "var(--bg-light)",
                cursor: "pointer",
                borderRadius: "20px",
                gap: "8px",
                height: "45px",

              }}>
              <div className="NewName" style={{ display: "flex", flexDirection: "column", gap: "0" }}>
                <Typography variant="subtitle2" sx={{
                  color: "var(--text-color)",


                }}>
                  {user?.firstName + " " + user?.lastName?.charAt(0) + "."}
                </Typography>

                <Typography component="span" variant="caption" sx={{
                  color: "var(--primary-color)",

                }}>
                  {user?.userType}
                </Typography>

              </div>
              <div className="user_profile relative" >
                <img className="ProPhoto" alt="Avatar" src={user?.profileImage ? user?.profileImage?.url : anonUser}
                  style={{
                    width: 35,
                    height: 35,
                    borderRadius: "9999px"
                  }}
                />
                <div className="absolute top-[60%] left-[60%] border-2 border-[#E4E4E4] flex justify-center items-center p-[0.5px] bg-white rounded-full">
                  <IoMdArrowDropdown style={{ color: "var(--color-icon)" }} fontSize="15" />
                </div>

              </div>

            </div>

            {/* <IconButton style={{
              padding: "0"
            }} onClick={() => navigate('/edit-account-details')} className="NewSetting" edge="end" color="inherit">
              <img src={Settings} alt="Settings" style={{ width: "20px", height: "20px" }} />
            </IconButton> */}
          </div>






        </div>
      </div>


      {

        <div style={{
          height: "48px",
          display: "flex",
          backgroundColor: "var(--bg-light)",
          alignItems: "center",
          padding: "0 20px",
          // position: "fixed",
          // top: "65px",
          width: "100%",
          zIndex: "100"
        }} className="BreadCrumbDiv">

          <Breadcrumbs light={document.body.classList.contains('dark')} icon={<HomeOutlinedIcon />} title={route[route.length - 1]} route={route} />

        </div>
      }



      <div className="w-full MainDiv p-0 sm:h-[calc(100vh-125px)] h-[calc(100vh-70px)] sm:p-2 sm:pt-4 sm:mt-2 mt-0" style={{
        overflow: "auto"

      }}>

        <div className="SideBarMain sm:py-4 py-0">
          {children}
        </div>
      </div>


     


      {
        showSideBar &&
        <div style={{
          display: 'flex', backgroundImage: `url("/images/banner/background.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover",
          transition: "all 0.5s",
        }}>
          {menuItems.length > 0 && (
            <div className="flex absolute  left-0 justify-center items-center " style={{
              top: "114px",
              // backgroundColor: "red",
              borderTopRightRadius: "25px",
            }}>
              <ProSidebar
                rootStyles={{
                  height: "calc(100vh - 114px)",
                  paddingTop: "1rem",
                  backgroundColor: "var(--bg-light) !important",
                  zIndex: "2",
                  border: ".5px solid var(--border-color) ",
                  scrollbarWidth: "none !important",
                  scrollbarColor: "transparent transparent",
                  borderTopRightRadius: "25px",
                  width: "265px",
                  // position: "absolute",
                  // top: "0",
                  // left: "0",

                }}
                onBackdropClick={() => setToggled(false)}
                onBreakPoint={(e) => { setBreakPoint(e); }}
                toggled={toggled}
                // breakPoint="md"

                collapsed={isCollapsed}
              >
                <Menu
                  menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      return {
                        // backgroundColor: active ? 'var(--color-icon-hover) !important' : undefined,
                        color: active ? 'var(--primary-color)' : 'var(--text-color)',
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        '&:hover': {
                          // backgroundColor: '#181E4B',
                          // color: '#FFFFFF'
                          color: 'var(--primary-color)',
                        }
                      };
                    },
                  }}
                >
                  {menuItems.map((item) => (
                    item.groupName ? (
                      <SubMenu
                        key={item.groupName}
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {item.icon && item.icon('currentColor', 20)}
                            {!isCollapsed && <Typography sx={{ ml: 1, fontSize: "0.9rem", textTransform:"uppercase" }}>{item.groupName}</Typography>}
                          </Box>
                        }
                      >
                        {item.items.map((subItem) => (
                          <Item
                            key={subItem.name}
                            title={subItem.name}
                            to={subItem.route}
                            icon={(color) => subItem.icon(color, 20)}
                            selected={selected}
                            setSelected={setSelected}
                            active={activeMenuItem?.name === subItem.name}
                          />
                        ))}
                      </SubMenu>
                    ) : (
                      <Item
                        key={item.name}
                        title={item.name}
                        to={item.route}
                        icon={(color) => item.icon(color, 20)}
                        selected={selected}
                        setSelected={setSelected}
                        active={activeMenuItem?.name === item.name}
                      />
                    )
                  ))}

                  <MenuItem
                    rootStyles={{
                      ['.' + menuClasses.button]: {
                        color: 'var(--text-color)',
                        fontSize: '0.9rem',
                        marginBottom: "5rem",
                        '&:hover': {
                          // backgroundColor: 'var(--color-icon-hover) !important',
                          color: 'var(--primary-color)',
                        },
                        '&:active': {
                          // backgroundColor: 'var(--color-icon-hover) !important',
                          color: 'var(--primary-color)',
                        },
                      },
                    }}
                    // active={active}
                    // component={<Link to={to} />}
                    icon={<IoIosLogOut size={20} sx={{ color: "var(--primary-color) !important" }} />}
                    onClick={() => {
                      if (connectionService && typeof connectionService.StopConnection === "function") {
                        console.log("Stopped Notificaiton Connection Service")
                        try {
                          connectionService.StopConnection();

                        }
                        catch (e) {
                          console.log(e)
                        }

                      }
                      localStorage.removeItem("searchFilters");
                      localStorage.removeItem("contactCenterLeadsFilter")
                      getAuth().signOut();
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                      Logout
                    </div>

                  </MenuItem>

                </Menu>
              </ProSidebar>
              <div onClick={handleArrowClick} style={{
                right: "-15px", zIndex: "3", borderColor: "var(--border-color)",
                cursor: "pointer", top: "4rem", backgroundColor: " var(--bg-light)", color: "var(--primary-color) !important", borderRadius: "10px"
              }} className="border-2 absolute p-1">
                {isCollapsed ? <ArrowRight fontSize="medium" sx={{ color: "var(--primary-color) !important" }} /> : <ArrowLeft sx={{ color: "var(--primary-color) !important" }} fontSize="medium" />}
              </div>
            </div>
          )}

        </div>

      }


    </>
  );
};

export default Sidebar;
