import React, { useState } from 'react';
import { Button, Toolbar, AppBar, IconButton, Typography, Box, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Add as AddIcon, CheckBox as CheckBoxIcon, RadioButtonChecked as RadioButtonCheckedIcon, ShortText as ShortTextIcon } from '@mui/icons-material';

const ModuleQuiz = ({ setQuizModalOpen }) => {
  const [quizElements, setQuizElements] = useState([]);

  const addElement = (type) => {
    setQuizElements([...quizElements, { type, id: quizElements.length, text: "Im onto something" }]);
  };

  const renderElement = (element) => {
    console.log(element);
    switch (element.type) {
      case 'checkbox':
        return <ListItem key={element.id}><CheckBoxIcon /><ListItemText primary={element.text} /></ListItem>;
      case 'radio':
        return <ListItem key={element.id}><RadioButtonCheckedIcon /><ListItemText primary={element.text} /></ListItem>;
      case 'text':
        return <ListItem key={element.id}><ShortTextIcon /><ListItemText primary={element.text} /></ListItem>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection:'column', height: '100vh' }} className="bg-white overflow-y-auto w-full flex-1 p-4 shadow-lg rounded-lg h-full transition-all duration-300 flex flex-col justify-between
    ">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Quiz Builder
          </Typography>
          <IconButton color="inherit" onClick={() => addElement('checkbox')}>
            <CheckBoxIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => addElement('radio')}>
            <RadioButtonCheckedIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => addElement('text')}>
            <ShortTextIcon />
          </IconButton>
          <Button color="inherit" onClick={() => setQuizModalOpen(false)}>Close</Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Paper sx={{ p: 2 }}>
          <List>
            {quizElements.map(renderElement)}
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default ModuleQuiz;
// import React, { useState } from 'react';
// import FormBuilder from "editorjs-form-builder";
// import { Button } from '@mui/material';

// const ModuleQuiz = ({ setQuizModalOpen }) => {
// const onChange = (data) => {
//   console.log(data); // 
// };

// const initialData = [
//     {
//         "id": "8Z7TsKEv7e",
//         "type": "textQuestion",
//         "data": {
//             "label": "First Name",
//             "help": "",
//             "placeholder": "Eg Uche",
//             "required": true
//         }
//     },
//     {
//         "id": "pCtsEP685y",
//         "type": "multipleChoiceQuestion",
//         "data": {
//             "label": "Countries",
//             "help": "",
//             "required": false,
//             "multipleChoice": false,
//             "options": [
//                 {
//                     "id": "d077745e-7cb3-4aaa-a9f6-bd8f60f5578a",
//                     "label": "Niger"
//                 },
//                 {
//                     "id": "722c49bb-1a03-4ae8-b332-2a84c1bcb112",
//                     "label": "Ghana"
//                 },
//                 {
//                     "id": "874a231a-300e-4035-b435-234b502e7523",
//                     "label": "Liberia"
//                 }
//             ]
//         }
//     },
//     {
//         "id": "7ZcdGLmOCO",
//         "type": "emailQuestion",
//         "data": {
//             "label": "Email",
//             "help": "",
//             "placeholder": "your email",
//             "required": true
//         }
//     },
//     {
//         "id": "hcuBk3QFKb",
//         "type": "numberQuestion",
//         "data": {
//             "label": "Age",
//             "help": "",
//             "placeholder": "",
//             "required": true
//         }
//     }
// ]

// return (
//   <div>
//     <Button onClick={() => setQuizModalOpen(false)}>Close</Button>
//     <FormBuilder onChange={onChange} initialData={initialData} />
//   </div>
// );
// }

// export default ModuleQuiz;