import React, { useCallback, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FaGripVertical } from 'react-icons/fa';
import { FaX } from 'react-icons/fa6';




const DraggableColumn = ({ header, index, isVisible, toggleVisibility, moveColumn }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'column',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveColumn(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`flex items-center justify-between p-3 border-b border-gray-200 ${
        isDragging ? 'opacity-50 bg-gray-100' : 'bg-white'
      } transition-colors duration-200 ease-in-out hover:bg-gray-50`}
    >
      <div className="flex items-center space-x-3">
        <FaGripVertical className="text-gray-400 cursor-move" size={20} />
        <label className="flex items-center space-x-2 cursor-pointer">
          <input
            type="checkbox"
            checked={isVisible}
            onChange={toggleVisibility}
            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
          />
          <span className="text-sm font-medium text-gray-700">{header.label}</span>
        </label>
      </div>
    </div>
  );
};

export const ColumnManager = ({
  headers,
  visibleColumns,
  onApplyChanges,
  onClose,
}) => {
  const [tempHeaders, setTempHeaders] = useState(headers);
  const [tempVisibleColumns, setTempVisibleColumns] = useState(new Set(visibleColumns));

  const moveColumn = useCallback(
    (dragIndex, hoverIndex) => {
      const newHeaders = [...tempHeaders];
      const [reorderedItem] = newHeaders.splice(dragIndex, 1);
      newHeaders.splice(hoverIndex, 0, reorderedItem);
      setTempHeaders(newHeaders);
    },
    [tempHeaders]
  );

  const toggleColumnVisibility = useCallback((columnKey) => {
    setTempVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(columnKey)) {
        newSet.delete(columnKey);
      } else {
        newSet.add(columnKey);
      }
      return newSet;
    });
  }, []);

  const handleApplyChanges = () => {
    onApplyChanges(tempHeaders, tempVisibleColumns);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl w-96 max-h-[80vh] flex flex-col">
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Manage Columns</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200 ease-in-out"
          >
            <FaX size={24} />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto px-6 py-4">
          <p className="text-sm text-gray-600 mb-4">
            Drag and drop to reorder columns. Use checkboxes to show or hide columns.
          </p>
          <div className="space-y-1">
            {tempHeaders.map((header, index) => (
              <DraggableColumn
                key={header.key}
                header={header}
                index={index}
                isVisible={tempVisibleColumns.has(header.key)}
                toggleVisibility={() => toggleColumnVisibility(header.key)}
                moveColumn={moveColumn}
              />
            ))}
          </div>
        </div>
        <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors duration-200 ease-in-out"
          >
            Cancel
          </button>
          <button
            onClick={handleApplyChanges}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out"
          >
            Apply Changes
          </button>
        </div>
      </div>
    </div>
  );
};
