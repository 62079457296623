class CreateSellingCardReq {
  constructor() {
    this.name = null;
    this.subTrainingModuleId = null;
    this.description = null;
    this.salesOrgId = null;
    this.type = null;
    this.files = [

    ];
  }
}



export default CreateSellingCardReq