import React, { useState, useRef, useEffect } from 'react';
import { Edit, Delete, Add, CloudUpload, SentimentDissatisfied, Close, VisibilityOffOutlined, Visibility } from '@mui/icons-material';
import Layout from '../../../Layouts/Layout';
import VersatileLoader from '../../../components/VersatileLoader';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { uploadFileToFirebase } from '../../../Services/DisplayFile';
import StringIntReq from '../../../Requests/StringIntReq';
import './ManageRecruitTemplates.scss';
import { set } from 'ol/transform';


const RecruitManagement = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [editedText, setEditedText] = useState(null);
    const [editedName, setEditedName] = useState(null);
    const [editedImage, setEditedImage] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    // New fields
    const [positionTitle, setPositionTitle] = useState(null);
    const [recruitOrCompany, setRecruitOrCompany] = useState('');
    const [jobDescription, setJobDescription] = useState(null);
    const [jobRequirements, setJobRequirements] = useState(null);
    const [subject, setSubject] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);

    useEffect(() => {
        fetchRecruitTemplates();
    }, []);

    const fetchRecruitTemplates = async () => {
        try {
            const response = await FiberCompanyApi.GetRecruitTemplates();
            if (response.status.success) {
                setTemplates(response.data);
                setIsLoaded(true);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.status.message
                });
                setIsLoaded(true);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
            setIsLoaded(true);
        }
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setIsEditing(false);
        setIsAdding(false);
        setEditedText(template.templateText);
        setEditedName(template.templateName);
        console.log(template.templateImage);
        setEditedImage(template.templateImage.url);
        setPositionTitle(template.positionTitle || '');
        console.log(template.recruitOrCompany);
        setRecruitOrCompany(template.candidateType || '');
        setJobDescription(template.jobDescription || '');
        setJobRequirements(template.jobRequirements ? template.jobRequirements.split('\n') : ['']);
        setSubject(template.subject || '');
        setCustomMessage(template.content || '');
    };

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleUpdate = async () => {
        try {
            Swal.fire({
                title: "Updating Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let cloudFile = null;

            if (selectedFile) {
                const uniqueId = uuidv4();
                cloudFile = await uploadFileToFirebase(
                    selectedFile,
                    `recruitTemplates/${uniqueId}`
                );
            }

            console.log(editedName, editedText, positionTitle, recruitOrCompany, jobDescription, jobRequirements, cloudFile);
            if (recruitOrCompany === 'Recruit' && (!positionTitle || !recruitOrCompany || !jobDescription || jobRequirements.length === 0 || !editedName)) {
                Swal.fire({
                    title: "Error Updating Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true
                });
                return;
            }

            if (recruitOrCompany === 'Company' && (!editedText || !editedName)) {
                Swal.fire({
                    title: "Error Updating Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            const updatedTemplateReq = {
                id: selectedTemplate.id,
                name: editedName,
                text: editedText,
                positionTitle: positionTitle,
                candidateType: recruitOrCompany,
                jobDescription: jobDescription,
                jobRequirements: jobRequirements.join('\n'),
                templateImage: cloudFile ? {
                    Name: cloudFile.name,
                    Url: cloudFile.url,
                    Path: cloudFile.path,
                    Extension: cloudFile.extension,
                    FileSize: cloudFile.fileSize,
                    FileType: cloudFile.fileType,
                    Caption: "Template Image"
                } : selectedTemplate.templateImage,
                customMessage: customMessage, 
                subject: subject
            };
            console.log(updatedTemplateReq);
            const response = await FiberCompanyApi.UpdateRecruitTemplate(updatedTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Updated Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchRecruitTemplates();

                handleTemplateSelect({
                    id: response.data.id, // Ensure you use the updated ID
                    templateName: response.data.name, // Updated name
                    templateText: response.data.text, // Updated text
                    templateImage: response.data.templateImage, // Updated image
                    positionTitle: response.data.positionTitle, // Updated position title
                    candidateType: response.data.candidateType, // Updated type
                    jobDescription: response.data.jobDescription, // Updated description
                    jobRequirements: response.data.jobRequirements, // Updated requirements (split back into array)
                    isVisible: response.data.isVisible,
                });
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Updating Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Updating Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const handleDelete = async () => {
        try {
            Swal.fire({
                title: "Deleting Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let req = new StringIntReq()
            req.int = selectedTemplate.id
            let response = await FiberCompanyApi.DeleteRecruitTemplate(req);
            if (response.status.success) {
                Swal.fire({
                    title: "Template Deleted Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchRecruitTemplates();
                setSelectedTemplate(null);
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Deleting Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error Deleting Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const handleVisibility = async () => {
        try {
            Swal.fire({
                title: "Updating Visibility",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const updatedTemplateReq = {
                id: selectedTemplate.id,
                isVisible: !selectedTemplate.isVisible,
            };

            const response = await FiberCompanyApi.UpdateRecruitTemplateVisibility(updatedTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Updated Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchRecruitTemplates();

                handleTemplateSelect({
                    id: response.data.id, // Ensure you use the updated ID
                    templateName: response.data.name, // Updated name
                    templateText: response.data.text, // Updated text
                    templateImage: response.data.templateImage, // Updated image
                    positionTitle: response.data.positionTitle, // Updated position title
                    candidateType: response.data.candidateType, // Updated type
                    jobDescription: response.data.jobDescription, // Updated description
                    jobRequirements: response.data.jobRequirements, // Updated requirements (split back into array)
                    isVisible: response.data.isVisible
                });
                setSelectedFile(null);
            } else {
                Swal.fire({
                    title: "Error Updating Template",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Deleting Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
        ;
    }

    const handleAddTemplate = () => {
        setIsAdding(true);
        setIsEditing(true);
        setSelectedTemplate(null);
        setEditedText(null);
        setEditedImage('/placeholder.svg?height=300&width=400');
        setNewTemplateName(null);
        setSelectedFile(null);
        setPositionTitle(null);
        setRecruitOrCompany('');
        setJobDescription(null);
        setJobRequirements(null);
    };

    const handleSaveNewTemplate = async () => {
        try {
            Swal.fire({
                title: "Adding New Template",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            let cloudFile = null;

            if (selectedFile) {
                const uniqueId = uuidv4();
                cloudFile = await uploadFileToFirebase(
                    selectedFile,
                    `recruitTemplates/${uniqueId}`
                );
            }

            console.log(newTemplateName, editedText, positionTitle, recruitOrCompany, jobDescription, jobRequirements, cloudFile);
            if (recruitOrCompany === "Recruit" && (!newTemplateName || !positionTitle || !recruitOrCompany || !jobDescription || jobRequirements.length === 0 || !cloudFile)) {
                console.log(newTemplateName, editedText, positionTitle, recruitOrCompany, jobDescription, jobRequirements, cloudFile);
                Swal.fire({
                    title: "Error Saving Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            if (recruitOrCompany === "Company" && (!newTemplateName || !editedText || !cloudFile)) {
                Swal.fire({
                    title: "Error Saving Template",
                    text: "All fields are required",
                    icon: "error",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            const newTemplateReq = {
                name: newTemplateName,
                text: editedText,
                positionTitle: positionTitle,
                candidateType: recruitOrCompany,
                jobDescription: jobDescription,
                jobRequirements: jobRequirements.join('\n'),
                templateImage: {
                    Name: cloudFile.name,
                    Url: cloudFile.url,
                    Path: cloudFile.path,
                    Extension: cloudFile.extension,
                    FileSize: cloudFile.fileSize,
                    FileType: cloudFile.fileType,
                    Caption: "Template Image"
                },
                customMessage: customMessage,
                subject: subject
            };
            console.log(newTemplateReq);
            const response = await FiberCompanyApi.AddRecruitTemplate(newTemplateReq);

            if (response.status.success) {
                Swal.fire({
                    title: "Template Added Successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchRecruitTemplates();
                handleTemplateSelect({
                    id: response.data.id,
                    templateName: newTemplateName,
                    templateText: editedText,
                    templateImage: cloudFile,
                    positionTitle: positionTitle,
                    candidateType: recruitOrCompany,
                    jobDescription: jobDescription,
                    jobRequirements: jobRequirements.join('\n'),
                    content: customMessage,
                    subject: subject,
                });

            } else {
                Swal.fire({
                    title: "Error Saving Templatee",
                    text: response.status.message,
                    icon: "error",
                    showConfirmButton: true
                });
            }

            setIsAdding(false);
            setIsEditing(false);
        } catch (error) {
            Swal.fire({
                title: "Error Saving Template",
                text: error.message,
                icon: "error",
                showConfirmButton: true
            });
        }
    };

    const triggerImageUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setEditedImage(URL.createObjectURL(file));
        }
    };

    const handleAddRequirement = () => {
        setJobRequirements([...jobRequirements, '']);
    };

    const handleRequirementChange = (index, value) => {
        const updatedRequirements = [...jobRequirements];
        updatedRequirements[index] = value;
        setJobRequirements(updatedRequirements);
    };

    const handleCandidateTypeChange = (e) => {
        setPositionTitle(null);
        setJobDescription(null);
        setJobRequirements([]);
        setEditedText(null);
        setRecruitOrCompany(e.target.value);
    };

    const handleDeleteRequirement = (index) => {
        const updatedRequirements = jobRequirements.filter((_, i) => i !== index);
        setJobRequirements(updatedRequirements);
    };

    if (!isLoaded) {
        return (
            <Layout>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%"
                }}>
                    <VersatileLoader size='large' color='#2761CF' />
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="manage-recruit-templates">
                <h1 className="page-header">Manage Recruitment Templates</h1>
                <div className="template-content">
                    <div className="template-list-container">
                        <div className="template-list">
                            {templates.length === 0 ? (
                                <div style={{ alignContent: "center", textAlign: "center" }}>
                                    <SentimentDissatisfied style={{ width: "2rem", height: "2rem" }} />
                                    <h5>No templates available</h5>
                                </div>
                            ) : (
                                templates.map(template => (
                                    <div
                                        key={template.id}
                                        className={`template-item ${selectedTemplate?.id === template.id ? 'active' : ''}`}
                                        onClick={() => handleTemplateSelect(template)}
                                    >
                                        {template.templateName}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="template-item add-template" onClick={handleAddTemplate}>
                            <Add /> Add Template
                        </div>
                    </div>
                    <div className="template-details">
                        {(selectedTemplate || isAdding) && (
                            <>
                                <div className="template-actions">
                                    {!isAdding && (
                                        <>
                                            <Edit onClick={handleEdit} />
                                            {selectedTemplate.isVisible ? (
                                                <VisibilityOffOutlined onClick={handleVisibility} />
                                            ) : (
                                                <Visibility onClick={handleVisibility} />
                                            )}
                                        </>
                                    )}
                                </div>
                                {isAdding ? (
                                    <input
                                        type="text"
                                        value={newTemplateName}
                                        onChange={(e) => setNewTemplateName(e.target.value)}
                                        placeholder="Enter template name"
                                        className="template-name-input"
                                    />
                                ) : (
                                    <>
                                        <label>Template Name</label>
                                        <input
                                            type="text"
                                            value={isEditing ? editedName : selectedTemplate?.templateName}
                                            onChange={(e) => setEditedName(e.target.value)}
                                            placeholder="Enter template name"
                                            disabled={!isEditing}
                                        />
                                    </>
                                )}


                                <label>Recruit or Company</label>
                                <select
                                    value={recruitOrCompany}
                                    onChange={(e) => handleCandidateTypeChange(e)}
                                    disabled={!isAdding}
                                    className="template-name-input"
                                >
                                    <option value="">Select type</option>
                                    <option value="Recruit">Recruit</option>
                                    <option value="Company">Company</option>
                                </select>

                                {recruitOrCompany === 'Recruit' && (
                                    <>
                                        <label>Position Title</label>
                                        <input
                                            type="text"
                                            value={positionTitle}
                                            onChange={(e) => setPositionTitle(e.target.value)}
                                            placeholder="Enter position title"
                                            disabled={!isEditing && !isAdding}
                                        />
                                    </>
                                )}

                                {recruitOrCompany === 'Recruit' && (
                                    <>
                                        <label>Job Description</label>
                                        <textarea
                                            value={jobDescription}
                                            onChange={(e) => setJobDescription(e.target.value)}
                                            placeholder="Enter job description"
                                            disabled={!isEditing && !isAdding}
                                            className="template-name-input"
                                        />
                                    </>
                                )}



                                {recruitOrCompany === 'Recruit' && (
                                    <>
                                        <label>Job Requirements</label>
                                        {jobRequirements.map((req, index) => (
                                            <div key={index} className="requirement-input">
                                                <input
                                                    type="text"
                                                    value={req}
                                                    onChange={(e) => handleRequirementChange(index, e.target.value)}
                                                    placeholder={`Requirement ${index + 1}`}
                                                    disabled={!isEditing && !isAdding}
                                                />
                                                {(isEditing || isAdding) && (
                                                    <button
                                                        onClick={() => handleDeleteRequirement(index)}
                                                        className="delete-requirement-btn"
                                                        aria-label="Delete requirement"
                                                    >
                                                        <Close />
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        {(isEditing || isAdding) && (
                                            <button onClick={handleAddRequirement} className="add-requirement-btn">
                                                <Add /> Add Requirement
                                            </button>
                                        )}
                                    </>
                                )}



                                {recruitOrCompany === 'Company' && (
                                    <>
                                        <label>Template Text</label>
                                        <textarea
                                            value={isEditing ? editedText : selectedTemplate?.templateText}
                                            onChange={(e) => setEditedText(e.target.value)}
                                            placeholder="Enter template text"
                                            disabled={!isEditing && !isAdding}
                                            className="template-name-input"
                                        />
                                    </>
                                )}

                                <label>Template Image</label>
                                <div className="image-upload">
                                    <img src={isEditing ? editedImage : selectedTemplate?.templateImage?.url} alt="Template Preview" />
                                    {(isEditing || isAdding) && (
                                        <button onClick={triggerImageUpload} className="upload-button">
                                            <CloudUpload />
                                            <span>{isAdding ? 'Upload Image' : 'Edit'}</span>
                                        </button>
                                    )}
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                {(
                                    <>
                                        <label>Message Subject</label>
                                        <input
                                            type="text"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            placeholder="Enter Mesage Subject"
                                            disabled={!isEditing && !isAdding}
                                        />
                                    </>
                                )}

                                {(
                                    <>
                                        <label>Message Content</label>
                                        <textarea
                                            value={customMessage}
                                            onChange={(e) => setCustomMessage(e.target.value)}
                                            placeholder="Enter Custom Message"
                                            disabled={!isEditing && !isAdding}
                                            className="template-name-input"
                                            rows={4}
                                        />
                                    </>
                                )
                                }


                                {(isEditing || isAdding) && (
                                    <button onClick={isAdding ? handleSaveNewTemplate : handleUpdate} className="save-button">
                                        {isAdding ? 'Add Template' : 'Update'}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default RecruitManagement;