import SignUp from "../Views/Public/SignUp";
import SignUpWithReferral from "../Views/Public/SignUpWithReferral/SignUpWithReferral";
import DigitalBusinessCenter from "../Views/Reps/DigitalBusinessCenter";
import SignIn from "./../Views/Public/SignIn"
import FiberTechnology from "../Views/Public/FiberTechnology";
import ReferralProgram from "../Views/Public/ReferralProgram";
import OurServices from "../Views/Public/OurServices";
import Home from "../Views/Public/Home";
import Opportubity from "../Views/Public/Opportunity";
import Offer from "../Views/Public/Offer";
import GenericOffer from "../Views/Public/GenericOffer";
import SummerSalesProgram from "../Views/Public/SummerSalesProgram";
import CustomerCancelForm from "../Views/Public/CancelForm";
import CustomerRescheduleForm from "../Views/Public/RescheduleForm";
import CreateBugTicket from "../Views/Public/BugTicket";
import FeedbackForm from "../Views/Public/FeedbackForm";
import NewOffer from "../Views/Public/NewOffer";
import FastwyreFullForm from "../Views/Public/FastwyreFullForm";
import LightCurveFullForm from "../Views/Public/LightCurveFullForm";
import RecruitInvitation from "../Views/Public/CandidateInvitation/Recruit";
import ConsultationForm from "../Views/Public/CandidateInvitation/Company";
import RecruitAcceptOffer from "../Views/Public/RecruitAcceptOffer";
const PublicRoutes = [
  {
    name: "LandingPage",
    key: "LandingPage",
    route: "/",
    component: <Home />,
  },
  {
    name: "LandingPage",
    key: "LandingPage",
    route: "*",
    component: <Home />,
  },
  {
    name: "SignIn",
    key: "sign-in",
    route: "/signin",
    component: <SignIn />,
  },
  {
    name: "SignUp",
    key: "SignUp",
    route: "/signup",
    component: <SignUp />,
  },
  {
    name: "SignUpWithReferral",
    key: "SignUpWithReferral",
    route: "/signup/:AffiliateCode",
    component: <SignUpWithReferral />,
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center/:id",
    component: <DigitalBusinessCenter />
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center/:id/:fiberCompany",
    component: <DigitalBusinessCenter />
  },
  {
    name:"Fiber Offer",
    key:"FiberOffer",
    route:"/fiber-offer/:id",
    component:<NewOffer />
  },
  {
    name: "Recruit Invitation",
    key: "RecruitInvitation",
    route: "/recruit-invitation/:id",
    component: <RecruitInvitation />
  },
  {
    name: "Consultation Form",
    key: "ConsultationForm",
    route: "/consultation-form/:id",
    component: <ConsultationForm />
  },
  {
    name: "Share Offer",
    key: "ShareOffer",
    route: "/share-offer/:id",
    component: <GenericOffer />
  },
  {
    name: "Fiber Technology",
    key: "FiberTechnology",
    route: "/fiber-technology",
    component: <FiberTechnology />
  },
  {
    name:"Referral Program",
    key:"ReferralProgram",
    route:"/referral-program",
    component:<ReferralProgram/>
  },
  {
    name:"Our Services",
    key:"OurServices",
    route:"/services",
    component:<OurServices/>
  },
  {
    name:"Opportunity",
    key:"Opportunity",
    route:"/opportunity/:form?",
    component:<Opportubity/>
  },
  {
    name:"Summer Sales Program",
    key:"SummerSalesProgram",
    route:"/summer-sales-program",
    component:<SummerSalesProgram/>
  },
  {
      name: "Customer Cancellation Form",
      key: "CustomerCancelForm",
      route: "/cancelationform/:phoneNumber?",
      component: <CustomerCancelForm />
  },
  {
      name: "Customer Reschedule Form",
      key: "CustomerRescheduleForm",
      route: "/rescheduleform/:phoneNumber?",
      component: <CustomerRescheduleForm />
  },
  {
      name:"Feedback Form",
      key:"FeedbackForm",
      route:"/feedbackform/:phoneNumber?",
      component: <FeedbackForm/>
  },
  {
      name:"New Offer Form",
      key:"NewOfferForm",
      route: "/new-offer-form/:id",
      component:<NewOffer />
  },
  {
    name:"Fastwyre Form",
    key:"FastwyreForm",
    route:"/fastwyre-form/:offerGuid",
    component:<FastwyreFullForm/>
  },
  {
    name :"LightCurve Form",
    key:"LightCurveForm",
    route:"/lightcurve-form/:offerGuid",
    component:<LightCurveFullForm/>
  },
  {
    name:"Recruit Accept Offer",
    key:"RecruitAcceptOffer",
    route:"/recruit-accept-offer/:id",
    component:<RecruitAcceptOffer/>
  }
]

export default PublicRoutes;