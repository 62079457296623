import { X } from '@mui/icons-material';
import React,{ useEffect } from 'react';
import { useTabs } from '../TabContext';
import MasterSheetApi from '../../../../API/MasterSheetApi';

export const HistoryPopup = ({ leadId, onClose,type }) => {

    const [history, setHistory] = React.useState([]);
    const { activeListId } = useTabs();   
    const getHistory = async () => {

        const req = {
            type : type,
            leadId : leadId,
            dynamicListId : activeListId
        }

        const res = await MasterSheetApi.SearchListLeadHistories(req)

        if (res.status.success) {
            setHistory(res.data)
        }
    }

    useEffect(() => {
        getHistory()
    }, [])

    return (
        <div className="absolute z-10 mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-50 px-4 py-3 border-b border-gray-200 flex justify-between items-center">
                <h3 className="text-lg font-semibold text-gray-800">Update History</h3>
                <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                    aria-label="Close history popup"
                >
                    <X size={20} />
                </button>
            </div>
            <div className="max-h-80 overflow-y-auto">
                {history.length === 0 ? (
                    <p className="p-4 text-gray-500 text-center">No update history available.</p>
                ) : (
                    <ul className="divide-y divide-gray-200">
                        {history.map((entry, index) => (
                            <li key={index} className="p-4 hover:bg-gray-50 transition-colors duration-150">
                                <div className="flex justify-between items-start mb-1">
                                    <span className="text-sm font-medium text-gray-900">{entry.user?.firstName + " " + entry?.user?.lastName}</span>
                                    <span className="text-xs text-gray-500">{new Date(entry.timestamp).toLocaleString()}</span>
                                </div>
                                <p className="text-sm text-gray-600 break-words">
                                    {Array.isArray(entry.description) ? entry.description.join(', ') : entry.description.toString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
