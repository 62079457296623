import RepMenuItems from "../MenuItems/RepMenuItems";
import ChatNew from "../Views/Shared/ChatNew";
import ContactCenter from "../Views/Shared/ContactCenter";
import Dashboard from "../Views/Shared/Dashboard";
import SellingCardDetails from "../Views/SuperAdmin/SellingCardDetails";
import DigitalBusinessCenter from "./../Views/Reps/DigitalBusinessCenter";
import CustomerSupport from "./../Views/Shared/CustomerSupport";
import EditAccountDetails from "./../Views/Shared/EditAccountDetails";
import ManageSellingCards from "./../Views/Shared/ManageSellingCards";
import SellingCardTutorial from "./../Views/Shared/SellingCardTutorial";
import MapPage from "./../Views/TeamAdmin/MapPage";
import CustomerFeedback from "./../Views/Reps/CustomerFeedback";
import LeaderBoard from "./../Views/Reps/LeaderBoard";
import ChatList from "../Views/Shared/ChatList";
import UserCommission from "../Views/Shared/UserCommission";
import CreateBugTicketForm from "../Views/Public/BugTicket";
import SellingCardDetailsNew from "../Views/Shared/ManageSellingCards/SellingCardDetails";
import SalesReportingPage from "../Views/Shared/SalesReport";
import SalesGeneralReport from "../Views/Shared/SalesGeneralReport";
import OffersReport from "../Views/Shared/OffersReport";
import CustomerDetails from "./../Views/SuperAdmin/CustomerDetails"
import SetReferalCompany from "../Views/Shared/SetReferalCompany";
import MyCalendar from "../Views/Shared/MyCalendar";
import Announcements from "../Views/Shared/Announcements";
// import SellingCardDetailsNew from "../Views/Shared/ManageSellingCards/SellingCardDetails";
const RepRoutes = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        component: <Dashboard routes={RepMenuItems} />,
    },
    {
        name: "Edit Account Details",
        key: "EditAccountDetails",
        route: "/edit-account-details",
        component: <EditAccountDetails />
    },
    {
        name: "Map",
        key: "map",
        route: "/map",
        component: <MapPage />
    },
    // {
    //     name: "Profile",
    //     key: "Profile",
    //     route: "/profile",
    //     component: <Profile />
    // },
    {
        name: "Customer Support",
        key: "CustomersSupport",
        route: "/customer-support",
        component: <CustomerSupport routes={RepMenuItems} />
    },
    {
        name: "Customer Details",
        key: "CustomerDetails",
        route: "/CustomerDetails/:id",
        component: <CustomerDetails />
      },
    {
        name: "Digigtal Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center/:id",
        component: <DigitalBusinessCenter />
    },
    {
        name: "Digigtal Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center/",
        component: <DigitalBusinessCenter />
    },
    {
        name: "Chat",
        key: "privatechatpage",
        route: '/chats',
        component: <ChatList menuItems={RepMenuItems} />
    },
    {
        name: "Manage Selling Cards",
        key: "ManageSellingCards",
        route: "/manage-training-modules",
        component: <ManageSellingCards />
    },
    {
        name: "Selling Card Tutorial",
        key: "SellingCardTutorial",
        route: "manage-training-modules/training-module-tutorial/:trainingModuleId/:trainingModuleName/:index",
        component: <SellingCardTutorial />
    },
    {
        name: "Edit Account Details",
        key: "EditAccountDetails",
        route: "/edit-account-details",
        component: <EditAccountDetails />
    },
    {
        name: "Selling Card Details",
        key: "sellingCardDetails",
        route: "/manageSellingCard/details",
        component: <SellingCardDetails />
    },
    {
        name: "Contact Center",
        key: "ContactCenter",
        route: "/contact-center",
        component: <ContactCenter />
    },
    {
        name:"Customer Feedback",
        key:"CustomerFeedback",
        route:"/customer-feedback",
        component:<CustomerFeedback />
    },
    {
        name:"Leader Board",
        key:"LeaderBoard",
        route:"/leader-board",
        component:<LeaderBoard />
    },
    {
        name: "Chat",
        key:"chat",
        route:"/chat",
        component:<ChatNew />
    },
    {
      name: "Digital Business Center",
      key: "DigitalBusinessCenter",
      route: "/digital-business-center/:id",
      component: <DigitalBusinessCenter />
    },
    {
        name:"My Commissions",
        key:"MyCommissions",
        route:"/my-commissions",
        component:<UserCommission />
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        component:<CreateBugTicketForm/>
    },
    {
        name: "Selling Card Details",
        key: "SellingCardDetails",
        route: "manage-training-modules/selling-card-details/:trainingModuleId",
        component: <SellingCardDetailsNew />
    },
    {
        name: "Training Module Details",
        key: "TrainingModuleDetails",
        route: "manage-training-modules/training-module-details/:trainingModuleId",
        component: <SellingCardDetailsNew />
    },
    {
        name: "Sales Reporting Page",
        key: "SalesReportingPage",
        route: "/sales-reporting-page",
        component: <SalesReportingPage />
    },
    {
        name: "Sales Reporting Page",
        key: "SalesReportingPage",
        route: "/general-sales-reporting-page",
        component: <SalesGeneralReport />
    },
    {
        name: "Offers Reporting Page",
        key: "OffersReportingPage",
        route: "/offers-reporting-page",
        component: <OffersReport />
    },
    {
        name:"My Calendar",
        key:"MyCalendar",
        route:"/my-calendar",
        component: <MyCalendar />
    },
    {
        name:"Announcements Board",
        key:"AnnouncementsBoard",
        route:"/announcements-board",
        component:<Announcements/>
    }
       

]

export default RepRoutes