import React, { useState } from 'react'
import authorized from './Images/authorized.png'
import authorizedFastwyre from './Images/authorizedFastwyre.png'
import { Modal } from '@mui/material'
import { IoCloseCircle } from 'react-icons/io5'
import FiberPlanSelector from '../../../../../components/FiberPlanSelector'
import Swal from 'sweetalert2'
import UpdateCustomerInstallDateReq from '../../../../../Requests/Customer/UpdateCustomerInstallDateReq'
import CustomerApi from '../../../../../API/CustomerApi'
import CreateRescheduleFormReq from '../../../../../Requests/Customer/CreateRescheduleFormReq'
const ScheduleForm = ({ open, setOpen, lead, fidiumCustomer, setReload }) => {

    const [rescheduleFrom, setRescheduleFrom] = useState('')
    const [installTimeFrom, setInstallTimeFrom] = useState('')
    const [installTimeTo, setInstallTimeTo] = useState('')
    const [selectedTimeRange, setSelectedTimeRange] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [installPlan, setInstalltPlan] = useState(0)

    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const updateInstallDate = async () => {

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Install Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        // the day should be the same in the install date and install date to and the time in the install date should be less than the time in the install date to
        if (!rescheduleFrom) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Date",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!installTimeFrom || !installTimeTo) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Time",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        const req = new UpdateCustomerInstallDateReq();
        req.customerId = fidiumCustomer.id;
        const [year, month, day] = rescheduleFrom.split('-').map(Number);

        // Create a new date object with the desired year, month, and day
        const installDate = new Date(year, month - 1, day);
        let from = formatDateToISOString(new Date(installDate.setHours(installTimeFrom.split(':')[0], installTimeFrom.split(':')[1])))
        let to = formatDateToISOString(new Date(installDate.setHours(installTimeTo.split(':')[0], installTimeTo.split(':')[1])))
        req.installDateTime = from;
        req.installDateTimeTo = to;
        req.promo = promoCode;
        const res = await CustomerApi.EditCustomerInstallDate(req);
        if (res?.status?.success) {
            setOpen(false)
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Install Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }

    const submitReschedule = async () => {

        setOpen(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Reschedule Request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!rescheduleFrom) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Date",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }




        if (!installTimeFrom || !installTimeTo) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Time",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lead?.fidiumCustomer) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There is no customer associated with this lead",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateRescheduleFormReq();
        req.FidiumCustomerId = fidiumCustomer.id;
        console.log(rescheduleFrom)
        const [year, month, day] = rescheduleFrom.split('-').map(Number);

        // Create a new date object with the desired year, month, and day
        const installDate = new Date(year, month - 1, day);

        // resshcedule from should be atleast 7 days from the current date
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + (fidiumCustomer?.fiberCompanyId === 9 ? 13 : 6));
        let selectedFromDate = new Date(installDate.setHours(installTimeFrom.split(':')[0], installTimeFrom.split(':')[1]))

        if (selectedFromDate < minDate) {
            Swal.fire({
                icon: "error",
                title: "Invalid Date",
                text: `Please select a date that is at least ${fidiumCustomer?.fiberCompanyId === 9 ? 14 : 7} days from today.`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }


        let from = formatDateToISOString(new Date(installDate.setHours(installTimeFrom.split(':')[0], installTimeFrom.split(':')[1])))
        let to = formatDateToISOString(new Date(installDate.setHours(installTimeTo.split(':')[0], installTimeTo.split(':')[1])))

        req.NewInstallDate = from;
        req.NewInstallDateTo = to;
        const res = await CustomerApi.RescheduleForm(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Reschedule Request Submitted Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    return (
        <Modal setOpen={setOpen} open={open} onClose={() => {
            setOpen(false)
        }} >
            {
                lead?.fidiumCustomer?.installDateTime ?
                    <div className='custom_modal'>
                        <IoCloseCircle onClick={() => {
                            setOpen(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        {
                            fidiumCustomer?.fiberCompanyId === 2
                            &&
                            <img alt="Logo" src={authorized} className='logo' />
                        }
                        {
                            fidiumCustomer?.fiberCompanyId === 9
                            &&
                            <img alt="Logo" src={authorizedFastwyre} className='logo' />
                        }

                        {/* <h1 >Install Date Time</h1> */}
                        <label>Reschedule Date</label>
                        <input type="date" value={rescheduleFrom} onChange={(e) => {
                            setRescheduleFrom(e.target.value)
                        }
                        } />
                        <label>Intall Time Slot:</label>
                        <div className='time_slot'>
                            <input type='time' value={installTimeFrom} onChange={(e) => {
                                setInstallTimeFrom(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                            <p>To</p>
                            <input type='time' value={installTimeTo} onChange={(e) => {
                                setInstallTimeTo(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                        </div>
                        <p className='or'>OR</p>
                        <div className='am_pm'>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("AM");
                                    setInstallTimeFrom("08:00")
                                    setInstallTimeTo("12:00")
                                }
                            } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM
                                <br />
                                <span className=' text-xs'>8:00am - 12:00pm</span>
                            </p>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("PM");
                                    setInstallTimeFrom("12:00")
                                    setInstallTimeTo("16:00")
                                }
                            } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM
                                <br />
                                <span className=' text-xs'>12:00pm - 4:00pm</span>
                            </p>
                        </div>
                        <button
                            onClick={() => {
                                submitReschedule()
                            }
                            }>SUBMIT RESCHEDULE REQUEST</button>
                        <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                    </div>
                    :
                    <div className='custom_modal'>
                        <IoCloseCircle onClick={() => {
                            setOpen(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        {
                            fidiumCustomer?.fiberCompanyId === 2
                            &&
                            <img alt="Logo" src={authorized} className='logo' />
                        }
                        {
                            fidiumCustomer?.fiberCompanyId === 9
                            &&
                            <img alt="Logo" src={authorizedFastwyre} className='logo' />
                        }
                        {/* <h1 >Install Date Time</h1> */}
                        <label>Install Date</label>
                        <input type="date" value={rescheduleFrom} onChange={(e) => {
                            setRescheduleFrom(e.target.value)
                        }
                        } />
                        <label>Intall Time Slot:</label>
                        <div className='time_slot'>
                            <input type='time' value={installTimeFrom} onChange={(e) => {
                                setInstallTimeFrom(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                            <p>To</p>
                            <input type='time' value={installTimeTo} onChange={(e) => {
                                setInstallTimeTo(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                        </div>
                        <p className='or'>OR</p>
                        <div className='am_pm'>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("AM");
                                    setInstallTimeFrom("08:00")
                                    setInstallTimeTo("12:00")
                                }
                            } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM
                                <br />
                                <span className=' text-xs'>8:00am - 12:00pm</span>
                            </p>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("PM");
                                    setInstallTimeFrom("12:00")
                                    setInstallTimeTo("16:00")
                                }
                            } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM
                                <br />
                                <span className=' text-xs'>12:00pm - 4:00pm</span>
                            </p>
                        </div>
                        {/* <FiberCompanySelector setUser={setInstallCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" /> */}
                        {/* <FiberPlanSelector setUser={setInstalltPlan} placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={fidiumCustomer?.fiberCompanyId} /> */}
                        <div className='promos'>
                            <p>Promos</p>
                            <div className='promo_options'>
                                {
                                    fidiumCustomer?.fiberCompany?.offerTemplates[0]?.promos?.split('\n').map((promo, index) => {
                                        return (
                                            <label htmlFor={`free` + index} key={index} className='promo'>
                                                <input onChange={
                                                    (e) => {
                                                        let arr = promoCode.split('\n')
                                                        if (arr.includes(promo)) {
                                                            arr = arr.filter(item => item !== promo)
                                                        }
                                                        else {
                                                            arr.push(promo)
                                                        }
                                                        setPromoCode(arr.join('\n'))
                                                    }
                                                } type="checkbox" id={`free` + index} />
                                                <sapn>{promo}</sapn>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <button
                            onClick={() => {
                                updateInstallDate()
                            }
                            }>SCHEDULE INSTALL</button>
                        <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                    </div>
            }
        </Modal>
    )
}

export default ScheduleForm