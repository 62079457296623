import { useState } from 'react'
import Swal from 'sweetalert2'
import CustomerApi from '../../../../API/CustomerApi'

export default function AddCustomFieldForm({ onSuccess }) {
  const [name, setName] = useState('')
  const [dataType, setDataType] = useState('')
  const [forAttribute, setForAttribute] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    Swal.fire({
        title: "Please Wait",
        text : "Creating Custom Field",
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
            Swal.showLoading();
        },
    });

    if(name === ''){
        Swal.fire({
            title: "Error",
            text : "Name is required",
            icon: 'error'
        });
        return;
    }

    if(dataType === ''){
        Swal.fire({
            title: "Error",
            text : "Data Type is required",
            icon: 'error'
        });
        return;
    }

    if(forAttribute === ''){
        Swal.fire({
            title: "Error",
            text : "For Attribute is required",
            icon: 'error'
        });
        return;
    }

    const req  = {
        name : name,
        dataType : dataType,
        for : forAttribute
    }

    const res = await CustomerApi.CreateCustomAttribute(req)
    if(res.status.success){
        Swal.fire({
            title: "Success",
            text : "Custom Field Created",
            icon: 'success',
        });
    }else{
        Swal.fire({
            title: "Error",
            text : res.status.message,
            icon: 'error'
        });
    }



    if (onSuccess) {
      onSuccess()
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Field Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="Enter field name"
        />
      </div>
      <div>
        <label htmlFor="dataType" className="block text-sm font-medium text-gray-700 mb-1">Data Type</label>
        <select
          id="dataType"
          name="dataType"
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
          required
          className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select Data Type</option>
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="date">Date</option>
          <option value="boolean">Boolean</option>
        </select>
      </div>
      <div>
        <label htmlFor="forAttribute" className="block text-sm font-medium text-gray-700 mb-1">For Attribute</label>
        <select
          id="forAttribute"
          name="forAttribute"
          value={forAttribute}
          onChange={(e) => setForAttribute(e.target.value)}
          required
          className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select For Attribute</option>
          <option value="Customer">Customer</option>
          <option value="Lead">Lead</option>
          <option value="Order">Order</option>
        </select>
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Custom Field
      </button>
    </form>
  )
}